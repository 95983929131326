import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export class Disclaimer extends Component {
  render() {
    return (
      <>
        <section className="disclaimer_section-conatiner">
          <Container className="disclaimer">
            <hr width="100%" />
            <h5 className="disc-title">
              Disclaimer:-
            </h5>
            <ul className="disc-point">
              <li className="disc">
                All reasonable care has been taken to ensure that the information contained herein is not misleading or untrue at the time of publication. However, we make no long standing commitment as to its accuracy or completeness.
                Nothing contained herein should be construed as an offer to buy or sell or a solicitation of an offer to buy or sell. The value of any investment may fall as well as rise. Investors are advised to avail personal counseling from our research or dealing desk if they wish for any further clarity or clarifications in the understanding on various aspects of investment.
              </li>
              <li className="disc">
                Mutual Fund investments are subject to market risks. Please read all the scheme relates documents carefully before investing. Past performance of the schemes is neither an indicator nor a guarantee of future performance.
                <a href="/disclaimer" target="_blank" rel="noopener noreferrer"> Read More</a>
              </li>
            </ul>
            <hr width="100%" />
          </Container>
        </section>
      </>
    )
  }
}

export default Disclaimer