import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import choice from '../Equity/Icons/choice.svg'

function How() {
    return (
        <>
            <section className="howSectionContainer">
                <Container className='fiidiihowSection'>
                    <div className="paraTitle">
                        <h1 className="title">
                            How works <span>FII</span> and <span>DII</span>?
                        </h1>
                    </div>
                    <Row className='howRows'>
                        <Col lg={7} md={12} sm={12} xs={12} className='howTxt'>
                            <div className="contentContainer">
                                <p>
                                    Foreign Institutional Investors (FIIs) and Domestic Institutional Investors (DIIs) play a significant role in the financial markets. Here's how they work:
                                </p>
                                <p>
                                    <strong>Foreign Institutional Investors (FIIs):</strong> FIIs are institutional investors from foreign countries that invest in the Indian financial markets. They include mutual funds, pension funds, and other entities. FIIs bring in foreign capital, which can impact the stock and bond markets. Their investments are closely monitored by regulators.
                                </p>
                                <p>
                                    <strong>Domestic Institutional Investors (DIIs):</strong> DIIs are institutional investors based in India. They comprise mutual funds, insurance companies, and other domestic financial institutions. DIIs play a crucial role in stabilizing the markets during volatile periods. They invest in various asset classes and provide liquidity to the markets.
                                </p>
                                <p>
                                    The interaction between FIIs and DIIs, along with retail investors, influences market movements. Changes in their investment patterns can impact stock prices, indices, and overall market sentiment.
                                </p>
                            </div>
                        </Col>
                        <Col lg={5} md={12} sm={12} xs={12} className='howImg'>
                            <img src={choice} alt="Growth Illustration" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default How;
