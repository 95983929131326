import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FVCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [rate, setRate] = useState('');
  const [years, setYears] = useState('');
  const [futureValue, setFutureValue] = useState('');

  const handlePrincipalChange = (e) => {
    setPrincipal(e.target.value);
  };

  const handleRateChange = (e) => {
    setRate(e.target.value);
  };

  const handleYearsChange = (e) => {
    setYears(e.target.value);
  };

  const calculateFutureValue = () => {
    const P = Number(principal);
    const r = Number(rate) / 100;
    const t = Number(years);
    const FV = P * Math.pow(1 + r, t) * 10;
    const formattedFV = new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(Math.round(FV));
    setFutureValue(`₹${formattedFV}`);
  };
  
  
  return (
    <>
      <Helmet>
        <title>Growth Securities || FV || Future Value Calculator</title>
      </Helmet>
      <section className="allCalcBack">
        <Container className="allCalcScss">
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className="fa fa-home" /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className="fa fa-calculator" /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Future Value</span>
            </li>
          </ul>
          <div className="fv-calculator calcBody">
            <div className="calculator-container">
              <h2>Future Value Calculator</h2>
              <div className="input-group">
                <label htmlFor="principal">Principal Amount (₹)</label>
                <input
                  type="number"
                  id="principal"
                  value={principal}
                  onChange={handlePrincipalChange}
                  placeholder="Enter principal amount"
                  min="0"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="rate">Annual Interest Rate (%)</label>
                <input
                  type="number"
                  id="rate"
                  value={rate}
                  onChange={handleRateChange}
                  placeholder="Enter interest rate"
                  min="0"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="years">Number of Years</label>
                <input
                  type="number"
                  id="years"
                  value={years}
                  onChange={handleYearsChange}
                  placeholder="Enter number of years"
                  min="0"
                  required
                />
              </div>
              <button className="calculate-btn" onClick={calculateFutureValue}>
                Calculate
              </button>
              {futureValue && (
                <div className="result">
                  <p>Future Value: {futureValue}</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  The Future Value (FV) Calculator helps you determine the value of an investment or savings account in the future based on the initial principal amount, 
                  annual interest rate, and the number of years of investment.
                </p>
                <p className="disc">
                  By using this calculator, you can estimate the future value of your investment and understand how compounding interest can impact your savings over time.
                </p>
              </div>
              <div className="formulaSec">
                <h2>Formula for Future Value (FV)</h2>
                <p className="disc">
                  The future value of an investment can be calculated using the following formula:
                </p>
                <h6>FV = P + (P * r * t)</h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>P: Principal Amount (Initial Investment)</li>
                  <li>r: Annual Interest Rate (as a decimal)</li>
                  <li>t: Number of Years</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FVCalculator;
