import React, { Component } from 'react';
import './Style.scss';
import {
    Col,
    Container,
    Row,
    Table
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import data from '../Icons/data.svg';
import investorData from './investorData.json';
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'



export class Data extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Growth Securities || Investor Complaints Data</title>
                </Helmet>
                <div className="data-container">
                    <AnimatedSection>
                        <Container className="data">
                            <Row className="dataRows">
                                <Col lg={12} md={12} sm={12} className="data-img">
                                    <img className='imgData' src={data} alt="Growth data illustration" width="100%" />
                                </Col>
                                <Col lg={12} md={12} sm={12} className="data-text">
                                    <div className="tableContainer">
                                        <Table striped bordered hover className="investors-data">
                                            <thead>
                                                <tr className="sticky-header">
                                                    <th>#</th>
                                                    <th>FILENAME</th>
                                                    <th>DOWNLOAD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {investorData.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>{item.filename}</td>
                                                        <td>
                                                            <a href={require(`./Files/${item.downloadLink}`)} download>
                                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Download
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </AnimatedSection>
                </div>
            </>
        );
    }
}

export default Data;
