import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import salient from './Icons/salient.svg'


export class Features extends Component {
    render() {
        return (
            <>
                <section className="feature_section-container">
                    <Container className='feature_section'>
                        <div className="paraTitle">
                        <h1 className="title">
                            Salient features of our <span>Depository Services</span>
                        </h1>
                        </div>
                        <Row className='feature_section-rows'>
                            <Col lg={4} md={12} sm={12} className='salient-text'>
                                <div className="salient-boxes">
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Safety of Funds
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Protection against Loss or Theft
                                            </li>
                                            <li className="list">
                                                Insured by Government Agencies
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Convenience
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Easy Access to Funds
                                            </li>
                                            <li className="list">
                                                Reduced Risk of Fraud
                                            </li>
                                            <li className="list">
                                                Accessible from Anywhere
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Cost-Effective
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Lower Transaction Costs
                                            </li>
                                            <li className="list">
                                                No need for Physical Cash Handling
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} className='salient-img'>
                                <div className="img">
                                    <img
                                        src={salient}
                                        alt="Growth salient illustration"
                                        className="salient-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} className='salient-text'>
                                <div className="salient-boxes">
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Increased Liquidity
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Ability to Easily Transfer Funds
                                            </li>
                                            <li className="list">
                                                Faster Processing Time
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Record Keeping
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Accurate and Up-to-date Records
                                            </li>
                                            <li className="list">
                                                Easily Accessible Transaction History
                                            </li>
                                            <li className="list">
                                                Reduced Risk of Errors
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="salient-box">
                                        <h5 className="head">
                                            Professional Management
                                        </h5>
                                        <ul className="lists">
                                            <li className="list">
                                                Experienced Investment Management
                                            </li>
                                            <li className="list">
                                                Diversification of Investments
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Features