import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ai from '../Home/Icons/ai.jpg'
import algotrade from './Icons/algotrade.jpg'
import autotrade from './Icons/Images/algotrade2.jpg'
import hft from './Icons/Images/hft.jpg'
import hft2 from './Icons/Images/hft2.jpg'


export class AI extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Blogs || How AI Impacting the Stock Market || Artificial Intelligence || Growth Blogs
                    </title>
                </Helmet>
                <section className="blogSection-container">
                    <div className="backgroundClr"></div>
                    <Container className="blogContainer">
                        <Row className="blogs-rows">                   
                            <Col lg={8} md={12} sm={12} className='blog'>
                                <div className="myBlogs">
                                    <div className="links">
                                        <a href="/high-frequency-trading" className="link">High Frequency Trading</a>
                                        <a href="algorithmic-trading" className="link padding">Algorithmic Trading</a>
                                        <a href="initial-public-offering-ipo" className="link padding">IPOs</a>
                                    </div>
                                    <h1 className="blogTitle">
                                        How AI Impacting the Stock Market?
                                    </h1>
                                    <h4 className="blogSubtitle">
                                        AI trading is a growing part of the fintech industry. These companies are paving the way.
                                    </h4>
                                    <h6 className="date">
                                        Saturday Jan 25<sup>th</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                                    </h6>
                                    <div className="divides">
                                        <h6 className="time">
                                        5minutes to read <i className="fa fa-clock-o" aria-hidden="true"></i>
                                        </h6>
                                        <h6 className="Author">
                                            ✍ Growth
                                        </h6>
                                    </div>
                                    <img
                                        src={ai}
                                        alt="Growth Blog Illustration"
                                        width='100%'
                                        className="blogImage"
                                    />
                                    <h6 className="image">
                                        Image: <span>Freepik</span>
                                    </h6>
                                    <p className="disc bigFont">
                                        <span>A</span>rtificial intelligence is a game changer for the stock market.
                                    </p>
                                    <p className="disc">
                                        AI trading companies use various tools in the AI wheelhouse — like machine learning, sentiment analysis and algorithmic predictions — to interpret the financial market, use data to calculate price changes, identify reasons behind price fluctuations, carry out sales and trades and monitor the ever-changing market.                             </p>
                                    <p className="disc">
                                        There are several types of AI trading: quantitative trading, <a href="/algorithmic-trading" className="link">algorithmic trading</a>, <a href="/high-frequency-trading" className="link">high-frequency trading</a> and <a href="/algorithmic-trading" className="link">automated trading</a>.
                                    </p>
                                    <p className="disc">
                                        Quantitative trading, also called quant trading, uses quantitative modeling to analyze the price and volume of stocks and trades, identifying the best investment opportunities.
                                    </p>
                                    <h3 className="whatIs">
                                        What Is AI Trading?
                                    </h3>
                                    <hr width='100%' />
                                    <p className="disc definition">
                                        AI trading refers to the use of artificial intelligence, predictive analytics and machine learning to analyze historical market and stock data, get investment ideas, build portfolios and automatically buy and sell stocks.
                                    </p>
                                    <h3 className="whatIs benefits">
                                        Benefits of AI Stock Trading
                                    </h3>
                                    <hr width='100%' />
                                    <p className="disc">
                                        AI trading can cut research time and improve accuracy, predict patterns and lower overhead costs.
                                    </p>
                                    <h4 className="subtitle">
                                        REDUCING RESEARCH TIME AND IMPROVING ACCURACY
                                    </h4>
                                    <p className="disc">
                                        AI trading automates research and data-driven decision making, which allows investors to spend less time researching and more time overseeing actual trades and advising their clients. One survey found that traders who used <a href="/algorithmic-trading" className="link">automated trading</a> increased productivity by 10 percent.
                                    </p>
                                    <p className="disc">
                                        And because AI trading uses historical financial data to inform decisions, there is less risk for human error and more room for accuracy.
                                    </p>
                                    <h4 className="subtitle">
                                        PREDICTING PATTERNS
                                    </h4>
                                    <p className="disc">
                                        Using sentiment analysis, which is the process of gathering text and linguistics and using natural language processing to identify patterns within subjective material, an AI trading system can gather information from news outlets and social media to determine market swings.
                                    </p>
                                    <h4 className="subtitle">
                                        LOWERING COSTS
                                    </h4>
                                    <p className="disc">
                                        Traditional investment firms might have hundreds of brokers, analysts and advisors working under them, but AI trading technology can replicate some of the repetitive tasks people have to do. There may be costs to implement and maintain AI, but over time firms and investors can spend less money on overhead expenses. Plus, AI algorithms can work continuously and monitor the stock market 24 hours a day.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4} md={0} sm={0} className='blogSide'>
                                <div className="allBlogs right">
                                    <a className="blogPost" href='/blog-algorithmic-trading'>
                                        <img src={algotrade} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                                5 Reasons to Choose Algorithmic Trading for Your Investment Strategy in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/blog-automated-trading'>
                                        <img src={autotrade} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                            See How Automated Trading Influence the Market Efficiency & its Impact on Market Efficiency in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/more-about-high-frequency-trading'>
                                        <img src={hft} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                            Everything you must know about High-Frequency Trading in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/blog-high-frequency-trading'>
                                        <img src={hft2} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                            Exploring the Rise of High-Frequency Trading: Why Investors are drawn to it.
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/artificial-intelligence-trading'>
                                        <img src={ai} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                            AI trading is a growing part of the fintech industry. These companies are paving the way.
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default AI