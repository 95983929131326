import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./Style.scss";

const AccountForm = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (mobileNumber.length === 10) {
      // Redirect to the next page
      window.location.href = "https://kyc.growthsec.com";
    }
  };

  return (
    <>
      <div className="dematAcnt">
        <form
          className="numForm"
          action="/your-backend-endpoint"
          method="post"
          onSubmit={handleSubmit}
        >
          <h4>
            Open a <span>#Free</span> Demat Account
          </h4>
          <div className="form-row align-items-center">
            <div className="input-container">
              <Form.Group
                className="mb-3 inputLine"
                controlId="exampleForm.ControlInput1"
              >
                <i className="fa fa-phone"></i>
                <span>+91</span>
                <Form.Control
                  type="number"
                  placeholder="Enter Mobile Number"
                  className="input-field"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Form.Group>
              <Button
                type="submit"
                className={`animated-button ${submitted ? "submitted" : ""}`}
                disabled={mobileNumber.length !== 10}
                onClick={handleSubmit}
              >
                Get OTP
              </Button>
            </div>
            <a
              href="/terms-conditions"
              className="tc"
              target="_blank"
              rel="noopener noreferrer"
            >
              T&amp;C Apply
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default AccountForm;
