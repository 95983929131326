import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import mutualfund from './Icons/Services/mutualfund.svg'
import hft from './Icons/Services/hft.svg'
import commodity from './Icons/Services/commodity.svg'
import derivativetrade from './Icons/Services/derivativetrade.svg'
import ipo from './Icons/Care/horn.svg'
import savings from './Icons/Services/savings.svg'
import currencytrade from './Icons/Services/currencytrade.svg'
import etf from './Icons/Services/etf.svg'


export default function Services() {
    return (
        <>
            <section className='service_section-container growth-products'>
                <Container className='service_section'>
                    <div className="paraTitle">
                        <h1 className='title'>
                            Our <span>Products</span> & <span>Services</span>
                        </h1>
                    </div>
                    <Row className='service_section-rows'>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-8'>
                            <div className="outer_service-card">
                                <a href='/algorithmic-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={etf}
                                                alt='Growth exchange traded fund illustration'
                                                style={{ paddingBottom: "19px" }}
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Algo Trading
                                            </h4>
                                            <p className='disc'>
                                                Harnessing automation for executing trades based on predefined rules to tap market opportunities.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-5'>
                            <div className="outer_service-card">
                                <a href='/high-frequency-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={hft}
                                                alt='Growth high frequency trading illustration'
                                                height='123px'
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                HF Trading
                                            </h4>
                                            <p className='disc'>
                                                Leveraging advanced algorithms to conduct rapid and high-volume trades with exceptional accuracy & results.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-1'>
                            <div className="outer_service-card">
                                <a href='/equity-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={savings}
                                                alt='Growth savings illustration'
                                                style={{ width: "123px" }}
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Equity Trading
                                            </h4>
                                            <p className='disc'>
                                                Buying & selling the shares of listed companies on Indian Exchanges.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-6'>
                            <div className="outer_service-card">
                                <a href='/derivative-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={derivativetrade}
                                                alt='Growth derivative trading illustration'
                                                width='100%'
                                                height='123px'
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Derivative Trading
                                            </h4>
                                            <p className='disc'>
                                                Trading in F&O contracts available on NSE & BSE of India.
                                            </p>
                                        </div>
                                        <div className="imgTxt"></div>
                                    </div>
                                    <div className="imgCard"></div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-7'>
                            <div className="outer_service-card">
                                <a href='/currency-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={currencytrade}
                                                alt='Growth currency trading illustration'
                                                width=""
                                                style={{ width: "123px" }}
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Currency Trading
                                            </h4>
                                            <p className='disc'>
                                                Trade in USD and other foreign currencies & hedge yourself.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-2'>
                            <div className="outer_service-card">
                                <a href='/commodity-trading'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={commodity}
                                                alt='Growth commodity trading illustration'
                                                style={{ width: "123px" }}

                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Commodity Trading
                                            </h4>
                                            <p className='disc'>
                                                Trading made easy in global commodities like precious metals, agri-products, energy, etc.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-4'>
                            <div className="outer_service-card">
                                <a href='/mutual-fund'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img'
                                                src={mutualfund}
                                                alt='Growth mutual fund illustration'
                                                width=""
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                Mutual Funds
                                            </h4>
                                            <p className='disc'>
                                                Start saving now for uncertainties in future with SIP and lumpsum investments.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} xs={12} className='service-card card-3'>
                            <div className="outer_service-card">
                                <a href='/initial-public-offering-ipo'>
                                    <div className="inner_service-card">
                                        <div className="imgCard">
                                            <img
                                                className='card-img img-a'
                                                src={ipo}
                                                alt='Growth ipo illustration'
                                                style={{ width: "120px" }}
                                            />
                                        </div>
                                        <div className="imgTxt">
                                            <h4 className='card-title'>
                                                IPO
                                            </h4>
                                            <p className='disc'>
                                                Invest in IPOs from the comfort of your house with a few clicks.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="fullLine">
                </div>
            </section>
        </>
    )
}
