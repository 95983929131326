import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import choice from '../Equity/Icons/choice.svg'



export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container why-etf">
                    <Container className="why_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why Through <span>Growth</span>?
                        </h1>
                        </div>
                        <h4 className="subtitle">
                            Here are a few reasons why you should consider relying on Growth ETFs for your investment portfolio.
                        </h4>
                        <Row className="why_section-rows">
                            <Col lg={7} md={12} sm={12} className="why_section-text">
                                <div className="texts">
                                    <p className="disc">
                                        Growth ETFs offer investors a convenient and cost-effective way to gain exposure to high-growth potential companies. With their low costs, ease of use and potential for high returns, GrowthETFs can be valuable to any investment portfolio. Before investing in any ETF, it is essential to carefully consider your investment goals, risk tolerance, and the specific details of the ETF, including its investment strategy and holdings.
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Diversification
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        By investing in a Growth ETF, you can gain exposure to a diverse portfolio of growth-oriented companies, reducing the risk associated with a single-stock investment.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Potential for High Returns
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Companies with high growth potential are often innovative and rapidly expanding, leading to the potential for high returns. Growth ETFs aim to capture these returns by investing in such companies.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Liquidity
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        ETFs are traded on stock exchanges and can be bought and sold just like individual stocks, making them highly liquid. This allows for quick and easy access to cash in an emergency or if you need to re-balance your portfolio.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Low Costs
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        ETFs are often cheaper than actively managed funds, as they typically have lower management fees and fewer transaction costs. This can add up over time, leading to higher returns for the investor.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                    Convenient
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                    ETFs are simple and convenient to invest in, making it easy for even the most inexperienced investors to access a diversified portfolio of growth-oriented companies.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="why_section-img">
                                <div className="imgs">
                                    <img
                                        src={choice}
                                        alt="Growth choice illustration"
                                        className="why-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why