import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';


const SIPCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState('');
  const [expectedReturn, setExpectedReturn] = useState('');
  const [maturityValue, setMaturityValue] = useState('');
  const [timePeriod, setTimePeriod] = useState(1);
  const [estimatedReturns, setEstimatedReturns] = useState('');

  const handleCalculation = () => {
    const P = parseFloat(monthlyInvestment);
    const r = parseFloat(expectedReturn) / 100 / 12;
    const t = parseFloat(timePeriod);

    const maturityValueResult = P * (((Math.pow(1 + r, t * 12) - 1) / r) * (1 + r));
    const totalInvestment = P * t * 12;
    const estimatedReturnsResult = maturityValueResult - totalInvestment;

    setMaturityValue(maturityValueResult.toLocaleString());
    setEstimatedReturns(estimatedReturnsResult.toLocaleString());
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(parseFloat(e.target.value));
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || SIP || Systematic Investment Plan
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>SIP</span>
            </li>
          </ul>
          <div className="sip-calculator calcBody">
            <div className="calculator-container">
              <h2>SIP</h2>
              <div className="input-group">
                <label>Monthly Investment Amount:</label>
                <input
                  type="number"
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Expected Annual Return (%):</label>
                <input
                  type="number"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Time Period (in years):</label>
                <input
                  type="range"
                  min={1}
                  max={50}
                  value={timePeriod}
                  onChange={handleTimePeriodChange}
                />
                <span>{timePeriod} years</span>
              </div>
              <button onClick={handleCalculation}>Calculate</button>
              {maturityValue && (
                <div className="result">
                  <p>Invested Amount: {Math.round(monthlyInvestment * timePeriod * 12).toLocaleString()}</p>
                  <p>Estimated Returns: {estimatedReturns}</p>
                  <p>Total Value: {maturityValue}</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  A Systematic Investment Plan (SIP) calculator is a simple tool that allows individuals to get an idea of the returns on their mutual fund investments made through SIP. SIP investments in mutual funds have become one of the most popular investment options for millennials lately.
                </p>
                <p className="disc">
                  These mutual fund SIP calculator is designed to give potential investors an estimate on their mutual fund investments. However, the actual returns offered by a mutual fund scheme varies depending on various factors. The SIP calculator does not provide clarification for the exit load and expense ratio (if any).
                </p>
              </div>
              <div className="formulaSec">
                <h2>How to calculate SIP?</h2>
                <p className="disc">
                  The formula used for calculating the maturity value of a SIP investment is:
                </p>
                <h6>
                  M = P × ( ( (1 + r)^(t * 12) - 1 ) / r ) × (1 + r)
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>M -</span> Maturity Value of SIP
                  </li>
                  <li>
                    <span>P -</span> Monthly Investment Amount
                  </li>
                  <li>
                    <span>r -</span> Monthly Expected Return Rate (as a decimal)
                  </li>
                  <li>
                    <span>t -</span> Time Period in years
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SIPCalculator;
