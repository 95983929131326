import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import choice from '../Equity/Icons/choice.svg'




export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container depository-why">
                    <Container className="why_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why should you choose <span>Growth </span> for your <span>Depository Services</span>?
                        </h1>
                        </div>
                        <Row className="why_section-rows">
                            <Col lg={6} md={12} sm={12} className="why_section-texts">
                                <div className="text">
                                    <p className="disc">
                                        Growth is an excellent choice for anyone looking for a secure and convenient way to hold their securities. With robust security measures, easy access, experienced professionals, and cost-effective services, Growth Securities is the ideal choice for your depository services needs. 
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                    Robust Security Measures
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                    Security is a significant concern for investors, and Growth Securities takes this very seriously. The company has implemented multiple layers of security to ensure the safety of your securities. Your securities are stored in a secure and encrypted electronic form, and access to the depository is restricted to authorized personnel only. In addition, Growth Securities uses cutting-edge technology to monitor for any suspicious activity and prevent any unauthorized access.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                    Convenient Access
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                    Growth provides its clients with easy and convenient access to their securities. You can view your securities portfolio and transactions online and receive updates on your securities through email or SMS alerts. With Growth Securities, you will have the peace of mind from knowing that your securities are always within reach.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                    Experienced Team
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                    Growth has a team of experienced professionals who deeply understand the stock market and the needs of investors. The team is always available to assist clients with any questions or concerns. Whether you are an experienced investor or just starting, Growth Securities has the expertise to help you achieve your investment goals.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                    Cost-Effective Services
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                    Growth offers its depository services at a competitive price, making it accessible to a broader range of investors. The company also offers flexible pricing options to suit each client's specific needs. With Growth, you can be sure you are getting the best value for your money.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="why_section-imgs">
                                <div className="img">
                                    <img
                                        src={choice}
                                        alt="Growth choice illustration"
                                        className="depository_img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why