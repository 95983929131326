import React, { Component } from "react";
import "./Style.scss";
import Header from "./Header";
import WhatIs from "./WhatIs";
import { Helmet } from "react-helmet";
import Why from "./Why";
import Features from "./Features";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

export class Depository extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Growth Securities || Depository Service || What is my DP name?
          </title>
        </Helmet>
        <div className="wrapper depository">
          <AnimatedSection>
            <Header />
          </AnimatedSection>
          <AnimatedSection>
            <WhatIs />
          </AnimatedSection>
          <AnimatedSection>
            <Why />
          </AnimatedSection>
          <AnimatedSection>
            <Features />
          </AnimatedSection>
        </div>
      </>
    );
  }
}

export default Depository;
