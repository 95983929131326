import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import zero from '../../Home/Icons/Care/zero.jpeg'

function ZeroBrokerage() {
    
    return (
        <>
            <div className="zeroBrokerageSection">
                <Container className='zeroBrokerage'>
                    <Row className='zeroBrokerageRows'>
                        <h2 className="subtitle">
                            Equity Delivery Zero Brokerage Offer
                        </h2>
                        <Col lg={4} md={12} sm={12} className='zeroBrokerageImg'>
                            <div className="imgContainer">
                                <img src={zero} alt="zero" className="zeroImg" width='100%' />
                            </div>
                        </Col>
                        <Col lg={8} md={12} sm={12} className='zeroBrokerageTxt term-text'>
                            <div className="txtContainer">
                                <ul className='brokerageLists'>
                                    <li className="brokerageList">
                                        Equity Delivery Brokerage: NIL (Rs. ZERO)
                                    </li>
                                    <li className="brokerageList">
                                        Equity Intraday / Future Brokerage: Rs.7/- per executed order or 0.02%, whichever is lower.
                                    </li>
                                    <li className="brokerageList">
                                        Options Brokerage: Rs.7/- per lot.
                                    </li>
                                </ul>
                                <p className="disc">
                                    Applicable terms & conditions:
                                </p>
                                <ul className="brokerageLists">
                                    <li className="brokerageList">
                                        Offer is valid for limited period only for new individual online clients enrolled
                                        between 15-Aug-2023 to 31-March-2024 using Growth - Stocks & Mutual Funds App
                                        available on <a href="https://play.google.com/store/apps/details?id=com.rs.growth&hl=en-IN" title='Android App Store' target="_blank" rel="noopener noreferrer">
                                            Google Playstore
                                        </a> or <a href="https://apps.apple.com/in/app/growth-stocks-mutual-funds/id1603052789" title='Apple App Store' target="_blank" rel="noopener noreferrer">iOS Store</a>
                                    </li>
                                    <li className="brokerageList">
                                        Account shall be opened & activated only after all procedures relating to IPV and
                                        client due diligence are completed in accordance with regulatory guidelines.
                                    </li>
                                    <li className="brokerageList">
                                        Client shall be eligible to trade only after successful transfer of money from Client's
                                        own registered bank account to the designated client bank account of GSPL.
                                    </li>
                                    <li className="brokerageList">
                                        Client agrees to do online trading and in case client calls GSPL for trade, Rs.25/- per
                                        executed order or 2% whichever is lower shall be charged for call and trade facility.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className='zeroBrokerageRows'>
                        <ul className="brokerageLists">
                            <li className="brokerageList">
                                In addition to the trading account, a new demat account shall be opened for all
                                subscribers / new clients. The account opening charges will be zero.
                            </li>
                            <li className="brokerageList">
                                For demat account Rs. ₹500/- shall be applicable as annual maintenance charges
                                (AMC) payable in advance from the beginning of second year. Demat transaction
                                shall be Rs. 12/- per transaction. Pledge creation & closure charges will be ₹18 per
                                PSN per day (+GST)
                            </li>
                            <li className="brokerageList">
                                Client authorizes Growth Securities Private Limited (“GSPL”) to contact him / her
                                including but not restricted to call / SMS / WhatsApp / email or other modes of
                                communication to him / her even though his / her mobile no. may be registered
                                under DND/DNC Registry.
                            </li>
                            <li className="brokerageList">
                                The offer is not applicable for trading in commodities segment.
                            </li>
                            <li className="brokerageList">
                                Investments in securities market are subject to market risks.
                            </li>
                            <li className="brokerageList">
                                Read all the related documents carefully before investing.
                            </li>
                            <li className="brokerageList">
                                Brokerage will not exceed SEBI prescribed limits.
                            </li>
                            <li className="brokerageList">
                                Statutory Charges/Taxes would be levied as applicable.
                            </li>
                            <li className="brokerageList">
                                Company reserves the right to withdraw / edit /modify the offer at any time in
                                future with or without any prior notice to the clients.
                            </li>
                            <li className="brokerageList">
                                For any further information please mail us on <a href="mailto:support@growthsec.com">support@growthsec.com</a>
                            </li>
                        </ul>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ZeroBrokerage;