import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import Advantage from './Icons/Advantages.svg'



export class Advantages extends Component {
    render() {
        return (
            <>
                <section className="advantages-container">
                    <Container className='advantages_section'>
                        <div className="paraTitle">
                        <h1 className="title">
                            Advantages of <span>Mutual Funds</span>
                        </h1>
                        </div>
                        <Row className='advantages_rows'>
                            <Col lg={5} md={12} sm={12} className="why-mutual-img">
                                <img
                                    className='advantages-img'
                                    src={Advantage}
                                    alt="Growth advantages illustration"
                                />
                            </Col>
                            <Col lg={7} md={12} sm={12} className="why-mutual-texts">
                                <div className="mutual-lists">
                                    <ul className="lists">
                                        <li className="list">
                                            Liquidity
                                        </li>
                                        <li className="list">
                                            Diversification
                                        </li>
                                        <li className="list">
                                            Expert Management
                                        </li>
                                        <li className="list">
                                            Flexibility to invest in Smaller Amounts
                                        </li>
                                        <li className="list">
                                            Accessibility – Mutual Funds are Easy to Buy
                                        </li>
                                        <li className="list">
                                            Schemes for Every Financial Goals
                                        </li>
                                    </ul>
                                    <ul className="lists">
                                        <li className="list">
                                            Safety and Transparency
                                        </li>
                                        <li className="list">
                                            Lower cost
                                        </li>
                                        <li className="list">
                                            Best Tax Saving Option
                                        </li>
                                        <li className="list">
                                            Lowest Lock-in Period
                                        </li>
                                        <li className="list">
                                            Lower Tax on the Gains
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Advantages