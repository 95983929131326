import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import "./Style.scss";
import brand from "../Icons/brand.png";
import google from "../Icons/googleplay.png";
import apple from "../Icons/applestore.png";
import Account from "./Account";

const SideNav = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="sideBar-container">
        <button className="sideBtn" onClick={handleShow}>
          <i className="fa fa-bars"></i>
          <i className="fa fa-close"></i>
        </button>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="sideAnime"
          title="sideMenu"
        >
          <Offcanvas.Header closeButton className="header-nav">
            <Offcanvas.Title>
              <a href="/" className="brand" title="home">
                <img
                  src={brand}
                  alt="brand"
                  className="brand-img"
                  width="100%"
                />
              </a>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="sideNav-container">
              <div className="navs">
                <Account />
                <div
                  className="accordion accordion-flush side-nav"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item nav-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <i className="fa fa-info-circle icon"></i>{" "}
                        <span>About</span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div id="about-us" className="back-C">
                          <a
                            href="/about-us"
                            className="nav-link"
                            title="about-us"
                          >
                            About Us
                          </a>
                          <a
                            href="/careers"
                            className="nav-link"
                            title="careers"
                          >
                            Careers
                          </a>
                          <a
                            href="/business"
                            className="nav-link"
                            title="business"
                          >
                            Business
                          </a>
                          <a
                            href="/research"
                            className="nav-link"
                            title="research"
                          >
                            Research
                          </a>
                          <a
                            href="/feedback"
                            className="nav-link"
                            title="feedback"
                          >
                            Feedback
                          </a>
                          <a href="/blogs" className="nav-link" title="blogs">
                            Read Blogs
                          </a>
                          <a
                            href="/contact"
                            className="nav-link"
                            title="contact"
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item nav-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <i className="fa fa-bar-chart icon"></i>{" "}
                        <span>Products</span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div id="products" className="back-C">
                          <a
                            href="/bonds-sgb"
                            className="nav-link"
                            title="bonds-sgb"
                          >
                            Bonds (SGB)
                          </a>
                          <a
                            href="/mutual-fund"
                            className="nav-link"
                            title="mutual-funds"
                          >
                            Mutual Funds
                          </a>
                          <a
                            href="/equity-trading"
                            className="nav-link"
                            title="equity-trading"
                          >
                            Equity Trading
                          </a>
                          <a
                            href="/currency-trading"
                            className="nav-link"
                            title="currency-trading"
                          >
                            Currency Trading
                          </a>
                          <a
                            href="/derivative-trading"
                            className="nav-link"
                            title="derivative-trading"
                          >
                            Derivative Trading
                          </a>
                          <a
                            href="/commodity-trading"
                            className="nav-link"
                            title="commodity-trading"
                          >
                            Commodity Trading
                          </a>
                          <a
                            href="/algorithmic-trading"
                            className="nav-link"
                            title="algorithmic-trading"
                          >
                            Algorithmic Trading
                          </a>
                          <a
                            href="/high-frequency-trading"
                            className="nav-link"
                            title="high-frequency-trading"
                          >
                            High Frequency Trading
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item nav-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <i className="fa fa-cubes icon"></i>{" "}
                        <span>Services</span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div id="services" className="back-C">
                          <a
                            href="/initial-public-offering-ipo"
                            className="nav-link"
                            title="initial-public-offerings-ipo"
                          >
                            IPO
                          </a>
                          <a
                            href="/exchange-traded-fund"
                            className="nav-link"
                            title="exchange-traded-funds"
                          >
                            ETF
                          </a>
                          {/* <a
                            href="/refer"
                            className="nav-link"
                            title="referral"
                          >
                            Refer
                          </a> */}
                          <a
                            href="/support"
                            className="nav-link"
                            title="supports"
                          >
                            Supports
                          </a>
                          <a
                            href="/depository"
                            className="nav-link"
                            title="depository-participant"
                          >
                            Depository
                          </a>
                          <a
                            href="/fund-transfer"
                            className="nav-link"
                            title="fund-transfer"
                          >
                            Fund Transfer
                          </a>
                          <a
                            href="/bankdetails"
                            className="nav-link"
                            title="bank-dp-details"
                          >
                            Bank DP Details
                          </a>
                          <a
                            href="/knowledgeCenter"
                            className="nav-link"
                            title="knowledgeCenter"
                          >
                            Knowledge Center
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item nav-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        <i className="fa fa-cogs icon"></i> <span>Tools</span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div id="services" className="back-C">
                          <a
                            href="/fiidii"
                            className="nav-link"
                            title="FII & DII"
                          >
                            FII & DII Activity
                          </a>
                          <a
                            href="/calculators"
                            className="nav-link"
                            title="Growth Calculator"
                          >
                            Calculators
                          </a>
                          <a
                            href="/quizCenter"
                            className="nav-link"
                            title="Growth Quiz"
                          >
                            Quiz Center
                          </a>
                          <a
                            href="/summary"
                            className="nav-link"
                            title="Growth Summary"
                          >
                            Summary
                          </a>
                          {/* <a
                            href="/news"
                            className="nav-link"
                            title="Growth News"
                          >
                            Latest News
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item nav-item last">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        <i className="fa fa-user-circle icon"></i>{" "}
                        <span>Accounts</span>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div id="services" className="back-C">
                          <a
                            href="https://rekyc.growthsec.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                            title="re-activate"
                          >
                            Re-Activate
                          </a>
                          <a
                            href="https://reports.growthsec.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                            title="back office"
                          >
                            Reports
                          </a>
                          <a
                            href="http://rms.growthsec.in:9999/WEBR/#noback"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                            title="risk management system"
                          >
                            RMS
                          </a>
                          <a
                            href="https://growthsec.com/ticketdata"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                            title="admin"
                          >
                            Admin
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr width="100%" />
                <div className="apps">
                  <div className="app google">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.rs.growth&hl=en&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="app-link"
                    >
                      <img
                        src={google}
                        alt="Growth Google"
                        title="Growth on google store"
                        className="google img"
                      />
                    </a>
                  </div>
                  <div className="app apple">
                    <a
                      href="https://apps.apple.com/in/app/growth-stocks-mutual-funds/id1603052789"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="app-link"
                    >
                      <img
                        src={apple}
                        alt="Growth Apple"
                        title="Growth on apple store"
                        className="google img"
                      />
                    </a>
                  </div>
                </div>
                <div className="social-links">
                  <ul className="nav-social-links">
                    <li className="nav-social-link facebook">
                      <a
                        href="https://www.facebook.com/growthsec"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                        title="follow on facebook"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="nav-social-link twitter">
                      <a
                        href="https://www.twitter.com/officialgrowths"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                        title="follow on twitter"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="nav-social-link linkedin">
                      <a
                        href="https://in.linkedin.com/company/growthsec"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                        title="follow on linkedin"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="nav-social-link instagram">
                      <a
                        href="https://instagram.com/growthsecurities?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                        title="follow on instagram"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default SideNav;
