import React, { Component } from "react";
import "./Style.scss";
import { Col, Container, Row } from "react-bootstrap";
import market from "./market.svg";
import { Helmet } from "react-helmet";
import BankDetailsContainer from "./BankDetailsContainer";
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'


export class BankDetails extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Bank Details</title>
        </Helmet>
        <div className="bank_details-container">
          <AnimatedSection>
            <Container className="bank-details">
              <Row className="bank-details-rows">
                <Col lg={12} md={12} sm={12} className="bank-details-img">
                  <img
                    src={market}
                    alt="Growth Market Img"
                    width={"100%"}
                    height="499px"
                  />
                  <ul className="breadcrumb-links">
                    <li className="breadcrumb-link">
                      <i className="fa fa-home" /> <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-link">
                      <i className="fa fa-angle-double-right"></i>
                    </li>
                    <li className="breadcrumb-link">
                      <p className="link">Bank DP Details</p>
                    </li>
                  </ul>
                </Col>
                <Col lg={12} md={12} sm={12} className="bank-details-text">
                  <div className="paraTitle">
                    <h1 className="title">BANK & DP DETAILS</h1>
                  </div>
                  <BankDetailsContainer />
                </Col>
              </Row>
            </Container>
          </AnimatedSection>
        </div>
      </>
    );
  }
}

export default BankDetails;
