import React from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import Header from "./Header";
import CommodityTrade from "./WhatIs";
import Why from "./Why";
import Features from "./Features";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function CommodityTrading() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Commodity Trading || Best Commodity Trading
          Platform in India
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <CommodityTrade />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
        <AnimatedSection>
          <Features />
        </AnimatedSection>
      </div>
    </>
  );
}

export default CommodityTrading;
