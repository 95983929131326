import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import laptop from './Icons/laptop.png'
import Digital from './Icons/Digital.svg'
import security from './Icons/security.svg'
import informed from './Icons/Informed.svg'


export class Platform extends Component {
    render() {
        return (
            <>
                <section className="platform-container">
                    <Container className="platform_section-container">
                        <Row className="platform_section-rows">
                            <Col lg={6} md={12} sm={12} className="platform_section-texts">
                                <h1 className="heading">
                                    Technology driven platform for easy <span>investing</span>
                                </h1>
                                <p className="disc">
                                    Ground Breaking innovations presented with simplified usability,
                                    investing in the stock market has never been this easy.
                                </p>
                                <div className="lists">
                                    <div className="list list_1">
                                        <div className="img">
                                            <img
                                                className='list-img'
                                                src={Digital}
                                                alt="Growth Digital Img"
                                            />
                                        </div>
                                        <div className="texts">
                                            <h4 className="list_heading">
                                                <a className="list" rel="noopener noreferrer" target='_blank' href="https://kyc.growthsec.com">
                                                    Digital Account Opening
                                                </a>
                                            </h4>
                                            <p className="disc">
                                                Paperless and hassle-free account opening process
                                            </p>
                                        </div>
                                    </div>
                                    <div className="list list_2">
                                        <div className="img">
                                            <img
                                                className='list-img'
                                                src={informed}
                                                alt="Growth information Img"
                                            />
                                        </div>
                                        <div className="texts">
                                            <h4 className="list_heading">
                                                <a className="list" href="#link">
                                                    Informed & Intuitive investing
                                                </a>
                                            </h4>
                                            <p className="disc">
                                                Charts, financial data & news to help make better decision
                                            </p>
                                        </div>
                                    </div>
                                    <div className="list list_3">
                                        <div className="img">
                                            <img
                                                className='list-img'
                                                src={security}
                                                alt="Growth Securities Img"
                                            />
                                        </div>
                                        <div className="texts">
                                            <h4 className="list_heading">
                                                <a className="list" href="#link">
                                                    Fast & Secure
                                                </a>
                                            </h4>
                                            <p className="disc">
                                                Comprehensive Two Factor Authentication
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='app'>
                                    <a className='app-icon'
                                        rel="noopener noreferrer" target='_blank' 
                                        href="https://play.google.com/store/apps/details?id=com.growthsec.xts">
                                        <img
                                            className='googleplay-img app'
                                            src={app1}
                                            alt="googleplay Google Play Store IMG"
                                        />
                                    </a>
                                    <a className='app-icon' 
                                        href="#link">
                                        <img
                                            className='googleplay-img app'
                                            src={app2}
                                            alt="applestore Apple Play Store IMG"
                                        />
                                    </a>
                                </div> */}
                            </Col>
                            <Col lg={6} md={12} sm={12} className="platform_section-img">
                                <img
                                    className='laptop-img'
                                    src={laptop}
                                    alt="Growth Laptop Img"
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Platform;