import React, { Component } from 'react'
import {
    Container
} from 'react-bootstrap'
import './Style.scss'


export class SmallFooter extends Component {
    render() {
        return (
            <>
                <section className="small_footer-container">
                    <Container className="small_footer">
                        <div className="sm-links">
                            <a href="/" className="sm-link">
                                <i className="fa fa-home" title='Home'></i>
                            </a>
                        </div>
                        <div className="sm-links">
                            <a href="/about-us" className="sm-link">
                                <i className="fa fa-info" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="sm-links mid">
                            <a href="https://trade.growthsec.com/#/" target="_blank" rel='noopener noreferrer' className="sm-link">
                                <i className="fa fa-line-chart" title='Trade Now'></i>
                            </a>
                        </div>
                        <div className="sm-links">
                            <a href="/initial-public-offering-ipo" className="sm-link">
                                <i className="fa fa-bullhorn" title='Apply IPOs'></i>
                            </a>
                        </div>
                        <div className="sm-links active">
                            <a href="https://kyc.growthsec.com" target="_blank" rel='noopener noreferrer' className="sm-link">
                                <i className="fa fa-user" title='Open Demat Account'></i>
                            </a>
                        </div>
                    </Container>
                </section>
            </>
        )
    }
}

export default SmallFooter;