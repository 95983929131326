import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import partner from './Icons/Partner.svg'



export class Partner extends Component {
    render() {
        return (
            <>
                <section className="partner-section-container">
                    <Container className='partner_section'>
                        <div className="paraTitle">
                        <h1 className="title">
                            Our <span>Partners</span>
                        </h1>
                        </div>
                        <Row className="partner_section-rows">
                            <Col lg={7} md={12} sm={12} className="partner_section-text">
                                <div className="mutual-lists">
                                    <ul className="lists">
                                        <li className="list">
                                            Axis Mutual Fund
                                        </li>
                                        <li className="list">
                                            Birla Sun Life Mutual Fund
                                        </li>
                                        <li className="list">
                                            DSP BlackRock Mutual Fundd
                                        </li>
                                        <li className="list">
                                            Franklin Templeton Mutual Fund
                                        </li>
                                        <li className="list">
                                            HDFC Mutual Fund
                                        </li>
                                        <li className="list">
                                            ICICI Prudential Mutual Fund
                                        </li>
                                    </ul>
                                    <ul className="lists">
                                        <li className="list">
                                            Kotak Mutual Fund
                                        </li>
                                        <li className="list">
                                            UTI Mutual Fund
                                        </li>
                                        <li className="list">
                                            Mirae Asset Mutual Fund
                                        </li>
                                        <li className="list">
                                            Reliance Mutual Fund
                                        </li>
                                        <li className="list">
                                            SBI Mutual Fund
                                        </li>
                                        <li className="list">
                                            Tata Mutual Fund
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="partner_section-img">
                                <img
                                    className='partner_img'
                                    src={partner}
                                    alt="Growth partner illustration"
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Partner