import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import assist from './Icons/assist.jpg'
import research from './Icons/research.jpg'
import time from './Icons/time.jpg'
import trade from './Icons/trade.jpg'




export class Why extends Component {
    render() {
        return (
            <>
                <section className="whyequity_section-container">
                    <Container className="whyequity_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why Trade Through <span>Growth</span>
                        </h1>
                        </div>
                        <Row className="whyequity_section-rows">
                            <Col lg={6} md={6} sm={12} className="whyequity_section-boxes">
                                <div className="inner_service-card">
                                    <img
                                        className='card-img'
                                        src={trade}
                                        alt="Growth care trade illustration"
                                        style={{height: "190px"}}
                                    />
                                    <h2 className="card-title">
                                        Best Trading Platform
                                    </h2>
                                    <p className="disc">
                                        We provide technically advanced mobile trading application and desktop based trading tool to
                                        connect you to the market.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="whyequity_section-boxes">
                                <div className="inner_service-card">
                                    <img
                                        className='card-img'
                                        src={research}
                                        alt="Growth research illustration"
                                        style={{height: "190px", minWidth: "250px"}}
                                    />
                                    <h2 className="card-title">
                                        Powerful Research
                                    </h2>
                                    <p className="disc">
                                        Our stock research has proven track records and provides with daily research stock recommendations along with several others, for smooth investment and trading experience.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="whyequity_section-boxes">
                                <div className="inner_service-card">
                                    <img
                                        className='card-img'
                                        src={assist}
                                        alt="Growth assist illustration"
                                        style={{height: "190px", maxWidth: "200px"}}
                                    />
                                    <h2 className="card-title">
                                        Assisted Trading Options
                                    </h2>
                                    <p className="disc">
                                        We enable you to trade with low connectivity with the help of our advisory desk, call, whatsup and SMS trade options.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="whyequity_section-boxes">
                                <div className="inner_service-card">
                                    <img
                                        className='card-img'
                                        src={time}
                                        alt="Growth time illustration"
                                        width='100%'
                                        style={{height: "190px", width: "200px"}}
                                    />
                                    <h2 className="card-title">
                                        Quick Query Resolution
                                    </h2>
                                    <p className="disc">
                                        We offer service support in the form of calls, chats, sms and video calling to provide you with the best solutions to your queries.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why