import React, { useState } from 'react';

const emojis = ["😭", "😔", "😑", "🙂", "😍"]; // Emojis corresponding to each rating

function QuizEnd({ score, totalQuestions, restartQuiz }) {
    const [rating, setRating] = useState(0);

    const handleRatingClick = (value) => {
        setRating(value);
    };

    const renderRatingHearts = () => {
        const maxRating = 5;
        const ratingHearts = [];

        for (let i = 1; i <= maxRating; i++) {
            const fillColor = i <= rating ? '#ff0000' : '#cccccc';
            ratingHearts.push(
                <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    onClick={() => handleRatingClick(i)}
                >
                    <path
                        fill={fillColor}
                        d="M12 4.118c-3.556-5.376-11-2.612-11 3.79 0 4.425 4.275 7.176 6.436 8.094.688.38 1.467.714 2.269 1.01l.295.115.296-.115c.802-.296 1.58-.63 2.269-1.01 2.16-.918 6.435-3.669 6.435-8.094 0-6.403-7.444-9.166-11-3.79zm-.001 1.452c3.042-4.632 9.564-1.99 9.564 2.94 0 2.869-2.823 5.396-5.564 7.638-.41.363-.87.732-1.363 1.122-.495-.39-.953-.759-1.363-1.122-2.741-2.242-5.564-4.769-5.564-7.638 0-4.93 6.522-7.572 9.563-2.94z"
                    />
                </svg>
            );
        }

        return (
            <div className="rating-hearts">
                {ratingHearts}
            </div>
        );
    };

    const renderRatingPopup = () => {
        return (
            <>
            <div className="popup">
                <div className="popup-content">
                    <h2 className="popup-heading">Rate the Quiz</h2>
                    <div className="big-emoji">{rating > 0 && emojis[rating - 1]}</div>
                    <p className="popup-text">How would you rate the quiz?</p>
                    {renderRatingHearts()}
                    <button className="popup-button" onClick={restartQuiz}>
                        Submit
                    </button>
                </div>
            </div>
            </>
        );
    };

    return (
        <>
            <div className="coverLast-page">
                <div className="header">
                    <h2 className="cover-heading">Time Over</h2>
                    <div className="big-emoji">{rating > 0 && emojis[rating - 1]}</div>
                </div>
                <p className="cover-text">
                    You have failed to answer in the given time or limit hearts. Your score: {score}/{totalQuestions}
                </p>
                {renderRatingPopup()}
            </div>
        </>
    );
}

export default QuizEnd;
