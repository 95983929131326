import React from 'react'
import { Container, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import "./Style.scss"
import nominationForm from '../PDFs/Nomination.pdf'
import ACForm from '../PDFs/ACForm.pdf'
import AccountClosureForm from '../PDFs/CLOSURE_FOR_DEMAT_TRADING.pdf'
import AccountModificationForm from '../PDFs/GSPL_MODIFICATION_FORM_PG1.pdf'

function Downloads() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Downloads
        </title>
      </Helmet>
      <div className="wrapper">
        <Container className='downloadContainer'>
          <div className="downloadFormContainer">
            <Table striped hover className='downloadsForm'>
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Name</th>
                  <th>Downloads</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>.1</td>
                  <td>Nomination Form</td>
                  <td>
                    <a href={nominationForm}>
                      <i className="fa fa-file-o"></i> Download
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>.2</td>
                  <td>Account Opening Form</td>
                  <td>
                    <a href={ACForm}>
                      <i className="fa fa-file-o"></i> Download
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>.2</td>
                  <td>Account Modification Form</td>
                  <td>
                    <a href={AccountModificationForm}>
                      <i className="fa fa-file-o"></i> Download
                    </a>
                  </td>
                </tr><tr>
                  <td>.2</td>
                  <td>Account Closure Form</td>
                  <td>
                    <a href={AccountClosureForm}>
                      <i className="fa fa-file-o"></i> Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Downloads