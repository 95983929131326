import React from 'react';
import {
    Container,
    Col,
    Nav,
    Row,
    Tab
} from 'react-bootstrap';


function ScrollTab() {
    return (
        <>
            <section className="scrollTabContainer">
                <Container className='scrollTab'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">First tab content</Tab.Pane>
                                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </>
    );
}

export default ScrollTab;