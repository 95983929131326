import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ROICalculator = () => {
  const [initialInvestment, setInitialInvestment] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [investmentPeriod, setInvestmentPeriod] = useState('');
  const [roi, setROI] = useState(null);

  const calculateROI = () => {
    const roiValue = ((currentValue - initialInvestment) / initialInvestment) * 100;
    setROI(roiValue.toFixed(2));
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || ROI || Return On Investment Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>ROI</span>
            </li>
          </ul>
          <div className="roi-calculator calcBody">
            <div className="calculator-container">
              <h2>ROI</h2>
              <div className="input-group">
                <label>Initial Investment (in INR):</label>
                <input
                  type="number"
                  value={initialInvestment}
                  onChange={(e) => handleInputChange(e, setInitialInvestment)}
                />
              </div>
              <div className="input-group">
                <label>Current Value (in INR):</label>
                <input
                  type="number"
                  value={currentValue}
                  onChange={(e) => handleInputChange(e, setCurrentValue)}
                />
              </div>
              <div className="input-group">
                <label>Investment Period (in years):</label>
                <input
                  type="number"
                  value={investmentPeriod}
                  onChange={(e) => handleInputChange(e, setInvestmentPeriod)}
                />
              </div>
              <button onClick={calculateROI}>Calculate</button>
              <div className="result">
                {roi && <p>Your ROI: {roi}%</p>}
              </div>
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  ROI, which stands for Return on Investment, is a financial metric used to evaluate the profitability or efficiency of an investment. It measures the return or gain generated on an investment relative to its initial cost. ROI is expressed as a percentage and provides valuable insights into the performance and success of an investment.
                </p>
                <p className="disc">
                  ROI helps investors and businesses assess the effectiveness of their investment decisions by comparing the amount gained or lost with the initial investment. It allows for the comparison of different investment opportunities and helps determine which ones are more favorable in terms of returns.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculate Return On Investment?
                </h2>
                <p>
                  The formula used in the ROI calculator is as follows:
                </p>
                <h6>
                  ROI = ((CurrentValue - InitialInvestment) / InitialInvestment) * 100
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>CurrentValue-</span> refers to the current value of the investment.
                  </li>
                  <li>
                    <span>InitialInvestment-</span> represents the amount initially invested.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ROICalculator;
