import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const MovingAverageCalculator = () => {
  const [data, setData] = useState('');
  const [period, setPeriod] = useState('');
  const [movingAverage, setMovingAverage] = useState(null);

  const calculateMovingAverage = () => {
    const dataArray = data.split(',').map(Number);
    const periodValue = parseInt(period);

    if (dataArray.length < periodValue) {
      alert('Insufficient data for the specified period.');
      return;
    }

    const movingAverageArray = [];
    for (let i = periodValue - 1; i < dataArray.length; i++) {
      const average = dataArray
        .slice(i - periodValue + 1, i + 1)
        .reduce((sum, value) => sum + value, 0) / periodValue;
      movingAverageArray.push(average.toFixed(2));
    }

    setMovingAverage(movingAverageArray.join(', '));
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Moving Average Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Moving Average</span>
            </li>
          </ul>
          <div className="moving-average-calculator calcBody">
            <div className="calculator-container">
              <h2>Moving Average</h2>
              <div className="input-group">
                <label>Data (comma-separated values):</label>
                <input
                  type="text"
                  value={data}
                  onChange={(e) => handleInputChange(e, setData)}
                />
              </div>
              <div className="input-group">
                <label>Period:</label>
                <input
                  type="number"
                  value={period}
                  onChange={(e) => handleInputChange(e, setPeriod)}
                />
              </div>
              <button onClick={calculateMovingAverage}>Calculate</button>
              {movingAverage && <p>Moving Average: {movingAverage}</p>}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  The Moving Average is a commonly used technical analysis indicator that helps smooth out price data over a specified period. It provides an average value of a set of data points, allowing traders and investors to identify trends and make informed decisions.
                </p>
              </div>
              <div className="formulaSec">
                <h2>How to calculate Moving Average?</h2>
                <p className="disc">
                  The formula used to calculate the Moving Average is as follows:
                </p>
                <h6>
                  Moving Average = Sum of data points in the specified period / Number of data points in the specified period
                </h6>
                <p className="disc">
                  The Moving Average is calculated by taking the sum of the data points within the specified period and dividing it by the number of data points in that period. This calculation is performed for each period, resulting in a series of moving average values.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MovingAverageCalculator;
