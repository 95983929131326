import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Cookies from 'js-cookie';
// import CookieConsent from 'react-cookie-consent';
import "./Style.scss";
import { Helmet } from "react-helmet";
import Why from "./Why";
import Special from "./Special";
import Core from "./Core";
import Services from "./Services";
import Header from "./Header";
import BackColor from "./BackColor";
import MidColor from "./MidColor";
import DematAccount from "./DematAccount";
import EasyInvesting from "./EasyInvesting";
import Blogs from "./Blogs";
import Nice from "./Nice";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";
// import Popup from "./Popup";

const Home = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore");
    if (!visitedBefore) {
      setShowMessage(true);
      localStorage.setItem("visitedBefore", "true");
    }
  }, []);

  useEffect(() => {
    if (showMessage) {
      const message = (
        <div>
          New <a href="/growth-quizCenter">quizzes</a> and{" "}
          <a href="growth-calculators">calculators</a> have been added!
        </div>
      );
      toast.info(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
      });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Growth Securities || Best Intraday Trading Platform and Online Stock
          Trading at Lowest Prices from India's Biggest Trading Platform
        </title>
      </Helmet>
      <ToastContainer />
      <div className="wrapper">
        {/* Background side color */}
        <BackColor />
        {/* <Popup /> */}

        <AnimatedSection>
          {/* Header */}
          <Header />
        </AnimatedSection>

        <AnimatedSection>
          {/* Box */}
          {/* <Counter /> */}
        </AnimatedSection>

        <AnimatedSection>
          {/* Main and Why Section Page */}
          <Why />
        </AnimatedSection>

        <AnimatedSection>
          {/* Service Section */}
          <Services />
        </AnimatedSection>

        <AnimatedSection>
          {/* Special Page Section */}
          <Special />
        </AnimatedSection>

        <AnimatedSection>
          {/* Nice Page */}
          <Nice />
        </AnimatedSection>

        <AnimatedSection>
          {/* About Section */}
          <Core />
        </AnimatedSection>

        <AnimatedSection>
          {/* Background Side Color */}
          <MidColor />
        </AnimatedSection>

        <AnimatedSection>
          {/* Awesoem Tabs */}
          {/* <AwesomeTabs /> */}
        </AnimatedSection>

        <AnimatedSection>
          {/* Easy Investing */}
          <EasyInvesting />
        </AnimatedSection>

        <AnimatedSection>
          {/* Pricing Section */}
          {/* <AccountDemo /> */}
        </AnimatedSection>

        {/* <AnimatedSection> */}
        {/* Demat Account */}
        <DematAccount />
        {/* </AnimatedSection> */}

        <AnimatedSection>
          {/* AppLinks */}
          {/* <AppLink /> */}
        </AnimatedSection>

        <AnimatedSection>
          {/* Blogs */}
          <Blogs />
        </AnimatedSection>

        {/* City */}
        {/* <IndiaMap /> */}
      </div>
    </div>
  );
};

export default Home;
