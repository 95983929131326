import React from "react";
import "./Style.scss";
import { Col, Container, Row, Table } from "react-bootstrap";
import ok from "./Icons/okGirl.svg";
import { Helmet } from "react-helmet";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function StatusPage() {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Status</title>
      </Helmet>
      <div className="wrapper growthStatusPage">
        <Container className="statusWrapper">
          <AnimatedSection>
            <div className="headerTick">
              <img src={ok} alt="okayLady" className="tickImg" width={100} />
              <h1>Growth is up and running</h1>
              <p>
                Having trouble? Troubleshoot connection issues or email us at{" "}
                <a href="mailto: support@growthsec.com" className="link">
                  support@growthsec.com
                </a>
                .
              </p>
            </div>
          </AnimatedSection>
          <Container className="statusContainer container">
            <AnimatedSection>
              <Row className="headerRows">
                <Col lg={4} md={4} sm={12} className="headingStatusLeft">
                  <h6>Current Status by Service</h6>
                </Col>
                <Col lg={8} md={4} sm={0} className="headingStatusRight">
                  <h6>
                    <i className="fa fa-check-circle"></i> No Issues
                  </h6>
                  <h6>
                    <i className="fa fa-wrench"></i> Maintenance
                  </h6>
                  <h6>
                    <i className="fa fa-bell"></i> Notice
                  </h6>
                  <h6>
                    <i className="fa fa-exclamation-triangle"></i> Incident
                  </h6>
                </Col>
                <hr />
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="statusRow">
                <Col lg={6} md={12} sm={12} className="statusColLeft colDesign">
                  <Table>
                    <tbody>
                      <tr>
                        <td>Demat Account Opening Page</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Re Activate</td>
                        <td>
                          <i className="fa fa-wrench"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Trading Web Page</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Trading App</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Back Office</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>RMS</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  className="statusColRight colDesign"
                >
                  <Table>
                    <tbody>
                      <tr>
                        <td>Website</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Chatbot</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Workflows</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Notifications</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Searchings</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Social Links</td>
                        <td>
                          <i className="fa fa-check-circle"></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </AnimatedSection>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default StatusPage;
