import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import company from './Icons/company.svg'


export class Market extends Component {
    render() {
        return (
            <>
                <section className="market_section-container">
                    <Container className="market_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Financial Market: A <span>Vibrant</span> and <span>Dynamic</span> Industry
                        </h1>
                        </div>
                        <Row className="market_section-rows">
                            <Col lg={6} md={12} sm={12} className="market_section-texts">
                                <div className="texts">
                                    <p className="disc">
                                        The financial market is a dynamic and constantly evolving industry that offers many opportunities for individuals willing to take on challenges and reap the rewards. This market is responsible for the flow of funds between savers and borrowers, and it plays a crucial role in the development and growth of the global economy.
                                    </p>
                                    <p className="disc">
                                        The financial market is home to various financial instruments, including stocks, bonds, and derivatives, and it is a hub of activity for investment banks, brokerage firms, and other financial institutions. It is a complex and challenging environment, but it is also full of opportunities for those willing to put in the effort to succeed.
                                    </p>
                                    <h4 className="heading">
                                        Some of the positions available at Growth include:
                                    </h4>
                                    <ul className="lists">
                                        <li className="list">
                                            Technical Analyst
                                        </li>
                                        <li className="list">
                                            Trader
                                        </li>
                                        <li className="list">
                                            Exchange Back Operation
                                        </li>
                                        <li className="list">
                                            DP Operation
                                        </li>
                                        <li className="list">
                                            Back Office Operation
                                        </li>
                                        <li className="list">
                                            Sales and Marketing Professional
                                        </li>
                                        <li className="list">
                                            HR Operation
                                        </li>
                                        <li className="list">
                                            A/c Operation
                                        </li>
                                    </ul>
                                    <p className="disc">
                                        Each of these positions offers unique challenges and opportunities, and Growth provides its employees with the training and support they need to succeed. The company is dedicated to helping its employees grow and advance their careers, and it provides a supportive and empowering work environment
                                    </p>
                                    <div className="career-btn">
                                        <a href="/career-form" className="trade">Apply Now</a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="market_section-imgs">
                                <div className="imgs">
                                    <img 
                                        src={company}
                                        alt="Growth company illustration" 
                                        className="company-img" 
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Market