import React, { Component } from 'react'
import './Style.scss'
import {
    Container, Row
} from 'react-bootstrap'
import hornboy from './Icons/Images/hornboy.png'



export class Header extends Component {
    render() {
        return (
            <>
                <section className="blog_header-container">
                    <Container className='blogss-container'>
                        <Row>
                            <div className="texts">
                                <h1 className="head">
                                    Get the latest news and updates about <span>Growth</span> here through latest blogs. See how <span>Growth</span> is breaking new records every day.
                                </h1>
                            </div>
                        </Row>
                        <Row>
                            {/* <div className='horn-boy'>
                                <img
                                    src={hornboy}
                                    alt="Growth A boy with megaphone"
                                    className="hornboy-img"
                                />
                            </div> */}
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header