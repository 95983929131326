import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import security from '../Icons/security.svg'


export class Security extends Component {
    render() {
        return (
            <>
                <div className='security-container'>
                    <Container className='security'>
                        <h2 className='subtitle'>
                            Security Precaution & Password
                        </h2>
                        <Row className='security-rows'>
                            <Col xl={4} md={12} sm={12} className='security-img'>
                                <img
                                    className=''
                                    src={security}
                                    alt='Growth security illustration'
                                />
                            </Col>
                            <Col xl={8} md={12} sm={12} className='security-text term-text'>
                                <p className="disc">
                                    Growth will provide the client with a username and a trading password
                                    which will enable him to avail of the facilities of Online Trading
                                    through the Growth website, over the telephone or in any such other manner
                                    as may be permitted by Growth for availing of the services. Growth may also
                                    provide the client with the username and password for accessing its
                                    back-office for various reports, etc. All terms regarding the use, reset
                                    and modification of such password shall be governed by information on
                                    the website
                                </p>
                                <p className="disc">
                                    Our trading terminal system have a stringent Two-Factor authentication
                                    (2FA) secure system for every client to enter into their login ID using
                                    their Client ID and Password, along with a 6 digit PIN OR Authentication
                                    via apps such as Google Authenticator set by the Client himself/herself.
                                    This 2FA mechanism is set in place as per SEBI & Exchange guidelines with
                                    respect to Internet Based Trading Rules.
                                </p>
                                <p className="disc">
                                    Any Password can be reset by you at any point of time, by selecting the
                                    ‘Forgot Password’ link under the login area. As soon as you select the
                                    ‘Forgot Password’ link, a reset link is sent to your registered email ID,
                                    where the same process of setting a unique password is followed.
                                </p>
                                
                            </Col>
                        </Row>
                        <Row className='security-rows'>
                            <Col xl={12} md={12} sm={12} className='security-text term-text'>
                                <p className="disc">
                                    The Client shall be responsible for keeping the Username and Password
                                    confidential and secure and shall be solely responsible for all orders
                                    entered and transactions done by any person whosoever through Growth’s Online
                                    Trading System using the Client’s Username and/or Password whether or not
                                    such person was authorized to do so.
                                </p>
                                <p className="disc">
                                    The Client acknowledges that he is fully aware of and understands the risks
                                    associated with availing of online trading services through internet including
                                    the risk of misuse and unauthorized use of his Username and/or Password by a
                                    third party and the risk of a person hacking into the Client’s account on Growth’s
                                    Online Trading System and unauthorized routing orders on behalf of the Client
                                    through the System. The Client agrees that he shall be fully liable and responsible
                                    for any and all unauthorized use and misuse of his Password and/or Username and also
                                    for any and all acts done by any person through Growth’s Online Trading System on the
                                    Client’s Username in any manner whatsoever.
                                </p>
                                <p className="disc">
                                    Without prejudice to the provisions mentioned herein above, the Client shall immediately
                                    notify Growth in writing with full details if: he discovers or suspects unauthorized access
                                    through his Username, Password or Account, he notices discrepancies that might be attributable
                                    to unauthorized access, he forgets his password or he discovers a security flaw in Growth’s
                                    Online Trading System.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Security