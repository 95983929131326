import React, { useState } from "react";
import "./Style.scss";

const ReferralForm = () => {
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [clientReferrals, setClientReferrals] = useState([
        { referralCount: "", name: "" },
    ]);

    const addClientReferral = () => {
        setClientReferrals([...clientReferrals, { referralCount: "", name: "" }]);
    };

    const removeClientReferral = (index) => {
        const updatedClientReferrals = [...clientReferrals];
        updatedClientReferrals.splice(index, 1);
        setClientReferrals(updatedClientReferrals);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isNewCustomer) {
            // Redirect to the KYC link for new customers
            window.location.href = "https://kyc.growthsec.com";
        } else {
            // Handle form submission for existing customers
            // You can access form values via refs or state
        }
    };

    return (
        <div className="referral-form">
            <div className="toggle-button">
                <label>
                    <input
                        type="radio"
                        name="customerType"
                        checked={!isNewCustomer}
                        onChange={() => setIsNewCustomer(false)}
                    />
                    <span>Existing Customer</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="customerType"
                        checked={isNewCustomer}
                        onChange={() => setIsNewCustomer(true)}
                    />
                    <span>New Customer</span>
                </label>
            </div>
            <form onSubmit={handleSubmit}>
                {isNewCustomer ? (
                    <div>
                        <label htmlFor="mobileNumber">Mobile Number:</label>
                        <input type="text" id="mobileNumber" name="mobileNumber" placeholder="99059*****" required />
                    </div>
                ) : (
                    <div>
                        <div className="exitingForm">
                            <label htmlFor="ucc">Client Code:</label>
                            <input type="text" id="ucc" name="clientCode" placeholder="ICK***" required />
                            <div className="txts" style={{display: "flex", justifyContent: "space-between", paddingTop: "3%"}}>
                                <h4>
                                    Your network, your rewards.
                                </h4>
                                <hr width="40%" />
                            </div>
                        </div>
                        {/* Add any additional fields for new customers */}
                        {clientReferrals.map((client, index) => (
                            <div key={index} className="inputGroup">
                                <label>Friend's Number:</label>
                                <input
                                    type="text"
                                    placeholder="99059*****"
                                    name={`referralCount${index}`}
                                    value={client.referralCount}
                                    onChange={(e) => {
                                        const updatedClientReferrals = [...clientReferrals];
                                        updatedClientReferrals[index].referralCount =
                                            e.target.value;
                                        setClientReferrals(updatedClientReferrals);
                                    }}
                                    required
                                />

                                <label>Friend's Name:</label>
                                <input
                                    type="text"
                                    placeholder="Karn***"
                                    name={`name${index}`}
                                    value={client.name}
                                    onChange={(e) => {
                                        const updatedClientReferrals = [...clientReferrals];
                                        updatedClientReferrals[index].name = e.target.value;
                                        setClientReferrals(updatedClientReferrals);
                                    }}
                                    required
                                />

                                <hr width="100%" />

                                {index > 0 && (
                                    <button
                                        className="addBtn rightSide"
                                        type="button"
                                        onClick={() => removeClientReferral(index)}
                                    >
                                        <i className="fa fa-minus"></i>
                                    </button>
                                )}
                            </div>
                        ))}

                        <button
                            className="addBtn"
                            type="button"
                            onClick={addClientReferral}
                        >
                            <i className="fa fa-plus"></i> Add More
                        </button>
                    </div>
                )}
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ReferralForm;
