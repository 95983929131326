import React from 'react'
import { Helmet } from 'react-helmet'
import './Style.scss'
import Header from './Header'
import Company from './Company'
import Hero from './Hero'
import Platform from './Platform'
import Investgrow from '../Home/Investgrow'
import AnimatedSection from '../../Defaults/Animations/AnimatedSection';


function AboutUs() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || About Us || Everything about Growth Securities || The Best Stock Market Trading Platform
        </title>
      </Helmet>
      <div className='wrapper about-us about-us-page'>
        <AnimatedSection>
          <Header />
        </AnimatedSection>

        <AnimatedSection delay={0}>
          <Company />
        </AnimatedSection>

        <AnimatedSection>
          <Platform />
        </AnimatedSection>

        <AnimatedSection>
          <Investgrow />
        </AnimatedSection>

        <AnimatedSection>
          <Hero />
        </AnimatedSection>
      </div>
    </>
  )
}

export default AboutUs;