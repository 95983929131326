import React from 'react'
import './Style.scss'

function SocialMedia() {
    // var prevScrollpos = window.pageYOffset;
    // window.onscroll = function () {
    //     var currentScrollPos = window.pageYOffset;
    //     if (prevScrollpos > currentScrollPos) {
    //         document.getElementById("icons").style.left = "0";
    //     } else {
    //         document.getElementById("icons").style.left = "-100px";
    //     }
    //     prevScrollpos = currentScrollPos;
    // }
    return (
        <>
            <div className='social-icons-all'>
                <div className="icons" id='icons'>
                    <h6 className="title">Follow Us</h6>
                    <a href="https://www.facebook.com/growthsec" title='Follow us on Facebook' className="facebook" rel="noreferrer" target='_blank'>
                        <i className="fa fa-facebook"></i>
                    </a>
                    <a href="https://in.linkedin.com/company/growthsec" title='Follow us on LinkedIn' className="linkedin" rel="noreferrer" target='_blank'>
                        <i className="fa fa-linkedin"></i>
                    </a>
                    <a href="https://www.twitter.com/officialgrowths" title='Follow us on Twitter' className="twitter" rel="noreferrer" target='_blank'>
                        <i className="fa fa-twitter"></i>
                    </a>
                    <a href="https://instagram.com/growthsecurities?igshid=YmMyMTA2M2Y=" title='Follow us on Instagram' className="instagram" rel="noreferrer noopener" target='_blank'>
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </>
    )
}

export default SocialMedia;