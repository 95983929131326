import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap';
import teams from './Icons/teams.svg'
import support from './Icons/support.svg'
import woho from './Icons/guide.svg'
import manual from './Icons/manual.svg'
import logo from './Icons/logoshade.svg'


export class Why extends Component {
    render() {
        return (
            <>
                <section className='why_section-container homeWhy home-why-mini home-why-big'>
                    <Container className='why_section'>
                        <div className="paraTitle">
                            <h1 className='title'>
                                Why choose <span>Growth?</span>
                            </h1>
                        </div>
                        <Row className='why_section-rows'>
                            <Col lg={6} md={12} sm={12} className='txt-column'>
                                <div className='txts'>
                                    <h2 className='subtitle'>
                                        Why should you trade through <span>Growth?</span>
                                    </h2>
                                    <p className='disc'>
                                        Our services are designed according to the needs and expectations of the clients                                                      .
                                        We discuss current status, future needs, goals, risk profile and work 
                                        out a best suitable plans for the clients. 
                                    </p>
                                    <p className="disc">
                                        Our motto is to encourage the people across India to invest for change which leads 
                                        to growth and prosperity.
                                    </p>
                                    <p className="disc">
                                        Click <a href='https://kyc.growthsec.com'>here</a> to sign up now and start investing.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='image-column'>
                                <Row className='card-rows'>
                                    <Col lg={6} md={6} sm={6} className='logo-img'>
                                        <img 
                                            className='logoshade-img'
                                            src={logo}
                                            alt="Growth logo brand" 
                                        />
                                        <div className='img-col-1  box-i-1 box-i'>
                                            <img
                                                className='why-card-img'
                                                src={teams}
                                                alt='Growth team illustration'
                                                width={120}
                                            />
                                            <p className='disc'>
                                                Extensive & detailed Research to guide all kind of Investors.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className='img-col-1  box-i-1 box-i'>
                                            <img
                                                className='why-card-img'
                                                src={support}
                                                alt='Growth support illustration'
                                                width={120}
                                            />
                                            <p className='disc' style={{ paddingTop: "15px" }}>
                                                Personalize support to all clients, traders & investors.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className='img-col-1  box-i-1 box-i'>
                                            <img
                                                className='why-card-img'
                                                src={woho}
                                                alt='Growth happy woho illustration'
                                                width={120}
                                            />
                                            <p className='disc'>
                                                Experienced teams to guide you in every step
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className='img-col-1  box-i-1 box-i'>
                                            <img
                                                className='why-card-img'
                                                src={manual}
                                                alt='Growth manual illustration'
                                                width={120}
                                            />
                                            <p className='disc'>
                                                You’ll experience an exciting work culture with us.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why