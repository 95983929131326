import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import promoter from './Icons/promoter.png'



export class Promoters extends Component {
    render() {
        return (
            <>
                <section className='promoter_section-container'>
                    <Container className='promoter_section'>
                        <div className="paraTitle">
                        <h1 className='title'>
                            Meet Our <span>Promoters</span>
                        </h1>
                        </div>
                        <Row className='promoter_section-rows'>
                            <Col lg={6} md={6} sm={12} className='promoters_card'>
                                <div className='cards card-1'>
                                    <div className='pic'>
                                        <img
                                            className='cards-img'
                                            src={promoter}
                                            alt='Growth Promoter Card Img'
                                            width=''
                                        />
                                    </div>
                                    <div className="text">
                                        <h2 className='heading'>
                                            Mr. Dhruv Gupta
                                        </h2>
                                        <h5 className='title'>
                                            Director
                                        </h5>
                                        <p className='subtitle'>
                                            CFA level 2, B.A.(Hons.) <br />Delhi University
                                        </p>
                                    </div>
                                    <div className='social_icons'>
                                        <a className='twt' href="http://www.twitter.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                        <a className='linked-in' href="https://www.linkedin.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                        <a className='fb' href="https://www.faccebook.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </div>
                                    <a className='btn btn-read_more' href='/about-us/#promoter'>Know More</a>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className='promoters_card'>
                                <div className='cards card-2'>
                                    <div className='pic'>
                                        <img
                                            className='cards-img'
                                            src={promoter}
                                            alt='Growth Promoter Card Img'
                                            width=''
                                        />
                                    </div>
                                    <div className="text">
                                        <h2 className='heading'>
                                            Mr. Devansh Gupta
                                        </h2>
                                        <h5 className='title'>Director</h5>
                                        <p className='subtitle'>
                                            B.Com (Hons.)<br />Delhi University
                                        </p>
                                    </div>
                                    <div className='social_icons'>
                                        <a className='twt' href="http://www.twitter.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                        <a className='linked-in' href="https://www.linkedin.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                        <a className='fb' href="https://www.faccebook.com" rel="noreferrer" target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </div>
                                    <a className='btn btn-read_more' href='/about-us/#promoter'>Know More</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Promoters;