import React from "react";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import career from './Icons/careerimg.svg'



const Header = () => {
    return (
        <>
            <section className="header_section-container career_header">
                <Container className="header_section">
                    <Row className="header_section-rows">
                        <Col lg={7} md={12} sm={12} className='header_section-texts'>
                            <div className="texts">
                                <h3 className="heading">
                                    Here you in the right place to boost your career
                                </h3>
                                <p className="disc">
                                    We provide you a suitable platform for many interesting job roles in the
                                    domain of capital market. With a fair amount of expertise and experience,
                                    professionals in this field can grow within short spans of
                                    time.
                                </p>
                                <p className="disc" style={{marginBottom: "4%"}}>
                                    If you are interested to boost your career, it may be beneficial for you
                                    to examine the variety of job roles which we're offering.
                                </p>
                                <div className="career-btn">
                                    <a href="career-form" className="trade">Apply Now</a>
                                </div>
                                <br />
                                <br />
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link sec">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link next">
                                        <span>Careers</span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={5} md={12} sm={12} className='career_section-img'>
                            <div className="img">
                                <img
                                    className="career-img"
                                    src={career}
                                    alt="Growth career center illustration"
                                    width="100%"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Header;