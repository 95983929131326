import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import derivative from './Icons/derivat.png'



function Header() {
    return (
        <>
            <section className="derivative_header_section-container">
                <Container className="derivative_header_section">
                    <Row className="derivative_header_section-rows">
                        <Col lg={7} md={12} sm={12} className="derivative_header_section-texts">
                            <div className="texts">
                                <h3 className="headerTitle">
                                    Derivatives are financial contracts, set between two or more parties, that derive their value from an underlying asset, group of assets, or benchmark
                                </h3>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        Derivative Trading
                                        </p>
                                    </li>
                                </ul>
                                <a className="trade" href='https://kyc.growthsec.com'>
                                    <span>Start Derivative Trading</span>
                                </a>
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </div>
                        </Col>
                        <Col lg={5} md={12} sm={12} className="derivative_header_section-img">
                            <div className="img">
                                <img
                                    src={derivative}
                                    alt="Growth Derivative illustration"
                                    className="derivat"
                                    width='100%'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Header