import React from 'react'
import {
    Container,
    Tab,
    Row,
    Col,
    Nav,
    Accordion
} from 'react-bootstrap'



function Queries() {

    return (
        <>
            <section className="queContainer">
                <Container className="que">
                    <Tab.Container id="" defaultActiveKey="first">
                        <Row>
                            <Col lg={2} md={2} sm={12} xs={12} className='pillSide'>
                                <Nav variant="tabs" className="flex-column">
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="first" >
                                            <span>Getting Started</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="second">
                                            <span></span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="third">
                                            <span>Offline Opening</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="fourth">
                                            <span>Closing Account</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg={10} md={10} sm={12} xs={12} className='queAns'>
                                <Tab.Content>
                                    <Tab.Pane className='contBack' eventKey="first">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">What is future & options (F&O)?</h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            F&O stands for Futures and Options, which are financial instruments traded on the stock exchanges. F&O contracts are derivatives, meaning their value is derived from an underlying asset such as stocks, indices, currencies, or commodities.
                                                        </p>
                                                        <p className="disc">
                                                            In futures contracts, the buyer agrees to purchase an underlying asset at a predetermined price and time in the future, while the seller agrees to sell the asset at the same price and time. The buyer and seller are obligated to complete the transaction at the specified time and price, regardless of the market price of the asset at that time.
                                                        </p>
                                                        <p className="disc">
                                                            In options contracts, the buyer has the right, but not the obligation, to buy or sell the underlying asset at a predetermined price and time in the future. The seller, on the other hand, has the obligation to buy or sell the asset at the predetermined price and time if the buyer decides to exercise their right.
                                                        </p>
                                                        <p className="disc">
                                                            F&O contracts are popular among traders and investors as they provide opportunities for hedging, speculation, and leverage. However, F&O trading also carries a high level of risk and requires a good understanding of the markets and the underlying assets. It's important to conduct thorough research and seek professional advice before entering into any F&O transactions.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What documents need to activate future and options (F&O)?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            To activate F&O (Futures and Options) trading, you will need to submit the following documents to your broker:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                KYC (Know Your Customer) documents: This includes your identity proof (PAN Card, Aadhaar Card, Passport, Voter ID, etc.), address proof (bank statement, utility bill, passport, etc.), and a recent photograph.
                                                            </li>
                                                            <li className="list">
                                                                Trading Account Opening Form: You will need to fill up and sign the trading account opening form provided by the broker.
                                                            </li>
                                                            <li className="list">
                                                                Demat Account Details: If you have an existing demat account, you will need to provide the details of the account. If you don't have a demat account, you will need to open one to trade in F&O.
                                                            </li>
                                                            <li className="list">
                                                                Income Proof: You may also be required to provide income proof, such as your salary slip, bank statement, or income tax return, depending on your broker's requirements.
                                                            </li>
                                                            <li className="list">
                                                                Risk Disclosure Documents: Brokers are required to provide you with the risk disclosure documents that explain the risks associated with F&O trading. You will need to read and sign these documents before you can start trading in F&O.
                                                            </li>
                                                        </ul>
                                                        <p className="disc">
                                                            Once you have submitted these documents, your broker will verify the details and activate your F&O trading account. It's important to ensure that all the documents submitted are genuine and up-to-date to avoid any delays or issues in activating your account.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What is Bear Spread in a Futures and Options Trading Market?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Bear Spread is a trading strategy in the futures and options market used by traders who anticipate a fall in the price of the underlying asset. This strategy involves buying a put option with a lower strike price and selling a put option with a higher strike price, both with the same expiration date and the same underlying asset.
                                                        </p>
                                                        <p className="disc">
                                                            The purpose of this strategy is to profit from a decline in the price of the underlying asset while limiting the potential losses. If the price of the underlying asset falls below the lower strike price of the put option purchased, the trader can exercise the option and sell the asset at the higher strike price of the put option sold, thereby making a profit.
                                                        </p>
                                                        <p className="disc">
                                                            The maximum profit in a bear spread is the difference between the premiums received from selling the put option with the higher strike price and the premium paid for buying the put option with the lower strike price. The maximum loss is limited to the net premium paid for the options.
                                                        </p>
                                                        <p className="disc">
                                                            Bear spread is a limited-risk strategy as the potential losses are known in advance, but the potential profits are also limited. It's important to consider the market conditions and the volatility of the underlying asset before implementing any trading strategy, including bear spread.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Who is the 'Holder' in Futures and Options Trading Market?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            In the futures and options trading market, a 'holder' refers to an individual or entity that holds a long position in a futures or options contract.
                                                        </p>
                                                        <p className="disc">
                                                            In a futures contract, the holder is the buyer of the contract who agrees to take delivery of the underlying asset at a specified future date and at a predetermined price. The holder has the right, but not the obligation, to hold the contract until expiration or sell it in the market before expiration.
                                                        </p>
                                                        <p className="disc">
                                                            In an options contract, the holder is the buyer of the contract who has the right, but not the obligation, to buy or sell the underlying asset at a predetermined price and date in the future. If the holder chooses to exercise their right, the seller of the option (also known as the 'writer') is obligated to fulfill the contract by selling or buying the underlying asset at the predetermined price and date.
                                                        </p>
                                                        <p className="disc">
                                                            The holder of a futures or options contract can either hold the contract until expiration or trade it in the market before expiration. If the holder decides to hold the contract until expiration, they must either take delivery of the underlying asset (in the case of a futures contract) or exercise their right to buy or sell the asset (in the case of an options contract). If the holder decides to sell the contract before expiration, they can do so in the market, either for a profit or a loss, depending on the market conditions and the price of the contract.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What is a 'Cash Contract' in a Futures And Options Trading Market?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            A cash contract is a type of financial agreement in the futures and options trading market that involves the immediate exchange of cash, without the delivery of a physical asset or commodity.
                                                        </p>
                                                        <p className="disc">
                                                            In a cash contract, the parties agree to exchange a specified amount of cash at a predetermined price at a specific time in the future. Cash contracts are often used to hedge against price fluctuations or to lock in a favorable price for a future transaction.
                                                        </p>
                                                        <p className="disc">
                                                            Cash contracts are different from futures contracts, which involve the exchange of a physical asset or commodity at a future date, and options contracts, which give the holder the right but not the obligation to buy or sell an asset at a future date.
                                                        </p>
                                                        <p className="disc">
                                                            Overall, cash contracts are useful financial tools that allow traders and investors to manage their risk exposure and protect themselves against unexpected market movements.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Why choose future over option?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            It depends on the context and what you are trying to achieve. In some situations, having an option can be beneficial, as it provides you with flexibility and the ability to choose between different courses of action.
                                                        </p>
                                                        <p className="disc">
                                                            However, in other situations, it may be more advantageous to focus on the future rather than just having an option. For example, if you have a clear vision of what you want to achieve in the future, you may need to make choices and take actions that will bring you closer to that goal, rather than simply keeping your options open.
                                                        </p>
                                                        <p className="disc">
                                                            Additionally, focusing on the future can help you to set goals, create a plan of action, and work towards achieving a desired outcome. By contrast, simply having an option without a clear direction or purpose may lead to indecision or a lack of progress.
                                                        </p>
                                                        <p className="disc">
                                                            Ultimately, the choice between focusing on the future or keeping your options open depends on your goals, values, and the specific situation you are in. It's important to consider both perspectives and make a decision that aligns with your priorities and will help you achieve the outcome you desire.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            How long can I hold F&O?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            The length of time that you can hold a Futures and Options (F&O) contract depends on the type of contract and the exchange where it is traded.
                                                        </p>
                                                        <p className="disc">
                                                            In general, Futures contracts have a pre-determined expiration date, which is specified by the exchange on which they are traded. For example, on the National Stock Exchange (NSE) in India, the expiration date for Futures contracts is the last Thursday of the expiry month. Therefore, you cannot hold a Futures contract beyond its expiration date.
                                                        </p>
                                                        <p className="disc">
                                                            Options contracts, on the other hand, have a fixed expiration date, beyond which they become worthless. The expiration date for Options contracts is also specified by the exchange on which they are traded. On the NSE, Options contracts expire on the last Thursday of the expiry month.
                                                        </p>
                                                        <p className="disc">
                                                            It's important to note that you don't necessarily have to hold a Futures or Options contract until its expiration date. You can close your position at any time before expiration by offsetting your contract with an opposite trade. This means that if you bought a Futures contract, you can sell an equal number of contracts to close your position before its expiration date. Similarly, if you bought an Options contract, you can sell the same number of contracts to exit your position.
                                                        </p>
                                                        <p className="disc">
                                                            Overall, the length of time that you can hold F&O contracts depends on the specific terms of the contract and the exchange on which it is traded. It's important to understand these terms before entering into any F&O trades.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Can we buy expired options?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            No, it is not possible to buy expired options because once an option has expired, it no longer exists as a tradable instrument. An expired option has no intrinsic value, and its holder cannot exercise the option or receive any payout. Therefore, attempting to buy an expired option would be meaningless.
                                                        </p>
                                                        <p className="disc">
                                                            However, it is possible to trade options that are approaching their expiration date. Options that are nearing expiration may experience changes in their value due to changes in the underlying asset price or volatility. As a result, some traders may look to buy or sell options that are close to expiration in order to capitalize on these changes.
                                                        </p>
                                                        <p className="disc">
                                                            It's important to note that trading options, particularly those that are near expiration, can be a complex and risky undertaking. Options trading involves significant leverage and can result in substantial losses, so it's important to have a solid understanding of the risks involved and to carefully consider your trading strategy and risk management techniques.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What is lot size in F&O?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            In Futures and Options (F&O) trading, a lot size refers to the standardized quantity in which a particular contract is traded on an exchange. The lot size varies depending on the underlying asset and the exchange on which the contract is traded.
                                                        </p>
                                                        <p className="disc">
                                                            For example, on the National Stock Exchange (NSE) in India, the lot size for Nifty 50 Index Futures is 75, which means that one Futures contract represents 75 shares of the Nifty 50 Index. Similarly, the lot size for Bank Nifty Index Futures is 25, which means that one Futures contract represents 25 shares of the Bank Nifty Index.
                                                        </p>
                                                        <p className="disc">
                                                            In Options trading, the lot size refers to the number of option contracts that must be bought or sold in a single transaction. For example, on the NSE, the lot size for Nifty 50 Index Options is 75, which means that one Options contract represents 75 shares of the Nifty 50 Index.
                                                        </p>
                                                        <p className="disc">
                                                            It's important to understand the lot size of a particular F&O contract before trading, as it determines the quantity of the underlying asset that is being traded. This can affect the margin requirements, the trading costs, and the potential profits or losses associated with the contract. Additionally, the lot size may be adjusted by the exchange from time to time, so it's important to stay up-to-date with any changes that may affect your trading strategy.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane className='contBack' eventKey="second">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to open a demat account online through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Opening a demat account online is a simple and convenient process. Here are the steps you can follow to open a demat account online:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                <b>Visit our website:</b> Click on the button to open a demat account.
                                                            </li>
                                                            <li className="list">
                                                                <b>Fill in the application form:</b> You will be required to fill in an application form with personal and financial details, including your PAN card and bank account details.
                                                            </li>
                                                            <li className="list">
                                                                <b>Submit supporting documents:</b> You will also need to upload supporting documents like your Aadhaar card, address proof, and passport size photograph.
                                                            </li>
                                                            <li className="list">
                                                                <b>e-sign the application:</b> Once you have filled in all the details and uploaded the necessary documents, you will be required to e-sign the application using Aadhaar-based e-KYC verification.
                                                            </li>
                                                            <li className="list">
                                                                <b>In-person verification:</b> After the online verification is complete, the DP may send a representative to verify your identity and address in-person.
                                                            </li>
                                                            <li className="list">
                                                                <b>Receive your account details:</b> Once your demat account is opened, you will receive your account details, including your demat account number, login ID, and password.
                                                            </li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any charges to open a demat account through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any hidden charges to buy or sell stocks?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to get verified my account online?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I forget my ID or Password?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I see password not match again and again?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className='contBack' eventKey="third">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className='contBack' eventKey="fourth">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </>
    )
}

export default Queries