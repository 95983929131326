import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import what from './Icons/what.svg'


function What() {
    return (
        <>
            <section className="whatSection">
                <Container className="whatWeDo">
                    <div className="paraTitle">
                        <h1 className="title">
                            What We Do At <span>Growth</span>
                        </h1>
                    </div>
                    <Row className="whatRow">
                        <Col lg={6} md={12} sm={12} xs={12} className="weImg">
                            <div className="img">
                                <img
                                    src={what}
                                    alt="Consulting at growth"
                                    className="whatImg"
                                    width='100%'
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={12} sm={12} xs={12} className="whatTxt">
                            <div className="texts">
                                <p className="disc">
                                    Here we are in the business of completely technology hedged derivatives
                                    arbitrage with a high focus on technology development
                                    and innovation since nearly 3 decades. Growth combines mathematics, economics with
                                    cutting edge software and latest hardware to create
                                    a unique ecosystem for arbitrage trade management,
                                    risk management systems and execution engines.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default What