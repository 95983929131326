import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import warrant from '../Icons/warrant.jpg'

export class Warranties extends Component {
    render() {
        return (
            <>
                <div className='warranty-container'>
                    <Container className='warranty'>
                        <h2 className="subtitle">
                            Representations & Warranties
                        </h2>
                        <Row className='warranty-rows'>
                            <Col lg={6} md={12} sm={12} className='warranty-text term-text'>
                                <p className="disc">
                                    The Client hereby warrants that he is capable of executing the present agreement and that the
                                    terms of the present are not in contravention of any rights of any party with whom such client
                                    has any agreements, at any time prior to the execution of this agreement.
                                </p>
                                <p className="disc">
                                    He agrees to provide and continue to provide all details about themselves as may be required by
                                    GSPL, including but not restricted to PAN Number or Unique Identification Number (issued by SEBI)
                                    , and states all details and facts represented to GSPL are true.
                                </p>
                                <p className="disc">
                                    The Client is aware and acknowledges that trading over the internet involves many uncertain factors
                                    and complex hardware, software, systems, communication lines, peripherals, etc., which are susceptible
                                    to interruptions and dislocations; and the Online Trading Service of GSPL may at any time be unavailable
                                    without further notice. GSPL and the Exchange do not make any representation or warranty that the Online
                                    Trading Service of GSPL will be available to the Client at all times without any interruption. The Client
                                    agrees that he shall not have any claim against the Exchange or GSPL on account of any suspension,
                                    interruption, non-availability or malfunctioning of the Online Trading System or Service of GSPL or the
                                    Exchange's service or systems for any reason whatsoever.
                                </p>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='warranty-img'>
                                <img
                                    className=''
                                    src={warrant}
                                    alt='Growth warranty warrant illustration'
                                    width='100%'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className='warranty-text term-text'>
                                <p className="disc">
                                    GSPL states that it has complied with and will continuously comply and if not propose to comply with all
                                    statutory and regulatory directions to offer the Internet Trading services through the website growthsec.in
                                    and for dealing in cash and derivatives segment of the exchange.
                                </p>
                                <p className="disc">
                                    The Client warrants that all or any of the securities deposited by him with GSPL in respect of margin
                                    requirements or otherwise, are owned by him and the title thereof is clear and free of encumbrances.
                                </p>
                                <p className="disc">
                                    The Client/s agree to indemnify and hold GSPL harmless against any loss that may be suffered by it,
                                    its customers or a third party or any claim or action that may be initiated by a third party which
                                    is in any way the result of improper use of user ID and password by the Client/s.
                                </p>
                                <p className="disc">
                                    The Client hereby confirms and warrants that the Client authorises GSPL to take all such steps on
                                    the Client’s behalf as may be required for provisions or to complete or settle any transactions
                                    entered into through or with GSPL or executed by GSPL on behalf of the Client. However, nothing
                                    herein shall oblige GSPL to take such steps.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Warranties