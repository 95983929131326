import React, { Component } from 'react'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'
import currency from './Icons/forex.jpg'


export class Forex extends Component {
  render() {
    return (
      <>
        <section className="forex-section-container">
          <Container className="forex-section">
            <div className="paraTitle">
              <h1 className="title">
                What is <span>Forex Trading</span>?
              </h1>
            </div>
            <Row className="forex-section-rows">
              <Col lg={5} md={12} sm={12} className="forex-section-img">
                <div className="img">
                  <img
                    src={currency}
                    alt="Growth choice illustration"
                    className="coin"
                    width='100%'
                  />
                </div>
              </Col>
              <Col lg={7} md={12} sm={12} className="forex-section-texts">
                <div className="texts">
                  <p className="disc">
                    A currency trading platform is a software interface provided by currency brokers to their customers to give them access as traders in the forex markets. This may be an online, web-based portal, mobile app, a standalone downloadable program, or any combination of the three.
                  </p>
                  <p className="disc">
                    Currency trading platforms provide tools to traders for executing buy and sell orders in the forex markets. The platforms are made available by forex brokers for the use of clients. Some forex brokers offer their own custom trading platforms for order processing and research, but many provide order access through integrated trading and research programs.
                  </p>
                  <p className="disc">
                    Many brokerages offer a wide array of currency trading platform solutions for both retail and institutional clients. In the institutional markets, banking or brokerage companies may also build proprietary currency trading platforms to meet their internal needs with trades executed through institutional trading channels.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Forex