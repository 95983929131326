import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import types from './Icons/Types.svg'

function Types() {
    return (
        <>
            <section className="typesSectionContainer">
                <Container className='fiidiitypesSection'>
                    <div className="paraTitle">
                        <h1 className="title">
                            Types of FIIs & DIIs Allowed in India
                        </h1>
                    </div>
                    <Row className='typesRows'>
                        <Col lg={5} md={12} sm={12} xs={12} className='typesImg'>
                            <img src={types} alt="Growth Illustration" width="100%" />
                        </Col>
                        <Col lg={7} md={12} sm={12} xs={12} className='typesTxt'>
                            <div className="typeContent">
                                <p>
                                    Foreign Institutional Investors (FIIs) and Domestic Institutional Investors (DIIs) are classified into different types based on their nature and activities. Here are some of the types allowed in India:
                                </p>
                                <h3>Types of FIIs:</h3>
                                <ol>
                                    <li>
                                        <strong>Foreign Pension Funds:</strong> These are pension funds from foreign countries that invest in Indian financial markets.
                                    </li>
                                    <li>
                                        <strong>Mutual Funds:</strong> Foreign mutual funds that invest in Indian equities and debt securities.
                                    </li>
                                    <li>
                                        <strong>Insurance Companies:</strong> Foreign insurance companies that participate in the Indian insurance sector and invest in financial markets.
                                    </li>
                                    <li>
                                        <strong>Foreign Central Banks:</strong> Central banks of foreign countries may also invest in Indian markets.
                                    </li>
                                    {/* Add more types as needed */}
                                </ol>
                                <h3>Types of DIIs:</h3>
                                <ol>
                                    <li>
                                        <strong>Mutual Funds:</strong> These are investment vehicles that pool money from retail investors and invest in various asset classes.
                                    </li>
                                    <li>
                                        <strong>Insurance Companies:</strong> Domestic insurance companies that invest their premium income in financial markets.
                                    </li>
                                    <li>
                                        <strong>Banks and Financial Institutions:</strong> Indian banks and financial institutions that participate in stock markets and debt markets.
                                    </li>
                                    <li>
                                        <strong>Provident Funds:</strong> Employee Provident Funds (EPFs) and other provident funds that invest in financial instruments.
                                    </li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Types;
