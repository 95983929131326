import React, { Component } from 'react'
import './Style.scss'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import gold from './Icons/gold.svg'

export class Header extends Component {
    render() {
        return (
            <>
                <section className="gold_bond-container">
                    <Container className="gold_bond">
                        <Row className="gold_bond-rows">
                            <Col lg={7} md={12} sm={12} className='gold_bond-text'>
                                <h4 className="headerTitle">
                                    Investing in gold is much more easy and convenient now. With the Government of
                                    India's Sovereign Gold Bonds(SGB) Scheme you can earn an assured interest rate
                                    eliminating risk and cost of storage.
                                </h4>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                        <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            Bonds (SGB)
                                        </p>
                                    </li>
                                </ul>
                                <a className='trade' href='https://ipo.growthsec.com/sgb' rel='noopener noreferrer'>Apply Now</a>
                                <i className='fa fa-arrow-right'></i>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='gold_bond-img'>
                                <img
                                    className='bond-img'
                                    src={gold}
                                    alt="Growth gold illustration"
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header