import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import './Style.scss'
import { Helmet } from 'react-helmet'
import KMP from '../Mandatory/KMP'
import Grievances from '../Mandatory/Grievances'
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";



export class Contact extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Growth Securities || Contact Us</title>
                </Helmet>
                <div className="contact_us-page">
                    <Container className='contact-us'>
                            <div className="header">
                                <h2 className="ContactTitle">
                                    Contact Us
                                </h2>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                        <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <i className="fa fa-heart"></i> <a href="/support">Support</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            Contact Us
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <Row className='contact-rows'>
                                <Col lg={6} md={12} sm={12} xs={12} className='locationBoxes location_1'>
                                    <div className="map-container map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.234864143496!2d72.6804391655124!3d23.161626616927485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e80272c274db1%3A0x47025c3b93604af0!2sGift%20One%20Tower!5e0!3m2!1sen!2sin!4v1677913178329!5m2!1sen!2sin"
                                            width="100%"
                                            height="250"
                                            style={{ border: "0", padding: "15px" }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            title='Growth at Gujarat'
                                            referrerPolicy="no-referrer-when-downgrade">
                                        </iframe>
                                        <h3 className='mapTitle'><i className="fa fa-map-marker"></i> Registered Office</h3>
                                        <p className="details">
                                            Unit No. 503A-B, 504A-B, 5<sup>th</sup> Floor, Tower A WTC, Block No. 51, Road 5E, Zone-5, GIFT City, Gandhinagar, Gujarat – 382355
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12} className='locationBoxes location_1'>
                                    <div className="map-container map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14006.48268001592!2d77.2373357!3d28.6411289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfdd29f27d9ed%3A0x77a888f13eb10c7e!2sGrowth%20Securities%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1677912355246!5m2!1sen!2sin"
                                            width="100%"
                                            height="250"
                                            style={{ border: "0", padding: "15px" }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            title='Growth at Delhi'
                                            referrerPolicy="no-referrer-when-downgrade">
                                        </iframe>
                                        <h3 className='mapTitle'>
                                            <i className="fa fa-map-marker"></i> Corporate Office
                                        </h3>
                                        <p className="details">
                                            2<sup>nd</sup> Floor 4/10, Asaf Ali Road, New Delhi-110002
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='top-rows contact-rows'>
                                <Col lg={4} md={4} sm={4} xs={6} className='contactBoxes contacts'>
                                    <div className="smallBox">
                                        <div className="items">
                                            <h2 className='subtitle'>
                                                <span className='icon icon-css'>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/osuxyevn.json"
                                                        trigger="hover"
                                                        colors="#2b2d42"
                                                    >
                                                    </lord-icon>
                                                </span>
                                                &nbsp;Find Us
                                            </h2>
                                            <p className="contact-link">
                                                <a
                                                    href='https://www.google.com/maps/place/Growth+Securities+Pvt.+Ltd./@28.6411289,77.2373357,15z/data=!4m5!3m4!1s0x0:0x77a888f13eb10c7e!8m2!3d28.6411289!4d77.2373357'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    2<sup>nd</sup> Floor, 4/10 Asaf Ali Road
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={6} className='contactBoxes contacts'>
                                    <div className="smallBox">
                                        <div className="items">
                                            <h2 className='subtitle'>
                                                <span className='icon icon-css'>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/ssvybplt.json"
                                                        trigger="hover"
                                                        colors="#2b2d42"
                                                    >
                                                    </lord-icon>
                                                </span>
                                                &nbsp;Call Us
                                            </h2>
                                            <p className="contact-link">
                                                <a
                                                    href="tel:+ 011-43140000"
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ paddingLeft: "20px" }}
                                                >
                                                    011-43140000
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={12} className='contactBoxes contacts'>
                                    <div className="smallBox">
                                        <div className="items">
                                            <h2 className='subtitle'>
                                                <span className='icon icon-css'>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/diihvcfp.json"
                                                        trigger="hover"
                                                        colors="#2b2d42"
                                                    >
                                                    </lord-icon>
                                                </span>
                                                &nbsp;Mail Us
                                            </h2>
                                            <p className='contact-link'>
                                                <a
                                                    href="mailto: Compliance@growthsec.in"
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    Compliance@growthsec.in
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        <AnimatedSection>
                            <Row className='contact-rows'>
                                <Col lg={6} md={6} sm={12} xs={12} className='officerBoxes location_1'>
                                    <div className="detail largebox">
                                        <b>Compliance Officer Details</b>:<br />
                                        Name: Himanjal Brahmbhatt
                                        <br />
                                        Phone: <a href="tel: 011-43140000">011-43140000</a> <br />
                                        Email:{" "}
                                        <a href="mailto: Compliance@growthsec.in">
                                            Compliance@growthsec.in
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className='officerBoxes location_1'>
                                    <div className="detail largebox">
                                        <p><b>Investor Grievance:</b> <a href='mailto: Complaints@growthsec.in'>Complaints@growthsec.in</a></p>
                                        <p><b>Depository Participant:</b> 12094100</p>
                                    </div>
                                </Col>
                            </Row>
                        </AnimatedSection>
                        <AnimatedSection>
                            <KMP />
                        </AnimatedSection>
                        <AnimatedSection>
                            <Grievances />
                        </AnimatedSection>
                    </Container>
                </div>
            </>
        )
    }
}

export default Contact