import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import headerImg from './Icons/Indicator.svg'

function Header() {
    return (
        <>
            <section className="fiidiiheadersection">
                <div className="fiidiiContainer">
                    <Container className='fiidii'>
                        <Row className='fiidiiRows'>
                            <Col lg={8} md={12} sm={12} className='fiidiitxt'>
                                <h1 className="headerTitle">
                                    FII & DII Trading Activity
                                </h1>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                        <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            FII & DII Trading Activity
                                        </p>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg={4} md={12} sm={12} className='fiidiiimg'>
                                <div className="fiidiiimg">
                                    <img src={headerImg} alt="Growth Illustration" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Header