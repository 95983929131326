import React, { Component } from 'react';
import {
    Col,
    Container,
    Row
} from 'react-bootstrap';
import terms from "../Icons/terms.svg";
import { Helmet } from 'react-helmet';


export class TermsConditions extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Terms & Conditions
                    </title>
                </Helmet>
                <div className='termsconditions_section-container'>
                    <Container className="termsconditions_section">
                        <h1 className='title'>
                            Te<span>rms</span> and Condi<span>tions</span>
                        </h1>
                        <Row className='terms-rows'>
                            <Col xl={8} md={12} sm={12} className="term-text">
                                <p className='disc'>
                                    <span className='firstword'>This</span> page contains important information
                                    regarding the terms and
                                    conditions which apply to your trading and demat account with Growth
                                    Securities Pvt. Ltd. (hereinafter referred to as GSPL). GSPL is a
                                    Trading Member of NSE and BSE, registered with Securities & Exchange
                                    Board of India ("SEBI") and having Registration No. INZ000299236 for
                                    Cash/Derivatives/Currency Derivatives segments of NSE & BSE. Growth
                                    Securities Pvt. Ltd. is also a Depository Participant using CDSL as
                                    the Depository having SEBI Registration no.: IN-DP-623-2021. Access
                                    to your account and the usage of your account is subject to your
                                    compliance with all the terms and conditions set forth herein, read
                                    along with all documents, including but not limited to; applications
                                    forms and undertakings, signed by you during account opening. Please
                                    read this page carefully and retain it for future reference.
                                </p>
                                <p className='disc'>
                                    The website is owned, operated and maintained by Growth Securities Pvt.
                                    Ltd. (hereinafter referred to as “GSPL”), a Company incorporated under
                                    the Companies Act, 2013 having CIN: U74899GJ1996PTC119714, and having
                                    its Registered Office at 503A, 5th Floor, Tower A , WTC, Block No 51,
                                    Road 5E, Zone -5 , GIFT City, Gandhinagar - 382355
                                </p>
                                <p className='disc'>
                                    <span>
                                        Please note that the information contained herein is subject to
                                        change without notice.
                                    </span>
                                </p>
                            </Col>
                            <Col xl={4} md={12} sm={12} className="term-img">
                                <img
                                    className='terms-img'
                                    src={terms}
                                    alt='Growth terms illustration'
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default TermsConditions;