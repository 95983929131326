import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import choice from './Icons/choice.svg'





export class Premier extends Component {
    render() {
        return (
            <>
                <section className="premier_section-container">
                    <Container className="premier_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why <span>Growth</span> is the premier choice for <span>Equity Trading</span>?
                        </h1>
                        </div>
                        <Row className="premier_section-rows">
                        <Col lg={5} md={12} sm={12} className="premier_section-imgs">
                                <div className="choice-img">
                                    <img
                                        src={choice}
                                        alt="Growth choice illustration"
                                        className="choice"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} className="premier_section-texts">
                                <div className="texts">
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey='0' flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Comprehensive Suite of Services
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth offers vast services that cater to the specific needs of equity traders. The company provides access to multiple stock exchanges, allowing traders to execute trades in real-time, anywhere in the world. In addition to trading services, we offer a wide range of resources to help traders make informed decisions, such as market analysis, news and research, and technical analysis.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Expert & Experienced Team
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        The success of Growth can be mainly attributed to its experienced team of professionals. The company’s trading experts have years of experience in the financial industry, giving them the knowledge and skills to provide traders with the best possible service. The company’s team of experts is dedicated to helping traders achieve their financial goals and is available to provide support and guidance whenever needed.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        State-of-the-Art Trading Platform
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth utilizes the latest in trading technology to provide traders with a seamless and efficient trading experience. The company’s state-of-the-art trading platform is designed to meet the demands of today’s fast-paced market. It provides traders with the ability to execute trades quickly and accurately. The platform is also equipped with advanced security measures to ensure the safety of traders’ assets.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Highly Competitive Fees
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        With highly competitive fees, our company’s costs are transparent and structured fairly for traders. We allow traders to keep more profits and focus on their trading strategies rather than worrying about fees eating into their returns.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <p className="disc last_para">
                                        Growth is the number one resource for equity trading services. The company’s comprehensive services, experienced team, state-of-the-art trading platform, and highly competitive fees make it the premier choice for equity traders. You can consider us a reliable source with expertise to help you to achieve your financial goals.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Premier