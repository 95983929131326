import React from 'react';

const EscalationMatrix = () => {
  const contactDetails = [
    {
      name: 'Satish Kumar',
      role: 'Customer Care',
      address: '4/10 Second Floor, Asaf Ali Road, New Delhi-110002',
      contact: '011-43140000',
      workHours: 'Mon-Sat; 9:00 AM to 6:00 PM',
      email: 'Complaints@growthsec.in'
    },
    {
      name: 'Aryan Birla',
      role: 'Head of Customer Care',
      address: '4/10, Second Floor, Asaf Ali Road, New Delhi-110002',
      contact: '9599543216',
      workHours: 'Mon-Sat; 9:00 AM to 6:00 PM',
      email: 'aryan@growthsec.com'
    },
    {
      name: 'Himanjal Brahmbhatt',
      role: 'Compliance Officer (Stock Broking & Depositary Participants)',
      address: '4/10, Second Floor, Asaf Ali Road, New Delhi-110002',
      contact: '9311786339',
      workHours: 'Mon-Sat; 9:00 AM to 6:00 PM',
      email: 'Compliance@growthsec.in'
    },
    {
      name: 'Devansh Gupta',
      role: 'Director',
      address: '4/10, Second Floor, Asaf Ali Road, New Delhi-110002',
      contact: '011-43140006',
      workHours: 'Mon-Sat; 9:00 AM to 6:00 PM',
      emails: [
        'Devansh@growthsec.in',
        'Compliance@growthsec.in'
      ]
    }
  ];

  const complaintChannels = [
    {
      name: 'SEBI SCORES',
      url: 'https://scores.sebi.gov.in'
    },
    {
      name: 'BSE',
      url: 'https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx'
    },
    {
      name: 'NSE',
      url: 'https://investorhelpline.nseindia.com/NICEPLUS/'
    },
    {
      name: 'MCX',
      url: 'https://www.mcxindia.com/Investor-Services'
    },
    {
      name: 'CDSL',
      url: 'https://www.cdslindia.com/Footer/grievances.aspx'
    }
  ];

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-4" style={{"textAlign": "center"}}>Investor Grievances Redressal System</h1>
      <h2 className="text-xl font-semibold mb-4" style={{"textAlign": "center"}}>Escalation Matrix</h2>
      <h3 className="text-lg font-semibold mb-4" style={{"textAlign": "center"}}>Growth Securities Pvt. Ltd</h3>

      <table className="w-full border-collapse border mb-6">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2">Particulars</th>
            <th className="border p-2">Address</th>
            <th className="border p-2">Contact No.</th>
            <th className="border p-2">Email Id</th>
          </tr>
        </thead>
        <tbody>
          {contactDetails.map((contact, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="border p-2">
                <div className="font-medium">{contact.name}</div>
                <div className="text-sm text-gray-600">{contact.role}</div>
              </td>
              <td className="border p-2">{contact.address}</td>
              <td className="border p-2">
                {contact.contact}
                <div className="text-sm text-gray-600">{contact.workHours}</div>
              </td>
              <td className="border p-2">
                {contact.emails ? (
                  contact.emails.map((email, emailIndex) => (
                    <div key={emailIndex}>
                      <a 
                        href={`mailto:${email}`} 
                        className="text-blue-600 hover:underline"
                      >
                        {email}
                      </a>
                    </div>
                  ))
                ) : (
                  <a 
                    href={`mailto:${contact.email}`} 
                    className="text-blue-600 hover:underline"
                  >
                    {contact.email}
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2" style={{"textAlign": "center"}}>Working Hours</h3>
        <p style={{"textAlign": "center"}}>Monday to Saturday</p>
        <p style={{"textAlign": "center"}}>9:00 AM to 12:00 PM and 2:00 PM to 6:00 PM</p>
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Additional Complaint Channels</h3>
        <p className="mb-2">
          In absence of response/complaint not addressed to your satisfaction, 
          you may lodge a complaint with SEBI at:
        </p>
        <ul className="list-disc pl-5">
          {complaintChannels.map((channel, index) => (
            <li key={index}>
              <a 
                href={channel.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:underline"
              >
                {channel.name}: {channel.url}
              </a>
            </li>
          ))}
        </ul>
        <p className="mt-2 font-semibold">
          Please quote your Service Ticket/Complaint Ref No. while raising 
          your complaint at SEBI SCORES/Exchange portal / Depository Portal.
        </p>
      </div>

      <div className="text-sm text-gray-600">
        <p className="mb-2">
          Investment in securities markets are subject to market risks, 
          read all the related documents carefully before investing.
        </p>
        <p>
          SEBI Registration No. INZ000299236 (NSE, BSE, MCX)<br />
          DP SEBI Registration No. (CDSL): IN-DP-623-2021
        </p>
      </div>
    </div>
  );
};

export default EscalationMatrix;