import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import team from './Icons/team.svg'


export class BondsDisc extends Component {
    render() {
        return (
            <>
                <section className="bonds_disc-container">
                    <Container className="bonds_disc">
                        <div className="paraTitle">
                            <h1 className="title">
                                What is <span>SGB</span>?
                            </h1>
                        </div>
                        <Row className="bonds_disc-rows">
                            <Col lg={4} md={12} sm={12} className='gold_bond-img'>
                                <img
                                    className='teams-img'
                                    src={team}
                                    alt="Growth Team Illustration"
                                />
                            </Col>
                            <Col lg={8} md={12} sm={12} className='gold_bond-text'>
                                <p className="disc">
                                    Sovereign Gold Bond Scheme was launched by Govt in November 2015, under Gold Monetisation Scheme. Under the scheme, the issues are made open for subscription in tranches by RBI in consultation with GOI. RBI notifies the terms and conditions for the scheme from time to time. The subscription for SGB will be open as per following calendar. The rate of SGB will be declare by RBI before every new tranche by issuing a Press Release.
                                </p>
                                <p className="disc">
                                    As per RBI instructions “Every application must be accompanied by the ‘PAN Number’ issued by the Income Tax Department to the investor(s)’’ as the PAN number of the first/ sole applicant is mandatory.
                                </p>
                                <a className='trade' rel='noopener noreferrer' href='https://www1.nseindia.com/products/content/equities/sgbs/sgbs.htm'>Know More</a>
                                <i className='fa fa-arrow-right'></i>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default BondsDisc