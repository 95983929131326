import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import mutual from './Icons/why/mutual.png'
import growth from './Icons/why/growth.png'
import user from './Icons/why/user.png'
import job from './Icons/why/job.png'
import platform from './Icons/why/platform.png'
import query from './Icons/why/query.png'


export class Why extends Component {
  render() {
    return (
      <>
        <section className="why_mutual-container">
            <Container className="why-mutual">
                <div className="paraTitle">
                <h1 className="title">
                    Why <span>Mutual Funds</span>
                </h1>
                </div>
                <Row className="why-mutual-rows">
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box">
                            <img
                                className="mutual-img"
                                src={job}
                                alt="Growth job illustration"
                                width='100px' 
                            />
                            <p className="disc">
                                A mutual fund is the trust that pools the savings of a number of investors who share a common financial goal.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box">
                            <img
                                className="mutual-img"
                                src={user}
                                alt="Growth user illustration"
                                width='100px' 
                            />
                            <p className="disc">
                                Anybody with an investible surplus of as little as a few hundred rupees can invest in Mutual Funds.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box-1">
                            <img
                                className="mutual"
                                src={query}
                                alt="Growth query illustration"
                                width='100px' 
                                style={{margin: "auto", display: "flex"}}
                            />
                            <p className="disc">
                                The money thus collected is then invested by the fund manager in different types of securities. These could range from shares to debentures to money market instruments, depending upon the scheme’s stated objective.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box">
                            <img
                                className="mutual-img"
                                src={platform}
                                alt="Growth platform illustration"
                                width='100px' 
                            />
                            <p className="disc">
                                It gives the market returns and not assured returns.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box">
                            <img
                                className="mutual-img"
                                src={growth}
                                alt="Growth growww illustration"
                                width='100px' 
                            />
                            <p className="disc">
                                In the long term, market returns have the potential to perform better than other assured return products.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className="why-mutual-box">
                        <div className="box">
                            <img
                                className="mutual-img"
                                src={mutual}
                                alt="Growth mutual illustration"
                                width='100px' 
                            />
                            <p className="disc">
                                Mutual Fund is the one of the most cost efficient financial products.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    )
  }
}

export default Why