import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Learn from "./Learn";
import ScrollTab from "./ScrollTab";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Knowledge() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Knowledge Center || Learn About Stock Trading and
          Investment || What is Share Market, Understand Stock Market Basics
        </title>
      </Helmet>
      <div className="wrapper knowledgeCenter">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <Learn />
        </AnimatedSection>
        {/* <ScrollTab /> */}
      </div>
    </>
  );
}

export default Knowledge;
