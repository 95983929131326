import React from 'react';
import sitemapData from './htmlData.json';
import './Style.scss';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'


const htmlSiteMap = () => {
  return (
    <>
      <Helmet>
        <title>Growth Securities || HTML Site Map</title>
      </Helmet>
      <div className="html-sitemap">
        <Container className='htmlSiteMap'>
          <div className="htmlDataImport">
            <AnimatedSection>
            <h2 className="title">
              Sitemap
            </h2>
            </AnimatedSection>
            <hr width="100%" />
            {sitemapData.map((section, index) => (
              <div key={index} className="sitemap-section">
                <h3>{section.heading}</h3>
                <ul>
                  {section.links.map((link, subIndex) => (
                    <AnimatedSection>
                      <li key={subIndex}>
                        <a href={link.url} rel='noreferrer noopener' target='_blank'>{link.name}</a>
                      </li>
                    </AnimatedSection>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default htmlSiteMap;
