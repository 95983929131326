import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const RetirementCalculator = () => {
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [monthlySaving, setMonthlySaving] = useState('');
  const [expectedReturn, setExpectedReturn] = useState('');
  const [retirementAmount, setRetirementAmount] = useState('');

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calculateRetirementAmount = () => {
    const age = parseFloat(currentAge);
    const retirement = parseFloat(retirementAge);
    const saving = parseFloat(monthlySaving);
    const returnRate = parseFloat(expectedReturn) / 100;

    const yearsToRetirement = retirement - age;
    const monthsToRetirement = yearsToRetirement * 12;

    const amount = saving * (Math.pow(1 + returnRate, monthsToRetirement) - 1) / returnRate;

    setRetirementAmount(formatNumberWithCommas(amount.toFixed(2)));
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Retirement Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Retirement</span>
            </li>
          </ul>
          <div className="retirement-calculator calcBody">
            <div className="calculator-container">
              <h2>Retirement</h2>
              <div className="input-group">
                <label>Current Age:</label>
                <input
                  type="number"
                  value={currentAge}
                  onChange={(e) => setCurrentAge(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Retirement Age:</label>
                <input
                  type="number"
                  value={retirementAge}
                  onChange={(e) => setRetirementAge(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Monthly Saving (₹):</label>
                <input
                  type="number"
                  value={monthlySaving}
                  onChange={(e) => setMonthlySaving(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Expected Return (%):</label>
                <input
                  type="number"
                  value={expectedReturn}
                  onChange={(e) => setExpectedReturn(e.target.value)}
                />
              </div>
              <button onClick={calculateRetirementAmount}>Calculate</button>
              {retirementAmount && (
                <div className="result">
                  <p>Retirement Amount: ₹ {retirementAmount}</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  Retirement is the phase of life when an individual chooses to stop working and transitions from employment to a period of leisure and relaxation. It is a significant milestone that marks the end of one's professional career and the beginning of a new chapter in life.
                </p>
                <p className="disc">
                  Retirement planning is essential to ensure financial security and a comfortable lifestyle during this phase. It involves setting financial goals, estimating future expenses, determining the required savings and investments, and making appropriate financial decisions to achieve a stable retirement income.
                </p>
                <p className="disc">
                  Retirement age varies from country to country and can also differ based on personal choices and circumstances. It is typically associated with reaching a certain age, such as 60 or 65, but some individuals may choose to retire earlier or later depending on their financial situation and personal preferences.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculate Retirement?
                </h2>
                <p className="disc">
                  The formula used by Growth for calculating the retirement amount in this calculator is as follows:
                </p>
                <h6>
                  Retirement Amount (M) = Monthly Savings (P) * ([1 + (R/12)]^(T * 12) - 1) / (Expected Return Rate/12)
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>Retirement Amount (M)-</span> represents the amount you will have at retirement.
                  </li>
                  <li>
                    <span>Monthly Savings (P)-</span> is the amount you contribute towards retirement savings every month.
                  </li>
                  <li>
                    <span>Expected Return Rate-</span> is the estimated average annual return rate on your investments.
                  </li>
                  <li>
                    <span>Remaining Years-</span> is the number of years remaining until your retirement age.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RetirementCalculator;
