import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "./Style.scss";
import { Helmet } from "react-helmet";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const NewsPage = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 9; // Number of articles to display per page
  const [selectedCategory, setSelectedCategory] = useState("top");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [selectedArticle, setSelectedArticle] = useState(null); // Store the selected article
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  const handleCategoryChange = (category) => {
    if (category === selectedCategory) {
      return; // Avoid refetching data for the same category
    }

    setSelectedCategory(category);
    // Reset to the first page when changing categories
    setCurrentPage(1);
    setIsLoading(true); // Set loading state when changing categories
  };

  // Calculate the range of articles to display for the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to fetch news based on the selected category
  const fetchNewsByCategory = (category) => {
    // Fetch data from the News API using the provided API key
    const apiKey = "dba393913505414c95db3a6f4b05b6f1";
    const pageSize = 100; // Adjust this as needed
    let categoryToFetch = "business"; // Default category

    if (category === "market") {
      categoryToFetch = "business"; // Change this to the desired category for stock market news
    } else if (category === "trending") {
      categoryToFetch = "general"; // Change this to the desired category for trending news
    } else if (category === "world") {
      categoryToFetch = "world"; // Change this to the desired category for world news
    }

    const apiUrl = `https://newsapi.org/v2/top-headlines?country=in&category=${categoryToFetch}&pageSize=${pageSize}&apiKey=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Set the retrieved news data directly without filtering here
        setNewsData(data?.articles);
        setIsLoading(false); // Set loading state to false after data is fetched
      })
      .catch((error) => {
        console.error("Having an issue while fetching news", error);
        setIsLoading(false); // Set loading state to false in case of an error
      });
  };

  useEffect(() => {
    // Fetch news initially with the default category
    fetchNewsByCategory(selectedCategory);
  }, [selectedCategory]);

  // Filter articles based on the selected category
  const currentArticles = newsData
    ?.filter((article) => {
      if (selectedCategory === "market") {
        return (
          article.title.includes("stock") || article.title.includes("market")
        );
      } else if (selectedCategory === "trending") {
        // Add logic for trending news filtering
        // Check if article description exists and includes the keyword
        return article.description && article.description.includes("trend");
      } else if (selectedCategory === "world") {
        // Add logic for world news filtering
        // Check if article country is not India
        return article.country !== "India";
      }
      // Add more conditions for other categories as needed

      return true; // If no specific category is selected, show all news
    })
    .slice(indexOfFirstArticle, indexOfLastArticle);

  // Function to handle opening the modal and setting the selected article
  const handleOpenModal = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedArticle(null);
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Latest News || Growth Securities || Growth News Center
        </title>
      </Helmet>
      <div className="wrapper">
        <Container className="news-page">
          <Row className="newsHeaderContainer">
            <button onClick={() => handleCategoryChange("top")}>
              Top News
            </button>
            <button onClick={() => handleCategoryChange("market")}>
              Market News
            </button>
            {/* <button onClick={() => handleCategoryChange("trending")}>
              Trending News
            </button>
            <button onClick={() => handleCategoryChange("world")}>
              World News
            </button> */}
            {/* Add buttons for other categories */}
          </Row>
          {isLoading ? (
            <p
              style={{
                color: "#086894",
                paddingTop: "15%",
                paddingBottom: "35%",
                textAlign: "center",
                fontSize: "3rem",
              }}
            >
              <i className="fa fa-spinner fa-spin"></i> Gathering the Latest
              Updates ...
            </p>
          ) : (
            <AnimatedSection>
              <Row className="news-container">
                {currentArticles?.map((article, index) => (
                  <Col key={index} lg={4} md={6} sm={12}>
                    <div className="newsHolder">
                      <Card className="news-card">
                        {article.urlToImage && (
                          <Card.Img variant="top" src={article.urlToImage} />
                        )}
                        <Card.Body>
                          <Card.Title>
                            <a href={article.url}>{article.title}</a>
                          </Card.Title>
                          <Card.Text>{article.description}</Card.Text>
                          {/* Replace this button with a modal button */}
                          {/* <Button onClick={() => handleOpenModal(article)} className="Read-Article">
                          View Details
                        </Button> */}
                          <a
                            className="Read-Article"
                            href={article.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Read More
                          </a>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                ))}
              </Row>
            </AnimatedSection>
          )}
          {/* Pagination */}
          <AnimatedSection>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the previous button on the first page
              >
                {"<"}
              </button>
              {Array?.from(
                { length: Math.ceil(newsData?.length / articlesPerPage) },
                (_, i) => {
                  if (
                    i < 3 || // Display the first 3 page numbers
                    i === Math.ceil(newsData?.length / articlesPerPage) - 1 // Display the last page number
                  ) {
                    return (
                      <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </button>
                    );
                  } else if (i === 2) {
                    // Display ">" after the first 3 page numbers
                    return (
                      <button key={i} disabled>
                        {">"}
                      </button>
                    );
                  }
                  return null; // Null for other page numbers in between
                }
              )}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(newsData?.length / articlesPerPage)
                } // Disable the next button on the last page
              >
                {">"}
              </button>
            </div>
          </AnimatedSection>
        </Container>
      </div>
    </>
  );
};

export default NewsPage;
