import React from "react";
import { 
  Button, 
  Modal 
} from "react-bootstrap";

function Scores(props) {
  return (
    <>
      <div className="modal-box">
        <Modal {...props} backdrop='static' centered>
          <Modal.Body className="modal-body">
            <h3
              className="title"
              style={{
                textAlign: "center",
                color: "#808080",
                fontFamily: "'Poppins",
              }}
            >
              Filling Complaints on SCORES
            </h3>
            <h4
              className="subtitle"
              style={{
                textAlign: "center",
                color: "#269ed6",
                fontFamily: "Poppins",
              }}
            >
              Easy & Quick
            </h4>
            <ul className="lists" style={{ listStyle: "square" }}>
              <li className="list">Register on SCORES portal</li>
              <li className="list">
                Mandatory details for filling complaints on SCORES
              </li>
            </ul>
            <ul
              className="inline-lists"
              style={{
                display: "flex",
                justifyContent: "center",
                listStyle: "none",
              }}
            >
              <li
                className="inline-list"
                style={{ paddingRight: "24px", fontWeight: "600" }}
              >
                Name
              </li>
              <li
                className="inline-list"
                style={{ paddingRight: "24px", fontWeight: "600" }}
              >
                PAN
              </li>
              <li
                className="inline-list"
                style={{ paddingRight: "24px", fontWeight: "600" }}
              >
                Address
              </li>
              <li
                className="inline-list"
                style={{ paddingRight: "24px", fontWeight: "600" }}
              >
                Phone No.
              </li>
              <li
                className="inline-list"
                style={{ paddingRight: "24px", fontWeight: "600" }}
              >
                Email ID
              </li>
            </ul>
            <h5
              className="heading"
              style={{ color: "#269ed6", fontFamily: "Poppins" }}
            >
              Benefits:
            </h5>
            <ul className="lists" style={{ listStyle: "square" }}>
              <li className="list">Effective communication</li>
              <li className="list">Speedy redressal of the grievances</li>
            </ul>
            <p className="disc">
              <b>Note</b>: You may refer the website click{" "}
              <a
                href="https://scores.sebi.gov.in/"
                className="link"
                style={{ textDecoration: "none" }}
                rel='noreferrer noopener' target='_blank'
                onClick={props.onHide}
              >
                Here
              </a>{" "}
              for more information
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="close-btn" style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
              <Button className="btn btn-score" onClick={props.onHide} style={{ display: "flex", justifyContent: "center", margin: "auto", backgroundColor: "white", border: "none", color: "#269ed6", fontWeight: "700", fontSize: "1.4rem" }}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Scores;
