import React, { Component } from 'react'

export class MidColor extends Component {
    render() {
        return (
            <>
                <div className="home_mid-color">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#269ed609" d="M34.9,-65.2C43.9,-55.2,49.1,-43.3,57.2,-32.1C65.2,-20.9,76.1,-10.5,80.1,2.3C84.2,15.1,81.4,30.2,74.1,42.8C66.9,55.4,55.2,65.4,42.2,71.3C29.1,77.2,14.5,78.9,1.8,75.7C-10.8,72.5,-21.7,64.4,-33.2,57.6C-44.6,50.8,-56.8,45.3,-67.8,35.9C-78.8,26.5,-88.6,13.3,-89.6,-0.6C-90.7,-14.4,-82.8,-28.8,-73.8,-41.7C-64.8,-54.5,-54.6,-65.7,-42.1,-73.7C-29.6,-81.7,-14.8,-86.5,-0.9,-84.9C12.9,-83.3,25.8,-75.2,34.9,-65.2Z" transform="translate(100 100)" />
                    </svg>
                </div>
            </>
        )
    }
}

export default MidColor;