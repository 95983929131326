import React, { useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import './Style.scss';

function Tickets() {
  const [formData, setFormData] = useState({
    clientId: '',
    emailId: '',
    number: '',
    subject: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleCloseModal = () => setShowModal(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === 'number') {
      updatedValue = value.slice(0, 10);
    } else if (name === 'clientId') {
      updatedValue = value.slice(0, 6);
    } else if (name === 'emailId') {
      updatedValue = value.slice(0, 35);
    } else if (name === 'subject') {
      updatedValue = value.slice(0, 50);
    }
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Set the Web App URL for submitting form data
    const WEB_APP_URL =
      'https://script.google.com/macros/s/AKfycbzfbZxCN7v8F1dYWSR9aVZnoifAFh-1vSPGKaYzN0InuncMKh4hrBfQMjFXwp9XkXOZ/exec';
  
    try {
      // Prepare the data object to be sent as JSON
      const dataToSend = {
        clientId: formData.clientId,
        emailId: formData.emailId,
        number: formData.number,
        subject: formData.subject,
      };
  
      // Submit the form data using a POST request with axios
      const response = await axios.post(WEB_APP_URL, dataToSend);
  
      // Get the response data from the Apps Script
      const data = response.data;
      console.log(data); // Success message from Apps Script
  
      // Show the success message modal
      setShowModal(true);
      setModalMessage(data);
  
      // Clear the form after successful submission
      setFormData({
        clientId: '',
        emailId: '',
        number: '',
        subject: '',
      });
    } catch (error) {
      console.error('Error submitting data:', error);
  
      // Show the error message modal
      setShowModal(true);
      setModalMessage('Error submitting data. Please try again.');
    }
  };
  
  

  return (
    <>
      <div className="wrapper ticketForm">
        <Container className="formContainer">
          <div className="formsDataInput">
            <h1 className="title">Create ticket</h1>
            <p>
              To create a ticket, please ensure that all your details are filled correctly and accurately in this form. 
              Any mismatched information may result in your ticket being rejected. Thank you for your cooperation.
            </p>
            <hr />
            <form className="ticket-form" onSubmit={handleSubmit}>
              <label htmlFor="clientId">Client ID:</label>
              <input
                type="text"
                name="clientId"
                value={formData.clientId}
                onChange={handleChange}
                placeholder="Enter Client ID"
                maxLength="6" 
                required
              />

              <label htmlFor="emailId">Email ID:</label>
              <input
                type="email"
                name="emailId"
                value={formData.emailId}
                onChange={handleChange}
                placeholder="Enter Email ID"
                maxLength="35"
                required
              />

              <label htmlFor="number">Number:</label>
              <input
                type="text"
                name="number"
                value={formData.number}
                onChange={handleChange}
                placeholder="Enter Number"
                maxLength="10"
                required
              />

              <label htmlFor="subject">Subject:</label>
              <textarea
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter Subject (Max 50 characters)"
                maxLength="50"
                height={150}
                required
              />

              <button type="submit">Create a ticket</button>
            </form>
          </div>
        </Container>
      </div>

      <div className="wholeDivContainer">
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Tickets;
