import React, { Component } from 'react'
import { 
  Col, 
  Container, 
  Row 
} from 'react-bootstrap'
import video from './Icons/invest.mp4'

export class Investgrow extends Component {
  render() {
    return (
      <>
        <section className="invest_growth-container">
          <Container className='invest_growth'>
            <h2 className='quote'>
              "In investing, what is comfortable is rarely profitable."
            </h2>
            <Row className='invest_growth-rows'>
              <Col lg={6} md={12} sm={12} className="item-1">
                <div className='span-growth'>
                  <video 
                    className='growth-video'
                    src={video} 
                    autoPlay={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    width='100%'
                  />
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="item-2">
                <p className='disc'>
                  At times, you will have to step out of your comfort
                  zone to realize significant gains. Know the boundaries
                  of your comfort zone and practice stepping out of it in
                  small doses. As much as you need to know the market, you
                  need to know yourself too. 
                </p>
                <p className="disc">
                  Can you handle staying in when
                  everyone else is jumping ship? Or getting out during the
                  biggest rally of the century? There's no room for pride in
                  this kind of self-analysis. The best investment strategy can
                  turn into the worst if you don't monitor the same as per market scenario.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Investgrow