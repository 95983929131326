// MarketView.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './Style.scss'

const MarketView = () => {
    const [marketData, setMarketData] = useState({});
    const apiKey = '58MDI0VJMGOK761K'; // Replace with your Alpha Vantage API key

    // Fetch a list of Indian stock symbols (you can replace this with your data source)
    const stockSymbols = [
        'NSE:RELIANCE', 'NSE:TATASTEEL', 'NSE:INFY', 'NSE:HDFCBANK', // Add more symbols as needed
        // Add more symbols here or fetch them from your data source
    ];
    const interval = '1min'; // Change the interval as needed

    useEffect(() => {
        const fetchData = async () => {
            const dataPromises = stockSymbols.map(async (symbol) => {
                const apiUrl = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${symbol}&interval=${interval}&apikey=${apiKey}`;
                const response = await axios.get(apiUrl);
                return response.data;
            });

            try {
                const responses = await Promise.all(dataPromises);
                const formattedData = {};

                responses.forEach((response, index) => {
                    formattedData[stockSymbols[index]] = response;
                });

                setMarketData(formattedData);
            } catch (error) {
                console.error('Error fetching data from Alpha Vantage:', error);
            }
        };

        fetchData();
    }, [apiKey, stockSymbols, interval]);

    return (
        <div className="wrapper market-view">
            <Container>
                <div className='market-cards'>
                    {stockSymbols.map((symbol) => (
                        <div key={symbol} className="market-card">
                            <div className="symbol">Symbol: {symbol}</div>
                            <div className="open">Open: {marketData[symbol]?.['Time Series (1min)']?.['1. open'] || 'Loading...'}</div>
                            <div className="high">High: {marketData[symbol]?.['Time Series (1min)']?.['2. high'] || 'Loading...'}</div>
                            <div className="low">Low: {marketData[symbol]?.['Time Series (1min)']?.['3. low'] || 'Loading...'}</div>
                            <div className="close">Close: {marketData[symbol]?.['Time Series (1min)']?.['4. close'] || 'Loading...'}</div>
                            <div className="volume">Volume: {marketData[symbol]?.['Time Series (1min)']?.['5. volume'] || 'Loading...'}</div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default MarketView;
