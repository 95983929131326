import React, { useState } from 'react';
import './Style.scss';

const MutualFundCalculator = () => {
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [duration, setDuration] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [investmentType, setInvestmentType] = useState('lumpsum');
  const [result, setResult] = useState(null);

  const handleInvestmentAmountChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setInvestmentAmount(value);
  };

  const handleDurationChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setDuration(value);
  };

  const handleInterestRateChange = (event) => {
    const value = event.target.value.replace(/[^\d.]/g, '');
    setInterestRate(value);
  };

  const handleInvestmentTypeChange = (event) => {
    setInvestmentType(event.target.value);
  };

  const calculateReturns = () => {
    if (investmentType === 'sip') {
      const monthlyRate = interestRate / 12 / 100;
      const totalMonths = duration * 12;

      const maturityAmount =
        (investmentAmount *
          ((1 + monthlyRate) ** totalMonths - 1) *
          (1 + monthlyRate)) /
        monthlyRate;

      const totalInvestment = investmentAmount * totalMonths;
      const totalInterest = maturityAmount - totalInvestment;
      const expectedReturn = (totalInterest / totalInvestment) * 100;

      setResult({
        maturityAmount: maturityAmount.toFixed(2),
        totalInterest: totalInterest.toFixed(2),
        expectedReturn: expectedReturn.toFixed(2),
      });
    } else {
      const maturityAmount =
        investmentAmount * (1 + interestRate / 100) ** duration;
      const totalInterest = maturityAmount - investmentAmount;
      const expectedReturn = (totalInterest / investmentAmount) * 100;

      setResult({
        maturityAmount: maturityAmount.toFixed(2),
        totalInterest: totalInterest.toFixed(2),
        expectedReturn: expectedReturn.toFixed(2),
      });
    }
  };

  return (
    <div className="mutual-fund-calculator">
      <h2>Mutual Fund Calculator</h2>
      <div className="input-row">
        <label>Investment Amount:</label>
        <div className="input-group">
          <span className="rupee-symbol">&#x20B9;</span>
          <input
            type="text"
            value={investmentAmount}
            onChange={handleInvestmentAmountChange}
            placeholder="Enter amount"
          />
        </div>
      </div>
      <div className="input-row">
        <label>Duration (in years):</label>
        <div className="input-group">
          <input
            type="text"
            value={duration}
            onChange={handleDurationChange}
            placeholder="Enter duration"
          />
          <span className="duration-unit">years</span>
        </div>
      </div>
      <div className="input-row">
        <label>Interest Rate:</label>
        <div className="input-group">
          <input
            type="text"
            value={interestRate}
            onChange={handleInterestRateChange}
            placeholder="Enter rate"
          />
          <span className="interest-unit">%</span>
        </div>
      </div>
      <div className="input-row">
        <label>Investment Type:</label>
        <div className="input-group">
          <label className="radio-label">
            <input
              type="radio"
              value="lumpsum"
              checked={investmentType === 'lumpsum'}
              onChange={handleInvestmentTypeChange}
            />
            Lumpsum
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="sip"
              checked={investmentType === 'sip'}
              onChange={handleInvestmentTypeChange}
            />
            SIP
          </label>
        </div>
      </div>
      <button className="calculate-button" onClick={calculateReturns}>
        Calculate
      </button>
      {result && (
        <div className="result-section">
          <h3>Results:</h3>
          <div className="result-row">
            <span className="result-label">Maturity Amount:</span>
            <span className="result-value">
              &#x20B9; {result.maturityAmount}
            </span>
          </div>
          <div className="result-row">
            <span className="result-label">Total Interest:</span>
            <span className="result-value">
              &#x20B9; {result.totalInterest}
            </span>
          </div>
          <div className="result-row">
            <span className="result-label">Expected Return:</span>
            <span className="result-value">{result.expectedReturn}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MutualFundCalculator;
