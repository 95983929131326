import React, { Component } from 'react'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'
import woho from './Icons/ipo.gif'



export class Header extends Component {
  render() {
    return (
      <>
        <section className="ipo_header-container">
          <Container className="ipo_header">
            <Row className="ipo_header-rows">
              <Col lg={7} md={12} sm={12} className="ipo_header-texts">
                <h3 className="headerTitle">
                  An initial public offering (IPO) is when a private company becomes public by selling its shares on a stock exchange.
                </h3>
                <ul className="breadcrumb-links">
                  <li className="breadcrumb-link">
                    <i className='fa fa-home' /> <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-link sec">
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li className="breadcrumb-link sec">
                    <p className="link sec">
                      Initial Public Offering
                    </p>
                  </li>
                </ul>
                <a className='trade' href='https://ipo.growthsec.com'>
                  Apply IPO
                </a><i className='fa fa-arrow-right'></i>
              </Col>
              <Col lg={5} md={12} sm={12} className="ipo_header-img">
                <div className="img">
                  <img
                    className='ipo-img'
                    src={woho}
                    alt="Growth happy woho illustration"
                    width='100%'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Header