import React from "react";
import { Table } from "react-bootstrap";

function BankDetailsContainer() {
  return (
    <>
      <section className="bankDeatilsSecton">
        <div className="details-container">
          <div className="details-rows">
            <p className="disc" style={{ textAlign: "justify" }}>
              Investors are requested to note that GROWTH SECURITIES PRIVATE
              LIMITED is permitted to receive money from investors through
              designated bank accounts only named as Growth Securities Private
              Limited - Up streaming Client Nodal Bank Account{" "}
              <b>(Growth Securities Pvt. Ltd. - USCNB Account)</b>. Growth
              Securities Private Limited is also required to disclose these
              USCNB accounts to Stock Exchanges. Hence, you are requested to use
              following USCNB accounts only for the purpose of dealings in your
              trading account with us. The details of these USCNB accounts are
              also displayed by Stock Exchanges on their website under “Know /
              Locate your Stock Broker.”
            </p>
            <div className="bank_details details">
              <h2 className="subtitle">BANK DETAILS</h2>
              <p className="disc">
                (Please use the registered bank account with us and send an
                email to
                <a href="mailto:Compliance@growthsec.in">
                  {" "}
                  Compliance@growthsec.in{" "}
                </a>
                with the copy of cheque or Reference Number in case of
                NEFT/RTGS/IFT)
              </p>
              <Table striped bordered hover className="bank-table">
                <thead>
                  <tr>
                    <th>Purpose</th>
                    <th>Bank Name</th>
                    <th>Account No.</th>
                    <th>IFSC CODE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PURCHASE OF SHARES</td>
                    <td>AXIS BANK</td>
                    <td>921020021519060</td>
                    <td>UTIB0000007</td>
                  </tr>
                  <tr>
                    <td>PAYMENT OF DP DUES</td>
                    <td>AXIS BANK</td>
                    <td>921020021519060</td>
                    <td>UTIB0000007</td>
                  </tr>
                  <tr>
                    <td>PURCHASE OF MUTUAL FUNDS</td>
                    <td>AXIS BANK</td>
                    <td>921020021519060</td>
                    <td>UTIB0000007</td>
                  </tr>
                  <tr>
                    <td>PAYMENTS OF COMMODITIES</td>
                    <td>AXIS BANK</td>
                    <td>921020021519060</td>
                    <td>UTIB0000007</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="dp_details details">
              <h2 className="subtitle">DP DETAILS</h2>
              <p className="disc">
                (Kindly use the demat accounts registered with us for payin and
                margin pledge)
              </p>
              <Table striped bordered hover className="bank-table">
                <thead>
                  <tr>
                    <th>PURPOSE</th>
                    <th>TMID/CMID/CMBPID</th>
                    <th>CM NAME</th>
                    <th>DP A/C NO.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SELLING OF SHARES - NORMAL PAYIN -CDSL</td>
                    <td>90234 / M52119 / M52119</td>
                    <td>Growth securities Pvt. Ltd.</td>
                    <td>1209410000000065</td>
                  </tr>
                  <tr>
                    <td>SELLING OF SHARES - EARLY PAYIN - CDSL</td>
                    <td>CMID-M52119</td>
                    <td>Growth securities Pvt. Ltd.</td>
                    <td>1100001100021860</td>
                  </tr>
                  <tr>
                    <td>MARGIN PLEDGE OF SHARES - CDSL</td>
                    <td>TMID-90234, CMID-M52119</td>
                    <td>Growth securities Pvt. Ltd.</td>
                    <td>1209410000000071</td>
                  </tr>
                  <tr>
                    <td>MARGIN PLEDGE OF SHARES - NSDL</td>
                    <td>TMID-90234, CMID-M52119</td>
                    <td>Growth securities Pvt. Ltd.</td>
                    <td>1209410000000071</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BankDetailsContainer;
