import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Miscellaneous services
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Transaction alerts through SMS, e-locker facilities, chatbots for
        instantaneously responding to investor queries etc. have also been
        developed.
      </p>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const Miscellaneousservices = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        e. Miscellaneous services{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Miscellaneousservices;
