import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const OptionPLCalculator = () => {
    const [underlyingPrice, setUnderlyingPrice] = useState('');
    const [strikePrice, setStrikePrice] = useState('');
    const [premium, setPremium] = useState('');
    const [quantity, setQuantity] = useState('');
    const [pl, setPL] = useState(0);

    const calculatePL = () => {
        const underlying = parseFloat(underlyingPrice);
        const strike = parseFloat(strikePrice);
        const prem = parseFloat(premium);
        const qty = parseFloat(quantity);

        const plAmount = (underlying - strike) * qty - prem * qty;

        setPL(plAmount);
    };

    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Growth Calculators || Options Profit & Loss
                </title>
            </Helmet>
            <section className="allCalcBack">
                <Container className='allCalcScss'>
                    <ul className="breadcrumbLinks">
                        <li className="breadcrumb-link">
                            <i className='fa fa-home' /> <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link">
                            <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link next">
                            <span>Options P&L</span>
                        </li>
                    </ul>
                    <div className="option-pl-calculator calcBody">
                        <div className="calculator-container">
                            <h2>Options P&amp;L</h2>
                            <div className="input-group">
                                <label>Underlying Price:</label>
                                <input
                                    type="text"
                                    value={underlyingPrice}
                                    onChange={(e) => setUnderlyingPrice(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Strike Price:</label>
                                <input
                                    type="text"
                                    value={strikePrice}
                                    onChange={(e) => setStrikePrice(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Premium:</label>
                                <input
                                    type="text"
                                    value={premium}
                                    onChange={(e) => setPremium(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Quantity:</label>
                                <input
                                    type="text"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                />
                            </div>
                            <button onClick={calculatePL}>Calculate</button>
                            <div className="result">
                                <p>Profit/Loss: {pl}</p>
                            </div>
                        </div>
                        <div className="someCalcTxt">
                            <div className="paras">
                                <p className="disc">
                                    The Options P&amp;L calculator is used to calculate the profit or loss of an options trade. It considers the underlying price, strike price, premium, and quantity to determine the overall P&amp;L of the trade.
                                </p>
                                <p className="disc">
                                    The profit or loss of an options trade is determined by taking the difference between the underlying price and strike price, multiplying it by the quantity, and subtracting the premium multiplied by the quantity. A positive value indicates a profit, while a negative value indicates a loss.
                                </p>
                            </div>
                            <div className="formulaSec">
                                <h2>How to calculate Options P&amp;L?</h2>
                                <p className="disc">
                                    The formula used to calculate the profit/loss is as follows:
                                </p>
                                <h6>
                                    Profit/Loss = (Underlying Price - Strike Price) * Quantity - Premium * Quantity
                                </h6>
                                <p className="disc">
                                    Where,
                                </p>
                                <ul>
                                    <li>
                                        <span>(Underlying Price - Strike Price)-</span> This calculates the difference between the current price of the underlying asset and the strike price. It represents the intrinsic value of the option.
                                    </li>
                                    <li>
                                        <span>(Underlying Price - Strike Price) * Quantity-</span> The intrinsic value is multiplied by the number of options contracts (quantity) to determine the total profit or loss based on the difference in price.
                                    </li>
                                    <li>
                                        <span>Premium * Quantity-</span> The premium paid for the options contract is multiplied by the quantity to determine the total cost of the premiums.
                                    </li>
                                    <li>
                                        <span>(Underlying Price - Strike Price) * Quantity - Premium * Quantity-</span> The total cost of the premiums is subtracted from the total profit or loss based on the difference in price. This provides the net profit or loss of the options trade.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default OptionPLCalculator;
