import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import qu from './Icons/AppQR.png';
import './Style.scss';

function BasicExample() {
    const [showToast, setShowToast] = useState(true);

    const toggleToast = () => {
        setShowToast(!showToast);
    };

    return (
        <div className='qr-toast'>
            {showToast && (
                <Toast className='whole-toast'>
                    <button className="btn-close" onClick={toggleToast}></button>
                    <Toast.Body className='body-here'>
                        <div className="custom-toast-content">
                            <p>
                                Donwload Growth App
                            </p>
                            <img src={qu} className="rounded" alt="App QR" width={100} />
                        </div>
                    </Toast.Body>
                </Toast>
            )}
        </div>
    );
}

export default BasicExample;
