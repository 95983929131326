import React from 'react';
import { Container } from 'react-bootstrap';
import ticket from './Icons/Ticket.PNG'


function ComplaintProcess() {
    return (
        <section className="complaintProcessContainer">
            <Container className="complaintProcess">
                <h4>Complaint Filing and Checking Status Process</h4>
                <div className="steps">
                    <div className="step">
                        <span>1</span>
                        <p>Visit <a href="https://growthsec.com/" target="_blank" rel="noopener noreferrer">https://growthsec.com/</a> and click on ‘SUPPORT’</p>
                    </div>
                    <div className="step">
                        <span>2</span>
                        <p>Select / Click – Create a Ticket</p>
                    </div>
                    <div className="step">
                        <span>3</span>
                        <p>Therein fill your client id, mobile, mail & issue</p>
                    </div>
                    <div className="step">
                        <span>4</span>
                        <p>Submit your issues by clicking on Submit button</p>
                    </div>
                    <div className="step">
                        <span>5</span>
                        <p>You will receive Ticket Raised confirmation and ticket id on screen, mobile & mail</p>
                    </div>
                    <div className="step">
                        <span>6</span>
                        <p>Click on Ticket status to enter your ticket id and check its status</p>
                    </div>
                    <div className="step">
                        <span>7</span>
                        <p>To escalate it further, please follow the escalation matrix</p>
                    </div>
                    <img src={ticket} alt="ticket flowchart" className="KycImg ticket" width="100%" />
                </div>
            </Container>
        </section>
    );
}

export default ComplaintProcess;
