import React, { Component } from 'react'

import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import whatis from './Icons/ipo-plan.svg'


export class WhatIs extends Component {
    render() {
        return (
            <>
                <section className="what_is-container">
                    <Container className="what_is">
                        <div className="paraTitle">
                            <h1 className="title">
                                What is <span>IPO</span> ?
                            </h1>
                        </div>
                        <Row className="what_is-rows">
                            <Col lg={5} md={12} sm={12} className="ipo_header-img">
                                <img
                                    className='whatis-img'
                                    src={whatis}
                                    alt="Growth what is illustration"
                                    width='100%'
                                />
                            </Col>
                            <Col lg={7} md={12} sm={12} className="ipo_header-texts">
                                <div className="texts">
                                    <p className="disc">
                                        An initial public offering refers to the process of offering shares of a private corporation to the public in a new stock issuance for the first time. An IPO allows a company to raise equity capital from public investors.
                                    </p>
                                    <p className="disc">
                                        An IPO is a big step for a company as it provides the company with access to raising a lot of money. This gives the company a greater ability to grow and expand. The increased transparency and share listing credibility can also be a factor in helping it obtain better terms when seeking borrowed funds as well.
                                    </p>
                                    <p className="disc">
                                        The transition from a private to a public company can be an important time for private investors to fully realize gains from their investment as it typically includes a share premium for current private investors. Meanwhile, it also allows public investors to participate in the offering.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default WhatIs