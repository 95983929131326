import React from 'react' 
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import support from './Icons/Support1.svg'




function Header() {
    return (
        <>
            <section className="helpHeaderSection">
                <Container className="help-container">
                    <Row className="help-rows">
                        <Col lg={8} md={6} sm={12} xs={12} className='left-help'>
                            <div className="help-contents">
                                <h1 className="headerTitle">
                                    Facing problem...?
                                </h1>
                                <h2 className="headerSubtitle">
                                    Don't worry, we are here to help you!
                                </h2>
                                <h5 className="subtitle">
                                    Try solving your queries using <b>Chatbot</b> or these following links :-
                                </h5>
                                <div className="links">
                                    <ul className="top-links">
                                        <li className="link">
                                            <a href="/faq" className="support-links" title='frequently asked questions answers'>
                                                <i className="fa fa-question" aria-hidden="true"></i>
                                                <span>FAQs</span>
                                            </a>
                                        </li>
                                        <li className="link">
                                            <a href="/contact" className="support-links" title='contact us'>
                                                <i className="fa fa-address-book" aria-hidden="true"></i>
                                                <span>Contact us</span>
                                            </a>
                                        </li>
                                        <li className="link">
                                            <a href="/blogs" className="support-links" title='read all our latest blogs'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z" /></svg>
                                                <span>
                                                    Read Blogs
                                                </span>
                                            </a>
                                        </li>
                                        <li className="link">
                                            <a href="/ticket" className="support-links" title='create a ticket'>
                                                <i className="fa fa-ticket" aria-hidden="true"></i>
                                                <span>
                                                    Create a ticket
                                                </span>
                                            </a>
                                        </li>
                                        <li className="link">
                                            <a href="/knowledgeCenter" className="support-links" title='stock market knowledge center'>
                                                <i className="fa fa-info" aria-hidden="true"></i>
                                                <span>Knowledge Center</span>
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                        <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link sec">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link next">
                                        <span>Supports</span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='right-help'>
                            <div className="img-contents">
                                <img
                                    src={support}
                                    alt="Growth Support Team"
                                    className="supportImg"
                                    width='100%'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Header