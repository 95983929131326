import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap';
import knowledge from './Icons/knowledge.svg';


export class Header extends Component {
    render() {
        return (
            <>
                <section className='header-knowledgeCenter knowledge_center-container'>
                    <Container className='knowledge_center'>
                        <Row className='knowledge_center-rows'>
                            <Col xl={8} md={12} sm={12} className='knowledge_center-text'>
                                <p className="disc">
                                    <span>A</span> share market is where shares are either issued or traded in.
                                </p>
                                <p className="disc">
                                    A share market is similar to a stock market. The key difference is
                                    that a stock market helps you trade financial instruments like bonds,
                                    mutual funds, derivatives as well as shares of companies. A share
                                    market only allows trading of shares
                                </p>
                                <p className="disc" style={{ marginBottom: "4%" }}>
                                    The key factor is the stock exchange – the basic platform that provides
                                    the facilities used to trade company stocks and other securities. A stock
                                    may be bought or sold only if it is listed on an exchange. Thus, it is the
                                    meeting place of the stock buyers and sellers. India's premier stock
                                    exchanges are the Bombay Stock Exchange and the National Stock Exchange
                                </p>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                        <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link sec">
                                        <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link next">
                                        <span>Knowledge Center</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xl={4} md={12} sm={12} className='knowledge_center-img'>
                                <img
                                    className=''
                                    src={knowledge}
                                    alt="Growth Knowledge Center"
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header;