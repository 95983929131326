import React, { Component } from 'react'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'
import currency from './Icons/coin.svg'


export class Header extends Component {
  render() {
    return (
      <>
        <section className="currency_header-section-container">
          <Container className="currency_header-section">
            <Row className="currency_header-section-rows">
              <Col lg={7} md={12} sm={12} className="currency_header-section-texts">
                <div className="texts">
                  <h4 className="headerTitle">
                    Currency trading is also known as Forex trading, is the buying and selling of different world currencies with the intention of making a profit.
                  </h4>
                  <ul className="breadcrumb-links">
                    <li className="breadcrumb-link">
                      <i className='fa fa-home' /> <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-link">
                      <i className="fa fa-angle-double-right"></i>
                    </li>
                    <li className="breadcrumb-link">
                      <p className="link">
                        Currency Trading
                      </p>
                    </li>
                  </ul>
                  <a href="https://kyc.growthsec.com" className="trade">Start Currency Trading</a> <i className="fa fa-arrow-right"></i>
                </div>
              </Col>
              <Col lg={5} md={12} sm={12} className="currency_header-section-img">
                <div className="img">
                  <img
                    src={currency}
                    alt="Growth currency illustration"
                    className="coin"
                    width='100%'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Header