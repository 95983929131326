import React, { Component } from 'react'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'
import mutualfund from './Icons/mutual_fund.jpg'


export class Header extends Component {
  render() {
    return (
      <>
        <section className="mutual_fund-container">
          <Container className='mutual_fund'>
            <Row className="mutual_fund-rows">
              <Col lg={7} md={12} sm={12} className="mutual_fund-texts">
                <div className="texts">
                  <h4 className="headerTitle">
                    Maximize your investments with growth securities! Choose mutual funds that
                    prioritize growth opportunities for long-term gains. With expert management and a
                    focus on growing companies, you can take advantage of the stock market's potential
                    for growth. Invest smart with us, and secure your financial future for a long run.
                  </h4>
                  <ul className="breadcrumb-links">
                    <li className="breadcrumb-link">
                      <i className='fa fa-home' /> <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-link">
                      <i className="fa fa-angle-double-right"></i>
                    </li>
                    <li className="breadcrumb-link">
                      <p className="link">
                        Mutual Funds
                      </p>
                    </li>
                  </ul>
                  <a className='trade' href='https://kyc.growthsec.com'>Start Invest</a><i className='fa fa-arrow-right'></i>
                </div>
              </Col>
              <Col lg={5} md={12} sm={12} className="mutual_fund-img">
                <div className="imgs">
                  <img
                    className='mutual_fund-img'
                    src={mutualfund}
                    alt="Growth mutual funds illustration"
                    width='100%'
                    height='400px'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Header;