import React, { useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import './Style.scss'

const TicketForm = () => {
  const [formData, setFormData] = useState({
    client_Id: '',
    email_Id: '',
    number: '',
    subject: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleCloseModal = () => setShowModal(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Enforce field limitations here
    let updatedValue = value;
    if (name === 'number') {
      // Allow only up to 10 digits for the 'number' field
      updatedValue = value.slice(0, 10);
    } else if (name === 'client_Id') {
      // Allow only up to 6 characters for the 'client_Id' field
      updatedValue = value.slice(0, 6);
    } else if (name === 'email_Id') {
      // Allow only up to 35 characters for the 'email_Id' field
      updatedValue = value.slice(0, 35);
    } else if (name === 'subject') {
      // Allow only up to 50 characters for the 'subject' field
      updatedValue = value.slice(0, 50);
    }
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Set the Web App URL
    const WEB_APP_URL = 'https://script.google.com/macros/s/AKfycbyWnfF2P9xEQcj8bVR9aPBiGy6lPQTIG1QlVgekbqCm4855Ou4nrl3hzM-9vLAZMHgQpQ/exec';

    try {
      // Submit the form data using a POST request
      const response = await fetch(WEB_APP_URL, {
        method: 'POST',
        body: new FormData(event.target),
      });

      const data = await response.text();
      console.log(data); // Success message from Apps Script
      setShowModal(true);
      setModalMessage(data);

      // Clear the form after submission
      setFormData({
        client_Id: '',
        email_Id: '',
        number: '',
        subject: '',
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      setShowModal(true);
      setModalMessage('Error submitting data. Please try again.');
    }
  };

  return (
    <>
      <div className="wrapper ticketForm">
        <Container className="formContainer">
          <div className="formsDataInput">
            <h1 className="title">Create ticket</h1>
            <p>
              To create a ticket, please ensure that all your details are filled correctly and accurately in this form. 
              Any mismatched information may result in your ticket being rejected. Thank you for your cooperation.
            </p>
            <hr />
            <form className="ticket-form" onSubmit={handleSubmit}>
              <label htmlFor="client_Id">Client ID:</label>
              <input
                type="text"
                name="client_Id"
                value={formData.client_Id}
                onChange={handleChange}
                placeholder="Enter Client ID"
                maxLength="6" // Set the maximum length to 6 characters
                required
              />

              <label htmlFor="email_Id">Email ID:</label>
              <input
                type="email"
                name="email_Id"
                value={formData.email_Id}
                onChange={handleChange}
                placeholder="Enter Email ID"
                maxLength="35" // Set the maximum length to 35 characters
                required
              />

              <label htmlFor="number">Number:</label>
              <input
                type="text"
                name="number"
                value={formData.number}
                onChange={handleChange}
                placeholder="Enter Number"
                maxLength="10" // Set the maximum length to 10 digits
                required
              />

              <label htmlFor="subject">Subject:</label>
              <textarea
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter Subject (Max 50 characters)"
                maxLength="50" // Set the maximum length to 50 characters
                height={150}
                required
              />

              <button type="submit">Create a ticket</button>
            </form>
          </div>
        </Container>
      </div>

      {/* Modal for Success/Error Message */}
      <div className="wholeDivContainer">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  );
};

export default TicketForm;