import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import Header from "./Header";
import HelpBoxes from "./HelpBoxes";
import AppLink from "./AppLink";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

export class Support extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Support || Help Portal</title>
        </Helmet>
        <div className="wrapper">
          <AnimatedSection>
            <Header />
          </AnimatedSection>
          <AnimatedSection>
            <HelpBoxes />
          </AnimatedSection>
          <AnimatedSection>
            <AppLink />
          </AnimatedSection>
        </div>
      </>
    );
  }
}

export default Support;
