import React from 'react'
import {
    Container,
    Tab,
    Row,
    Col,
    Nav,
    Accordion
} from 'react-bootstrap'



function Queries() {

    return (
        <>
            <section className="queContainer">
                <Container className="que">
                    <Tab.Container id="" defaultActiveKey="first">
                        <Row>
                            <Col lg={2} md={2} sm={12} xs={12} className='pillSide'>
                                <Nav variant="tabs" className="flex-column">
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="first" >
                                            <span>Getting Started</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="second">
                                            <span></span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="third">
                                            <span>Offline Opening</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="fourth">
                                            <span>Closing Account</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg={10} md={10} sm={12} xs={12} className='queAns'>
                                <Tab.Content>
                                    <Tab.Pane className='contBack' eventKey="first">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0" className='borderAccord'>
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">What is trading?</h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Trading refers to the act of buying and selling financial instruments, such as stocks, bonds, currencies, commodities, or derivatives, with the aim of making a profit. Traders may operate in different markets and timeframes, from short-term intra-day trades to long-term investments, and use various strategies and tools, such as technical analysis, fundamental analysis, charting, algorithmic trading, or options trading, to identify opportunities and manage risk. Trading can be done by individuals, institutional investors, or through automated systems, and can involve significant risks and rewards, depending on the market conditions, the trader's skills, and the capital invested.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What is stock market?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            The stock market, also known as the equity market, is a system or network of financial markets where publicly traded stocks and other securities are bought and sold. It is a platform where buyers and sellers can trade stocks, bonds, derivatives, and other financial instruments.
                                                        </p>
                                                        <p className="disc">
                                                            The stock market enables companies to raise capital by issuing shares of stock to the public, which can be bought and sold on the stock exchange. Investors can buy and sell these shares in the secondary market, hoping to make a profit through price appreciation or dividends.
                                                        </p>
                                                        <p className="disc">
                                                            Stock markets are typically divided into national or regional exchanges, such as the New York Stock Exchange (NYSE) in the United States or the Tokyo Stock Exchange in Japan. These exchanges provide a platform for buyers and sellers to trade securities, and they regulate the market to ensure transparency, fairness, and investor protection.
                                                        </p>
                                                        <p className="disc">
                                                            The stock market plays a crucial role in the economy by providing businesses with access to capital and investors with opportunities to invest and generate returns. It is also an important source of information about the financial health of companies and the broader economy. The performance of the stock market is often used as a barometer of economic activity and investor sentiment.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Can I trade without a stockbroker?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            In most cases, you cannot trade on a stock exchange without a stockbroker. A stockbroker is a licensed professional who is authorized to buy and sell securities on behalf of clients. They act as intermediaries between buyers and sellers, helping to facilitate trades and providing investment advice and research.
                                                        </p>
                                                        <p className="disc">
                                                            Individual investors typically need to open an account with a brokerage firm in order to buy and sell stocks on an exchange. The brokerage firm will provide access to trading platforms, research tools, and other resources that can help investors make informed decisions about their investments.
                                                        </p>
                                                        <p className="disc">
                                                            However, some companies offer direct stock purchase plans (DSPPs) that allow investors to buy shares directly from the company without going through a broker. This option is usually only available for companies that are listed on a stock exchange and have established DSPPs.
                                                        </p>
                                                        <p className="disc">
                                                            In addition, some online platforms offer commission-free trading for certain types of securities, such as exchange-traded funds (ETFs) or cryptocurrencies. These platforms typically make money through other means, such as interest on cash balances or payment for order flow.
                                                        </p>
                                                        <p className="disc">
                                                            Overall, while it may be possible to trade without a traditional stockbroker, it is important to carefully evaluate the risks and benefits of any alternative trading methods before making investment decisions.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Can my demat account have joint holders?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Yes, it is possible to have joint holders for a Demat account. A Demat account is an electronic account that is used to hold securities in a dematerialized or electronic form. It is similar to a bank account, but instead of holding money, it holds stocks, bonds, mutual fund units, and other financial instruments.
                                                        </p>
                                                        <p className="disc">
                                                            Having joint holders for a Demat account means that the account is jointly held by two or more individuals. This can be beneficial in situations where multiple people want to invest together or share ownership of the securities held in the account.
                                                        </p>
                                                        <p className="disc">
                                                            To open a joint Demat account, all the account holders must provide their details and sign the account opening form. Each account holder will have equal rights and responsibilities for the account, including the ability to trade and manage the securities held in the account.
                                                        </p>
                                                        <p className="disc">
                                                            It is important to note that joint holders must agree on all transactions related to the account, and any decision to sell or transfer securities must be made jointly. Additionally, joint accounts may have different taxation and legal implications, so it is recommended to consult with a financial advisor or tax professional before opening a joint Demat account.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Why is a demat account in trading so important?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            A Demat account is an essential component of trading in modern financial markets. Here are some reasons why a Demat account is important in trading:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                Safe and secure storage of securities: A Demat account allows investors to hold securities in electronic or dematerialized form. This eliminates the risk of physical certificates being lost, stolen, or damaged, which was a common problem in the past.
                                                            </li>
                                                            <li className="list">
                                                                Easy transfer of securities: Transferring securities from one account to another is much easier and faster with a Demat account. It eliminates the need for physical delivery of securities and simplifies the process of buying and selling securities.
                                                            </li>
                                                            <li className="list">Faster settlement process: With a Demat account, the settlement process is much faster as there is no need for physical delivery of securities. This results in faster credit of shares to the investor's account after a trade has been executed.
                                                            </li>
                                                            <li className="list">Lower transaction costs: Trading in dematerialized form reduces transaction costs as there is no need for stamp duty, handling charges, or other fees associated with physical delivery of securities.
                                                            </li>
                                                            <li className="list">
                                                                Access to a wider range of financial instruments: A Demat account provides access to a wide range of financial instruments such as stocks, bonds, mutual funds, and exchange-traded funds (ETFs), which can be bought and sold easily.
                                                            </li>
                                                        </ul>
                                                        <p className="disc">
                                                            Overall, a Demat account is important in trading as it provides a secure and convenient way to hold and transfer securities, reduces transaction costs, and provides access to a wider range of financial instruments. It also simplifies the process of trading and settlement, making it easier for investors to participate in the financial markets.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                        Can I trade when markets are closed or shut down?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                        No, you cannot trade on Indian stock exchanges when the markets are closed or shut down. The Indian stock exchanges operate during specific hours, which are known as market hours or trading hours. These hours are set by the stock exchange and are based on Indian Standard Time (IST).
                                                        </p>
                                                        <p className="disc">
                                                        The National Stock Exchange of India (NSE) and the Bombay Stock Exchange (BSE), which are the two major stock exchanges in India, operate from Monday to Friday, except on holidays declared by the exchange. The market hours for both exchanges are as follows:
                                                        </p>
                                                        <ul className="dots">
                                                            <li className="dot">Pre-open session: 9:00 am to 9:15 am IST</li>
                                                            <li className="dot">Normal trading session: 9:15 am to 3:30 pm IST</li>
                                                            <li className="dot">Closing session: 3:30 pm to 3:40 pm IST</li>
                                                        </ul>
                                                        <p className="disc">
                                                        During the pre-open session, investors can place orders to buy or sell securities, but these orders are not executed until the market opens for normal trading. The closing session is used to determine the closing price of securities for the day.
                                                        </p>
                                                        <p className="disc">
                                                        Outside of these hours, the markets are closed, and trading is not possible. However, some trading activities, such as pre-market trading and after-hours trading, may be available for certain securities, but these are typically limited in scope and may involve additional risks.
                                                        </p>
                                                        <p className="disc">
                                                        It is important to note that trading hours may be affected by holidays, early closures, or other events that can impact market activity. As a result, it is always important to check the trading hours of the exchange or security you are interested in before making any trades.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                        Is it possible to have varied demat and trading accounts?                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                        Yes, it is possible to have a different demat account and trading account in India. In fact, it is common for investors to have separate accounts for trading and holding securities.
                                                        </p>
                                                        <p className="disc">
                                                        A demat account is used for holding securities in electronic form, while a trading account is used for buying and selling securities. While both accounts are necessary for trading in the stock market, they serve different purposes.
</p>
                                                        <p className="disc">
                                                        Investors can choose to open a demat account and a trading account with the same broker or with different brokers. It is important to note that while a demat account can be used to hold securities from multiple trading accounts, a trading account can be linked to only one demat account at a time.
                                                        </p>
                                                        <p className="disc">
                                                        Having separate accounts for trading and holding securities can offer some benefits. For example, it allows investors to choose a broker based on their trading requirements and preferences, while also being able to hold their securities in a separate account that is not tied to the broker.
</p>
                                                        <p className="disc">
                                                        It is important to choose a reputable and reliable broker when opening a demat and trading account. Investors should also ensure that they understand the terms and conditions associated with the accounts and the fees that will be charged by the broker.
</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                        How many Sectors are there to invest in Stock Market?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                        There are multiple sectors to invest in the Indian stock market. The exact number of sectors may vary depending on how they are defined, but broadly speaking, there are around 11 major sectors in the Indian stock market. These sectors are:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">Banking and finance</li>
                                                            <li className="list">Consumer goods</li>
                                                            <li className="list">Energy</li>
                                                            <li className="list">Healthcare</li>
                                                            <li className="list">Information technology</li>
                                                            <li className="list">Infrastructure</li>
                                                            <li className="list">Manufacturing</li>
                                                            <li className="list">Metals ani mining</li>
                                                            <li className="list">pharmaceuticals</li>
                                                            <li className="list">Services</li>
                                                            <li className="list">Telecom</li>
                                                        </ul>
                                                        <p className="disc">
                                                        Each sector consists of several companies that operate in that particular industry or segment. By investing in different sectors, investors can diversify their portfolio and potentially reduce the overall risk of their investments. It is important for investors to research and understand the performance and trends of each sector before making any investment decisions.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                        What are the 4 types of stock market?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                        The 4 types of financial markets are currency markets, money markets, derivative markets, and capital markets. Capital markets are used to sell equities (stocks), debt securities.                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane className='contBack' eventKey="second">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to open a demat account online through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Opening a demat account online is a simple and convenient process. Here are the steps you can follow to open a demat account online:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                <b>Visit our website:</b> Click on the button to open a demat account.
                                                            </li>
                                                            <li className="list">
                                                                <b>Fill in the application form:</b> You will be required to fill in an application form with personal and financial details, including your PAN card and bank account details.
                                                            </li>
                                                            <li className="list">
                                                                <b>Submit supporting documents:</b> You will also need to upload supporting documents like your Aadhaar card, address proof, and passport size photograph.
                                                            </li>
                                                            <li className="list">
                                                                <b>e-sign the application:</b> Once you have filled in all the details and uploaded the necessary documents, you will be required to e-sign the application using Aadhaar-based e-KYC verification.
                                                            </li>
                                                            <li className="list">
                                                                <b>In-person verification:</b> After the online verification is complete, the DP may send a representative to verify your identity and address in-person.
                                                            </li>
                                                            <li className="list">
                                                                <b>Receive your account details:</b> Once your demat account is opened, you will receive your account details, including your demat account number, login ID, and password.
                                                            </li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any charges to open a demat account through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any hidden charges to buy or sell stocks?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to get verified my account online?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I forget my ID or Password?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I see password not match again and again?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className='contBack' eventKey="third">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className='contBack' eventKey="fourth">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </>
    )
}

export default Queries