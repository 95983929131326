import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Define from "./Define";
import Grow from "./Grow";
import Why from "./Why";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function CurrencyTrading() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Currency Trading || Best currency or forex
          trading platform in India
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <Define />
        </AnimatedSection>
        <AnimatedSection>
          <Grow />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
      </div>
    </>
  );
}

export default CurrencyTrading;
