import React, { Component } from 'react'
import Header from './Header'
import Queries from './Queries'

export class TradingMarket extends Component {
  render() {
    return (
      <>
        <div className="wrapper">
            <Header />
            <Queries />
        </div>
      </>
    )
  }
}

export default TradingMarket