import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            closeButton
        >
            <Modal.Header closeButton>
                Terms & conditions of the quiz.
            </Modal.Header>
            <Modal.Body>
                <p className="disc">
                    <ul className='quizLists'>
                        <li>
                            This quiz is for educational purposes only and does not provide financial advice. The questions and answers in this quiz are designed to test your knowledge of stock market concepts and should not be interpreted as recommendations or endorsements for specific investment actions. Always do your own research and consult with a qualified financial professional before making any investment decisions.
                        </li>
                        <li>
                            The information presented in this quiz may not be comprehensive or up-to-date, and it is subject to change without notice. The performance of stocks and other investment instruments can vary greatly, and past performance is not indicative of future results.
                        </li>
                        <li>
                            It is important to remember that investing in the stock market involves risks, and you should carefully consider your own financial situation, investment goals, and risk tolerance before making any investment decisions. Diversification, asset allocation, and proper risk management are key principles to follow when investing.
                        </li>
                        <li>
                            The creator of this quiz, developers do not assume any responsibility or liability for the accuracy, completeness, or usefulness of the information provided in this quiz. By participating in this quiz, you acknowledge and agree to these terms and release the creators from any claims or damages arising from your use of the quiz or reliance on its content.
                        </li>
                        <li>
                            This quiz should not be used as a substitute for professional financial advice. If you have specific questions or concerns about your financial situation or investment strategy, it is recommended that you consult with a qualified financial advisor or planner.
                        </li>
                    </ul>
                </p>
            </Modal.Body>
        </Modal>
    );
}

function QuizTerms() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button className='quizTerms' onClick={() => setModalShow(true)}>
                Quiz Terms
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default QuizTerms;
