import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import Refunds from '../Icons/Refunds.svg'
import { Helmet } from 'react-helmet'

export class Refund extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Refund Policy
                    </title>
                </Helmet>
                <section className='refund-container'>
                    <Container className='refund'>
                        <h1 className='title'>
                            Re<span>fund</span> & Cancella<span>tion</span> Policy
                        </h1>
                        <Row className='refund-rows'>
                            <Col lg={8} md={12} sm={12} className='refund-text term-text'>
                                <p className='disc'>
                                    The Refund & Cancellation policy for all payments made towards account
                                    opening or any other services using any mode of payment shall stand as
                                    under:
                                </p>
                                <ul>
                                    <li className="list">
                                        The Fees paid towards account opening charges for enabling equities and
                                        commodities, or any other services is non-refundable
                                    </li>
                                    <li className="list">
                                        Pick up of required documents related to the account opening procedure is
                                        subject to availability of our representatives, given at any particular
                                        time and location.
                                    </li>
                                    <li className="list">
                                        In case your account has not been opened by Team GSPL, after the fifteen
                                        day passing by from the day of collection of all necessary supporting
                                        documents and receipt of all due authorizations from you, you may request
                                        for a full refund of the charges as paid by you towards account opening.
                                    </li>
                                    <li className="list">
                                        In case you have paid the charges relating to account opening multiple
                                        times, please create a ticket here and we will initiate the necessary
                                        procedure to refund your money.
                                    </li>
                                </ul>
                                <p className="disc">
                                    <span>
                                        Note: The completion of the refund procedure is subject to agencies such as
                                        banks, payment gateways.
                                    </span>
                                </p>
                            </Col>
                            <Col lg={4} md={12} sm={12} className="refund-img">
                                <img
                                    className=''
                                    src={Refunds}
                                    alt='Growth refunds illustration'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Refund