import React from "react";
import './Style.scss'
import Cookie from "./Cookie";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";


const Cookies = () => {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Cookie Policy</title>
      </Helmet>
      <div className="wrapper cookiePolicyPage">
        <AnimatedSection>
          <Container>
            <div className="cookies-container">
              <div className="cookies-content">
                <h1>Data Usage and Cookie Policy</h1>
                <p>
                  At Growth Securities, we are committed to protecting your privacy and
                  providing a seamless user experience. Our website uses cookies to
                  improve functionality and performance.
                </p>
                <p>
                  By using our website, you agree to our{" "}
                  <Cookie /> and{" "}
                  <a href="/terms-conditions">Terms & Conditions</a>, including the use of
                  cookies.
                </p>
                <p>
                  If you have any questions or concerns regarding our data usage and
                  cookie policy, please <a href="/contact">contact us</a>.
                </p>
              </div>
            </div>
          </Container>
        </AnimatedSection>
      </div>
    </>
  );
};

export default Cookies;
