import React, {useState} from 'react'
// import Ticket from './Ticket'
import { Table } from 'react-bootstrap';



function Withdraw() {

//   const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <section className="withdrawContainer">
                <div className="withdraw">
                    <ul className="lists">
                        <li className="list">
                            <p className="disc">Fund withdrawal requests can be placed from Growth App.</p>
                        </li>
                        <li className="list">
                            <p className="disc">Funds transferred cannot be withdrawn on the same day.</p>
                        </li>
                        <li className="list">
                            <p className="disc">Fund withdrawal requests are processed at cut-off times shown in the table below. If the request is placed before the cut-off time, the funds are typically credited to the bank account within 24 hours from the cut-off time. If the request is placed after the cut-off time, it takes an additional working day (up to 48 hours in total) for the funds to be credited to the bank account.</p>
                        </li>
                    </ul>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Withdrawal day</th>
                                <th>Cut-off time for equity, FO, currency</th>
                                <th>Cut-off time for commodity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Weekday</td>
                                <td>10:00 PM</td>
                                <td>08:00 AM</td>
                            </tr>
                            <tr>
                                <td>Saturday</td>
                                <td>04:30 PM</td>
                                <td>04:00 PM</td>
                            </tr>
                            <tr>
                                <td>Sunday or public holiday</td>
                                <td>Withdrawal is processed on the next working day.</td>
                                <td>Withdrawal is processed on the next working day.</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <p className="infoText">
                    If the funds are still not received, please <a href="/ticket" className="ticketLinkBtn">create a ticket</a>
                    {/* <button className="ticketLinkBtn" href="#SCORES" onClick={() => setModalShow(true)}>create a ticket</button><Ticket show={modalShow} onHide={() => setModalShow(false)} /> */}
                    {/* &amp; <a href="https://docs.google.com/forms/d/e/1FAIpQLScIY_1QVJA83La-ldAWgBWzWoNpDcB6kaCAOx8ZAxO9CZTEbg/viewform?embedded=true" className="link">create a ticket</a> */}
                    
                </p>
            </section>
        </>
    )
}

export default Withdraw