import React, { Component } from 'react'
import { Col, Container, Row, Tab, Nav } from 'react-bootstrap'
import benifit from './Icons/benefit.svg'



export class Benefits extends Component {
    render() {
        return (
            <>
                <section className="benefits_section-container">
                    <Container className="benefits_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Benefits of <span>Algorithmic Trading</span>
                        </h1>
                        </div>
                        <Row className="benefits_section-rows">
                            <Col lg={4} md={12} sm={12} className="benefits_section-img">
                                <div className="imgs">
                                    <img
                                        className='benifit-img'
                                        src={benifit}
                                        alt="Growth Benefits Section Img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={8} md={12} sm={12} className="benefits_section-txt">
                                <div className="tabs">
                                    <Tab.Container id="left-tabs" defaultActiveKey="first">
                                        <Row>
                                            <Col md={4} sm={12}>
                                                <Nav variant="tabs" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first">
                                                            <h6 className="head">
                                                                Best Execution:
                                                            </h6>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second">
                                                            <h6 className="head">
                                                                Low Latency:
                                                            </h6>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third">
                                                            <h6 className="head">
                                                                Low Costs:
                                                            </h6>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="fourth">
                                                            <h6 className="head">
                                                                No Human Error:
                                                            </h6>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col md={8} sm={12} className='bordered'>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <p className="disc">
                                                            Trades are often executed at the best possible prices.
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <p className="disc">
                                                            Trade order placement is instant and accurate (there is a high chance of execution at the desired levels). Trades are timed correctly and instantly to avoid significant price changes.
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <p className="disc">
                                                            Reduced transaction costs.
                                                        </p>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth">
                                                        <p className="disc">
                                                            Reduced risk of manual errors or mistakes when placing trades. Also negates human traders; tendency to be swayed by emotional and psychological factors.
                                                        </p>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Benefits