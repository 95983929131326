import React from 'react'
import './Style.scss'
import { Container } from 'react-bootstrap'
import accountIll from '../Icons/Account.svg'


function Account() {
    return (
        <>
            <section className="accountSection-Container">
                <Container className='midThings'>
                    <img src={accountIll} alt="" className="accountImg" width="100%" />
                    <div className="texts">
                        <h6 className="accountTitle">
                            For Simple & Secured Life
                        </h6>
                        <p>
                            Start Investing Today!
                        </p>
                        <div className="btns">
                            <a href="https://kyc.growthsec.com" target="_blank" rel='noreferrer noopener' className="link1">
                                Register
                            </a>
                            <a href="https://trade.growthsec.com" target="_blank" rel='noreferrer noopener' className="link2">
                                Trade Now
                            </a>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Account