import React from "react";
import Header from "./Header";
import { Helmet } from "react-helmet";
import "./Style.scss";
import CFunds from "./CFunds";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function FundTransfer() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Fund Transfer || Deposite Funds || Withdraw Funds
          || Fund Transfer using Growth App UPI | Net Banking and More
        </title>
      </Helmet>
      <div className="wrapper testerr">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
          <CFunds />
      </div>
    </>
  );
}

export default FundTransfer;
