import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import welcome from './Icons/welcome.svg'
import logoshade from './Icons/logoshade.svg'




export class About extends Component {
    render() {
        return (
            <>
                <section className='about_section-container'>
                    <Container className='about_section'>
                        <div className="paraTitle">
                            <h1 className="title">
                                Welcome to<span> Growth </span>
                            </h1>
                        </div>
                        <Row className='about_section-rows'>
                            <Col xl={6} md={12} sm={12} className='about_section-txt'>
                                <img 
                                    className='logoshade'
                                    src={logoshade}
                                    alt="growth logo growth securities brand logo" 
                                />
                                <p className='disc'>
                                    Growth is one of the fastest growing investment 
                                    solutions companies that provide a wide range of services to its vast 
                                    and diversified client base. We always aim to deliver best in class technology
                                    to help our clients execute their trades in the best at most atomized way. 
                                </p>
                                <p className='disc'>
                                    The company has been in business of stock broking and allied activities and is providing services
                                    like equity & derivatives broking, commodities trading, mutual fund & IPO
                                    distribution, research & advisory services, & investor guidance &
                                    education.
                                </p>
                                <p className='disc'>
                                    We are in the business of completely hedged derivatives arbitrage with a high focus on
                                    technology development and innovation.
                                </p>
                            </Col>
                            <Col xl={6} md={12} sm={12} className='about_section-image'>
                                <img
                                    className='about-img'
                                    src={welcome}
                                    alt='A man trading with mobile with Growth' 
                                    name=''
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default About