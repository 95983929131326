import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import help from './Icons/help.svg'




export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container why-career">
                    <Container className="why_section">
                        <div className="paraTitle">
                            <h1 className="title">
                                Why Choose <span>Growth</span> for <span>Career</span>?
                            </h1>
                        </div>
                        <Row className='why_section-rows'>
                            <Col lg={6} md={12} sm={12} className='why_section-img'>
                                <div className="imgs">
                                    <img
                                        src={help}
                                        alt="Growth help illustration"
                                        className="help"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='why_section-txt'>
                                <div className="text">
                                    <p className="disc">
                                        The company provides a supportive and empowering work environment and invests in its employees to help them reach their full potential. Some of the benefits of working at Growth include the following:
                                    </p>
                                    <ul className="lists">
                                        <li className="list">
                                            A challenging and exciting work environment
                                        </li>
                                        <li className="list">
                                            Opportunities for career growth and advancement
                                        </li>
                                        <li className="list">
                                            A supportive and empowering work culture
                                        </li>
                                        <li className="list">
                                            A comprehensive training program
                                        </li>
                                        <li className="list">
                                            Competitive compensation and benefits packages
                                        </li>
                                    </ul>
                                    <p className="disc">
                                        The financial market is a dynamic and constantly evolving industry that offers many opportunities for individuals willing to take on challenges and reap the rewards. Growth is committed to providing job opportunities to aspiring financial market professionals and offers a supportive and empowering work environment to help individuals build their careers. Whether you are just starting or looking to take your career to the next level, Growth is the place to be.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why