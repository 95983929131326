import React, { Component } from "react";
import "./Style.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import privacypolicy from "../Icons/privacypolicy.svg";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Privacy Policy</title>
        </Helmet>
        <div className="privacy_policy-conatiner">
          <Container className="pivacy_policy">
            <AnimatedSection>
              <h1 className="title">
                Privacy <span>Policy</span>
              </h1>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="pivacy_policy-row">
                <Col lg={8} md={12} sm={12} className="privacy_policy-text">
                  <p className="disc">
                    We at <b>Growth</b> do our best to ensure the information
                    shared with us is stored in a safe and Secure manner.
                    Personal information collected by us is used to help provide
                    you products and services as per your choice.
                  </p>
                  <p className="disc">
                    Any personally identifiable information of the customers
                    obtained by us shall not be used or shared other than for
                    the purposes to which the customers consents. However
                    security and confidentiality of information cannot be
                    guaranteed cent percent. Hence despite our utmost efforts to
                    protect your personal information <b>Growth</b> cannot
                    warrant the security of any information you transmit to us
                    through our online services. Such transmission of your
                    personal information is done at your own risk, but we
                    guarantee that your privacy information will not be sold out
                    to any company for marketing and promotion purpose.
                  </p>
                  <p className="disc">
                    We would also impart your personal information wherever it
                    is required to be disclosed under any law for the time being
                    in force or to any of the governmental agency or regulatory
                    bodies.
                  </p>
                  <p className="disc">
                    All information gathered will be stored using firewall
                    software in order to ensure that your information is secure
                    and safe. And further to bring it to your notice your
                    information is treated with utmost care and secrecy, and it
                    may be used by <b>Growth</b> for the intent of business
                    communication if needed.
                  </p>
                </Col>
                <Col lg={4} md={12} sm={12} className="privacy_policy-img">
                  <img
                    className="privacy_policy-img"
                    src={privacypolicy}
                    alt="Growth privacy policy illustration"
                    width="100%"
                  />
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="pivacy_policy-rows">
                <Col className="privacy_policy-text">
                  <p className="disc">
                    Our website may contain links to other websites of interest.
                    However, once you have used these links to leave our site,
                    you should note that we do not have any control over that
                    other website. Therefore, we cannot be responsible for the
                    protection and privacy of any information which you provide
                    whilst visiting such sites and such sites are not governed
                    by this privacy statement. You should exercise caution and
                    look at the privacy statement applicable to the website in
                    question.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
          </Container>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
