import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import './Style.scss'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import broker from '../Icons/broker.jpg'
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'

export class BrokerNorms extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Broker Norms
                    </title>
                </Helmet>
                <div className="broker_norms-container">
                    <Container className='broker_norms'>
                        <AnimatedSection>
                            <h1 className="title">
                                Bro<span>ker</span> No<span>rms</span>
                            </h1>
                        </AnimatedSection>
                        <AnimatedSection>
                            <Row className='broker_norms-rows'>
                                <Col lg={8} md={12} sm={12} className='broker_norms-texts term-text'>
                                    <h2 className="subtitle">
                                        ACCEPTANCE OF TERMS AND CONDITIONS
                                    </h2>
                                    <p className="disc">
                                        The following should be read carefully and accepted prior to becoming a Constituent for online trading
                                        i.e. for trading availing the facilities and/or any information, or any part thereof, as the case may
                                        be, as may be made available from time to time on the Web-Site and/or entering into any securities
                                        dealings through the broker whether by use of any of the facilities available on the Web-Site, or
                                        by any other means whatsoever. Please read the following, which contains important information concerning
                                        use of the Web Site. The use of the Web Site is conditional upon and subject to, acceptance of and
                                        compliance with, the Terms. And whereas for offline the Constituent can avail the facilities subject to
                                        acceptance of and compliance with the terms contained herein.
                                    </p>
                                </Col>
                                <Col lg={4} md={12} sm={12} className='broker_norms-img'>
                                    <img
                                        className='broker_norms-img'
                                        src={broker}
                                        alt="Growth broker norms illustration"
                                        width='100%'
                                    />
                                </Col>
                            </Row>
                        </AnimatedSection>
                        <Row className='broker_norms-rows'>
                            <Col lg={12} md={12} sm={12} className='broker_norms-texts term-text'>
                                <AnimatedSection>
                                    <h2 className="subtitle">
                                        TERMS OF USE
                                    </h2>
                                </AnimatedSection>

                                <h5 className="disc_title">
                                    1. Definitions
                                </h5>
                                <p className="disc">
                                    Growth Securities Pvt. Ltd. (<b>Growth</b>), provides a commodity information service operated by <b>Growth</b> on the World
                                    Wide Web of the internet the "Web", consisting of information services and content provided by <b>Growth</b> affiliates
                                    of <b>Growth</b> and other third parties.
                                </p>
                                <h5 className="disc_title">
                                    2. General
                                </h5>
                                <p className="disc">
                                    This agreement sets forth the terms and conditions that apply to the use of this site by the end user. By using this
                                    site (other than to read this agreement for the first time), end user agrees to comply with all of the terms and
                                    conditions hereof. The right to use this site is personal to End-User and is not transferable to any other person or
                                    entity. End user shall be responsible for protecting the confidentiality of End user's password(s), if any. End user
                                    acknowledges that, although the internet is often a secure environment, sometimes there are interruptions in service
                                    or events that are beyond the control of <b>Growth</b>, and <b>Growth</b> shall not be responsible
                                    for any data lost while transmitting information on the internet. While it is <b>Growth</b>'s objective is to
                                    ake the site accessible 24 hours per day, 7 days per week, the site may be unavailable from time to time for any
                                    reason including, without limitation, routine maintenance. You understand and acknowledge that due to circumstances
                                    both within and outside of the control of <b>Growth</b>, access to the site may be interrupted,
                                    suspended or terminated from time to time.
                                </p>
                                <p className="disc">
                                    <b>Growth</b> shall have the right at any time to change or discontinue any aspect or feature of <b>Growth</b>,
                                    including, but not limited to, content, hours of availability and equipment needed for access or
                                    use. Further, <b>Growth</b> may discontinue disseminating any portion of information or category of information,
                                    may change or eliminate any transmission method and may change transmission speeds or other signal characteristics.
                                </p>
                                <h5 className="disc_title">
                                    3. Modified Terms
                                </h5>
                                <p className="disc">
                                    <b>Growth</b> reserves the right at all times to discontinue or modify any of our Terms of Use and/or our
                                    Privacy Policy as we deem necessary or desirable without prior notification to you. Such changes may include, among
                                    their things, the adding of certain fees or charges. We suggest to you, therefore, that you re-read this important
                                    notice containing our Terms of Use and Privacy Policy from time to time in order that you stay informed as to any
                                    such changes. If we make changes to our Terms of Use and Privacy Policy and you continue to use our Site, you are
                                    impliedly agreeing to the Terms of Use and Privacy Policy expressed herein. Any such deletions or modifications shall
                                    be effective immediately upon <b>Growth</b>'s posting thereof. Any use of <b>Growth</b> by End User
                                    after such notice shall be deemed to constitute acceptance by End User of such modifications.
                                </p>
                                <h5 className="disc_title">
                                    4. Equipment
                                </h5>
                                <p className="disc">
                                    End User shall be responsible for obtaining and maintaining all telephone, computer hardware and other equipment needed
                                    for access to and use of this Site and all charges related thereto. <b>Growth</b> shall not be liable for
                                    any damages to the End User's equipment resulting from the use of this Site.
                                </p>
                                <h5 className="disc_title">
                                    5. End User Conduct
                                </h5>
                                <p className="disc">
                                    This site and any other individual Microsites now or here in after contained within or otherwise available through external
                                    hyperlinks with our Site (the "Microsites") are private property. All interactions on this Site and/or the Microsites must
                                    comply with these Terms of Use. Although we welcome and encourage user interaction on our Site, we do insist and require
                                    that all end users restrict any and all activity in connection with the use of this Site and the Microsites to that which
                                    involves lawful purposes only. End User shall not post or transmit through this site any material which violates or
                                    infringes in any way upon the rights of others, or any material which is unlawful, threatening, abusive, defamatory,
                                    invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct
                                    that would constitute a criminal offense, give rise to civil liability or otherwise violate any law, or which, without
                                    <b>Growth</b>'s express prior, written approval, contains advertising or any solicitation with
                                    respect to products or services. Any conduct by an End User that in <b>Growth</b>'s exclusive
                                    discretion restricts or inhibits any other End User from using or enjoying this Site and/or any of the Microsites is
                                    strictly prohibited. End User shall not use this Site or any of the Microsites to advertise or perform any commercial,
                                    religious, political or non-commercial solicitation, including, but not limited to, the solicitation of users of this
                                    Site and/or the Microsites to become users of other on- or offline services directly or indirectly competitive or
                                    potentially competitive with <b>Growth</b>.
                                </p>
                                <p className="disc">
                                    The foregoing provisions of this Section 5 apply equally to and are for the benefit of <b>Growth</b>, its subsidiaries,
                                    affiliates and its third party content providers and licensors, and each shall have the right to assert and enforce
                                    such provisions directly or on its own behalf.
                                </p>
                                <h5 className="disc_title">
                                    6. Copyright and Trademarks
                                </h5>
                                <p className="disc">
                                    Everything located on or in this site, including the Microsites, is the exclusive property of <b>Growth</b>, or
                                    used with express permission of the copyright and/or trademark owner. <b>ANY COPYING, DISTRIBUTING,
                                        TRANSMITTING, POSTING, LINKING, DEEP LINKING, OR OTHERWISE MODIFYING OF THIS SITE OR ANY OF THE MICROSITES WITHOUT
                                        THE EXPRESS WRITTEN PERMISSION OF <b>Growth</b> IS STRICTLY PROHIBITED</b>. Any violation of this policy
                                    may result in a copyright, trademark or other intellectual property right infringement that may subject End User to
                                    civil and / or criminal penalties.
                                </p>
                                <p className="disc">
                                    This site may contain copyrighted material, trademarks and other proprietary information, including, but not limited to,
                                    text, software, photos, video, graphics, music, sound, and the entire contents of <b>Growth</b> is protected
                                    by copyright as a collective work under the Indian copyright laws. <b>Growth</b> owns a copyright in the selection, coordination,
                                    arrangement and enhancement of such content, as well as in the content original to it. End User may not modify, publish,
                                    transmit, participate in the transfer
                                    or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. End User may download /
                                    print / save copyrighted material for End User's personal use only. Except as otherwise expressly stated under copyright
                                    law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material without the
                                    express permission of <b>Growth</b> and the copyright owner is permitted. If copying, redistribution or
                                    publication of copyrighted material is permitted, no changes in or deletion of author attribution, trademark legend or
                                    copyright notice shall be made. End User acknowledges that he/she/it does not acquire any ownership rights by downloading
                                    copyrighted material. Trademarks that are located within or on the site or a Web site otherwise owned or operated in
                                    conjunction with <b>Growth</b> shall not be deemed to be in the public domain but rather the exclusive
                                    property of <b>Growth</b>, unless such site is under license from the Trademark owner thereof in which
                                    case such license is for the exclusive benefit and use of <b>Growth</b> , unless otherwise stated.
                                </p>
                                <p className="disc">
                                    End User shall not upload, post or otherwise make available on this Site any material protected by copyright, trademark
                                    or other proprietary right without the express permission of the owner of the copyright, trademark or other proprietary
                                    right. <b>Growth</b> does not have any express burden or responsibility to provide End User with indications, markings or anything
                                    else that may aid End User in determining whether the material in question is copyrighted or trademarked.
                                    End User shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights or
                                    any other harm resulting from such a submission. By submitting material to any public area of this Site, End User warrants that
                                    the owner of such material has expressly granted <b>Growth</b> the royalty-free, perpetual, irrevocable,
                                    non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in
                                    whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter
                                    developed for the full term of any copyright that may exist in such material. End User also permits any other end user to
                                    access, view, store or reproduce the material for that end user's personal use. End User hereby grants <b>Growth</b> the right to edit,
                                    copy, publish and distribute any material made available on this site by End User.
                                </p>
                                <p className="disc">
                                    The foregoing provisions of Section 6 apply equally to and are for the benefit of <b>Growth</b>, its
                                    subsidiaries, affiliates and its third party content providers and licensors and each shall have the right to assert
                                    and enforce such provisions directly or on its own behalf.
                                </p>
                                <h5 className="disc_title">
                                    7. Disclaimer of Warranty; Limitation of Liability
                                </h5>
                                <p className="disc">
                                    End user expressly agrees that use of this site and the Microsites is at end user's sole risk. neither <b>Growth</b> , its affiliates nor any of their respective employees, agents, third party content providers or licensors warrant that use of the site will be uninterrupted or error free; nor do they make any warranty as to (i) the results that may be obtained from use of this site, or (ii) the accuracy, reliability or content of any information, service or merchandise provided through this site or the Microsites.
                                </p>
                                <p className="disc">
                                    This site and the Microsites are made accessible on an "as is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this agreement.
                                </p>
                                <p className="disc">
                                    This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortious behavior, negligence, or under any other cause of action. end user specifically acknowledges that <b>Growth</b> is not liable for the defamatory, offensive or illegal conduct of other users or third-parties and that the risk of injury from the foregoing rests entirely with end user.
                                </p>
                                <p className="disc">
                                    In no event shall <b>Growth</b> , or any person or entity involved in creating, producing or distributing this site or the contents hereof, including the Microsites and any software, be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use this site. end user hereby acknowledges that the provisions of this section shall apply to all content on this site and the Microsites.
                                </p>
                                <p className="disc">
                                    In addition to the terms set forth above, neither <b>Growth</b> , nor its affiliates, information providers or content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness or unauthenticity of, the information contained within this site or any of the Microsites, or for any delay or interruption in the transmission thereof to the end user, or for any claims or losses arising therefrom or occasioned thereby. none of the foregoing parties shall be liable for any third-party claims or losses of any nature, including without limitation lost profits, punitive or consequential damages.
                                </p>
                                <p className="disc">
                                    <b>Growth</b> is not responsible for any content that a user, subscriber, or an unauthorized user may post on this site or any of the Microsites. any content that is posted or uploaded that is or may be deemed unsuitable can and may be taken down by <b>Growth</b> moreover, <b>Growth</b> reserves the right to edit, change, alter, delete and prohibit any and all content that it, <b>Growth</b>, deems unsuitable.
                                </p>
                                <h5 className="disc_title">
                                    8. Monitoring
                                </h5>
                                <p className="disc">
                                    <b>Growth</b> shall have the right, but not the obligation, to monitor the content of the Site at all times, including any chat rooms and forums that may hereinafter be included as part of the Site, to determine compliance with this Agreement and any operating rules established by <b>Growth</b>, as well as to satisfy any applicable law, regulation or authorized government request. Without limiting the foregoing, <b>Growth</b> shall have the right to remove any material that <b>Growth</b> , in its sole discretion, finds to be in violation of the provisions hereof or otherwise objectionable.
                                </p>
                                <h5 className="disc_title">
                                    9. Privacy
                                </h5>
                                <p className="disc">
                                    End User acknowledges that all discussion for ratings, comments, bulletin board service, chat rooms and/or other message or communication facilities (collectively "Communities") are public and not private communications, and that, therefore, others may read End User's communications without End User's knowledge. <b>Growth</b> does not control or endorse the content, messages or information found in any Community, and, therefore, <b>Growth</b> specifically disclaims any liability concerning the Communities and any actions resulting from End Users participation in any Community, including any objectionable content. Generally, any communication which End User posts to <b>Growth</b> (whether in chat rooms, discussion groups, message boards or otherwise) is considered to be non-confidential. If particular web pages permit the submission of communications that will be treated by <b>Growth</b> as confidential, that fact will be stated on those pages. By posting comments, messages or other information on the Site, End User grants <b>Growth</b> the right to use such comments, messages or information for promotions, advertising, market research or any other lawful purpose. For more information see <b>Growth</b>'s Privacy Policy.
                                </p>
                                <h5 className="disc_title">
                                    10. License Grant
                                </h5>
                                <p className="disc">
                                    By posting communications on or through this Site, End User shall be deemed to have granted to <b>Growth</b> a royalty-free, perpetual, irrevocable, non-exclusive license to use, reproduce, modify, publish, edit, translate, distribute, perform, and display the communication alone or as part of other works in any form, media, or technology whether now known or hereafter developed, and to sublicense such rights through multiple tiers of sublicensees.
                                </p>
                                <h5 className="disc_title">
                                    11. Indemnification
                                </h5>
                                <p className="disc">
                                    End User agrees to defend, indemnify and hold harmless <b>Growth</b>, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys' fees, arising out of the use of this Site and/or the Microsites by End User.
                                </p>
                                <h5 className="disc_title">
                                    12. Termination
                                </h5>
                                <p className="disc">
                                    <b>Growth</b> may terminate this Agreement at any time. Without limiting the foregoing, <b>Growth</b> shall have the right to immediately terminate any passwords or accounts of End User in the event of any conduct by End User which <b>Growth</b> , in its sole discretion, considers to be unacceptable, or in the event of any breach by End User of this Agreement. The provisions of Sections 3, 5, 6, 7, 10, 11, 12 will survive termination of this Agreement.
                                </p>
                                <h5 className="disc_title">
                                    13. Trademarks
                                </h5>
                                <p className="disc">
                                    <b>Growth</b> is a trademark of <b>Growth</b> , Inc. All rights in respect of this trademark are hereby expressly reserved. Unless otherwise indicated, all other trademarks appearing on <b>Growth</b> are the property of their respective owners.
                                </p>
                                <h5 className="disc_title">
                                    14. Third Party Content
                                </h5>
                                <p className="disc">
                                    <b>Growth</b> , similar to an Internet Service Provider, is a distributor (and not a publisher) of content supplied by third parties and End Users. Accordingly, <b>Growth</b> has no more editorial control over such content than does a public library, bookstore or newsstand. Any opinions, advice, statements, services, offers, or other information or content expressed or made available by third parties, including information providers, or any other end users are those of the respective author(s) or distributors) and not of <b>Growth</b> . Neither <b>Growth</b> nor any third-party provider of information guarantees the accuracy, completeness, or usefulness of any content, nor its merchantability or fitness for any particular purpose. (Refer to Section 6 above for the complete provisions governing limitation of liabilities and disclaimers of warranty.)
                                </p>
                                <p className="disc">
                                    In many instances, the content available through this Site represents the opinions and judgments of the respective information provider, end user, or other user not under contract with <b>Growth</b> . <b>Growth</b> neither endorses or is responsible for the accuracy or reliability of any opinion, advice, or statement made on <b>Growth</b> by anyone other than authorized <b>Growth</b> employee spokespersons while acting in official capacities. Under no circumstances will <b>Growth</b> be liable for any loss or damage caused by an end user's reliance on information obtained through <b>Growth</b>. It is the responsibility of End User to evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, etc., or other content available through <b>Growth</b> .
                                </p>
                                <p className="disc">
                                    <b>Growth</b> contains links to third party Web sites maintained by other content providers. These links are provided solely as a convenience to you and not as an endorsement by <b>Growth</b> of the contents on such third-party sites and <b>Growth</b> hereby expressly disclaims any representations regarding the content or accuracy of materials on such third-party Web sites. If End User decides to access linked third-party Web sites, End User does so at its own risk. Unless you have executed a written agreement with <b>Growth</b> expressly permitting you to do so, you may not provide a hyperlink to the Site from any other website. <b>Growth</b> reserves the right to revoke its consent to any link at any time in its sole discretion.
                                </p>
                                <h5 className="disc_title">
                                    15. Miscellaneous
                                </h5>
                                <p className="disc">
                                    This Agreement and any operating rules for <b>Growth</b> established by <b>Growth</b> constitute the entire agreement of the parties with respect to the subject matter hereof. No waiver by either party of any breach or default hereunder is a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall be of no legal force or effect. If any provision of this Agreement is held invalid by a court of competent jurisdiction, such invalidity shall not affect the enforceability of any other provisions contained in this Agreement and the remaining portions of this Agreement shall continue in full force and effect. The failure of either party to exercise any of its rights under this Agreement shall not be deemed a waiver or forfeiture of such rights or any other rights provided hereunder.
                                </p>
                                <p className="disc">
                                    <b>Growth</b>'s headquarter is in East Delhi (India). Legal issues arising out of, but not exclusive to the use of, this Site or the Microsites (unless otherwise specifically stated) are governed by and in accordance with the laws of the State (exclusive of its rules regarding conflicts of laws). By using this Site, End User agrees that any dispute or claim arising out of or in connection with this Agreement or the performance, breach or termination thereof, or the Site or any Microsites, shall be finally settled by arbitration in East Delhi.
                                </p>
                            </Col>
                        </Row>
                        <AnimatedSection>
                            <Row>
                                <Col>
                                    <h2 className="subtitle">
                                        Note:-
                                    </h2>
                                    <p className="note" style={{ fontFamily: "Poppins" }}>
                                        Futures and options trading is not suitable for everyone. It is speculative in nature and a substantial risk of loss exists; only invest risk capital. Any statement of fact herein is derived from sources deemed to be reliable, but are not guaranteed as to accuracy, nor are they purported to be complete. Options and futures do not move in tandem, and seasonal factors do not in and of themselves influence the market. Past performance is not necessarily indicative of future results. The possibility of large movement in commodity contracts is remote, and currently known news may already be factored into the market. The price movements in examples contained herein are for reference only and do not necessarily imply that any <b>Growth</b> clientele has or will achieve similar results.
                                    </p>
                                </Col>
                            </Row>
                        </AnimatedSection>
                    </Container>
                </div>
            </>
        )
    }
}

export default BrokerNorms