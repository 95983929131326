import React, { Component } from 'react'
import {
    Col,
    Container, 
    Row,
} from 'react-bootstrap'
import depository from './Icons/whatis.svg'


export class WhatIs extends Component {
  render() {
    return (
        <>    
            <section className="whatis_section-container">
                <Container className='whatis_section'>
                    <div className="paraTitle">
                    <h1 className="title">
                        Depository Services
                    </h1>
                    </div>
                    <Row className='whatis-rows'>
                        <Col lg={5} md={12} sm={12} className='whatis-img'>
                            <img 
                                className='depository-img'
                                src={depository}
                                alt="Growth depository illustration" 
                                width='100%'
                            />
                        </Col>
                        <Col lg={7} md={12} sm={12} className='whatis-text'>
                            <p className="disc">
                                A depository is an organization where the securities of an investor are held in electronic form. A depository can be compared to a bank. To avail of the services of a depository, an investor has to open an account with the Depository through a depository participant, just as he opens an account with the bank. Holding shares in the account is akin to holding money in the bank.
                            </p>
                            <p className="disc">
                            As an investor you will enjoy many benefits if you buy and sell shares in the depository mode.
                            </p>
                            <p className="disc">
                                Depository services allow investors to hold and transfer securities such as shares, bonds, equities, and mutual funds in electronic form. It is similar to a bank safekeeping all your cash in an account and facilitating transactions. The two main depositories in India include:
                            </p>
                            <ul className="lists">
                                <li className="list">
                                    <a href='https://nsdl.co.in/' rel="noopener noreferrer" target='_blank'>National Securities Depository Ltd. (NSDL)</a>
                                </li>
                                <li className="list">
                                    <a href='https://www.cdslindia.com/' rel="noopener noreferrer" target='_blank'>Central Depository Services Ltd. (CDSL)</a>
                                </li>
                            </ul>
                            <p className="disc">
                                While the Depository System involves paperless trading, a depository agent or a Depository Participant (DP) coordinates between the depository and the investors.
                            </p>
                            <p className="disc">
                                Findoc Financial Services Group is a depository participant of the National Securities Depository Limited (NSDL). We provide a complete range of both offline and online facilities for depository services, everything under one roof.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="disc">
                                Investing in the stock market is crucial forto securing your financial future. One of the most important factors to consider when investing in stocks is the safekeeping of your securities. This is where depository services come in. Depository services provide a secure and convenient way to electronically hold your stocks and other securities. Growth Securities is one of the market's leading providers of depository participants services.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
  }
}

export default WhatIs