import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        e-DIS / Demat Gateway
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Investors can give instructions for transfer of securities through e-DIS
        apart from physical DIS. Here, for on-market transfer of securities,
        investors need to provide settlement number along with the ISIN and
        quantity of securities being authorized for transfer. Client shall be
        required to authorize each e-DIS valid for a single settlement number /
        settlement date, by way of OTP and PIN/password, both generated at
        Depositories end. Necessary risk containment measures are being adopted
        by Depositories in this regard.
      </p>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const EDISDematGateway = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        c. e-DIS / Demat Gateway{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default EDISDematGateway;
