import React from 'react';

const Popup = ({ userName, nameSubmitted, handleNameBlur, handleNameSubmit, setShowPopup, setUserName }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <h2>Welcome to the Quiz!</h2>
        {!nameSubmitted ? (
          <>
            <p>Please enter your name to start the quiz:</p>
            <input type="text" onBlur={handleNameBlur} />
            <button onClick={handleNameSubmit}>Start Quiz</button>
          </>
        ) : (
          <>
            <p>Hi {userName}, are you ready to continue?</p>
            <button onClick={() => setShowPopup(false)}>Yes</button>
            <button onClick={() => setUserName('')}>Change Name</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Popup;
