import React from 'react'
import { Container } from 'react-bootstrap'

function FooterDetails() {
    return (
        <>
            <section className="footerDetailsContainer">
                <Container className='footerDetails'>
                    <div className="detailSectionHere">
                        <p className="disc">
                            In absence of response/complaint not addressed to your satisfaction, you may lodge a complaint
                            with SEBI at <a target='_blank' rel='noopener noreferrer' href="https://scores.gov.in/scores/Welcome.html">https://scores.gov.in/scores/Welcome.html</a> or Exchanges or Depositories at :
                        </p>
                        <ul className="lists">
                            <li className="list">
                                For BSE: <a target='_blank' rel='noopener noreferrer' href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx">https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx</a>
                            </li>
                            <li className="list">
                                For NSE: <a target='_blank' rel='noopener noreferrer' href="https://investorhelpline.nseindia.com/NICEPLUS/">https://investorhelpline.nseindia.com/NICEPLUS/</a>
                            </li>
                            <li className="list">
                                MCX – <a target='_blank' rel='noopener noreferrer' href="https://www.mcxindia.com/Investor-Services">https://www.mcxindia.com/Investor-Services</a>
                            </li>
                            <li className="list">
                                For CDSL: <a target='_blank' rel='noopener noreferrer' href="https://www.cdslindia.com/Footer/grievances.aspx">https://www.cdslindia.com/Footer/grievances.aspx</a>
                            </li>
                        </ul>
                        <br />
                        <p className="disc">
                            Please quote your Service Ticket/Complaint Ref No. while raising your complaint at SEBI
                            SCORES/Exchange portal / Depository Portal.
                        </p>
                        <br />
                        <h6>
                            GROWTH SECURITIES PVT. LTD. (Corporate Identity Number: U74899GJ1996PTC119714)
                        </h6>
                        <p className="disc">
                            Regd. Office: Unit No. 503 A-B, 504 A-B, 5th Floor, Tower A WTC Block No. 51, Road 5E, Zone-5, GIFT City Gandhinagar, Gujarat-382355
                            Corporate Office: 2nd Floor, 4/10 Asaf Ali Road, New Delhi-110002 | Ph:+91-9910032394 | Email:
                            <a href="mailto:Compliance@growthsec.in">Compliance@growthsec.in</a>, SEBI Regd. No: (Stock Broker) INZ000299236 (W.E.F 01-06-2021)
                            SEBI Regd. No:(Depositary Participant) IN-DP-623-2021 (W.E.F 14-09-2021)
                        </p>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default FooterDetails