import React, { useState } from 'react';
import {
    Modal
} from 'react-bootstrap';



function Cookie() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <a href="#" onClick={handleShow}>Cookie Policy</a>
            <Modal
                size='xl'
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cookie Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="disc">
                        Last updated: {new Date().toLocaleDateString()}
                    </p>

                    <p className="disc">
                        This Cookie Privacy Policy explains how cookies and such similar technologies are used to collect and store information when you visit website. By using website, you consent to the use of cookies in accordance with this policy.
                    </p>

                    <h5>
                        What are cookies?
                    </h5>
                    <p className="disc">
                        Cookies are small text files that are placed on computer or mobile device when a user visits websites. They are widely used to make websites work more efficiently and to provide information to website owners.
                    </p>

                    <h5>
                        How we use cookies
                    </h5>
                    <p className="disc">
                        We use cookies for the following purposes:
                    </p>
                    <ul style={{ listStyle: "num", fontFamily: "Poppins" }}>
                        <li>
                            To improve the performance and functionality of our website
                        </li>
                        <li>
                            To analyze how visitors use our website and to improve the user experience
                        </li>
                        <li>
                            To personalize your experience on our website
                        </li>
                        <li>
                            To serve targeted advertising on our website and third-party websites
                        </li>
                        <li>
                            Types of cookies we use on the website:
                            <ul style={{ listStyle: "lower-alpha" }}>
                                <li>
                                    <b>Essential cookies:</b> These cookies are necessary for the website to function properly and cannot be switched off in our systems.
                                </li>
                                <li>
                                    <b>Performance cookies:</b> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website.
                                </li>
                                <li>
                                    <b>Functionality cookies:</b> These cookies enable the website to provide enhanced functionality and personalization.
                                </li>
                                <li>
                                    <b>Targeting cookies:</b> These cookies are used to deliver advertising that is more relevant to you and your interests.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h5>Third-party cookies</h5>
                    <p className="disc">
                        This website may also use third-party cookies for advertising and analytics purposes. These third-party cookies may collect information about users’ browsing interests.
                    </p>

                    <h5>Managing cookies</h5>
                    <p className="disc">
                        Users can manage cookies of their own using settings in their web browser. However, please note that disabling cookies may limit their ability to use certain features of the website.
                    </p>

                    <h5>Updates to this policy</h5>
                    <p className="disc">
                        The website may update this Cookie Privacy Policy from time to time and shall notify of any changes by posting the updated policy.
                    </p>

                    <h5>
                        Contact us
                    </h5>
                    <p className="disc">
                        If you have any questions or concerns about use of cookies, please <a href="/contact" target="_blank" rel="noopener noreferrer" style={{ color: "#000", textDecoration: "none", fontFamily: "Poppins" }}>contact us</a>.
                    </p>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default Cookie