import React from "react";
import { TypeAnimation } from "react-type-animation";
import { Row, Col, Carousel } from "react-bootstrap";
import book from "./Icons/Care/book.svg";
import currency from "./Icons/Care/currency.svg";
import megaphone from "./Icons/Care/horn.svg";
import savings from "./Icons/Services/savings.svg";
import algo from "./Icons/Services/algo.svg";
import hft from "./Icons/Services/hft.svg";
import derivative from "./Icons/Services/derivativetrade.svg";
import zero from './Icons/Care/zero.jpeg'
import $ from "jquery";
// import QuoteShow from "./QuoteModal";
import AccountForm from "./AcntForm/AccounttForm";


$(function () {
  $(".scroll-down").click(function () {
    $("html, body").animate(
      { scrollTop: $(".growth-products").offset().top },
      "slow"
    );
    return false;
  });
});

function Header(props) {
  return (
    <>
      <section className="home_header_section-container">
        <div className="home-header-items container-fluid">
          <div className="img-back"></div>
          <Row className="header_section-rows">
            <Col lg={1}></Col>
            <Col
              lg={4}
              xl={4}
              md={12}
              sm={12}
              className="header_section-carousel"
            >
              <div className="left_header">
                <Carousel variant="dark" className="carousel">
                  <Carousel.Item interval={700}>
                    <img
                      src={algo}
                      alt="Growth algorithmic trading illustration"
                      className="care-img"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/algorithmic-trading">Algorithmic Trading</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={hft}
                      alt="Growth high frequency trading illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/high-frequency-trading">
                        High Frequency Trading
                      </a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={currency}
                      alt="Growth currency trading illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/currency-trading">Currency Trading</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={derivative}
                      alt="Growth derivative trading illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/derivative-trading">Derivative Trading</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={savings}
                      alt="Growth savings illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="equity">Equity Trading</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={book}
                      alt="Growth knowledge center illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/knowledgeCenter">Knowledge Center</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img"
                      src={megaphone}
                      alt="Growth megaphone illustration"
                      width="100%"
                    />
                    <h3 className="caption">
                      <a href="/initial-public-offering-ipo">IPOs</a>
                    </h3>
                  </Carousel.Item>
                  <Carousel.Item interval={700}>
                    <img
                      className="care-img zeroBrokerage"
                      src={zero}
                      alt="Growth megaphone illustration"
                      width="50%"
                      style={{height: "100%", marginLeft:"25%", marginBottom: "20px"}}
                    />
                    <h3 className="caption">
                      <a href="/terms-conditions#zero-brokerage">Zero Brokerage</a>
                    </h3>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
            <Col lg={7} xl={7} md={12} sm={12} className="header_section-texts">
              <div className="right_header">
                {/* Hero Titles */}
                <div className="brandTitle">
                  <h4 className="title">Welcome to</h4>
                  <h1 className="subTitle">
                    India's Leading <br />
                    Stock <span>Trading</span> Platform!
                  </h1>
                  <h6 className="disc">Invest in everything from anywhere</h6>
                </div>
                {/* Hero Typer */}
                <div className="typer">
                  <TypeAnimation
                    sequence={[
                      "EQUITY",
                      3000,
                      "DERIVATIVES",
                      3000,
                      "CURRENCY",
                      3000,
                      "COMMODITY",
                      3000,
                      "MUTUAL FUNDS",
                      3000,
                      "IPO",
                      3000,
                    ]}
                    wrapper="div"
                    cursor={true}
                    repeat={Infinity}
                    style={{
                      fontSize: "2.5rem",
                      color: "#269ed6",
                      fontWeight: "600",
                      letterSpacing: "1px",
                      fontFamily: "'Poppins'",
                    }}
                  />
                </div>
                <AccountForm />
              </div>
            </Col>
          </Row>
          <div className="animate-down">
            <a
              href="#products"
              className="scroll-down"
              address="true"
              title="See products"
            >
              <span></span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
