import React from 'react';
import { Carousel } from 'react-bootstrap';
import Account from "./Icons/Account.png";
import Hiring from "./Icons/Hiring.png";
import Learn from "./Icons/Learn .png";
import Support from "./Icons/Support.png";
import App from "./Icons/App.png";

function NavCar() {
    return (
        <>
            <section className="navCarContainer">
                <div className="navCar">
                    <Carousel>
                        <Carousel.Item interval={1200}>
                            <a href="https://kyc.growthsec.com" target='_blank' rel='noreferrer noopener' className="carLink">
                                <img
                                    className="d-block w-100"
                                    src={Account}
                                    alt="First slide"
                                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item interval={1200}>
                            <a href="https://play.google.com/store/apps/details?id=com.rs.growth&hl=en&gl=US" target='_blank' rel='noreferrer noopener' className="carLink">
                            <img
                                className="d-block w-100"
                                src={App}
                                alt="Second slide"
                            />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item interval={1200}>
                            <a href="/knowledgeCenter" className="carLink">
                                <img
                                    className="d-block w-100"
                                    src={Learn}
                                    alt="Third slide"
                                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item interval={1200}>
                            <a href="/support" className="carLink">
                                <img
                                    className="d-block w-100"
                                    src={Support}
                                    alt="Fourth slide"
                                />
                            </a>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>
        </>
    );
}

export default NavCar;