import React from 'react'
import { Container, Table } from 'react-bootstrap'

function EsclationMatrix() {
  return (
    <>
        <section className="escalationMatrixContainer">
            <Container className='escalationMatrix'>
                <div className="detailsContainer">
                <h3 className="title">
                    Escalation Matrix
                </h3>
                <Table hover striped bordered className='escTable'>
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Details of</th>
                            <th>Contact Person</th>
                            <th>Address</th>
                            <th>Contact No.</th>
                            <th>Email Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>Customer Care</td>
                            <td>Satish Kumar</td>
                            <td>Office</td>
                            <td>011- 43140000</td>
                            <td>Complaints@growthsec.in</td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>Head of Customer Care</td>
                            <td>Aryan Birla</td>
                            <td>Office</td>
                            <td>9599543216</td>
                            <td>support@growthsec.com</td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Compliance Officer</td>
                            <td>Himanjal Brahmbhatt</td>
                            <td>Office</td>
                            <td>9311786339</td>
                            <td>Compliance@growthsec.in</td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>CEO</td>
                            <td>Devansh Gupta</td>
                            <td>Office</td>
                            <td>011-43140000</td>
                            <td>devansh@growthsec.in</td>
                        </tr>
                    </tbody>
                </Table>
                </div>
            </Container>
        </section>
    </>
  )
}

export default EsclationMatrix