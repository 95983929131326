import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const DematAccount = () => {
  const canvasRef = useRef(null);
  const [spots, setSpots] = useState([]);
  const [hue, setHue] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const handleMouseMove = (event) => {
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      for (let i = 0; i < 3; i++) {
        createParticle(ctx, mouseX, mouseY);
      }
    };

    window.addEventListener("resize", handleResize);
    canvas.addEventListener("mousemove", handleMouseMove);

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      handleParticle();
      setHue((prevHue) => prevHue + 1);
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener("resize", handleResize);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const createParticle = (ctx, x, y) => {
    const size = Math.random() * 12 + 0.1;
    const speedX = Math.random() * 4 - 2;
    const speedY = Math.random() * 4 - 2;
    const color = `hsl(${hue}, 100%, 50%)`;

    const particle = { x, y, size, speedX, speedY, color };

    particle.update = function () {
      this.x += this.speedX;
      this.y += this.speedY;
      if (this.size > 0.05) this.size -= 0.05;
    };

    particle.draw = function () {
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fill();
    };

    setSpots((prevSpots) => [...prevSpots, particle]);
  };

  const handleParticle = () => {
    setSpots((prevSpots) => {
      const updatedSpots = prevSpots.filter((spot) => spot.size > 0.05);
      updatedSpots.forEach((spot) => {
        spot.update();
        spot.draw();
      });
      return updatedSpots;
    });
  };

  return (
    <section className="account-container" style={{ position: "relative" }}>
      <AnimatedSection>
        <canvas
          ref={canvasRef}
          id="canvas"
          style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
        ></canvas>
        <div className="circleContainer">
          <div className="lineOuter">
            <span className="earth"></span>
            <div className="lineInner">
              <span className="earth"></span>
            </div>
          </div>
        </div>
        <Container className="account_section">
          <div className="content">
            <h1 className="dematTitle">
              Open <span>Demat Account</span> with us
            </h1>
            <h4 className="subtitle">
              Grow your <span>wealth like never before</span>
            </h4>
            <a
              className="btn btn-account"
              href="https://kyc.growthsec.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa fa-user"></i> Open Now
              </span>
            </a>
          </div>
        </Container>
      </AnimatedSection>
    </section>
  );
};

export default DematAccount;
