import React, { Component } from 'react'
import dLady from './data.svg'
import "./Style.scss"
import {
  Container,
  Row,
  Col,
  Table
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'



export class AnnualReturn extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Growth Securities || Annual Return
          </title>
        </Helmet>
        <div className="annualReturn-container">
          <Container className="annualReturn">
            <Row className="annualReturn-rows">
              <Col lg={8} md={12} sm={12} className="annualReturn-text">
                <Table striped bordered hover className='annualReturn-data'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>FILENAME</th>
                      <th>DOWNLOAD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Annual Return - 2022</td>
                      <td>
                        <a href="{data1}" target='_blank' rel="noopener noreferrer">
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Download
                        </a>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>2</td>
                      <td>GSPL_DP_INVESTORS_COMPLAINT_DATA_JULY_2022	</td>
                      <td>
                        <a href={data2} target='_blank' rel="noopener noreferrer">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>GSPL_DP_ INVESTORS_COMPLAINT_DATA_AUGUST_2022</td>
                      <td>
                        <a href={data3} target='_blank' rel="noopener noreferrer">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>GSPL_DP_INVESTORS_COMPLAINT_DATA_SEPTEMBER_2022</td>
                      <td>
                        <a href={data4} target='_blank' rel="noopener noreferrer">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>GSPL_DP_INVESTORS_COMPLAINT_DATA_OCTOBER_2022</td>
                      <td>
                        <a href={data5} target='_blank' rel="noopener noreferrer">Download</a>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>GSPL_DP_INVESTORS_COMPLAINT_DATA_NOVEMBER_2022</td>
                      <td>
                        <a href={data6} target='_blank' rel="noopener noreferrer">Download</a>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Col>
              <Col lg={4} md={12} sm={12} className="data-img">
                <img
                  src={dLady}
                  alt="Growth data illustration"
                  width='100%'
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default AnnualReturn