import React, { useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import TermsConditions from "./TermsConditions";
import "./Style.scss";

function FAQs() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <section
        className={`faqSectionContainer ${hovered ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container className="faqSection">
          <h1 style={{ textAlign: "center", color: "#269ed6" }}>
            Frequently Asked Questions
          </h1>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How does the referral program work?
              </Accordion.Header>
              <Accordion.Body>
                The referral program allows you to refer friends to Growth. When
                your referred friends open an account and meet the program
                criteria, you can earn rewards based on our <TermsConditions />.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How can I refer a friend?</Accordion.Header>
              <Accordion.Body>
                To refer a friend, you will receive a unique referral link or
                code from Growth. Share this link or code with your friends, and
                when they use it to open an account, you become eligible for
                rewards.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                What are the rewards for the referrer?
              </Accordion.Header>
              <Accordion.Body>
                The rewards for the referrer may include gift vouchers or a
                percentage of the brokerage sharing from their referred friends'
                trades, as specified in the program. The exact rewards are
                detailed in the program's <TermsConditions />.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What benefits do referred friends receive?
              </Accordion.Header>
              <Accordion.Body>
                Referred friends may be eligible for benefits such as free
                account opening and the first year of Demat AMC. Specific
                benefits are outlined in the program details.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Is there a limit to the number of friends I can refer?
              </Accordion.Header>
              <Accordion.Body>
                There is typically no limit to the number of friends you can
                refer. However, please check the program's <TermsConditions />{" "}
                for any specific limitations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                How do I track my referral rewards?
              </Accordion.Header>
              <Accordion.Body>
                You can usually track your referral rewards by logging into your
                account on the Growth website and accessing the referral
                dashboard.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                What is the validity of the referral rewards?
              </Accordion.Header>
              <Accordion.Body>
                The validity of referral rewards may vary. Please refer to the
                program's <TermsConditions /> for details on the validity period
                of the rewards.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Can I refer friends from outside the country?
              </Accordion.Header>
              <Accordion.Body>
                Whether you can refer friends from outside the country depends
                on the specific rules of the referral program. Some programs may
                allow international referrals, while others may have
                restrictions.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                What should I do if my referral link/code is not working?
              </Accordion.Header>
              <Accordion.Body>
                If your referral link or code is not working, you should reach
                out to the customer support of Growth for assistance and
                troubleshooting.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                Are there any restrictions on how my referred friends should
                open an account?
              </Accordion.Header>
              <Accordion.Body>
                Yes, there may be specific criteria for your referred friends to
                open an account. These criteria can include factors like initial
                deposit amounts and account verification.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </>
  );
}

export default FAQs;
