import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Hiring from "./Hiring";
import Market from "./Market";
import Why from "./Why";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const Careers = () => {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Careers || Start your career with Growth
          Securities
        </title>
      </Helmet>
      <div className="wrapper careers">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <Hiring />
        </AnimatedSection>
        <AnimatedSection>
          <Market />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
      </div>
    </>
  );
};

export default Careers;
