import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import referralIMG from "./Icons/referral.svg";
import ReferralForm from "./ReferralForm";

const Header = () => {
  return (
    <section className="referralHeaderSection">
      <Container>
        <Row>
          <Col md={6} lg={5} className="logo">
            <div className="leftSideRefer">
              <img
                src={referralIMG}
                alt="Growth Securities Referral Illustration"
              />
              <h4>
                Unlock a <span>World of Financial</span> Opportunities!
              </h4>
              <p>
                Join the Growth Securities family and embark on a journey of
                financial prosperity. Your network, your rewards – it's time to
                share and earn.
              </p>
            </div>
          </Col>
          <Col md={6} lg={7} className="content">
            <ReferralForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
