import React from "react";
import { Container } from "react-bootstrap";
import Disclaimer from "./Disclaimer";

const Attention = () => {
  return (
    <>
      <section className="attention-container">
        <Container className="attention">
          <div className="attention-disc">
            <h5 className="attention_title">
              <i className="fa fa-warning"></i> ATTENTION INVESTORS
            </h5>
            <div className="scrolling_text">
              <p className="scroll">
                Prevent unauthorized transactions in your account~
              </p>
            </div>
            <ul className="footer-lists">
              <li className="footer-list">
                Stock Brokers can accept securities as margin from clients only
                by way of pledge in the depository system w.e.f. September 01,
                2020.
              </li>
              <li className="footer-list">
                Check your securities / MF / bonds in the consolidated account
                statement issued by NSDL/CDSL every month.{" "}
              </li>
              <li className="footer-list">
                Receive alerts on your registered mobile for all debit and other
                important transactions in your demat account directly from{" "}
                <b>NSDL/CDSL </b>on the same day..
              </li>
              <li className="footer-list">
                Update your mobile numbers/email ID with your stock broker.
                Receive information of your transactions directly from exchange
                on your registered mobile/email at the end of the day.
              </li>
              <li className="footer-list">
                Never deal with a stock broker who is not registered with SEBI.
              </li>
              <li className="footer-list">
                Issued in the interest of investor to prevent unauthorized
                transactions in your demat account~ update your mobile number
                with your depository participant.
              </li>
              <li className="footer-list">
                KYC is one time exercise while dealing in securities markets -
                once KYC is done through a SEBI registered intermediary (broker,
                dipository participant, mutual fund etc.), you need not undergo
                the same process again when you approach another intermediary.
              </li>
              <li className="footer-list">
                No need to issue cheques by investors while subscribing to IPO,
                just use ASBA facility for hassle free experience.
              </li>
              <li className="footer-list">
                SEBI has mandated the Nomination in the Demat and Trading
                Account, request you to please provide the Nomination or Opt Out
                of the the Nomination before September 30,2023.
              </li>
              <li className="footer-list">
                Pay 20% upfront margin of the transaction value to trade in cash
                market segment.
              </li>
              <li className="footer-list">
                Investors may please refer to the Exchange's Frequently Asked
                Questions (FAQs) issued vide circular reference NSE/INSP/45191
                dated July 31, 2020 and NSE/INSP/45534 dated August 31, 2020 and
                other guidelines issued from time to time in this regard.
              </li>
              <li className="footer-list">
                Dear Investor, As you are aware, under the rapidly evolving
                dynamics of financial markets, it is crucial for investors to
                remain updated and well-informed about various aspects of
                investing in securities market. In this connection, please find
                a link to the BSE Investor Protection Fund website where you
                will find some useful educative material in the form of text and
                videos, so as to become an informed investor.{" "}
                <a
                  href="https://www.bseipf.com/investors_education.html"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: "bolder",
                  }}
                >
                  'Investor Education'
                </a>{" "}
                We believe that an educated investor is a protected investor !!!
              </li>
              <li className="footer-list under-links">
                <ul className="m-lists">
                  <span>Company:-</span>
                  <li className="list">CIN: U74899GJ1996PTC119714 |</li>
                  <li className="list">
                    SEBI Regd No: INZ000299236 (Stock Broker) |
                  </li>
                  <li className="list">
                    SEBI Regd No: IN-DP-623-2021 (D.P.) |
                  </li>
                  <li className="list">AMFI Regd. No: (ARN) 189273</li>
                </ul>
              </li>
              <li className="footer-list under-links">
                <ul className="m-lists">
                  <span>Membership & ID:-</span>
                  <li className="list">NSE ID : 90234 |</li>
                  <li className="list">BSE ID : 6758 |</li>
                  <li className="list">MCX ID : 56970 |</li>
                  <li className="list">NCL Clearing ID : M52119 |</li>
                  <li className="list">ICCL Clearing ID : 6758 |</li>
                  <li className="list">D.P. ID : 12094100</li>
                </ul>
              </li>
              <Disclaimer />
              <div className="usefulLinksContainer">
                <p className="useful_links">
                  {/* <span>Other Links :-</span> */}
                  <a
                    href="https://www.nseindia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    NSE
                  </a>
                  <a
                    href="https://www.bseindia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    BSE
                  </a>
                  <a
                    href="https://www.ncdex.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    NCDEX
                  </a>
                  <a
                    href="https://www.mcxindia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    MCX
                  </a>
                  <a
                    href="https://nsdl.co.in/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    NSDL
                  </a>
                  <a
                    href="https://www.cdslindia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CDSL
                  </a>
                  <a
                    href="https://www.sebi.gov.in/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    SEBI
                  </a>
                  <a
                    href="https://www.rbi.org.in/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    RBI
                  </a>
                  <a
                    href="https://smartodr.in/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    SmartODR
                  </a>
                  <a
                    href="https://investor.sebi.gov.in/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Investor.SEBI
                  </a>
                </p>
              </div>
            </ul>
          </div>
        </Container>
      </section>
    </>
  );
};


export default Attention;
