import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Code of Conduct for Depositories
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>A Depository shall:</p>
      <ul className="lists">
        <li className="list">
          always abide by the provisions of the Act, Depositories Act, 1996, any
          Rules or Regulations framed thereunder, circulars, guidelines and any
          other directions issued by the Board from time to time.
        </li>
        <li className="list">adopt appropriate due diligence measures.</li>
        <li className="list">
          take effective measures to ensure implementation of proper risk
          management framework and good governance practices.
        </li>
        <li className="list">
          take appropriate measures towards investor protection and education of
          investors.
        </li>
        <li className="list">
          treat all its applicants/members in a fair and transparent manner.
        </li>
        <li className="list">
          promptly inform the Board of violations of the provisions of the Act,
          the Depositories Act, 1996, rules, regulations, circulars, guidelines
          or any other directions by any of its issuer or issuer's agent.
        </li>
        <li className="list">
          take a proactive and responsible attitude towards safeguarding the
          interests of investors, integrity of depository's systems and the
          securities market.
        </li>
        <li className="list">
          endeavor for introduction of best business practices amongst itself
          and its members.
        </li>
        <li className="list">
          act in utmost good faith and shall avoid conflict of interest in the
          conduct of its functions.
        </li>
        <li className="list">
          not indulge in unfair competition, which is likely to harm the
          interests of any other Depository, their participants or investors or
          is likely to place them in a disadvantageous position while competing
          for or executing any assignment.
        </li>
        <li className="list">
          segregate roles and responsibilities of key management personnel
          within the depository including
          <ul className="lists">
            <li className="list">
              Clearly mapping legal and regulatory duties to the concerned
              position
            </li>
            <li className="list">
              Defining delegation of powers to each position.
            </li>
            <li className="list">
              Assigning regulatory, risk management and compliance aspects to
              business and support teams.
            </li>
          </ul>
        </li>
        <li className="list">
          be responsible for the acts or omissions of its employees in respect
          of the conduct of its business.
        </li>
        <li className="list">
          monitor the compliance of the rules and regulations by the
          participants and shall further ensure that their conduct is in a
          manner that will safeguard the interest of ors and the securities
          market.
        </li>
      </ul>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const CodeOfConduct = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Code of Conduct for Depositories
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default CodeOfConduct;
