import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Rights of investors
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ul className="lists">
        <li className="list">
          Receive a copy of KYC, copy of account opening documents.
        </li>
        <li className="list">
          No minimum balance is required to be maintained in a demat account.
        </li>
        <li className="list">
          No charges are payable for opening of demat accounts.
        </li>
        <li className="list">
          If executed, receive a copy of Power of Attorney. However, Power of
          Attorney is not a mandatory requirement as per SEBI / Stock Exchanges.
          You have the right to revoke any authorization given at any time.
        </li>
        <li className="list">
          You can open more than one demat account in the same name with single
          DP/ multiple DPs.
        </li>
        <li className="list">
          Receive statement of accounts periodically. In case of any
          discrepancies in statements, take up the same with the DP immediately.
          If the DP does not respond, take up the matter with the Depositories.
        </li>
        <li className="list">
          Pledge and /or any other interest or encumbrance can be created on
          demat holdings.
        </li>
        <li className="list">
          Right to give standing instructions with regard to the crediting of
          securities in demat account.
        </li>
        <li className="list">
          Investor can exercise its right to freeze/defreeze his/her demat
          account or specific securities / specific quantity of securities in
          the account, maintained with the DP.
        </li>
        <li className="list">
          In case of any grievances, Investor has right to approach Participant
          or Depository or SEBI for getting the same resolved within prescribed
          timelines.
        </li>
        <li className="list">
          Every eligible investor shareholder has a right to cast its vote on
          various resolutions proposed by the companies for which Depositories
          have developed an internet based ‘e-Voting’ platform.
        </li>
        <li className="list">
          Receive information about charges and fees. Any charges/tariff agreed
          upon shall not increase unless a notice in writing of not less than
          thirty days is given to the Investor.
        </li>
        <li className="list">
          Do not follow herd mentality for investments. Seek expert and
          professional advice for your investments.
        </li>
        <li className="list">Beware of assured/fixed returns.</li>
      </ul>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const Rightsofinvestors = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Rights of investors{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Rightsofinvestors;
