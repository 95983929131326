import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './Header'
import Queries from './Queries'

function FutureOptions() {
  return (
    <>
        <Helmet>
            <title>
                Growth Securities || Queries related futures & options || Find your solutions related F&O
            </title>
        </Helmet>
        <div className="wrapper">
            <Header />
            <Queries />
        </div>
    </>
  )
}

export default FutureOptions