import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const VolatilityCalculator = () => {
    const [initialPrice, setInitialPrice] = useState('');
    const [finalPrice, setFinalPrice] = useState('');
    const [timePeriod, setTimePeriod] = useState('');
    const [volatility, setVolatility] = useState(0);

    const calculateVolatility = () => {
        const initial = parseFloat(initialPrice);
        const final = parseFloat(finalPrice);
        const time = parseFloat(timePeriod);

        const returns = Math.log(final / initial);
        const volatilityValue = (returns / time) * Math.sqrt(252) * 100;

        setVolatility(volatilityValue);
    };

    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Growth Calculators || Volatility Calculator
                </title>
            </Helmet>
            <section className="allCalcBack">
                <Container className='allCalcScss'>
                    <ul className="breadcrumbLinks">
                        <li className="breadcrumb-link">
                            <i className='fa fa-home' /> <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link">
                            <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link next">
                            <span>Volatility</span>
                        </li>
                    </ul>
                    <div className="volatility-calculator calcBody">
                        <div className="calculator-container">
                            <h2>Volatility</h2>
                            <div className="input-group">
                                <label>Initial Price:</label>
                                <input
                                    type="text"
                                    value={initialPrice}
                                    onChange={(e) => setInitialPrice(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Final Price:</label>
                                <input
                                    type="text"
                                    value={finalPrice}
                                    onChange={(e) => setFinalPrice(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Time Period (in years):</label>
                                <input
                                    type="text"
                                    value={timePeriod}
                                    onChange={(e) => setTimePeriod(e.target.value)}
                                />
                            </div>
                            <button onClick={calculateVolatility}>Calculate</button>
                            <div className="result">
                                <p>Volatility: {volatility}%</p>
                            </div>
                        </div>
                        <div className="someCalcTxt">
                            <div className="paras">
                                <p className="disc">
                                    Volatility is a statistical measure of the dispersion of returns for a given financial instrument. It indicates the degree of variation of a financial instrument's price over time. In the context of investing, volatility is often considered a measure of risk.
                                </p>
                            </div>
                            <div className="formulaSec">
                                <h2>How to calculate Volatility?</h2>
                                <p className="disc">
                                    The formula used to calculate volatility is as follows:
                                </p>
                                <h6>
                                    Volatility = (Returns / Time) * √(252) * 100
                                </h6>
                                <p className="disc">
                                    Where:
                                </p>
                                <ul>
                                    <li>
                                        <span>Returns -</span> Natural logarithm of the ratio of final price to initial price
                                    </li>
                                    <li>
                                        <span>Time -</span> Time period in years
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default VolatilityCalculator;
