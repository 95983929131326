import React from "react";
import Header from "./Header";
import About from "./About";
import DematAccount from "../Home/DematAccount";
import Services from "../Home/Services";
import Promoters from "../Home/Promoters";
import { Helmet } from "react-helmet";
import Quote from "./Quote";
import What from "./What";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Business() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Our Business || The Best Stock Market Trading
          Company
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <About />
        </AnimatedSection>
        <AnimatedSection>
          <What />
        </AnimatedSection>
        <AnimatedSection>
          <Services />
        </AnimatedSection>
        <AnimatedSection>
          <Quote />
        </AnimatedSection>
        <AnimatedSection>
          <DematAccount />
        </AnimatedSection>
        <AnimatedSection>
          <Promoters />
        </AnimatedSection>
      </div>
    </>
  );
}

export default Business;
