import React from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import Header from "./Header";
import WhatIs from "./WhatIs";
import WhyEquity from "./WhyEquity";
import Benefits from "./Benefits";
import Premier from "./Premier";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Equity() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Equity Trading || How to Trade Equities || What
          is Equity Trading in Share Market & How Does It Work?
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <WhatIs />
        </AnimatedSection>
        <AnimatedSection>
          <Benefits />
        </AnimatedSection>
        <AnimatedSection>
          <WhyEquity />
        </AnimatedSection>
        <AnimatedSection>
          <Premier />
        </AnimatedSection>
      </div>
    </>
  );
}

export default Equity;
