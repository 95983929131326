import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export class FooterLast extends Component {
  render() {
    return (
      <>
        <section className="footer-last last-footer-container">
          <Container className='last-footer'>
            <p className="last">
             &copy; <span>Growth Securities</span>, All rights reserved.
            </p>
          </Container>
        </section>
      </>
    )
  }
}

export default FooterLast;