import React from "react";
import EsclationMatrix from "./EsclationMatrix";
import KMP from "./KMP";
import "./Style.scss";
import { Helmet } from "react-helmet";
import FooterDetails from "./FooterDetails";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Mendatory() {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Mendatory Details</title>
      </Helmet>
      <div className="wrapper mendatoryDetails">
        <AnimatedSection>
          <EsclationMatrix />
        </AnimatedSection>
        <AnimatedSection>
          <KMP />
        </AnimatedSection>
        <AnimatedSection>
          <FooterDetails />
        </AnimatedSection>
      </div>
    </>
  );
}

export default Mendatory;
