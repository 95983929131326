import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import investing from './Icons/investing.svg'
import easy1 from './Icons/easy1.svg'
import easy2 from './Icons/easy2.svg'
import easy3 from './Icons/easy3.svg'



export class EasyInvesting extends Component {
    render() {
        return (
            <>
                <section className="investing-container">
                    <Container className="investing_section">
                        <Row className="investing-rows">
                            <Col lg={7} md={12} sm={12} className="investing-text">
                                <div className="texts">
                                    <h1 className="grow-title">
                                        Investing helps in building wealth over time
                                    </h1>
                                    <p className="disc">
                                        By way of providing modern technology and best tools to help you in growth of your wealth. 
                                    </p>
                                    <p className="disc">
                                        {/* Growth brings you to the best of the technology along with simple tools to help you grow your wealth. */}
                                        It's a great way that you can earn returns on your investment which leads to greater financial security to fullfil your goals.
                                    </p>
                                    <a className='story' href='/about-us'>
                                        <span>Read our story</span>
                                        <i className='fa fa-arrow-right story'></i>
                                    </a> 
                                    <div className="three-dots">
                                        <div className='heading'>
                                            <img 
                                                src={easy1}
                                                alt="Years Experience Growth" 
                                                width="50px"
                                            />
                                            <p className='disc'>
                                               27 years of experience in financial markets
                                            </p>
                                        </div>
                                        <div className='heading'>
                                            <img 
                                                src={easy2}
                                                alt="Growth easy illustration" 
                                                width="50px"
                                            />
                                            <p className='disc'>
                                                Cutting edge trading technology
                                            </p>
                                        </div>
                                        <div className='heading'>
                                            <img 
                                                src={easy3}
                                                alt="Growth easy illustration" 
                                                width="50px"
                                            />
                                            <p className='disc'>
                                                Brokerage plan that suits your goals
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="investing-img">
                                <img
                                    src={investing}
                                    alt="Start Investing With Growth"
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default EasyInvesting