import React from "react";
import "./Style.scss";
import error from "./Icons/error.svg";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const NoPage = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
      <Container className="nopageContainer">
        <AnimatedSection>
          <div className="noPage">
            <h1 className="nopage-title">Oops! Page Not Found</h1>
            <img
              src={error}
              alt="Error Page"
              className="errorImg"
              width="100%"
            />
            <p className="nopage-description">
              The page you're looking for doesn't exist.
            </p>
            <div className="back">
              <a href="/" className="nopage-link">
                Go Home
              </a>
            </div>
          </div>
        </AnimatedSection>
      </Container>
    </>
  );
};

export default NoPage;
