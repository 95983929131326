import React from "react";
import "./Style.scss";
import Header from "./Header";
import FeedbackForm from "./FeedbackForm";
import { Helmet } from "react-helmet";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Feeddback() {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Feedback || Support Feedback</title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <FeedbackForm />
        </AnimatedSection>
      </div>
    </>
  );
}

export default Feeddback;
