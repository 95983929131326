import React from 'react'
import { Container } from 'react-bootstrap'

function Header() {
  return (
    <>
      <section className="fundTransferHeaderSection">
        <Container className='fundTransferHeader'>
          <h1 className="title">
            Fund transfer
          </h1>
          <h4 className="subTitle">
            Transfer funds only from the bank account registered with Growth Securities
          </h4>
          <hr width="100%" />
        </Container>
      </section>
    </>
  )
}

export default Header