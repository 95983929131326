import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import grow from './Icons/grow.svg'



export class Grow extends Component {
    render() {
        return (
            <>
                <section className="grow_section-container">
                    <Container className="grow_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            <span>Growth</span>: The Ideal Choice for <span>Currency Trading</span>
                        </h1>
                        </div>
                        <Row className="grow_section-rows">
                            <Col lg={7} md={12} sm={12} className="grow_section-text">
                                <div className="texts">
                                    <p className="disc">
                                        The currency market is the largest and most liquid financial market in the world, with a daily trading volume of over $6 trillion. Forex trading is done through a broker, who matches buyers and sellers in the market. Currency rates are influenced by a variety of factors, including political stability, economic growth, and interest rates. Forex traders can use technical and fundamental analysis to determine their trades. It is important to note that currency trading carries a high level of risk due to the volatility of currency exchange rates. So you can rely on Growth to meet your expectations.
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey={false} flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Experience and Expertise
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth is a leading currency trading platform with years of experience and expertise in the industry. Our team of professionals has a deep understanding of the market and is always up-to-date on the latest trends and changes in the world of currency trading. With Growth, you can be confident that you’re making informed and strategic decisions.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Ease of Use
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        One of the most significant advantages of Growth is its user-friendly platform. Whether you’re a seasoned trader or just starting, our platform is designed to be easy to use and navigate. The process is straightforward and seamless, from opening an account to making trades. You can also access your account and make trades from anywhere with an internet connection, making it easy to stay on top of your investments.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        A Wide Range of Options
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth offers a wide range of currency pairs for trading, giving you access to various markets. This means that you have the flexibility to choose the pairs that are most in line with your investment goals and strategies. Our platform also allows you to trade in real-time, taking advantage of market opportunities.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Superior Technology
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        At Growth, we use state-of-the-art technology to provide a secure and reliable trading experience. Our platform is equipped with advanced security features to protect your investments. Additionally, our technology is designed to be fast and responsive so that you can execute trades quickly and efficiently.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="grow_section-img">
                                <div className="imgs">
                                    <img
                                        src={grow}
                                        alt="Growth grow illustration"
                                        className="grow-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Grow