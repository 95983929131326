import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import tradings from "../Icons/tradings.svg";
import "./Style.scss";

export class Trading extends Component {
  render() {
    return (
      <>
        <div className="trading-container">
          <Container className="trading">
            <Row className="trading-rows">
              <Col lg={6} md={12} sm={12} className="trading-text term-text">
                <h2 className="subtitle">Trading, Settlement & Accounts</h2>
                <p className="disc">
                  The client agrees that all orders placed through the website
                  or trading terminal or mobile application shall be forwarded
                  by the system to the Exchange. All orders placed otherwise
                  than through the website shall be forwarded by the system to
                  Exchange terminals or any other order execution mechanism at
                  the discretion of GSPL. In the event that the order is placed
                  during the trading hours, it shall be routed to and executed
                  on the market system.
                </p>
                <p className="disc">
                  Online confirmation will be sent to the client by electronic
                  mail or SMS after the execution of the order, trade and this
                  shall be deemed to be valid delivery thereof by GSPL. It shall
                  be the responsibility of the client to review immediately upon
                  receipt, whether delivered to him by electronic mail or any
                  other electronic means all confirmations of order,
                  transactions, or cancellations. It shall be the responsibility
                  of the client to follow up with GSPL for all such
                  confirmations that are not received by him within a stipulated
                  time.
                </p>
              </Col>
              <Col lg={6} md={12} sm={12} className="trading-img">
                <img
                  className=""
                  src={tradings}
                  alt="Growth trading illustration"
                />
              </Col>
            </Row>
            <Row className="trading-rows">
              <Col lg={12} md={12} sm={12} className="trading-text term-text">
                <p className="disc">
                  Cancellation or modification of an order pursuant to the
                  client’s request in that behalf is not guaranteed. The order
                  will be cancelled or modified only if the client’s request for
                  cancellation and modification is received and the order is
                  successfully cancelled or modified before it is executed.
                  Market orders are subject to immediate execution wherever
                  possible.
                </p>
                <p className="disc">
                  The client shall bring any errors in any report, confirmation
                  or contract note of executed trades (including execution
                  prices, scripts or quantities) to GSPL’s notice in writing by
                  an electronic mail or fax within twenty four hours of receipt
                  of the concerned report, confirmation or contract note. Any
                  other discrepancy in the confirmation or account shall be
                  notified by the client to GSPL in writing via electronic mail
                  or fax within twenty four hours from the time of receipt of
                  the first notice. In all cases, GSPL shall have a right to
                  accept or reject the client’s objection.
                </p>
                <p className="disc">
                  The client shall not be entitled to presume an order having
                  been executed, cancelled or modified until a confirmation from
                  GSPL is received by the client. However, due to technical
                  other factors the confirmation may not be immediately
                  transmitted to or received by the client and such a delay
                  shall not entitle the client to presume that the order has not
                  been executed cancelled or modified unless and until GSPL has
                  so confirmed in writing.
                </p>
                <p className="disc">
                  The client shall bring any errors in any report, confirmation
                  or contract note of executed trades (including execution
                  prices, scripts or quantities) to GSPL’s notice in writing by
                  an electronic mail or fax within twenty four hours of receipt
                  of the concerned report, confirmation or contract note. Any
                  other discrepancy in the confirmation or account shall be
                  notified by the client to GSPL in writing via electronic mail
                  or fax within twenty four hours from the time of receipt of
                  the first notice. In all cases, GSPL shall have a right to
                  accept or reject the client’s objection.
                </p>
                <p className="disc">
                  The pending orders shall be governed as per the exchange
                  systems, after the market is closed for the day.
                </p>
                <p className="disc">
                  There may be a delay in GSPL receiving the reports of
                  transaction, status, from the respective exchanges or other
                  persons in respect of or in connection with which GSPL has
                  entered into contracts or transactions on behalf of the
                  clients. Accordingly GSPL may forward to the client late
                  reports in respect of such transactions that were previously
                  unreported to him as been expired, cancelled or executed. The
                  client shall not hold GSPL responsible for any losses suffered
                  by the client on account of any late reports, statements or
                  any errors in the report / statements computed by or received
                  from any exchange.
                </p>
                <p className="disc">
                  GSPL shall issue contract notes in terms of the SEBI (Brokers
                  and Sub-Brokers) Rules and Regulations, 1992, within 24 hours
                  of the execution of the trade. Such a contract note, if issued
                  in physical form shall be dispatched by GSPL by courier, at
                  the address mentioned in this agreement or at any other
                  address expressly informed to GSPL by the client. The client
                  agrees that GSPL to issue the contract note in digital form
                  which shall be sent by way of electronic mail to the address
                  provided by the client. GSPL shall not be responsible for the
                  non-receipt of the trade confirmation due to any change in the
                  correspondence address of the Client not intimated to GSPL in
                  writing. Client is aware that it is his responsibility to
                  review the trade confirmations, the contract notes, the bills
                  or statements of account immediately upon their receipt. All
                  such confirmations and statements shall be deemed to have been
                  accepted as correct if the client does not object in writing
                  to any of the contents of such trade confirmation/intimation
                  within 24 hours to GSPL.
                </p>
                <p className="disc">
                  The client agrees that, if for any circumstance or for any
                  reason, the markets close before the acceptance of the Order
                  by the Exchange, the order may be rejected. The client agrees
                  further, that GSPL may reject Orders if the same are rejected
                  by the Exchange for any reason. In case of rejection of an
                  order due to rejection by the Exchange, the client agrees that
                  the order shall remain declined and shall not be re-processed,
                  in any event.
                </p>
                <p className="disc">
                  GSPL may allow/disallow client from trading in any security or
                  class of securities, or derivatives contracts and impose such
                  conditions including scrip-wise conditional trading for
                  trading as it may deem fit from time to time.
                </p>
                <p className="disc">
                  GSPL may, at its sole discretion, reject any order placed on
                  the website or in any other manner due to any reason,
                  including but not limited to the non-availability of funds in
                  the trading account of the client, non-availability of
                  securities in the Demat account of the client with a
                  designated depository participant, insufficiency of margin
                  amount if the client opts for margin trading, suspension of
                  scrip- specific trading activities by or on an Exchange and
                  the applicability of circuit breaker to a scrip in which
                  orders are placed.
                </p>
                <p className="disc">
                  The client agrees that, if the order is not accepted for any
                  reason, GSPL shall have the right to treat the order as having
                  lapsed.
                </p>
                <p className="disc">
                  The client is aware that the electronic trading systems either
                  at the Exchange or in the office of GSPL offices are
                  vulnerable to temporary disruptions, breakdowns or failures.
                  In the event of non- execution of trade orders or trade
                  cancellation due to the happening of such events or
                  vulnerabilities due to failure / disruption / breakdown of
                  system or link, GSPL shall be entitled to cancel relative
                  request/(s) with the Client and shall not be liable to execute
                  the desired transactions of the client’s. In such event, GSPL
                  does not accept responsibility for any losses incurred / that
                  may be incurred by the Client due to such eventualities which
                  are beyond the control of GSPL.
                </p>
                <p className="disc">
                  GSPL may at its sole discretion permit execution of orders in
                  respect of securities, irrespective of the amount in the
                  balance of the account of the client.
                </p>
                <p className="disc">
                  The client agrees to abide with and be bound by all the rules,
                  regulations and bye-laws of the Exchange as are in force
                  pertaining to the transactions on his behalf carried out by
                  GSPL and the orders placed by him on the website or any other
                  manner.
                </p>
                <p className="disc">
                  GSPL shall not be responsible for any order, that is made by
                  the Client by mistake and every order that is entered by the
                  Client through the use of the allotted user name and the
                  security code(s) shall be deemed to be a valid order for which
                  the Client shall be fully responsible.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Trading;
