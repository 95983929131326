import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './Style.scss';
import logo from '../Icons/brand.png';

const MobileBot = () => {
  const [showModal, setShowModal] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  // Set the iframeLoaded state to true when the iframe has finished loading
  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    // Reset the iframeLoaded state when the modal is closed
    if (!showModal) {
      setIframeLoaded(false);
    }
  }, [showModal]);

  return (
    <div className="mobileBotContainer">
      <Button className="chatbot-link for-small-device smallMobileBot" onClick={handleModalShow}>
        <i className="fa fa-commenting" aria-hidden="true" title='How may I help you?'></i>
      </Button>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="fullscreen-modal"
        backdrop={iframeLoaded ? true : "static"} // Show backdrop only when iframe is loaded
      >
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="img" style={{width: "100%"}}>
              <img src={logo} alt="Growth" className="brand" style={{width: "40%"}} />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="botContainer" style={{ height: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
            {!iframeLoaded && (
              <div className="loadingBot">
                <span>
                </span>
              </div>
            )}
            <iframe
              src="https://chatbot.meon.co.in/growthsec"
              width='100%'
              height='100%'
              title='Growthbot'
              onLoad={handleIframeLoad}
              style={{ display: iframeLoaded ? 'block' : 'none' }} // Show iframe when loaded
            ></iframe>
            <span className="coverName"></span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MobileBot;
