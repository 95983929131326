import React, { useState, useEffect } from 'react';
import './Style.scss';
import questionsData from './Data.json';
import QuizTerms from './QuizTerms';
import { Helmet } from 'react-helmet';
import Popup from './Popup';
import AnimatedSection from '../Defaults/Animations/AnimatedSection'


function MCQs() {
  const [userName, setUserName] = useState('');
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [hearts, setHearts] = useState(5);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(120); // 2 minutes in seconds
  const [timerId, setTimerId] = useState(null);
  const [showCover, setShowCover] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [previousAnswer, setPreviousAnswer] = useState('');
  const [motivationalMessage, setMotivationalMessage] = useState('');

  const questions = questionsData.questions;

  useEffect(() => {
    if (!showCover) {
      shuffleOptions();
      startTimer();
    }
  }, [currentQuestion, showCover]);

  useEffect(() => {
    if (timeRemaining === 0) {
      disableQuiz();
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (showResult && selectedOption === questions[currentQuestion].correctAnswer) {
      showMotivationalMessage();
    }
  }, [showResult]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const checkAnswer = () => {
    if (selectedOption === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
      setShowResult(true);
      setSelectedOption('');
      resetTimer();
      setPreviousAnswer('');
      showMotivationalMessage(); // Display motivational message
      setTimeout(nextQuestion, 2000); // Move to the next question after 2 seconds
    } else {
      setHearts(hearts - 1);
      setPreviousAnswer(selectedOption);
      setSelectedOption('');
      setShowPopup(true);
      shuffleOptions();
    }
  };

  const nextQuestion = () => {
    setShowResult(false);
    setCurrentQuestion(currentQuestion + 1);
    setSelectedOption('');
    resetTimer();
    setShowPopup(false);
    setPreviousAnswer('');
  };

  const shuffleOptions = () => {
    const options = [...questions[currentQuestion].options];
    const shuffledOptions = options.sort(() => Math.random() - 0.5);
    setShuffledOptions(shuffledOptions);
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);
    setTimerId(timer);
  };

  const resetTimer = () => {
    clearInterval(timerId);
    setTimeRemaining(120); // Reset timer to 2 minutes
  };

  const disableQuiz = () => {
    clearInterval(timerId);
    setHearts(0);
    setShowPopup(true);
    resetTimer();
    setTimeout(() => {
      window.location.reload(); // Auto-refresh the page after 2 minutes
    }, 5000);
  };

  const handleNameBlur = (e) => {
    const enteredName = e.target.value;
    if (!enteredName.trim()) {
      setUserName('Anonymous');
    } else {
      setUserName(enteredName);
    }
  };

  const handleNameSubmit = () => {
    if (!userName.trim()) {
      setUserName('Anonymous');
    }
    setNameSubmitted(true);
    setShowCover(false);
  };

  const showMotivationalMessage = () => {
    const messages = ['Great job!', 'Well done!', 'Excellent!', 'Fantastic!', 'Awesome!'];
    const randomIndex = Math.floor(Math.random() * messages.length);
    setMotivationalMessage(messages[randomIndex]);
  };

  return (
    <>
      <Helmet>
        <title>Growth Quiz</title>
      </Helmet>
      <div className="quiz-container">
        <AnimatedSection>
        <div className="quizContents">
          <h1>Growth Quiz</h1>
          {showCover && (
            <>
              <div className="cover">
                <h2>Welcome to the Growth Quiz!</h2>
                <p>
                  Test your knowledge about various topics related to personal growth and self-improvement.
                  <br />
                  Are you ready to get started?
                </p>
                <button onClick={handleNameSubmit}>Start Quiz</button>
              </div>
            </>
          )}
          {!showCover && (
            <>
              {hearts > 0 && (
                <>
                  <div className="stats">
                    <div className="timer">
                      <span>Time Remaining:</span>
                      <span>{timeRemaining} seconds</span>
                    </div>
                    <div className="score">
                      <span>Score:</span>
                      <span>{score}</span>
                    </div>
                    <div className="hearts">
                      <span>Hearts:</span>
                      <span>{'♥'.repeat(hearts)}</span>
                    </div>
                  </div>
                  <div className="question">
                    <h3>{questions[currentQuestion].question}</h3>
                  </div>
                  <div className="options">
                    {shuffledOptions.map((option) => (
                      <QuizTerms
                        key={option}
                        option={option}
                        selectedOption={selectedOption}
                        previousAnswer={previousAnswer}
                        showResult={showResult}
                        handleClick={handleOptionClick}
                        correctAnswer={questions[currentQuestion].correctAnswer}
                      />
                    ))}
                  </div>
                  <div className="submit">
                    <button onClick={checkAnswer} disabled={!selectedOption}>
                      Submit
                    </button>
                  </div>
                  {showPopup && (
                    <Popup
                      userName={userName}
                      nameSubmitted={nameSubmitted}
                      handleNameBlur={handleNameBlur}
                      handleNameSubmit={handleNameSubmit}
                      setShowPopup={setShowPopup}
                      setUserName={setUserName}
                    />
                  )}
                  {showResult && <div className="result">{motivationalMessage}</div>}
                </>
              )}
              {hearts === 0 && (
                <div className="game-over">
                  <h2>Game Over!</h2>
                  <p>You ran out of hearts. Better luck next time!</p>
                  <p>Refreshing the page...</p>
                </div>
              )}
            </>
          )}
        </div>
        </AnimatedSection>
      </div>
    </>
  );
}

export default MCQs;
