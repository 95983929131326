import React, { Component } from 'react'
import {
    Col,
    Container,
    Row,
    Accordion
} from 'react-bootstrap'
import how from './Icons/how.svg'



export class Influence extends Component {
    render() {
        return (
            <>
                <section className="influence_section-container">
                    <Container className="influence_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            How <span>Algo Trading</span> influence the Market Efficiency?
                        </h1>
                        </div>
                        <h6 className="subtitle">
                            Algorithmic Trading is a method of executing trades in the financial markets using advanced mathematical algorithms and computational power. These algorithms are designed to analyze vast amounts of data, identify trade opportunities, and execute trades at high speed, accuracy, and efficiency.
                        </h6>
                        <Row className="influence_section-rows">
                            <Col lg={6} md={12} sm={12} className="influence_section-texts">
                                <div className="txts">
                                    <p className="disc">
                                        Algorithmic Trading has significantly impacted market efficiency, transforming how trades are executed and the financial markets operate. In this section, we'll discuss the effects of algorithmic Trading on market efficiency in detail.
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey={false} flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Reduced Market Inefficiencies
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        One of the most significant benefits of algorithmic Trading is that it has reduced market inefficiencies, such as bid-ask spread, price slippage, and latency. Algo trading eliminates human emotions and biases, which can lead to irrational decision-making, and provides a more efficient, objective, and systematic approach to executing trades.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Increased Liquidity
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Algorithmic Trading has also increased liquidity in the financial markets. The high-speed nature of algorithmic Trading and the ability to execute large trades in milliseconds has made it easier for buyers and sellers to match, resulting in increased liquidity and reduced market volatility.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Improved Price Discovery
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Price discovery is determining the fair price of an asset in the financial markets. High-frequency Trading has improved price discovery by providing real-time market data and enabling sophisticated algorithms to analyze this data and arrive at more accurate prices. This has resulted in more efficient price discovery and better allocation of capital.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Reduced Emotional Influence
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Another advantage of algorithmic Trading is that it reduces the emotional influence on trading decisions. This is because the trades are executed based on pre-determined algorithms and rules, eliminating the need for human intervention. As a result, traders can make more objective and rational decisions, avoiding the common pitfalls of emotional Trading, such as impulsive buying and selling.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Improved Risk Management
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Algorithmic Trading also allows traders to better manage their risk by implementing advanced risk management techniques. This includes setting stop-loss orders and executing trades based on pre-defined risk parameters. This helps traders to minimize their losses and protects their capital.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Final Words
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Algorithmic Trading has profoundly impacted the efficiency of the financial markets, reducing inefficiencies, increasing liquidity, and improving price discovery. These benefits make algorithmic trading an attractive option for traders looking to maximize their returns and minimize their risks in the financial markets.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="influence_section-imgs">
                                <div className="imgs">
                                    <img
                                        src={how}
                                        alt="Growth How to influence section img"
                                        className="how-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Influence