import React from 'react';

const SummaryButton = ({ title, onClick }) => {
  return (
    <button className='summaryBtn' onClick={onClick}>
      {title}
    </button>
  );
};

export default SummaryButton;
