import React from 'react';
import { Container } from 'react-bootstrap';
import offlineKyc from './Icons/offlineKyc.PNG'

function OfflineAC() {
    return (
        <section className="offlineAcContainer">
            <Container className="offlineAc">
                <h4>Offline Account Opening Process</h4>
                <div className="steps">
                    <div className="step">
                        <span>1</span>
                        <p>Visit the office of Growth Securities Pvt. Ltd.</p>
                    </div>
                    <div className="step">
                        <span>2</span>
                        <p>Fill the pre-printed account opening form</p>
                    </div>
                    <div className="step">
                        <span>3</span>
                        <p>Therein fill your personal details (mobile, mail, PAN, Aadhar, etc.)</p>
                    </div>
                    <div className="step">
                        <span>4</span>
                        <p>Fill in your Bank Details</p>
                    </div>
                    <div className="step">
                        <span>5</span>
                        <p>Submit Documents, Photo, Proof of Address, Proof of Identity, Income Details, etc.</p>
                    </div>
                    <div className="step">
                        <span>6</span>
                        <p>Sign A/C Opening Form</p>
                    </div>
                    <div className="step">
                        <span>7</span>
                        <p>A/C will be active after verification</p>
                    </div>
                    <img src={offlineKyc} alt="flowchart" className="KycImg" width="100%" />
                </div>
            </Container>
        </section>
    );
}

export default OfflineAC;
