import React from 'react';
import { Container } from 'react-bootstrap';
import onlineKyc from './Icons/onlineKyc.PNG'

function OnlineAC() {
    return (
        <section className="onlineAcContainer">
            <Container className="onlineAc">
                <h4>Online Account Opening Process</h4>
                <div className="steps">
                    <div className="step">
                        <span>1</span>
                        <p>Visit <a href="https://growthsec.com/">https://growthsec.com</a> and click on ‘OPEN ACCOUNT’</p>
                    </div>
                    <div className="step">
                        <span>2</span>
                        <p>Enter your mobile number & email id to get it validated thru OTP</p>
                    </div>
                    <div className="step">
                        <span>3</span>
                        <p>Fill in your personal details (PAN, Aadhar, etc.)</p>
                    </div>
                    <div className="step">
                        <span>4</span>
                        <p>Fill in your Bank Details</p>
                    </div>
                    <div className="step">
                        <span>5</span>
                        <p>Upload Documents, Selfie, Signatures, etc.</p>
                    </div>
                    <div className="step">
                        <span>6</span>
                        <p>E-sign A/C Opening Forms</p>
                    </div>
                    <div className="step">
                        <span>7</span>
                        <p>A/C will be active after verification</p>
                    </div>
                    <img src={onlineKyc} alt="flowchart" className="KycImg" width="100%" />
                </div>
            </Container>
        </section>
    );
}

export default OnlineAC;
