import React, { Component } from 'react'
import './Style.scss'
import { Helmet } from 'react-helmet'
import Header from './Header'
import GrowthBlogs from './GrowthBlogs'
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'




export class Blogss extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Growth Securities || Blogs || Get the latest news and updates about Growth Securities the India's biggest trading platform
          </title>
        </Helmet>
        <div className="wrapper">
          <AnimatedSection>
            <Header />
          </AnimatedSection>
          <AnimatedSection>
            <GrowthBlogs />
          </AnimatedSection>
        </div>

      </>
    )
  }
}

export default Blogss