import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import choice from '../Equity/Icons/choice.svg'



export class Why extends Component {
    render() {
        return (
            <>
                <section className="currency-why">
                    <Container className="why_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why should you choose <span>Growth</span> for <span>Currency Trading</span>?
                        </h1>
                        </div>
                        <Row className="why_section-rows">
                            <Col lg={5} md={12} sm={12} className="why_section-imgs">
                                <div className="img">
                                    <img
                                        src={choice}
                                        alt="Growth choice illustration"
                                        className="choice-img"
                                        width='100%'
                                    />
                                </div>
                                </Col>
                                <Col lg={7} md={12} sm={12} className="why_section-texts">
                                <div className="texts">
                                    <p className="disc">
                                        Growth is the ideal choice for currency trading. With years of experience, a user-friendly platform, a wide range of options, superior technology, and exceptional support and education resources, we provide everything you need to succeed in the world of currency trading. Open an account with us today and start trading with confidence.
                                    </p>
                                    <h5 className="heading">
                                        Support and Education
                                    </h5>
                                    <p className="disc">
                                        At Growth, we understand that currency trading can be overwhelming for some people. That’s why we offer comprehensive support and education resources to help you become a successful trader. 
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why