import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import research from './Icons/researchicon.svg'



export class Header extends React.Component {
    render() {
        return (
            <>
                <div className="research_header-container">
                    <Container className="research_header">
                        <Row className="research_header-rows">
                            <Col lg={6} md={12} sm={12} className="research_header-texts">
                                <div className="left-header">
                                    <h3 className="texts" style={{marginBottom: "4%"}}>
                                        Research <span>Better</span>, Invest <span>Smarter</span>
                                    </h3>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        Research
                                        </p>
                                    </li>
                                </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="research_header-img">
                                <div className="right-header">
                                    <img
                                        src={research}
                                        alt="Growth research illustration"
                                        className="research-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Header;