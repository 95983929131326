import React from "react";
import "./Style.scss";
import { Container, Row } from "react-bootstrap";
import faqs from "./Icons/faqs.svg";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";


function FAQs() {
  return (
    <>
      <div className="wrapper faqs-container">
        <Container className="faqs">
          <AnimatedSection>
            <div className="img-container">
              <img
                src={faqs}
                alt="Faqs illustration Growth"
                className="faq-img"
              />
            </div>
            <div className="paraTitle">
              <h1 className="title">
                <span>FAQ</span>s
              </h1>
            </div>
          </AnimatedSection>
            <Row className="faq-rows">
              <div className="col-md-12 faq-lists">
                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What is Growth ?
                </h3>
                <p className="disc">
                  Growth is a leading technology-driven financial services
                  provider group providing investment solutions since 1996. We
                  offer various services to their clients, including stock
                  broking, trading in equity and derivatives, commodities and
                  currency, investment platforms for mutual funds, IPO
                  distribution, research, investor awareness, and education.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What are the features of
                  Trading account ?
                </h3>
                <ul className="lists">
                  <li className="list">
                    Seamless Trading: You can trade in shares (Intraday Trading or
                    Delivery) without going through the hassle of tracking
                    settlement cycles, writing cheques and transfer instructions.
                    Absolutely hassle free!
                  </li>
                  <li className="list">
                    Security: Instead of transferring monies to a broker's pool or
                    towards deposits, you can manage your own <b>Demat</b> when
                    you trade through <a href="/">growthsec.com</a> It provides
                    you the flexibility to pay only when you trade
                  </li>
                  <li className="list">
                    Wide range of products: Share trading in both NSE and BSE,
                    innovative offerings like - Margin, MarginPlus, BTST, SPOT.
                    Derivatives trading, overseas trading, mutual funds, IPOs etc.
                  </li>
                  <li className="list">
                    Control: You can be rest assured, that your order will be
                    precisely for the amount you wanted it to be, without any
                    deviation, giving you full control of your money and your
                    trades
                  </li>
                  <li className="list">
                    Tracking and Review: Monitoring your investments is as
                    important if not more than making that investment itself. Our
                    portfolio tracker and watchlist along with sms alerts will
                    always keep you updated on the status of your investments with
                    us and act on them when required
                  </li>
                </ul>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Why should I Trade through
                  Growth?
                </h3>
                <p className="disc">
                  Main advantages that <strong>Growth</strong> gives you:
                </p>
                <ul className="lists">
                  <li className="list">
                    Extensive & detailed Research to guide all kind of Traders &
                    Investors.
                  </li>
                  <li className="list">
                    Always available to support all our traders & investors.
                  </li>
                  <li className="list">
                    Experienced teams to guide you in every step
                  </li>
                  <li className="list">
                    You’ll experience an exciting work culture with us.
                  </li>
                </ul>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What documents are required
                  to open 2-in-1 account with us?
                </h3>
                <p className="disc">Documents required</p>
                <ul className="lists">
                  <li className="list">Address proof</li>
                  <li className="list">Permanent Account Number (PAN) card</li>
                  <li className="list">Passport-sized color photographs</li>
                </ul>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Apart from Trading account,
                  what else is required to start online investment?
                </h3>
                <p className="disc">
                  After opening trading account, you need to have a bank balance
                  in your linked Savings Account with your trading account and one
                  has to choose his brokerage plan like Prime or prepaid.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What is the difference
                  between Demat and Trading account?
                </h3>
                <p className="disc">
                  The key difference between a Demat and a Trading account is that
                  a Demat account is used to hold your securities such as your
                  share certificates and other documents in electronic format
                  whereas a Trading account is used for buying and selling these
                  securities in the stock market. The combination of Trading,
                  Demat and savings account is known as a 3-in-1 account in the
                  stock market.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What is Trading Account?
                </h3>
                <p className="disc">
                  The 2-in-1 online trading account gives you the convenience of
                  opening a Online <b>Trading</b> & <b>Demat</b> accounts. You can
                  view live share price, trade in shares, be it Intraday Trading
                  or delivery on NSE and BSE, trade in Futures & Options,
                  Currencies, invest in Mutual Funds, IPOs and Life Insurance
                  through our seamless and secure 3-in-1 online trading account.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What kind of services does
                  Growth provide?
                </h3>
                <p className="disc">
                  Growth provides a wide range of financial services, including
                  stock broking, trading in equity and derivatives, commodities
                  and currency, investment platforms for mutual funds, IPO
                  distribution, research, investor awareness, and education.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> How long has Growth been in
                  business?
                </h3>
                <p className="disc">
                  We have provided investment solutions since 1996, meaning we
                  have over 26 years of experience in the financial services
                  industry.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What stock exchanges does
                  Growth have memberships with?
                </h3>
                <p className="disc">
                  Growth has memberships with the National Stock Exchange (NSE),
                  Bombay Stock Exchange (BSE), and Multi Commodity Exchange (MCX).
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What are the investment
                  platforms offered by Growth?
                </h3>
                <p className="disc">
                  Growth offers several investment platforms, including mutual
                  funds, IPO distribution, research, investor awareness, and
                  education.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Does Growth provide research
                  services?
                </h3>
                <p className="disc">
                  Yes, Growth provides research services to its clients.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Does Growth offer educational
                  resources for investors?
                </h3>
                <p className="disc">
                  Yes, Growth provides educational resources for investors to help
                  them make informed investment decisions.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Is Growth a trustworthy
                  company to invest with?
                </h3>
                <p className="disc">
                  Yes, Growth has been a reputable and trusted company in the
                  financial services industry for over 27 years. The company is
                  registered with the Securities and Exchange Board of India
                  (SEBI). It is a member of the National Stock Exchange (NSE),
                  Bombay Stock Exchange (BSE), and Multi Commodity Exchange of
                  India (MCX).
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What is self-clearing
                  membership?
                </h3>
                <p className="disc">
                  Self-clearing membership is a type of membership that allows a
                  brokerage firm to clear its trades rather than relying on a
                  third-party clearing firm.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Does Growth offer
                  self-clearing membership?
                </h3>
                <p className="disc">
                  Growth offers self-clearing membership through National Clearing
                  Corporation of India Limited (NCL) and Indian Clearing
                  Corporation Limited (ICCL).
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What is investor awareness?
                </h3>
                <p className="disc">
                  Investor awareness refers to educating investors about financial
                  markets, investment opportunities, and the risks involved in
                  investing.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Does Growth offer investor
                  awareness services?
                </h3>
                <p className="disc">
                  Yes, Growth offers investor awareness services to its clients to
                  help them make informed investment decisions.
                </p>
                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> How Do I Open an Account with
                  Growth?
                </h3>
                <p className="disc">
                  Opening an account with Growth is simple. You can start by
                  visiting our website and following the account opening
                  procedure. Make sure to have your address proof, PAN card, and
                  passport-sized color photographs ready for verification.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Can I Trade on Mobile
                  Devices?
                </h3>
                <p className="disc">
                  Yes, we offer mobile trading options through our dedicated
                  trading app. Download the app from your app store, and you can
                  trade conveniently from your mobile device, ensuring you never
                  miss a trading opportunity.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What Are the Different
                  Trading Platforms Offered by Growth?
                </h3>
                <p className="disc">
                  Growth provides a variety of trading platforms to suit different
                  trading styles. From a user-friendly web platform to advanced
                  trading software, we have options that cater to both novice and
                  experienced traders.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> How Do I Fund My Trading
                  Account?
                </h3>
                <p className="disc">
                  Funding your trading account is easy. You can deposit funds
                  through various methods such as online bank transfers,
                  debit/credit cards, and electronic payment gateways available on
                  our platform.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What Are the Trading Hours?
                </h3>
                <p className="disc">
                  Our trading hours vary depending on the market segments.
                  Equities, derivatives, and commodities have specific trading
                  hours that you can find on our website. Make sure to check them
                  before placing trades.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> Are There Any Account
                  Maintenance Charges?
                </h3>
                <p className="disc">
                  We do not charge any account maintenance fees. However, please
                  review our fee schedule for detailed information on other
                  charges that may apply to specific services.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> How Do I Contact Customer
                  Support?
                </h3>
                <p className="disc">
                  Our customer support team is available via phone and email. You
                  can find our contact details on our website's "Contact Us"
                  section. Feel free to reach out to us for any assistance or
                  queries.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What Are Stop-Loss Orders?
                </h3>
                <p className="disc">
                  Stop-loss orders are a risk management tool that allows you to
                  set a predetermined price at which your trade will be
                  automatically executed. It helps you limit potential losses in
                  case the market moves against your position.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What Educational Resources
                  Does Growth Provide for New Traders?
                </h3>
                <p className="disc">
                  We offer a range of educational resources for new traders,
                  including video tutorials, trading guides, and webinars. Our
                  goal is to help you understand the basics of trading and make
                  informed decisions.
                </p>

                <h3 className="faq-question">
                  <i className="fa fa-hand-o-right"></i> What Are the Different Order
                  Types?
                </h3>
                <p className="disc">
                  We support various order types to suit your trading preferences.
                  Market orders, limit orders, and stop orders are among the
                  options available. Each order type has its own advantages and
                  use cases.
                </p>
              </div>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default FAQs;
