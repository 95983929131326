import React, { Component } from 'react'
import { 
  Col, 
  Container, 
  Row 
} from 'react-bootstrap'
import company from './Icons/company.svg'


export class Company extends Component {
  render() {
    return (
      <>
        <section className="company_section-container">
          <Container className='company_section'>
            <div className="paraTitle">
            <h1 className="title">
              We and Our <span>Story</span>
            </h1>
            </div>
            <Row className='company_section-rows'>
              <Col lg={7} md={12} sm={12} className='company_section-text'>
                <p className="disc">
                  We're leading technology driven financial services provider group since 1996. Growth is one of the fastest growing investment solutions company that provide a wide range of services to its vast and diversified client base.
                </p>
                <p className="disc">
                  The company is in the business of stock broking (TM of NSE, BSE & MCX), self clearing membership/clearing membership (NCL & ICCL), allied activities and inter alias is providing services like trading in equity & derivatives, commodities & currency. And also provides investment platform for mutual fund, IPO distribution, research, investor awareness & education.
                </p>
                <p className="disc">
                  We are in the business of completely hedged derivatives arbitrage with a high focus on technology development and innovation.
                </p>
                <p className="disc">
                  We have a diversified client base that includes retail customers (including high net worth individuals), foreign investors, NRIs, financial institutions and corporate clients. Our corporate office is in New Delhi since 2021.
                </p>
                <p className="disc">
                  The company is also a depository participant of Central Depository Services Ltd (CDSL). CDSL's 'easiest' facility has been recently provided to facilitate the instructions through internet.
                </p>
              </Col>
              <Col lg={5} md={12} sm={12} className='company_section-img'>
                <img
                  className='company-img'
                  src={company}
                  alt="Growth Company Img"
                  width=''
                />
              </Col>
            </Row>
            <Row className='company_section-row'>
              <Col lg={12} md={12} sm={12} className='company_section-text'>
                <p className="disc">
                  Internet-based trading facility is freely available for investors. Needless to mention, our entire back-office operations are fully computerized, giving us that technological edge to service the clients effectively. Our company has networks all over India.
                </p>
                <p className="disc">
                  Our company is highly qualified in effective risk control and operations. Our team consists of highly qualified Relationship Managers (RMs) with more than 25 years of experience in stock market who will effectively manage your wealth. We work our best to achieve stability in your portfolio with the help of our technically qualified research team. We are dedicated to deliver more value for our clients by putting their interests ahead of ours.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Company;