import React from "react";
import "./Style.scss";
import Attention from "./Attention";
import FooterLinks from "./FooterLinks";
import FooterLast from "./FooterLast";
// import FooterApps from './FooterApps'
// import TopFooter from "./TopFooter";
// import Version from "./Version";
// import Disclaimer from './Disclaimer'
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function Footer() {
  return (
    <>
      <section className="wrapper footer-color">
        {/* <Version /> */}
        {/* <AnimatedSection> */}
        {/* <TopFooter /> */}
        {/* </AnimatedSection> */}
        <AnimatedSection>
          <FooterLinks />
        </AnimatedSection>
        <AnimatedSection>
          <Attention />
        </AnimatedSection>
        <FooterLast />
        {/* <Disclaimer /> */}
        {/* <FooterApps /> */}
      </section>
    </>
  );
}

export default Footer;
