import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        E-account opening
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Account opening through digital mode, popularly known as “
        <a
          href="https://kyc.growthsec.com/Growth/signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          On-line Account opening
        </a>
        ”, wherein investor intending to open the demat account can visit DP
        website, fill in the required information, submit the required
        documents, conduct video IPV and demat account gets opened without
        visiting DPs office.
      </p>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const Eaccountopening = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        a. E-account opening{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Eaccountopening;
