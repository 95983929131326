import React, { Component } from "react";
import NavBar from "./TopNav//NavBar";
import { Outlet } from "react-router-dom";
import Footer from "../Component/Footers/Footer";
import SocialMedia from "../Component/SocailMedia/SocialMedia";
import GrowthBot from "./Chatbot/GrowthBot";
import TopSlider from "./TopSlider/TopSlider";
import SmallFooter from "./BottomNav/SmallFooter";
import Toast from "./Toast";
import AnimatedSection from "./Animations/AnimatedSection";
import Scroll from "../Component/ScrollTop/Scroll";

export class Layout extends Component {
  render() {
    return (
      <>
        {/* <TopSlider /> */}
        <NavBar />
        <SocialMedia />
        <GrowthBot />
        <Outlet />
        <Footer />
        {/* <Toast /> */}
        <SmallFooter />
        <Scroll />
      </>
    );
  }
}

export default Layout;
