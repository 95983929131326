import React, { Component } from 'react'
import hft from './Icons/hft.svg'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'


export class Header extends Component {
    render() {
        return (
            <>
                <section className="hft_header-section-container">
                    <Container className="hft_header">
                        <Row className="hft_header-rows">
                            <Col lg={7} md={12} sm={12} className='header-texts'>
                                <div className="texts">
                                    <h4 className="headerTitle">
                                        High-frequency trading has revolutionized the financial markets, increasing efficiency and liquidity. It leads to cost savings for investors and helps to improve market fairness. In addition, HFT improves market liquidity, reduces inefficiencies, and provides more accurate pricing information.      
                                    </h4>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        High Frequency Trading
                                        </p>
                                    </li>
                                </ul>
                                    <a href='https://kyc.growthsec.com' className='trade' target='_blank' rel='noopener noreferrer'>Start HF Trading</a> 
                                    <i className='fa fa-arrow-right'></i>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='header-img'>
                                <div className="img">
                                    <img
                                        className='hft_img'
                                        src={hft}
                                        alt="Growth high frequency trading illustration"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header