import React from 'react'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import algotrade from './Icons/Images/algotrade.jpg'
import algotrade2 from './Icons/Images/algotrade2.jpg'
import hft from './Icons/Images/hft.jpg'
import hft2 from './Icons/Images/hft2.jpg'
import hftrade from './Icons/algotrade2.jpg';
import ipo from './Icons/ipo.jpg';
import Calc from '../../Calculators/CalcColl/Imgs/growthCalc.jpg'
import mt from '../../Calculators/CalcColl/Imgs/mutualFunds.jpg'
import sip from '../../Calculators/CalcColl/Imgs/sip.jpg'



function GrowthBlogs() {

  return (
    <>
      <section className="growth-blogs-container">
        <Container className="growth-blogs">
          <div className="paraTitle">
            <h1 className="title">Blogs</h1>
          </div>
          <hr width='100%' />
          <Row className="blogs-boxes rows">
            <Col lg={4} md={4} sm={12} className="blog-box">
              <div className="box">
                <img
                  src={algotrade}
                  alt="Growth Algorithm Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  5 Reasons to Choose Algorithmic Trading for Your Investment Strategy in 2023
                </h4>
                <h6 className="date">
                  Wednesday Sep 14<sup>th</sup> 2022 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  <a className='blog-link' href='algorithmic-trading'>Algorithmic trading</a> is a modern
                  and advanced trading method that uses computer algorithms to execute trades based on
                  specific rules and guidelines. It has gained immense popularity as one of the essential
                  trading strategies in recent years,
                </p>
                <a href="/blog-algorithmic-trading" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={algotrade2}
                  alt="Growth Algorithm Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  See How Automated Trading Influence the Market Efficiency &
                  its Impact on Market Efficiency in 2023
                </h4>
                <h6 className="date">
                  Monday Nov 21<sup>st</sup> 2022 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  <a className='blog-link' href='algorithmic-trading'>Algorithmic Trading</a>,
                  also known as Algo Trading or automated Trading, is a method of executing
                  trades in the financial markets using advanced mathematical algorithms and
                  computational
                </p>
                <a href="/blog-automated-trading" className="trade">Read More</a> <i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={hft}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  Everything you must know about High-Frequency Trading in 2023
                </h4>
                <h6 className="date">
                  Friday Dec 2<sup>nd</sup> 2022 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  <a className='blog-link' href='high-frequency-trading'>High-Frequency Trading</a> (
                  <a className='blog-link' href='high-frequency-trading'>HFT</a>) is a method of trading
                  financial assets, such as stocks, currencies, and commodities, which utilizes
                  high-speed computers and algorithms to execute trades in milliseconds. <a className='blog-link' href='high-frequency-trading'>HFT</a> has
                  become increasingly
                  popular in recent years due to the speed and
                </p>
                <a href="/more-about-high-frequency-trading" className="trade">Read More</a> <i className='fa fa-arrow-right'></i>
              </div>
            </Col>
          </Row>
          <Row className='blogs-boxes sec-rows'>
            <Col lg={4} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={hft2}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  Exploring the Rise of High-Frequency Trading: Why Investors are drawn to it.
                </h4>
                <h6 className="date">
                  Thursday Jan 12<sup>th</sup> 2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  <a className='blog-link' href='high-frequency-trading'>High-Frequency Trading</a> (<a className='blog-link' href='high-frequency-trading'>HFT</a>) is
                  a technique that involves using high-speed algorithms to buy and sell financial securities in milliseconds or even
                  microseconds. <a className='blog-link' href='high-frequency-trading'>HFT</a> has become increasingly
                  popular over the past decade and has been the subject of much discussion and debate among
                  investors and market participants.
                </p>
                <a href="/blog-high-frequency-trading" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={hftrade}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  AI trading is a growing part of the fintech industry. These companies are paving the way.
                </h4>
                <h6 className="date">
                  Saturday Jan 25<sup>th</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  AI trading companies use various tools in the AI wheelhouse — like machine learning,
                  sentiment analysis and algorithmic predictions — to interpret the financial market, use
                  data to calculate price changes, identify reasons behind price fluctuations, carry out
                  sales and trades and monitor the ever-changing market.
                </p>
                <a href="/artificial-intelligence-trading" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={Calc}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  The Importance of Investment Calculators: Empowering Investors with Informed Decision-Making
                </h4>
                <h6 className="date">
                  Saturday May 27<sup>th</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  In the fast-paced world of investment markets, making informed decisions is crucial for success.
                  One tool that has revolutionized the way investors track their profits and losses is the investment
                  calculator.
                </p>
                <a href="/growth_calculator-article" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
          </Row>
          <Row className='blogs-boxes sec-rows'>
            <Col lg={6} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={mt}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  Investing Made Easy with Mutual Funds: The Smart Way to Invest Your Money
                </h4>
                <h6 className="date">
                  Thursday Jan 01<sup>st</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  Mutual funds offer several benefits over individual stock or bond investments, such as diversification,
                  low cost, liquidity, and professional management. Mutual funds also offer tax benefits under
                </p>
                <a href="/mutual_fund-article" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={sip}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  What is SIP and its 10 Benefits: Why You Should Start Investing Today
                </h4>
                <h6 className="date">
                  Friday May 19<sup>th</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                  Systematic Investment Plan (SIP) is a popular investment strategy that allows you to invest a fixed
                  amount of money at regular intervals (weekly, monthly, or quarterly) in mutual funds. In this article
                  we're
                </p>
                <a href="/sip-article" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            {/* <Col lg={4} md={6} sm={12} className='blog-box'>
              <div className="box">
                <img
                  src={ipo}
                  alt="Growth high frequency Trading Img"
                  className="blog-img"
                  width='100%'
                />
                <h4 className="title">
                  Everything about IPO.                
                </h4>
                <h6 className="date">
                  Thursday Apr 13<sup>th</sup>2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                </h6>
                <p className="disc">
                An initial public offering, commonly known as an IPO, is a process through which a private company becomes a public company by offering shares of its stock to the public for the first time. In this blog, we will explore the basics of an IPO, the benefits and drawbacks of going public, and the process involved in conducting an IPO.
                </p>
                <a href="/ipo" className="trade">Read More</a><i className='fa fa-arrow-right'></i>
              </div>                    
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  )
}


export default GrowthBlogs