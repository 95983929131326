import React, { Component } from 'react'
import {
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import generalterm from '../Icons/generalterm.svg'


export default class GeneralTerm extends Component {
  render() {
    return (
      <>
        <div className='general-term-container'>
            <Container className='general-term terms'>
                <h2 className='subtitle'>
                    General Terms and Conditions
                </h2>
                <Row className='term-rows'>
                    <Col lg={4} md={12} sm={12} className='term-img'>
                        <img
                            className='img'
                            src={generalterm}
                            alt='Growth general term illustration'
                        />
                    </Col>
                    <Col lg={8} md={12} sm={12} className='term-text'>
                        <p className='disc'>
                            Before availing of online trading services, the Client shall complete the 
                            registration process as may be prescribed from time to time. The Client 
                            shall follow the instruction given in the website for registering himself 
                            as a client.
                        </p>
                        <p className='disc'>
                            The Client agrees that all investment and disinvestment decisions are based 
                            on the Client's own evaluation of financial circumstances and investment 
                            objectives. This extends to any decisions made by the Client on the basis of 
                            any information that may be made available on the web site of GSPL. 
                            The Client will not hold nor seek to hold GSPL or any of its officers, 
                            directors, partners, employees, agents, subsidiaries, affiliates or business 
                            associates liable for any trading losses, cost of damage incurred by the 
                            Client consequent upon relying on investment information, research opinions 
                            or advice or any other material/information whatsoever on the web site, 
                            literature, brochure issued by GSPL or any other agency appointed/authorised 
                            by GSPL. The Client should seek independent professional advice regarding the 
                            suitability of any investment decisions. The Client also acknowledges that 
                            employees of GSPL are not authorized to give any such advice and that the 
                            Client will not solicit or rely upon any such advice from GSPL or any of its 
                            employees.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
      </>
    )
  }
}
