import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './Style.scss'
import TermsConditions from "./TermsConditions";

const OfferBox = () => {
    return (
        <section className="referEarnSection">
            <Container className="referEarnSectionContainer">
                <Row>
                    <Col md={8} lg={7} className="benefits-box">
                        <div className="box">
                            <h4>Benefits for You</h4>
                            <div className="boxDivision">
                                <div className="benefit">
                                    <h5><i className="fa fa-inr" /> 2000*</h5> {/* Add the Font Awesome icon class here */}
                                    <p>Gift voucher</p>
                                </div>
                                <div className="benefit">
                                    <h5><i className="fa fa-percent" /> 10%</h5> {/* Add the Font Awesome icon class here */}
                                    <p>Percentage Brokerage sharing from your friends’ trades, for life</p>
                                    <a href={TermsConditions} target="_blank" rel="noopener noreferrer">
                                        <TermsConditions />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} lg={5} className="benefits-box">
                        <div className="box">
                            <h4>Benefits for Your Friend</h4>
                            <div className="benefit">
                                <h5>Free</h5>
                                <p>✓ Account opening</p>
                                <p>✓ Demat AMC for the 1st year</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default OfferBox;
