import React from 'react'
import "./Style.scss";
import { Helmet } from 'react-helmet'
import Header from './Header'
import Queries from './Queries'




function AccountOpening() {
  return (
    <>
        <Helmet>
            <title>Growth Securities || Problems Related Demat Account Opening || Queries of account opening || Useful Documents</title>
        </Helmet>
        <div className="wrapper">
            <Header />
            <Queries />
        </div>
    </>
  )
}

export default AccountOpening