import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import step from './Icons/process.svg'


function Header() {
  return (
    <>
      <section className="cpHeaderContainer">
        <Container className='cpHeader'>
          <Row className='cpRows'>
            <Col lg={8} md={12} sm={12} className='cpCol'>
              <div className="cpTxt">
                <h3 className='cpTitle'>
                  Simplifying Investment Actions: Account Opening, Complaints, and Status Tracking
                </h3>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} className='cpCol'>
              <div className="cpImg">
                <img src={step} alt="ProcessImg" className="processImg" width="100%" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Header;