import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import feedback from './Feedback.svg';

function Header() {
  return (
    <>
      <section className="feedbackHeaderContainer">
        <Container className="feedbackHeader">
          <Row className="feedbackHeaderRows">
            <Col lg={8} md={6} sm={12} xs={12} className="leftHeader">
              <h1 className="heroTitle">
                Help us to improve your experience. We value your feedback!
              </h1>
              <h3 className="heroSubtitle">Let us know how we can serve you better.</h3>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12} className="rightHeader">
              <img src={feedback} alt="" className="feedbackImg" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Header;
