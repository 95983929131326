import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const EquityRatioCalculator = () => {
  const [totalEquity, setTotalEquity] = useState('');
  const [totalLiabilities, setTotalLiabilities] = useState('');
  const [equityRatio, setEquityRatio] = useState('');

  const calculateEquityRatio = () => {
    const equity = parseFloat(totalEquity);
    const liabilities = parseFloat(totalLiabilities);

    const ratio = (equity / (equity + liabilities)) * 100;
    setEquityRatio(ratio.toFixed(2));
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Equity Ratio Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Equity Ratio</span>
            </li>
          </ul>
          <div className="equity-ratio-calculator calcBody">
            <div className="calculator-container">
              <h2>Equity Ratio</h2>
              <div className="input-group">
                <label>Total Equity:</label>
                <input
                  type="number"
                  value={totalEquity}
                  onChange={(e) => setTotalEquity(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Total Liabilities:</label>
                <input
                  type="number"
                  value={totalLiabilities}
                  onChange={(e) => setTotalLiabilities(e.target.value)}
                />
              </div>
              <button onClick={calculateEquityRatio}>Calculate</button>
              {equityRatio && (
                <div className="result">
                  <p>Equity Ratio: {equityRatio}%</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  The equity ratio is a financial metric that represents the proportion of equity to total assets. It indicates the percentage of a company's assets that are financed by equity.
                </p>
                <p className="disc">
                  To calculate the equity ratio, divide the total equity by the sum of total equity and total liabilities, and multiply by 100 to get the percentage.
                </p>
              </div>
              <div className="formulaSec">
                <h2>How to calculate Equity Ratio?</h2>
                <p className="disc">
                  The formula for calculating the equity ratio is as follows:
                </p>
                <h6>
                  Equity Ratio = (Total Equity / (Total Equity + Total Liabilities)) * 100
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>Total Equity</span> - The total equity of the company.
                  </li>
                  <li>
                    <span>Total Liabilities</span> - The total liabilities of the company.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EquityRatioCalculator;
