import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import applyIPO from './Icons/applyIPO.svg'


export class How extends React.Component {
    render() {
        return (
            <>
                <section className="how-container">
                    <Container className="how">
                        <div className="paraTitle">
                            <h1 className="title">
                                How to <span>Apply</span>
                            </h1>
                        </div>
                        <Row className="how-rows">
                            <Col lg={7} md={12} sm={12} className="how-img">
                                {/* <div className="ipo-content">
                                    <a href="https://ipo.growthsec.com" id='ipo-page' title='expand me' className="btn ipo-btn">
                                        <i className="fa fa-expand"></i>
                                    </a>
                                    <iframe src="https://ipo.growthsec.com" title='All IPOs' frameBorder="0"/>
                                </div> */}
                                <img src={applyIPO} alt="applyIPO illustration" className="ipoIMG" />
                            </Col>
                            <Col lg={5} md={12} sm={12} className="how-texts">
                                <div className="how-text">
                                    <ul className="lists">
                                        <li className="list">
                                            <span>Demat Account</span>
                                            <p className="disc">
                                                Must have a <a href='https://kyc.growthsec.com'>Demat Account</a> with <a href="/">Growth</a> to get <a href='https://ipo.growthsec.com'>IPOs</a>.
                                            </p>
                                        </li>
                                        <li className="list">
                                            <span>Select IPOs</span>
                                            <p className="disc">
                                                Select the <a href='https://ipo.growthsec.com'>IPO</a> you want to Buy.
                                            </p>
                                        </li>
                                        <li className="list">
                                            <span>
                                                Apply
                                            </span>
                                            <p className="disc">
                                                Click on the tab Apply<a href='https://ipo.growthsec.com'> IPO</a> to start process.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default How