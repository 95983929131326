import React, { Component } from 'react'

export class BackColor extends Component {
  render() {
    return (
      <>

        <section className='home-color small-home-color'>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="#269ed657" d="M18.2,-35.6C25.1,-27.6,33.2,-25.7,44.1,-20.8C55,-15.9,68.7,-7.9,74.4,3.3C80.2,14.6,78,29.2,72.3,43.1C66.6,57,57.4,70.3,44.8,77.7C32.1,85.1,16.1,86.7,7.6,73.5C-0.9,60.4,-1.8,32.5,-3.8,19C-5.9,5.5,-9.1,6.3,-12.6,5.5C-16.2,4.8,-20,2.4,-28.1,-4.7C-36.2,-11.7,-48.5,-23.4,-51,-34.7C-53.5,-45.9,-46.2,-56.7,-36.1,-62.7C-25.9,-68.8,-13,-70.2,-3.6,-63.9C5.7,-57.6,11.4,-43.5,18.2,-35.6Z" transform="translate(100 100)" />
          </svg>
        </section>
      </>
    )
  }
}

export default BackColor;