import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const LumpsumCalculator = () => {
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [rate, setRate] = useState('');
  const [duration, setDuration] = useState('');
  const [lumpsumAmount, setLumpsumAmount] = useState('');

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calculateLumpsumAmount = () => {
    const P = parseFloat(investmentAmount);
    const r = parseFloat(rate) / 100;
    const n = 1; // Compounded annually
    const t = parseFloat(duration);

    const A = P * Math.pow(1 + r / n, n * t);

    setLumpsumAmount(formatNumberWithCommas(A.toFixed(2)));
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculator || Lumpsum
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Lumpsum</span>
            </li>
          </ul>
          <div className="lumpsum-calculator calcBody">
            <div className="calculator-container">
              <h2>Lumpsum</h2>
              <div className="input-group">
                <label>Investment Amount (₹):</label>
                <input
                  type="number"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Rate (%):</label>
                <input
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Duration (years):</label>
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
              <button onClick={calculateLumpsumAmount}>Calculate</button>
              <div className="result">
                <p>Lumpsum Amount: ₹ {lumpsumAmount}</p>
              </div>
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  Investments in Mutual Funds can be broadly classified into two types- lumpsum and SIP. A lumpsum investment is when the depositor invests a significant sum of money on a particular mutual fund scheme. SIP or Systematic Investment Plan, on the other hand, entails the investment of smaller amounts on a monthly basis.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculate Lumpsum?
                </h2>
                <p className="disc">
                  The Lumpsum formula to calculate the investments is represented by:
                </p>
                <h6>FV = P + (P * r * t)</h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>FV-</span> Future Value
                  </li>
                  <li>
                    <span>p-</span> Principal amount (initial investment)
                  </li>
                  <li>
                    <span>r-</span> Interest rate (expressed as a decimal)
                  </li>
                  <li>
                    <span>t-</span> Time period (in years)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default LumpsumCalculator;
