import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import commodity from './Icons/commodity.svg'



export class Header extends Component {
    render() {
        return (
            <>
                <section className="commodity_header_section">
                    <Container className="header_section">
                        <Row className="header_section-rows">
                            <Col lg={7} md={12} sm={12} className="header_section-texts">
                                <div className="texts">
                                    <h4 className="headerTitle">
                                        Get ahead in the trend of commodity trading with expert insights and strategies. Stay up-to-date on market trends, understand the impact of global events, and make informed decisions. Join us on the journey to financial freedom.
                                    </h4>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            Commodity Trading
                                        </p>
                                    </li>
                                </ul>
                                    <a 
                                        href="https://kyc.growthsec.com" 
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        className="trade">
                                            Start Commodity Trading
                                        </a> <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="header_section-img">
                                <div className="img">
                                    <img
                                        src={commodity}
                                        alt="Growth commodity illustration"
                                        className="commodity"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header