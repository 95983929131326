import React, { Component } from 'react';
import './Style.scss'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import dos from '../Icons/dos.svg'
import dont from '../Icons/dont.svg'
import { Helmet } from 'react-helmet'
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'

export class DoDont extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Do's & Dont's
                    </title>
                </Helmet>
                <div className="dodont-container">
                    <Container className="dodont">
                        <AnimatedSection>
                        <Row className="dodont-rows">
                            <Col lg={7} md={12} sm={12} className="dodont-text">
                                <h1 className="title">
                                    Investor Charter
                                </h1>
                                <h3 className="subtitle">
                                    DO<span>'S</span> and DONT<span>'S</span>
                                </h3>
                                <p className="disc">
                                    <span>Dear Investor,</span> Before you step into the securities market, you are requested to go through carefully and understand the following:
                                </p>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="dodont-img">
                                <img
                                    src={dos}
                                    alt="Growth dos illustration"
                                    width='100%'
                                />
                            </Col>
                        </Row>
                        </AnimatedSection>
                        <AnimatedSection>
                        <Row className="dodont-rows">
                            <Col lg={12} md={12} sm={12} className="dodont-text">
                                <h3 className="heading">
                                    DO'S ACCOUNT OPENING
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Read and understand and thereafter execute the Member-Client Agreement with your broker setting out the terms and conditions clearly.
                                    </li>
                                    <li className="list">
                                        Enter accurate and complete details in the form, fill up all fields. Do not leave any spaces blank.

                                    </li>
                                    <li className="list">
                                        All information provided should be factually correct and accurate since this will form the basis of your relationship with Indian Finance Guaranty Limited.

                                    </li>
                                    <li className="list">
                                        Please read and understand every annexure in the Registration kit very carefully before signing.

                                    </li>
                                    <li className="list">
                                        Please provide accurate Email ID, DP ID and Bank account number.

                                    </li>
                                    <li className="list">
                                        The correspondence address should always be updated.

                                    </li>
                                    <li className="list">
                                        Please submit your Permanent Account Number (PAN)

                                    </li>
                                    <li className="list">
                                        Please understand the utility of maintaining a running account before signing the authorization for the same.

                                    </li>
                                    <li className="list">
                                        Ensure that all details are correctly mentioned in the welcome letter sent by karvy after account opening.

                                    </li>
                                </ul>
                                <h3 className="heading">
                                    TRADING
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Call on the landline numbers of your local branch and give clear and unambiguous instructions while placing orders over the phone. In case you visit the branch office, kindly give your orders in writing.

                                    </li>
                                    <li className="list">
                                        Be aware of the risk associated with your Cash Market and Futures & Options positions in the market and margin calls on them. In case of inadequate margin or nonpayment of margin in your account, your positions will be squared off by the Risk Management Team.

                                    </li>
                                    <li className="list">
                                        Please bear in mind that while it is our endeavor to inform you in case of a margin call, it is your responsibility to maintain margins. Indian Finance Guaranty Limited will square up outstanding position in case of shortfall in margin and delay in payments.

                                    </li>
                                    <li className="list">
                                        Maintain secrecy of your password in case of Internet trading, since you will be solely responsible for all transactions effected from its usage.

                                    </li>
                                    <li className="list">
                                        Change your password every fortnight.

                                    </li>
                                </ul>
                                <h3 className="heading">
                                    Post Trading
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Ensure that you receive digital contract notes through E-mail of trades done by you on the same day. If you are not receiving the same, contact E-Broking team immediately. Please note that the details or every executed trade are posted on our website www.Karvy.com in the form of digital contract notes on a daily basis. Access the website, wherever possible and satisfy yourself in respect thereof.

                                    </li>
                                    <li className="list">
                                        Verify all details in contract notes, immediately on receipt. If you find any discrepancy bring the same to the notice of E-Broking team

                                    </li>
                                    <li className="list">
                                        All the information provided should be factually correct and accurate since this will form the basis of your relationship with broker.

                                    </li>
                                    <li className="list">
                                        Check your email account mentioned in the 'Know Your Client' form daily. All your account related information including trade confirmation and margin calls will be emailed to you.

                                    </li>
                                    <li className="list">
                                        Pay the margins within the prescribed time. Collect/pay, your mark to market margins on your positions, on a daily basis, from/to your broker.

                                    </li>
                                    <li className="list">
                                        Ensure receipt of payment/deliveries within one working day of payout.

                                    </li>
                                    <li className="list">
                                        Deliver the shares in case of sale or pay the money in case of purchase before the pay-in day.

                                    </li>
                                    <li className="list">
                                        Make payments; enhance margins through cheques made out in favour of Indian Finance Guaranty Limited only.

                                    </li>
                                    <li className="list">
                                        Issue cheques / drafts in the trade name of broker only i.e. Indian Finance Guaranty Limited'.

                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        </AnimatedSection>
                        <AnimatedSection>
                        <Row className="dodont-rows">
                            <Col lg={6} md={12} sm={12} className="dodont-text">
                                <img
                                    src={dont}
                                    alt="Growth dont's illustration"
                                    width='100%'
                                />
                            </Col>
                            <Col lg={6} md={12} sm={12} className="dodont-text">
                                <h3 className="heading">
                                    DON’TS ACCOUNT OPENING
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Do not sign the Member-Client Agreement without clearing your doubts in respect thereof.

                                    </li>
                                    <li className="list">
                                        Do not fill in wrong or incomplete details. Do not overwrite, cancel, mis-spell the details.

                                    </li>
                                    <li className="list">
                                        Do not provide information which is factually incorrect as the same is illegal.

                                    </li>
                                </ul>
                                <h3 className="heading">
                                    Trading
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Do not trade in any product without knowing and/or understanding the associated risks and rewards.

                                    </li>
                                    <li className="list">
                                        Do not trade in illiquid shares or penny stocks or options or futures stocks or Z, T2T category stock.

                                    </li>
                                    <li className="list">
                                        Do not match / synchronize trades with any other person for any thinly traded shares.

                                    </li>
                                    <li className="list">
                                        Do not indulge in any trading activity which results in disturbance of market equilibrium in any manner including manipulation of price of any scrips.

                                    </li>
                                    <li className="list">
                                        Do not undertake deals for others or trade on your own name and then issue cheques from family members/ friend accounts

                                    </li>
                                    <li className="list">
                                        Do not pay in cash or do not issue a cheque in the name of any employee of the broker or any other person in respect of the transactions.

                                    </li>
                                    <li className="list">
                                        Do not give instructions which are not clear. They should be given verbally over recorded phone lines or in writing.

                                    </li>
                                    <li className="list">
                                        Do not forget to take note of risks involved in the investment.

                                    </li>
                                    <li className="list">
                                        Do not reveal your password to any other person.

                                    </li>
                                </ul>
                                <h3 className="heading">
                                    Post Trading:
                                </h3>
                                <ul className="lists">
                                    <li className="list">
                                        Do not delay payment/deliveries of securities to broker/sub-broker.

                                    </li>
                                    <li className="list">
                                        Do not accept unsigned contract notes or signed by an unauthorized person

                                    </li>
                                    <li className="list">
                                        Do not pay more than the agreed brokerage to the intermediary.

                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        </AnimatedSection>
                    </Container>
                </div>
            </>
        )
    }
}

export default DoDont;