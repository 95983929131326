// IssueLogin.jsx
import React, { useState } from 'react';
import './Style.scss';
import IssueFiled from './IssueFiled';
import { Helmet } from 'react-helmet';

const IssueLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Replace 'admin' and 'password' with your desired credentials
        if (username === 'admin' && password === 'dev') {
            setError('');
            setIsLoggedIn(true);
        } else {
            setError('Invalid username or password.');
            setUsername('');
            setPassword('');
        }
    };

    if (isLoggedIn) {
        // If logged in, render the IssueFiled component
        return <IssueFiled />;
    }

    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Admin Panel
                </title>
            </Helmet>
            <div className="login-wrapper">
                <div className="formContainer">
                    <form className="login-form" onSubmit={handleFormSubmit}>
                        <h2>Login Dashboard</h2>
                        {error && <p className="error-message">{error}</p>}
                        <div className="form-group">
                            <label htmlFor="username">Username:</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit">Login</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default IssueLogin;
