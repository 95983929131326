import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Basic Services Demat Account (BSDA)
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        The facility of BSDA with limited services for eligible individuals was
        introduced with the objective of achieving wider financial inclusion and
        to encourage holding of demat accounts. No Annual Maintenance Charges
        (AMC) shall be levied, if the value of securities holding is upto Rs.
        50,000. For value of holdings between Rs 50,001- 2,00,000, AMC not
        exceeding Rs 100 is chargeable. In case of debt securities, there are no
        AMC charges for holding value upto Rs 1,00,000 and a maximum of Rs 100
        as AMC is chargeable for value of holdings between Rs 1,00,001 and Rs
        2,00,000.
      </p>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const BasicServicesDematAccount = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        a. Basic Services Demat Account (BSDA){" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BasicServicesDematAccount;
