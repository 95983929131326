import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap';
import './Style.scss'


function NiftyShortTermTrend() {
  const [showFiiIframe, setShowFiiIframe] = useState(false);
  const [showCashIframe, setShowCashIframe] = useState(false);
  const [showLegacyIframe, setShowLegacyIframe] = useState(false);

  const handleRefreshClick = () => {
    setShowFiiIframe(false);
    setShowCashIframe(false);
    setShowLegacyIframe(false);
  };

  return (
    <Container fluid className="nifty-short-term-trend">
      <div className="buttonHolder">
        <Button className='refreshFiiDii' onClick={handleRefreshClick} title='Refresh Data'>
          <i className="fa fa-refresh"></i>
        </Button>
      </div>
      {showFiiIframe ? (
        <iframe
          src="https://www.valuestocks.in/en/nifty-trend-data-nhf"
          title="F&amp;O Position Data"
          width="100%"
          height="700"
        ></iframe>
      ) : showCashIframe ? (
        <iframe
          src="https://www.valuestocks.in/en/cash-market-data"
          title="Cash Position Data"
          width="100%"
          height="700"
          className='martopType'
        ></iframe>
      ) : showLegacyIframe ? (
        <iframe
          src="https://www.valuestocks.in/en/nifty-trend-legacy-nhf"
          title="Legacy Position Data"
          width="100%"
          height="700"
        ></iframe>
      ) : (
        <div className="rowsContainer">
          <Row>
            <Col lg={6} className="left-column">
              <div className="underCol">
                <h2>Total FnO Open Net FII Positions</h2>
                <p>(This data includes Index Futures, Index Options, Stock Futures, and Stock Options FII positions)</p>
                <div className="nifty-barometer">
                  <Image src="https://www.valuestocks.in/image/nifty-trend-image2.png" alt="VS" className="barometer-img" />
                  <Image src="https://www.valuestocks.in/image/risk-profile-needle.svg" alt="VS" className="barometer-needle" />
                  <div className="barometer-prices">
                    <p className="price position-left"></p>
                    <p className="price position-top"> -393669</p>
                    <p className="price position-right"></p>
                  </div>
                </div>
                <div className="trend-details">
                  <h3>Nifty Short Term Trend</h3>
                  <Button variant="warning" className='btn-warning'>Buy Trend</Button>
                  <p>as on 2023-09-14</p>
                  <p className="trend-description">
                    <b>Current FII net open position is: -142829. Trend will change to Sell Trend at -393669 OI, and Nifty will break 19565 i.e., 20 SMA.</b>
                  </p>
                  <Button
                    variant="info"
                    className="position-data-btn"
                    onClick={() => setShowFiiIframe(!showFiiIframe)}
                  >
                    Check F&amp;O Position Data
                  </Button>

                </div>
              </div>
            </Col>
            <Col lg={6} className="right-column">
              <div className="underCol">
                <h2>FII Cash Positions</h2>
                <p>(This data is calculated by adding up the last 3 days net cash positions of FIIs)</p>
                <div className="nifty-barometer">
                  <Image src="https://www.valuestocks.in/image/nifty-trend-image2.png" alt="VS" className="barometer-img" />
                  <Image src="https://www.valuestocks.in/image/risk-profile-needle.svg" alt="VS" className="barometer-needle" />
                  <div className="barometer-prices">
                    <p className="price position-left"></p>
                    <p className="price position-top"> -5000</p>
                    <p className="price position-right"></p>
                  </div>
                </div>
                <div className="trend-details">
                  <h3>Cash Market Trend</h3>
                  <Button variant="warning" className='btn-warning'>Buy Trend</Button>
                  <p>as on 2023-09-13</p>
                  <p className="trend-description">
                    <b>Current FII net open position is: -228. Trend will change to Sell Trend at -5000 OI, and MidCap Select will break 8988 and SmallCap 100 will break 12495 i.e., 14 SMA.</b>
                  </p>
                  <Button
                    variant="info"
                    className="position-data-btn"
                    onClick={() => setShowCashIframe(true)}
                  >
                    Check Cash Position Data
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="secRow">
            <Col className="text-center">
              <Button
                variant="outline-dark"
                className="position-data-btn"
                onClick={() => setShowLegacyIframe(true)}
              >
                FII Stock Futures &amp; Index Net Open Position
              </Button>
              <p className="disclaimer">The securities quoted are for illustration only and are not recommendatory.</p>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default NiftyShortTermTrend;
