import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import OurResearch from "./OurResearch";
import CRPI from "./CRPI";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const Research = () => {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Research || Stock Market Today || Everything
          about stock market
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <OurResearch />
        </AnimatedSection>
        <AnimatedSection>
          <CRPI />
        </AnimatedSection>
      </div>
    </>
  );
};

export default Research;
