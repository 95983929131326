import React from "react";
import "./Style.scss";
import Header from "./Header";
import Description from "./FiiDiiDesc";
import GraphTable from "./GraphTable";
import { Helmet } from "react-helmet";
import How from "./How";
import Types from "./Types";
import Difference from "./Difference";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function FiiDii() {
  return (
    <>
      <Helmet>Growth Securities | FII and DII Trading Activity</Helmet>
      <div className="wrapper fiidiipageContainer">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <Description />
        </AnimatedSection>
        <AnimatedSection>
          <GraphTable />
        </AnimatedSection>
        <AnimatedSection>
          <How />
        </AnimatedSection>
        <AnimatedSection>
          <Types />
        </AnimatedSection>
        <AnimatedSection>
          <Difference />
        </AnimatedSection>
      </div>
    </>
  );
}

export default FiiDii;
