import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Responsibilities of Investors
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ul className="lists">
        <li className="list">
          Deal with a SEBI registered DP for opening demat account, KYC and
          Depository activities.
        </li>
        <li className="list">
          Provide complete documents for account opening and KYC (Know Your
          Client). Fill all the required details in Account Opening Form / KYC
          form in own handwriting and cancel out the blanks.
        </li>
        <li className="list">
          Read all documents and conditions being agreed before signing the
          account opening form.
        </li>
        <li className="list">
          Accept the Delivery Instruction Slip (DIS) book from DP only
          (preprinted with a serial number along with client ID) and keep it in
          safe custody and do not sign or issue blank or partially filled DIS.
        </li>
        <li className="list">
          Always mention the details like ISIN, number of securities accurately
        </li>
        <li className="list">
          Inform any change in information linked to demat account and obtain
          confirmation of updation in the system.
        </li>
        <li className="list">
          Regularly verify balances and demat statement and reconcile with
          trades / transactions.
        </li>
        <li className="list">
          Appoint nominee(s) to facilitate heirs in obtaining the securities in
          their demat account.
        </li>
        <li className="list">
          Do not fall prey to fraudsters sending emails and SMSs luring to trade
          in stocks / securities promising huge profits
        </li>
      </ul>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const ResponsibilitiesofInvestors = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Responsibilities of Investors{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ResponsibilitiesofInvestors;
