import React, { Component } from 'react'
import './Style.scss'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import business from './Icons/business.svg'


export class Header extends Component {
    render() {
        return (
            <>
                <section className="business_header_section-container">
                    <Container className="business_header_section">
                        <Row className='header-rows'>
                            <Col lg={5} md={12} sm={12} className="business-img">
                                <div className="img">
                                    <img
                                        src={business}
                                        alt="Growth business illustration"
                                        className="business-img"
                                    />
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} className="business-text">
                                <div className="texts">
                                    <h3 className="heading">
                                    Investing in the stock market can be unpredictable, but by doing your research and sticking to a sound investment strategy, you can position yourself for long-term success.
                                    </h3>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            Our Business
                                        </p>
                                    </li>
                                </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header