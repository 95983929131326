import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import fund from './Icons/fund.svg'


export class AboutETF extends Component {
    render() {
        return (
            <>
                <section className="etf_aboutetf-section-container">
                    <Container className="etf_aboutetf">
                        <div className="paraTitle">
                        <h1 className="title">
                            What is <span>Exchange-Traded Fund</span>?
                        </h1>
                        </div>
                        <Row className="etf_aboutetf-rows">
                            <Col lg={5} md={12} sm={12} className='etf-img'>
                                <div className="img">
                                    <img
                                        className='etf_img'
                                        src={fund}
                                        alt="Growth fund illustration"
                                    />
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} className='etf-texts'>
                                <div className="texts">
                                    <p className="disc">
                                        Exchange-traded funds (ETFs) have become increasingly popular in recent years due to their versatility and ease of use. With a single investment, ETFs allow investors to gain exposure to various assets, such as stocks, bonds, and commodities. One type of ETF that has gained popularity is Growth ETFs, which invest in companies with high growth potential.
                                    </p>
                                    <p className="disc">
                                        An ETF is called an exchange-traded fund because it’s traded on an exchange just like stocks are. The price of an ETF’s shares will change throughout the trading day as the shares are bought and sold on the market. This is unlike mutual funds, which are not traded on an exchange, and which trade only once per day after the markets close. Additionally, ETFs tend to be more cost-effective and more liquid compared to mutual funds.
                                    </p>
                                    <p className="disc">
                                        An exchange-traded fund (ETF) is a type of pooled investment security that operates much like a mutual fund. Typically, ETFs will track a particular index, sector, commodity, or other assets, but unlike mutual funds, ETFs can be purchased or sold on a stock exchange the same way that a regular stock can.
                                    </p>
                                    <p className="disc">
                                        An ETF can be structured to track anything from the price of an individual commodity to a large and diverse collection of securities. ETFs can even be structured to track specific investment strategies.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default AboutETF