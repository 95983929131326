import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import './Style.scss'
import Header from './Header'
import Define from './Define'
import Influence from './Influence'
import Benefits from './Benefits'
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'


export class AlgorithmicTrading extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Algo or Algorithmic or Automated Trading || India's best algo trading platform</title>
        </Helmet>
        <div className="wrapper">
          <AnimatedSection>
            <Header />
          </AnimatedSection>
          <AnimatedSection delay={100}>
            <Define />
          </AnimatedSection>
          <AnimatedSection delay={100}>
            <Influence />
          </AnimatedSection>
          <AnimatedSection>
            <Benefits />
          </AnimatedSection>
        </div>
      </>
    )
  }
}

export default AlgorithmicTrading