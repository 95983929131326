import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import trade from './Icons/trade.svg'



export class WhatIs extends Component {
    render() {
        return (
            <>
                <section className="trade_section-container">
                    <Container className="trade_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            What is <span>Commodity Trading</span>?
                        </h1>
                        </div>
                        <Row className="trade_section-rows">
                            <Col lg={5} md={12} sm={12} className="trade_section-img">
                                <div className="img">
                                    <img
                                        src={trade}
                                        alt="Growth trade illustration"
                                        className="trade"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} className='trade_section-texts'>
                                <div className="texts">
                                    <p className="disc">
                                        Commodity trading is an investment strategy where traders buy and sell commodities, such as precious metals, energy, and agricultural products, to profit. With the increasing popularity of commodities as an investment, choosing a reliable and experienced broker is crucial to success in the commodity market. You can rely on Growth Securities to accomplish your goals.
                                    </p>
                                    <p className="disc">
                                        Commodity trading is where various commodities and their derivatives products are bought and sold. A commodity is any raw material or primary agricultural product that can be bought or sold, whether wheat, gold, or crude oil, among many others. When you engage in commodity trading, such commodities can diversify your asset portfolio.
                                    </p>
                                    <p className="disc">
                                        Trading commodities is an ancient profession with a longer history than the trading of stocks and bonds. The rise of many empires can be directly linked to their ability to create complex trading systems and facilitate the exchange of commodities.
                                    </p>
                                    <p className="disc">
                                        Commodities that are traded are typically sorted into four categories broad categories: metal, energy, livestock and meat, and agricultural.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default WhatIs