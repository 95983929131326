import React, { useState, useEffect } from "react";
import "./Style.scss";

const Animation = () => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const generateParticles = () => {
      const particleCount = 50;
      const particlesArray = [];

      for (let i = 0; i < particleCount; i++) {
        particlesArray.push({
          id: i,
          top: Math.random() * 100,
          left: Math.random() * 100,
          connectedParticles: new Set(),
        });
      }

      setParticles(particlesArray);
    };

    generateParticles();

    // Re-generate particles on window resize
    window.addEventListener("resize", generateParticles);

    return () => {
      window.removeEventListener("resize", generateParticles);
    };
  }, []);

  const handleMouseOver = (id) => {
    const updatedParticles = particles.map((particle) => {
      if (particle.id === id) {
        const connectedParticles = particles.filter(
          (p) =>
            p.id !== id &&
            Math.abs(p.top - particle.top) < 10 &&
            Math.abs(p.left - particle.left) < 10
        );
        particle.connectedParticles = new Set(
          connectedParticles.map((p) => p.id)
        );
      }
      return particle;
    });
    setParticles(updatedParticles);
  };

  const handleMouseOut = () => {
    const updatedParticles = particles.map((particle) => {
      particle.connectedParticles = new Set();
      return particle;
    });
    setParticles(updatedParticles);
  };

  return (
    <div className="particles-container">
      {particles.map((particle) => (
        <div
          key={particle.id}
          className="particle"
          style={{
            top: `${particle.top}%`,
            left: `${particle.left}%`,
            borderColor:
              particle.connectedParticles.size > 0 ? "#ffffff" : "transparent",
          }}
          onMouseOver={() => handleMouseOver(particle.id)}
          onMouseOut={handleMouseOut}
        />
      ))}
    </div>
  );
};

export default Animation;
