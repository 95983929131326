import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const DividendCalculator = () => {
  const [dividendAmount, setDividendAmount] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [dividendYield, setDividendYield] = useState(null);

  const calculateDividendYield = () => {
    const dividendYieldValue = (dividendAmount / investmentAmount) * 100;
    setDividendYield(dividendYieldValue.toFixed(2));
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Dividend Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Dividend</span>
            </li>
          </ul>
          <div className="dividend-calculator calcBody">
            <div className="calculator-container">
              <h2>Dividend</h2>
              <div className="input-group">
                <label>Dividend Amount (in INR):</label>
                <input
                  type="number"
                  value={dividendAmount}
                  onChange={(e) => handleInputChange(e, setDividendAmount)}
                />
              </div>
              <div className="input-group">
                <label>Investment Amount (in INR):</label>
                <input
                  type="number"
                  value={investmentAmount}
                  onChange={(e) => handleInputChange(e, setInvestmentAmount)}
                />
              </div>
              <button onClick={calculateDividendYield}>Calculate Dividend Yield</button>
              <div className="result">
                {dividendYield && <p>Dividend Yield: {dividendYield}%</p>}
              </div>
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  A dividend calculator is a tool used to estimate the dividend income earned from an investment in dividend-paying stocks. Dividends are payments made by companies to their shareholders as a distribution of profits. The calculator helps investors assess the potential income generated by their dividend investments.
                </p>
                <p className="disc">
                  The dividend yield is expressed as a percentage and represents the return on investment from dividends. It can be obtained from financial data sources or by analyzing the historical dividend payments of a company.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculator Dividend?
                </h2>
                <p className="disc">
                  The calculation of dividends depends on several factors, including the dividend yield and the number of shares held. The formula used in the dividend calculator is as follows:
                </p>
                <h6>
                  Dividend Yield = (Dividend Amount / Investment Amount) * 100
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>dividendAmount-</span> represents the amount of dividends received from the investment.
                  </li>
                  <li>
                    <span>investmentAmount-</span> represents the total investment amount.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DividendCalculator;
