import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import think from './Icons/whatis.svg'




function WhatIs() {
    return (
        <>
            <section className="whatis_section-container">
                <Container className="whatis_section">
                    <div className="paraTitle">
                        <h1 className="title">
                            What is <span>High Frequency Trading</span>?
                        </h1>
                    </div>
                    <Row className="whatis_rows">
                        <Col lg={6} md={12} sm={12} className="whatis_img">
                            <div className="img_side">
                                <img
                                    src={think}
                                    alt="Growth think illustration"
                                    className="think"
                                    width='100%'
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} className="whatis_txt">
                            <div className="texts">
                                <p className="disc">
                                    High-frequency trading (HFT) is an automated trading platform that large investment banks,
                                    hedge funds, and institutional investors employ. It uses powerful computers to transact a
                                    large number of orders at extremely high speeds.
                                </p>
                                <p className="disc">
                                    High-frequency trading (HFT) is considered algorithmic trading that uses advanced technology to execute trades at lightning speed. It has revolutionized the financial markets, leading to increased efficiency and liquidity, but it also raises concerns over its impact on the stability of the markets.
                                </p>
                                <h3 className="head">
                                    Working Principle
                                </h3>
                                <p className="disc">
                                    HFT involves using complex algorithms and high-speed computers to analyze and execute trades in a fraction of a second. It lets traders take advantage of small price movements and make huge profits.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WhatIs