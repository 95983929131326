import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import trade from "./Icons/trade.svg";

function Trade() {
  return (
    <>
      <section className="equity_section-container">
        <Container className="trade-container">
          <div className="paraTitle">
            <h1 className="title">
              What is <span>Equity Trading</span>?
            </h1>
          </div>
          <Row className="trade-rows">
            <Col lg={5} md={12} sm={6} className="img-side">
              <div className="imgs">
                <img
                  className="trade-img"
                  src={trade}
                  alt="Growth trade illustration"
                />
              </div>
            </Col>
            <Col lg={7} md={12} sm={6} className="text-side">
              <div className="texts">
                <p className="disc">
                  Equity trading is the buying and selling of company shares or
                  stocks, also known as equities, on the financial market. There
                  are a few ways in which you can invest in equities. Most
                  equity trading refers to the buying and selling of public
                  company shares through a stock exchange or as over-the-counter
                  products.
                </p>
                <p className="disc">
                  Every country comes equipped with its own organized market,
                  also known as a stock exchange.
                </p>
                <p className="disc">
                  On these exchanges, the shares of listed companies are
                  purchased or sold. These shares can vary across industries or
                  sectors, and each stock exchange comes with its own trading
                  hours. Trading hours apply mainly to the weekdays while
                  closing on the weekends, although this varies based on the
                  country’s timetable.
                </p>
                <h3 className="head">
                  Premier Resource for Equity Trading Services
                </h3>
                <p className="disc">
                  Growth provides a comprehensive suite of equity trading
                  services. The company has been operating long and has built a
                  reputation as the resource for equity trading services.
                </p>
                <p>
                  Charts are powered by{" "}
                  <a
                    href="https://www.tradingview.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    TradingView
                  </a>
                  .
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Trade;
