import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap';
import equity from './Icons/equity.jpg'

export class Header extends Component {
    render() {
        return (
            <>
                <section className="equity_header-container">
                    <Container className="equity_header">
                        <Row className="equity_header-rows">
                            <Col lg={7} md={12} sm={12} className="equity_header-texts">
                                <div className='equity_texts'>
                                    <h4 className="headerTitle">
                                        Unleash the power of the stock market and get control of your financial future with our top-notch equity trading services! With our expert advice and cutting-edge technology, you can trade smarter, not harder. Start investing in your future today!
                                    </h4>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        Equity Trading
                                        </p>
                                    </li>
                                </ul>
                                    <a className="trade" href='https://kyc.growthsec.com'>
                                        <span>Start Equity Trading</span>
                                    </a>
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="equity_header-img">
                                <div className="equity_img">
                                    <img
                                        src={equity}
                                        alt="Growth equity illustration"
                                        width="100%"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header;