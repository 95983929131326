import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'

export class OtherTerms extends Component {
    render() {
        return (
            <>
                <div className="rest-term-container">
                    <Container className='rest-terms'>
                        <Row className='rest-term-rows'>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Fees & Brokerage
                                </h2>
                                <p className="disc">
                                    The Client agrees to pay GSPL any brokerage, commission, fees, service tax and other taxes
                                    and transaction charges as agreed upon and due from time to time, as applied to such Client's
                                    account, transactions, and trading ledger by GSPL, for the services that the Client receives
                                    from GSPL.
                                </p>
                                <p className="disc">
                                    All fees, brokerage, and charges on GSPL's platform are subject to change from time to time,
                                    subject to complying with rules prescribed by the Exchanges and SEBI, at GSPL's discretion,
                                    with due notification to the Client. Each time there is any change with respect to fees,
                                    charges, and brokerage, GSPL would notify all Clients by an email and/or SMS to their registered
                                    email ID and contact number with all details, along with the date of such changes being effective.
                                </p>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Investment or any other advice
                                </h2>
                                <p className="disc">
                                    The Client agrees that none of the services available on the website shall amount to investment
                                    advice on the part of GSPL.
                                </p>
                                <p className="disc">
                                    The Client agrees that in the event of GSPL or any employee or official of GSPL, providing any
                                    information, recommendation or advice to the client, the client may act upon the same at the sole
                                    risk and cost of the client, and GSPL shall not be liable or responsible for the same
                                </p>
                                <p className="disc">
                                    GSPL, and its officers, directors, partners, employees, agents and affiliates will have no liability
                                    with respect to any investment decisions or transactions of the client.
                                </p>
                            </Col>
                        </Row>
                        <Row className='rest-term-rows'>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Pragrammes & Initiatives
                                </h2>
                                <p className="disc">
                                    Growth Securities may from time to time have programmes to promote engagement and education among clients.
                                    Growth Securities runs an initiative of a 60 day challenge where clients participating and ending profitable
                                    after 60 days, shall be awarded a certificate of recognition. Similarly, Growth Securities encourages
                                    clients to refer their friends and family to begin investing with Growth Securities. In all such cases,
                                    Growth Securities reserves the absolute right to change, withdraw, modify or suspend a part of or an entire
                                    programme / initiative, without any prior notice to any such user or client.
                                </p>
                                <p className="disc">
                                    Growth Securities’s decision on any condition of how any such initiative or programme shall operate, would
                                    be final.
                                </p>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Miscellaneous
                                </h2>
                                <p className="disc">
                                    GSPL does not warrant that the service will be uninterrupted or error-free. The service is provided in an
                                    "as is" and "as available" basis without warranties of any kind, either express or implied, including,
                                    without limitation, those of merchantability and fitness for a particular purpose. The client agrees
                                    that GSPL shall not be held responsible for delays in transmission of orders due to breakdown of the
                                    system or failure of communication facilities either due to the fault of the systems of GSPL or of
                                    the Exchanges or otherwise or for any other delay beyond the reasonable control of GSPL due to a
                                    breakdown or failure of communication facilities or for any other delay beyond the reasonable control
                                    of GSPL.
                                </p>
                                <p className="disc">
                                    All modification to this Agreement shall be made solely at the discretion of GSPL and shall be intimated
                                    to the client by a suitable modification to the terms and conditions or other applicable section on the
                                    website or in any other manner.
                                </p>
                            </Col>
                        </Row>
                        <Row className='rest-term-rows'>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Indemnity
                                </h2>
                                <p className="disc">
                                    In the event of death or insolvency of the client, winding up or liquidation, or their otherwise becoming
                                    incapable of receiving and paying for or delivering or transferring securities which the client has
                                    ordered to be bought or sold, GSPL may close out the transaction of the client and the client or his
                                    legal representative shall be liable for any losses, costs and be entitled to any surplus which may
                                    result therefrom.
                                </p>
                                <p className="disc">
                                    The client is aware that authentication technologies and strict securities measures are required for
                                    internet trading through order routed system and undertake to ensure that the password of the client
                                    and /or their authorized representatives are not revealed to any third party. The client also agrees
                                    to indemnify GSPL from any loss, injury, claim or any action instituted against GSPL arising from the
                                    misuse of the password by any party.
                                </p>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Force majeure
                                </h2>
                                <p className="disc">
                                    GSPL shall not be responsible for delay or default in the performance of their obligations due to
                                    contingencies beyond their control, such as (including but not limited to) losses caused directly or
                                    indirectly by exchange or market rulings, suspension of trading, fire, flood, civil commotion,
                                    earthquake, war, strikes, failure of the systems, failure of the internet links or government /
                                    regulatory action.
                                </p>
                            </Col>
                        </Row>
                        <Row className='rest-term-rows'>
                            <Col lg={12} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Severance
                                </h2>
                                <p className="disc">
                                    In the event of any one or more of the provisions contained in this Agreement becoming invalid, illegal or
                                    unenforceable in any respect under any law for the time being in force, the validity, legality and enforceability
                                    of the remaining provisions contained herein shall not in any way be prejudiced or affected thereto.
                                </p>
                            </Col>
                        </Row>
                        <Row className='rest-term-rows'>
                            <Col lg={12} md={12} sm={12} className='rest-term-texts term-text'>
                                <h2 className="subtitle">
                                    Margin
                                </h2>
                                <p className="disc">
                                    The Client agrees and undertakes to immediately deposit with GSPL such cash, 
                                    securities or other acceptable security, which GSPL may require as margin. 
                                    The Client agrees that GSPL shall be entitled to require the Client to deposit 
                                    with GSPL a higher margin than that prescribed by the Exchange. GSPL shall also 
                                    be entitled to require the Client to keep permanently with GSPL a margin of a 
                                    value specified by GSPL so long as the Client desires to avail of the Online 
                                    Trading Service of GSPL.
                                </p>
                                <p className="disc">
                                    The Margin will not be interest bearing. GSPL shall have, at its sole discretion, 
                                    the irrevocable right to set off a part or whole of the Margin i.e., by the way 
                                    of appropriating of the relevant amount of cash or by sale or transfer of all or 
                                    some of the Securities which form part of the Margin, against any dues of the 
                                    Client or of a member of the group of the Client (for the purposes of these Terms, 
                                    "Group" shall mean all the individuals, group companies, firms, entities and the 
                                    persons as specified in the schedule to the Member Client Agreement) in the event 
                                    of the failure of the Client or a member of the Group of the Client to meet any of 
                                    their respective obligations under these Terms.
                                </p>
                                <p className="disc">
                                    The client agrees and authorises GSPL to determine the market value of securities 
                                    placed as margin after applying a haircut that GSPL may deem appropriate. The 
                                    client undertakes to monitor the market value of such securities on a continuous 
                                    basis. The client further undertakes to replenish any shortfall in the value of 
                                    the margin consequents to a fall in the market value of such securities placed as 
                                    margin immediately whether or not GSPL intimates such shortfall.
                                </p>
                                <p className="disc">
                                    GSPL may at its sole discretion prescribe the payment of Margin in the form of cash 
                                    instead of or in addition to margin in form of securities. The Client accepts to 
                                    comply with the requirement of GSPL with regards to payment of Margin in the form 
                                    of cash immediately. Without prejudice to the stock broker's other rights (including 
                                    the right to refer a matter to arbitration), the stock broker shall be entitled to 
                                    liquidate / close out all or any of the client's positions for non- payment of 
                                    margins or other amount, outstanding debts, etc., and adjust the proceeds of such 
                                    liquidation / close out, if any, against the client's liabilities / obligations. 
                                    Any and all losses and financial charges on account of such liquidation / closing-out 
                                    shall be charged to and borne by the client.
                                </p>
                                <p className="disc">
                                    The client agrees to abide by the exposure limits, if any, set by the stock broker or 
                                    by the Exchange or Clearing Corporation or SEBI from time to time.
                                </p>
                                <p className="disc">
                                    The client is also aware that GSPL is required to deposit sufficient margin with the 
                                    Exchange to enable all its eligible clients to trade subject to such limits as may be 
                                    imposed by GSPL on the basis of GSPLs' Risk perception and other factors considered 
                                    relevant by GSPL. However, there may be circumstances when the deposits made by GSPL 
                                    with the Exchange may not be sufficient in times of extreme volatility and trading 
                                    terminals of GSPL may get temporarily suspended because of the cumulative effect of 
                                    non-meeting of obligation by various clients as per this agreement. In these circumstances, 
                                    no client shall have the right to claim any damages from GSPL for any loss that they might 
                                    incur on account of such suspension of trading.
                                </p>
                                <p className="disc">
                                    The Client agrees that any securities/cash placed by him/her/it as margin may in turn be 
                                    placed as margin by GSPL with the Exchanges or banks or such other institution as GSPL may 
                                    deem fit. The Client authorises GSPL to do all such acts, deeds and things as may be necessary 
                                    and expedient for placing such securities/cash with the Exchanges/Banks/Institutions as margin
                                </p>
                                <p className="disc">
                                    Any reference in these terms to sales or transfer of Securities by GSPL shall be deemed to 
                                    include sale of the Securities which form part of the Margin maintained by the Client with 
                                    GSPL. In exercise of GSPL's right to sell securities under the Agreement, the Client agrees 
                                    that the choice of specific securities to be sold shall be solely at the discretion of GSPL
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default OtherTerms