import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import algo from './Icons/trading.svg'


export class Define extends Component {
  render() {
    return (
      <>
        <section className="algotrade-section-container">
            <Container className='algotrade'>
                <div className="paraTitle">
                <h1 className="title">
                    What is <span>Algorithmic Tradings</span>?
                </h1>
                </div>
                <Row className='algotrade-rows'>
                    <Col lg={5} md={12} sm={12} className='algotrade-img'>
                        <img 
                            className='algotrade-img'
                            src={algo}
                            alt="Growth Algorithm Trading Img"
                            width='100%'
                        />
                    </Col>
                    <Col lg={7} md={12} sm={12} className='algotrade-text'>
                        <div className="define-texts">
                            <p className="disc">
                                Algorithmic trading (also called automated trading, black-box trading, or algo-trading) uses a computer program that follows a defined set of instructions (an algorithm) to place a trade. The trade, in theory, can generate profits at a speed and frequency that is impossible for a human trader.
                            </p>
                            <p className="disc">
                                The defined sets of instructions are based on timing, price, quantity, or any mathematical model. Apart from profit opportunities for the trader, algo-trading renders markets more liquid and trading more systematic by ruling out the impact of human emotions on trading activities.
                            </p>
                            <p className="disc">
                                With algorithmic trading, there are no human emotions involved. The only thing that guides the overall trading process is the coded instructions, determining if the buyers’ and sellers’ requirements match. If so, the system opens and closes the deal accordingly.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    )
  }
}

export default Define