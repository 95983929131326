import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import benefit from './Icons/benefits.png'


export class Benefits extends Component {
  render() {
    return (
      <>
        <section className="benefit_section-container">
            <Container className="benefit_container">
                <div className="paraTitle">
                <h1 className="title">
                    Benefits of <span>Equities</span>
                </h1>
                </div>
                <div className="benefit-circle">
                    <Row className="benefits-rows">
                        <Col lg={4} md={12} sm={12} className="left-col benefits-col">
                            <div className="left-texts small_texts">
                                <ul className="lists">
                                    <li className="list">
                                        Potential returns that tackle inflate
                                    </li>
                                    <li className="list">
                                        Profit Potential
                                    </li>
                                    <li className="list">
                                        Dividend Income
                                    </li>
                                    <li className="list">
                                        Exercise Control
                                    </li>
                                    <li className="list">
                                        Stock Split
                                    </li>
                                    <li className="list">
                                        Liquidity
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className="benefits-col benefit-circle">
                            <div className="img-col">
                                <img 
                                    className='benefits-img'
                                    src={benefit}
                                    alt="Growth benifit illustration" 
                                    width='100%'
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className="right-col benefits-col">
                            <div className="right-text small_texts">
                                <ul className="lists">
                                    <li className="list">
                                        Right Over Assets and Income
                                    </li>
                                    <li className="list">
                                        Diversification of Portfolio
                                    </li>
                                    <li className="list">
                                        Bonus Shares
                                    </li>
                                    <li className="list">
                                        Right Shares
                                    </li>
                                    <li className="list">
                                        Share in Growth
                                    </li>
                                    <li className="list">
                                        Tax Advantages
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
      </>
    )
  }
}

export default Benefits;