import React, { Component } from "react";
import { 
  Col, 
  Container, 
  Row 
} from "react-bootstrap";
import step from "./Icons/stepping.svg";


export class Quote extends Component {
  render() {
    return (
      <>
        <section className="quote_section-container">
          <Container className="quote_section">
            <h4 className="quote">
              "I don't look to jump over seven-foot bars; I look around for
              one-foot bars that I can step over."
            </h4>
            <Row className="quote_section-rows">
            <Col lg={4} md={12} sm={12} className="quote_section-video">
               <img 
                className="quote-img"
                src={step}
                alt="Growth quote illustration" 
                width='100%'
              /> 
                </Col>
              <Col lg={8} md={12} sm={12} className="quote_section-texts">
                <p className="disc">
                  Investors often make things too hard for themselves. The value
                  stocks that Buffet prefers frequently outperform the market,
                  making success easier. Supposedly sophisticated strategies,
                  such as short selling, lose money in the long-run, so
                  profiting is much more difficult.
                </p>
                <p className="disc">
                  The world of investing can be cold and hard. Refer back to 
                  these quotes when you're feeling shaky or confused about 
                  investing.
                </p>
              </Col>
              
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Quote;
