import React from 'react';
import App from './App';
import 'font-awesome/css/font-awesome.min.css';



const Root = () => {
  return (
    <React.StrictMode>
      <App /> 
    </React.StrictMode>
)}


export default Root;
