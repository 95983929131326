import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import orders from '../Icons/orders.svg'

export class Order extends Component {
    render() {
        return (
            <>
                <div className='order-container'>
                    <Container className='order'>
                        <h2 className="subtitle">
                            Orders
                        </h2>
                        <Row className='order-rows'>
                            <Col lg={5} md={12} sm={12} className='order-img'>
                                <img
                                    className=''
                                    src={orders}
                                    alt='Growth order illustration'
                                    style={{paddingTop: "50px"}}
                                />
                            </Col>
                            <Col lg={7} md={12} sm={12} className='order-text term-text'>
                                <p className="disc">
                                    All orders for purchase, sale or other dealings in securities and other 
                                    instructions routed through the GSPL’s Online Trading System via the 
                                    Client’s Username shall be deemed to have been given by the Client.
                                </p>
                                
                                <p className="disc">
                                    GSPL may from time to time impose and vary limits on the orders which 
                                    the Client can place through GSPL’s online trading System (including 
                                    exposure limits, turnover limits, limits as to the number, value and/or 
                                    kind of securities in respect of which orders can be placed, the companies 
                                    in respect of whose securities orders can be placed, etc.). The Client is 
                                    aware and agrees that GSPL may need to vary or reduce the limits or impose 
                                    new limits urgently on the basis of the GSPL’s risk perception and other 
                                    factors considered relevant by GSPL, and GSPL may be unable to inform the 
                                    Client of such variation, reduction or imposition in advance. The Client 
                                    agrees that GSPL shall not be responsible for such variation, reduction or 
                                    imposition or the Client’s inability to route any order through GSPL’s Online 
                                    Trading System on account of any such variation, reduction or imposition of 
                                    limits. The Client understands and agrees that GSPL may at any time, at its 
                                    sole discretion and without prior notice, prohibit or restrict the Client’s 
                                    ability to place orders or trade in securities through GSPL.
                                </p>
                            </Col>
                        </Row>
                        <Row className='order-rows'>
                            <Col lg={12} md={12} sm={12} className='order-text term-text'>
                                <p className="disc">
                                    The client agrees to provide information relating to customer user 
                                    identification number, and such other information as may be required 
                                    while placing orders on the telephone to determine the identity of the 
                                    client.
                                </p>
                                <p className="disc">
                                    The orders and instructions and all contracts and transactions entered 
                                    into pursuant thereto and the settlement thereof will be in accordance 
                                    with the Exchange Provisions.
                                </p>
                                <p className="disc">
                                    Though orders will generally be routed to the Exchange’s computer systems 
                                    within a few seconds from the time the order is placed by the Client on 
                                    GSPL’s Online Trading System, GSPL shall not be liable for any delay in 
                                    the execution of any order or for any resultant loss on account of the 
                                    delay.
                                </p>
                                <p className="disc">
                                    The client agrees GSPL may impose scripwise surveillance or such other 
                                    conditions as to scripwise limits, etc. The client also understands that 
                                    GSPL may impose various surveillances which may differ from client to client 
                                    on the basis of the GSPL’s risk perception and other factors considered 
                                    relevant by GSPL.
                                </p>
                                <p className="disc">
                                    In case of a market order, the Client agrees that he will receive the price at 
                                    which his order is executed by the exchange’s computer system; and such price 
                                    may be different from the price at which the security is trading when his order 
                                    is entered into GSPL’s Online Trading System.
                                </p>       
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Order