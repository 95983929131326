import React, { useState } from 'react';
import SummaryButton from './SummaryButton';
import data from './Data.json';



function WelcomeSummary() {
    const [selectedTitle, setSelectedTitle] = useState(null);

    const handleTitleClick = (title) => {
        setSelectedTitle(title);
    };

    const renderTitleButtons = () => {
        const exampleTitles = Object.keys(data).slice(3, 5); // Get the 3rd and 4th titles as examples
        return exampleTitles.map((title, index) => (
            <SummaryButton
                key={index}
                title={title}
                onClick={() => handleTitleClick(title)}
            />
        ));
    };

    const renderSummary = () => {
        if (selectedTitle) {
            const { answer, key_points, benefits, additional_information } = data[selectedTitle];

            return (
                <>
                    <p className="summary-title">{selectedTitle}</p>
                    <p className="summary-answer">{answer}</p>
                    {key_points && key_points.length > 0 && (
                        <>
                            <ul className='sumLists'>
                                <li className='list'>
                                    <strong>Key Points:</strong>
                                    <ul className='lists'>
                                        {key_points.map((point, index) => (
                                            <li key={`point-${index}`}>{point}</li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </>
                    )}
                    {benefits && benefits.length > 0 && (
                        <>
                            <ul className="sumLists">
                                <li className="list">
                                    <strong>Benefits:</strong>
                                    <ul>
                                        {benefits.map((benefit, index) => (
                                            <li key={`benefit-${index}`}>{benefit}</li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </>
                    )}
                    {additional_information && additional_information.length > 0 && (
                        <>
                            <strong>Additional Information:</strong>
                            <p>{additional_information}</p>
                        </>
                    )}
                </>
            );
        }

        return null;
    };

    return (
        <section className="welcomeSummary">
            {selectedTitle ? (
                <div className="summary">
                    {renderSummary()}
                </div>
            ) : (
                <div className="default-message">
                    <h4 className="title">Growth's Stock Market Summary Page!</h4>
                    <p className="disc">
                        The motive of this page is to provide you with comprehensive and insightful summaries on various topics related to the Stock Market. For example {renderTitleButtons()}
                    </p>
                    <p className="disc">
                        This page serves as a valuable resource for gaining a deeper understanding of the Stock Market by exploring key points, benefits, and additional information about specific topics.
                    </p>
                    <p className="disc">
                        By entering a title in the input field, you can quickly obtain a well-crafted summary of that topic, enabling you to stay informed and make well-informed investment decisions.
                    </p>
                    <p className="disc">
                        Whether you are a seasoned investor seeking to stay updated or someone new to the world of stocks and securities, this page offers a powerful solution to access essential information and enhance your knowledge of the Stock Market.
                    </p>
                    <p className="disc">
                        Explore the summaries, delve into the key points, and uncover the benefits related to each topic. Gain valuable insights and broaden your understanding to make informed investment choices with confidence.
                    </p>
                    <p className="disc">
                        We strive to provide accurate and up-to-date summaries, drawing from reliable sources and expert analysis, to ensure the information you receive is trustworthy and relevant.
                    </p>
                    <p className="disc">
                        Start exploring now and empower yourself with the knowledge you need to navigate the dynamic world of the Stock Market effectively.
                    </p>
                    <p className="disclaimer disc">
                        In the unlikely event that you come across any incorrect or misleading information on this page, we apologize for any inconvenience caused. We are committed to maintaining the highest standards of accuracy, and your feedback is invaluable in helping us continuously improve the quality of our summaries.
                    </p>
                </div>
            )}
        </section>
    );
}

export default WelcomeSummary;
