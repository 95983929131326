import React from "react";
import "./Style.scss";
import Header from "./Header";
import AboutETF from "./AboutETF";
import { Helmet } from "react-helmet";
import Why from "./Why";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function ETF() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Exchange Trade Funds || Meaning, Types, Benefits
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <AboutETF />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
      </div>
    </>
  );
}

export default ETF;
