import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const MarginCalculator = () => {
  const [cost, setCost] = useState('');
  const [revenue, setRevenue] = useState('');
  const [marginPercentage, setMarginPercentage] = useState('');
  const [marginAmount, setMarginAmount] = useState('');

  const calculateMarginAmount = () => {
    const costValue = parseFloat(cost);
    const revenueValue = parseFloat(revenue);
    const marginPercentageValue = parseFloat(marginPercentage);

    const margin = (revenueValue - costValue) * (marginPercentageValue / 100);
    setMarginAmount(margin.toFixed(2));
  };

  return (
    <>
      <Helmet>
        <title>
          Growth securities || Growth Calculators || Margin Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Margin</span>
            </li>
          </ul>
          <div className="margin-calculator calcBody">
            <div className="calculator-container">
              <h2>Margin</h2>
              <div className="input-group">
                <label>Cost:</label>
                <input
                  type="number"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Revenue:</label>
                <input
                  type="number"
                  value={revenue}
                  onChange={(e) => setRevenue(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Margin Percentage (%):</label>
                <input
                  type="number"
                  value={marginPercentage}
                  onChange={(e) => setMarginPercentage(e.target.value)}
                />
              </div>
              <button onClick={calculateMarginAmount}>Calculate</button>
              {marginAmount && (
                <div className="result">
                  <p>Margin Amount: ₹ {marginAmount}</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  The Margin Calculator is a useful tool for businesses and individuals involved in buying and selling products or services. It helps calculate the margin amount, which represents the profit earned on each unit of sale.                  
                </p>
                <p className="disc">
                  By using the Margin Calculator, individuals and businesses can gain insights into their profit margins, make informed pricing decisions, and assess the financial viability of their products or services.
                  To calculate the margin amount, subtract the cost from the revenue, then multiply by the margin percentage divided by 100.
                </p>
              </div>
              <div className="formulaSec">
                <h2>How to calculate Margin Amount?</h2>
                <p className="disc">
                  The formula for calculating the margin amount is as follows:
                </p>
                <h6>
                  Margin Amount = (Revenue - Cost) * (Margin Percentage / 100)
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>Revenue</span> - The total revenue generated.
                  </li>
                  <li>
                    <span>Cost</span> - The total cost incurred.
                  </li>
                  <li>
                    <span>Margin Percentage</span> - The desired margin percentage.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MarginCalculator;
