import React, { useEffect, useState } from "react";
import styles from "./Style.scss";

const WeatherCard = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const success = (pos) => {
      const lat = pos.coords.latitude;
      const long = pos.coords.longitude;
      weather(lat, long);
    };

    const error = () => {
      console.log("Geolocation is not available or not allowed.");
      // Handle the case where geolocation is not available or not allowed
    };

    // Check if geolocation is available and permission is granted
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation is not supported.");
      // Handle the case where geolocation is not supported
    }
  }, []);

  const weather = (lat, long) => {
    const URL = `https://fcc-weather-api.glitch.me/api/current?lat=${lat}&lon=${long}`;
    fetch(URL)
      .then((response) => response.json())
      .then((data) => display(data))
      .catch((error) => console.error("Error fetching weather data:", error));
  };

  const display = (data) => {
    setWeatherData(data);
  };

  if (!weatherData) {
    return null;
  }

  const city = weatherData.name.toUpperCase();
  const temp =
    Math.round(weatherData.main.temp_max) +
    "°C | " +
    Math.round(weatherData.main.temp_max * 1.8 + 32) +
    "°F";
  const date = new Date();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fontColor =
    Math.round(weatherData.main.temp_max) > 25 ? "#d36326" : "#44c3de";
  const bgColor =
    Math.round(weatherData.main.temp_max) > 25 ? "#f3f5d2" : "#eff3f9";

  const weatherIcon =
    weatherData.weather[0].main.toLowerCase() === "sunny" ? (
      <i
        className={`fa fa-sun ${styles.weatherIcon}`}
        style={{ color: "#d36326" }}
      />
    ) : (
      <i
        className={`fa fa-cloud ${styles.weatherIcon}`}
        style={{ color: "#44c3de" }}
      />
    );

  const minutes =
    date.getMinutes() < 11 ? "0" + date.getMinutes() : date.getMinutes();
  const formattedDate =
    weekday[date.getDay()].toUpperCase() +
    " | " +
    months[date.getMonth()].toUpperCase().substring(0, 3) +
    " " +
    date.getDate() +
    " | " +
    date.getHours() +
    ":" +
    minutes;

  return (
    <div
      className={`${styles.box} ${styles.fadeIn}`}
      style={{
        background: bgColor,
        padding: "5px 20px",
        boxShadow: "0 2px 10px #e5e5e5",
        borderRadius: "25px",
      }}
    >
      <div className={`${styles.wave} ${styles.one}`}></div>
      <div className={`${styles.wave} ${styles.two}`}></div>
      <div className={`${styles.wave} ${styles.three}`}></div>
      <div className={styles.weatherCon}>{weatherIcon}</div>
      <div className={styles.info}>
        <h4 className={styles.location} style={{ color: fontColor }}>
          {city}
        </h4>
        <p className={styles.date}>{formattedDate}</p>
        <h1 className={styles.temp} style={{ color: fontColor }}>
          {temp}
        </h1>
      </div>
    </div>
  );
};

export default WeatherCard;
