import React, { Component } from 'react'
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'
import { Helmet } from 'react-helmet'
import './Style.scss';
import Header from './Header';
import OfferBox from './OfferBox';
import FAQList from './FAQs';
import ReferCard from './ReferCard';


export class Referr extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Growth Securities || Referr & Earn
          </title>
        </Helmet>
        <div className="wrapper">
          <AnimatedSection>
            <Header />
          </AnimatedSection>

          <AnimatedSection>
            <OfferBox />
          </AnimatedSection>

          <AnimatedSection>
            <ReferCard />
          </AnimatedSection>

          <AnimatedSection>
            <FAQList />
          </AnimatedSection>

        </div>
      </>
    )
  }
}

export default Referr