import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { parse } from 'papaparse';
import { Helmet } from 'react-helmet';
import { Container, Table } from 'react-bootstrap';
import { saveAs } from 'file-saver'; // Import file-saver library
import './Style.scss';


const IssueFiled = () => {
    const [issues, setIssues] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                'https://docs.google.com/spreadsheets/d/e/2PACX-1vTu2HHcI5Mz_EBzbSQ0MUVm8LcYW11sensyUbsVndn6YIIWzaZeLl-wa7gLeqLIbQE54Gb8xAJjHlRZ/pub?output=csv'
            );

            const csvData = response.data;

            // Parse the CSV data using papaparse library
            const parsedData = parse(csvData, { header: true });
            setIssues(parsedData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Define the handleDownloadCSV function here to access the issues state
        const handleDownloadCSV = () => {
            const csvContent = issues.reduce((acc, issue) => {
                const row = Object.values(issue).join(',');
                return acc + row + '\n';
            }, 'client_Id, email_Id, mobile, subject\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, 'issue_report.csv');
        };

        // Attach the event listener for the download button here
        const downloadBtn = document.querySelector('.downloadBtn');
        downloadBtn.addEventListener('click', handleDownloadCSV);

        // Cleanup the event listener when the component unmounts
        return () => {
            downloadBtn.removeEventListener('click', handleDownloadCSV);
        };
    }, [issues]); // Add the issues state as a dependency

    const handleRefresh = () => {
        fetchData();
    };

    return (
        <>
            <Helmet>
                <title>Growth Securities || Issued Files</title>
            </Helmet>
            <div className="wrapper issuedData">
                <Container className="issues">
                    <div className="issueContainer">
                        <h2 className="title">Issues</h2>
                        <div className="tableHeader">
                            <button className="downloadBtn" title="Download table data as CSV">
                                <i className="fa fa-download"></i>
                            </button>
                            <button className="refreshTable" title="Refresh table data" onClick={handleRefresh}>
                                <i className="fa fa-refresh"></i>
                            </button>
                        </div>
                        <div className="showingTable">
                            {error ? (
                                <p className="errTitle">Error: {error}</p>
                            ) : (
                                <Table responsive striped bordered hover className="issueTable">
                                    <thead>
                                        <tr>
                                            <th>Client ID</th>
                                            <th>Email ID</th>
                                            <th>Mobile No.</th>
                                            <th>Subject</th>
                                            <th>Time Stamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {issues.map((issue, index) => (
                                            <tr key={index} className="data">
                                                <td>{issue['client_Id']}</td>
                                                <td>{issue['email_Id']}</td>
                                                <td>{issue.number}</td>
                                                <td>{issue.subject}</td>
                                                <td>{issue.time_stamp}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default IssueFiled;
