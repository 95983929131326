import React, { useState, useEffect } from "react";
import { Modal, Card } from "react-bootstrap";
import webpageContent from "./Data.json";
// import SearchCards from "./SearchCards";

// SearchContainer functional component
const SearchContainer = () => {
  // State variables
  const [fullscreen, setFullscreen] = useState(true); //Full screen
  const [showModal, setShowModal] = useState(false); // Modal show
  const [filter, setFilter] = useState(""); // State for the filter string
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  

  // Function to show the modal
  const handleShow = () => {
    setFullscreen(true);
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setFilter(""); // Clear filter when closing the modal
  };

  // Function to handle input change in the filter box
  const handleInputChange = (event) => {
    setFilter(event.target.value);
  };

  // Handle Suggestions
  const handleSuggestionClick = (suggestion) => {
    setFilter(suggestion);
  };

  // Effect to filter results based on the filter string
  useEffect(() => {
    const filterResults = () => {
      const filteredResult = webpageContent.filter((item) =>
        item.title.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredData(filteredResult);
    };

    // Filter data based on the filter string if the filter length is greater than or equal to 3
    if (filter.length >= 3) {
      filterResults();
    } else {
      setFilteredData([]); // Clear filtered data when filter length is less than 3
    }
  }, [filter]);

  // JSX structure
  return (
    <>
      {/* Search bar button */}
      <div className="search-bar">
        <button className="searchBtnContainer" onClick={handleShow}>
          <i
            className="fa fa-search search-iconBig"
            title="Click & enter Tab to search anything"
            disabled
          ></i>
        </button>
      </div>

      {/* Modal structure */}
      <Modal show={showModal} fullscreen={fullscreen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            <h4>Uncover, Explore, and Prosper</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="searchModalBody" style={{ overflow: "hidden" }}>
          {/* Filter Box */}
          <div className="searchHeader">
            <div className="suggestions">
              {" "}
              Keywords:{" "}
              <button
                onClick={() => handleSuggestionClick("tra")}
                style={{
                  fontWeight: "bolder",
                  border: "none",
                  background: "transparent",
                }}
              >
                #trading
              </button>{" "}
              <button
                onClick={() => handleSuggestionClick("sto")}
                style={{
                  fontWeight: "bolder",
                  border: "none",
                  background: "transparent",
                }}
              >
                #stock
              </button>{" "}
              <button
                onClick={() => handleSuggestionClick("exc")}
                style={{
                  fontWeight: "bolder",
                  border: "none",
                  background: "transparent",
                }}
              >
                #exchange
              </button>{" "}
              <button
                onClick={() => handleSuggestionClick("acc")}
                style={{
                  fontWeight: "bolder",
                  border: "none",
                  background: "transparent",
                }}
              >
                #account
              </button>
            </div>

            <br />
            <div className="searchInputBoxContainer">
              <div className="search-input-container">
                <svg
                  className="growth"
                  xmlns="http://www.w3.org/2000/svg"
                  width="307"
                  height="348.993"
                  viewBox="0 0 307 348.993"
                >
                  <g id="logo_full-shade" opacity="0.319">
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="20.653"
                      cy="20.653"
                      r="20.653"
                      transform="translate(265.695 243.591)"
                      fill="#269ed6"
                    />
                    <ellipse
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="20.653"
                      cy="21.083"
                      rx="20.653"
                      ry="21.083"
                      transform="translate(132.492 220.344)"
                      fill="#269ed6"
                    />
                    <ellipse
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="20.294"
                      cy="21.083"
                      rx="20.294"
                      ry="21.083"
                      transform="translate(0 243.005)"
                      fill="#269ed6"
                    />
                    <ellipse
                      id="Ellipse_4"
                      data-name="Ellipse 4"
                      cx="20.294"
                      cy="21.083"
                      rx="20.294"
                      ry="21.083"
                      transform="translate(62.173 140.363)"
                      fill="#269ed6"
                    />
                    <ellipse
                      id="Ellipse_5"
                      data-name="Ellipse 5"
                      cx="20.294"
                      cy="21.083"
                      rx="20.294"
                      ry="21.083"
                      transform="translate(133.693)"
                      fill="#269ed6"
                    />
                    <ellipse
                      id="Ellipse_6"
                      data-name="Ellipse 6"
                      cx="20.294"
                      cy="21.083"
                      rx="20.294"
                      ry="21.083"
                      transform="translate(189.073 119.28)"
                      fill="#269ed6"
                    />
                    <path
                      id="Path_17"
                      data-name="Path 17"
                      d="M151,506.382,287.684,422.91V257.4l-136.44-83.183L14.324,257.4V423.2Zm-3.2-10.136L35.551,427.978l-7.075-6.346,119.326-18.7Zm-120.856-80.7,3.633-9.944,49.146-80.794,10.613,10.9,49.051,56.22,8.414,3.73Zm112.146-34.375L85.27,319.935,194.652,301l2.294,7.172L151,391.453Zm15.595,115.071V402.928l118.943,21.418ZM276.5,418.226l-11.092-2.677L156.6,396.234l6.513-14.024,43.514-77.382,9.206,11.714L276.5,415.357Zm4.015-9.944-2.486-2.487L220.579,313.05,212.246,299l68.268-34.709ZM276.5,258.263,208.8,293.257l-2.179-3.44L158.892,186.553Zm-76.013,32.7-8.033,4.59L87.374,313.05,151,186.553Zm-63.967-100.3,5.451-1.243L82.593,308.174H72.649L23.12,259.889Zm-115.5,76.2,53.257,51.967v2.533l-6.789,12.526-46.468,74.96Z"
                      transform="translate(2.742 -157.389)"
                      fill="#269ed6"
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  placeholder="trading"
                  value={filter} // Filter function
                  onChange={handleInputChange} // Input values
                />
              </div>
            </div>
          </div>

          {/* Display Filtered Cards */}
          <div className="searchFilterResult">
            <div className="filtered-cards">
              {filteredData.map((item, index) => (
                <a
                  className="cardHolderLink"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Card style={{ width: "100%", margin: "15px 0" }}>
                    <Card.Body>
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Text>{item.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              ))}
            </div>
          </div>

          {/* Footer Section */}
          <div className="footerSection">
            {/* I want to add here some scrolling cards */}
            {/* <SearchCards /> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// Export the SearchContainer component
export default SearchContainer;
