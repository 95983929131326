import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import TermsConditions from "./TermsConditions";
import GeneralTerm from "./GeneralTerm";
import Pay from "./Pay";
import Maintanance from "./Maintanance";
import Security from "./Security";
import Order from "./Order";
import Trading from "./Trading";
import Account from "./Account";
import Warranties from "./Warranties";
import OtherTerms from "./OtherTerms";
import ZeroBrokerage from "./ZeroBrokerage";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";



export class Terms extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Terms & Conditions</title>
        </Helmet>
        <div className="wrapper terms-container" 
          style={{ 
            overflow: "hidden !important",
            maxWidth: "100% !important",
            }}>

          <AnimatedSection>
            <TermsConditions />
          </AnimatedSection>

          <AnimatedSection>
            <GeneralTerm />
          </AnimatedSection>

          <AnimatedSection>
            <Pay />
          </AnimatedSection>

          <AnimatedSection>
            <Security />
          </AnimatedSection>

          <AnimatedSection>
            <Maintanance />
          </AnimatedSection>

          <AnimatedSection>
            <Order />
          </AnimatedSection>

          <AnimatedSection>
            <ZeroBrokerage/>
          </AnimatedSection>

          <AnimatedSection>
            <Trading />
          </AnimatedSection>

          <AnimatedSection>
            <Account />
          </AnimatedSection>

          <AnimatedSection>
            <Warranties />
          </AnimatedSection>

          <AnimatedSection>
            <OtherTerms />
          </AnimatedSection>
        </div>
      </>
    );
  }
}

export default Terms;
