import React, { Component } from "react";
import {
  Col,
  Container,
  Row
} from "react-bootstrap";
import user from './Icons/promoter.png';
import user2 from './Icons/promoter2.png';


export class Hero extends Component {
  render() {
    return (
      <>
        <section className="hero_section-container" id="promoter">
          <Container className="hero_section" id="promoter">
            <div className="paraTitle">
              <h1 className="title">
                Our <span>Promoters</span>
              </h1>
            </div>
            <Row className="hero_section-rows">
              <Col xl={6} xs={6} md={6} sm={12} className='heros_card hero_card-1'>
                <div className="proCard">
                  <div className="topCard">
                    <div className="proImg">
                      <img src={user} alt="Dhruv Gupta" className="cardImg" width='100%' />
                    </div>
                    <h3 className="proTitle">
                      Mr. Dhruv Gupta
                    </h3>
                    <div className="parent">
                      <div className="circle"></div>
                    </div>
                  </div>
                  <div className="discCard">
                      <img src={user} alt="Dhruv Gupta" className="InnerCardImg" width='100%' />
                    <h4 className="proTitle">
                      Mr. Dhruv Gupta
                    </h4>
                    <p className="disc">
                      He is the Director and the Promoter of the Company. He is a graduate and also a
                      CFA Level 3 Candidate. He has an experience of 7 years in managing risk & technology and
                      he is also involved in Seed Funding of tech focused early stage startup. He is young,
                      dynamic, hardworking and a dedicated team leader. His involvement and guidance has been
                      instrumental in the growth and development of the company. His varied experience and vision
                      helps our Company work united towards the same goals of the vision set by the management.                      </p>
                  </div>
                </div>
              </Col>
              <Col xl={6} xs={6} md={6} sm={12} className='heros_card hero_card-2'>
                <div className="proCard card-2">
                  <div className="topCard">
                    <div className="proImg">
                      <img src={user2} alt="Dhruv Gupta" className="cardImg" width='100%' />
                    </div>
                    <h3 className="proTitle">
                      Mr. Devansh Gupta
                    </h3>
                    <div className="parent">
                      <div className="circle"></div>
                    </div>
                  </div>
                  <div className="discCard">
                      <img src={user2} alt="Dhruv Gupta" className="InnerCardImg" width='100%' />
                    <h4 className="proTitle">
                      Mr. Devansh Gupta
                    </h4>
                    <p className="disc">
                      He is the Director and the Promoter of the Company. He has completed his graduation
                      from Delhi University. He has an experience of 6 years in managing the business
                      and trading operations and at the Organization. He has been associated with
                      our Company as an additional Director and later been re-designated as Managing
                      Director of the Company with effect from June 30, 2021. He has been a key player in
                      the overall growth of the Company with his efforts. His varied experience and vision
                      helps our Company work united towards the same goals of the vision set by the management.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Hero;