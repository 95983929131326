import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import choice from '../Equity/Icons/choice.svg'



export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container commodity_why">
                    <Container className='why_section'>
                        <div className="paraTitle">
                        <h1 className="title">
                            Why is <span>Growth</span> a top choice for <span>Commodity Trading</span>?
                        </h1>
                        </div>
                        <Row className='why_section-rows why-commodity'>
                            <Col lg={7} md={12} sm={12} className='why_section-texts'>
                                <div className="text">
                                    <h3 className="head">
                                        Comprehensive Trading Platform
                                    </h3>
                                    <p className="disc">
                                        Growth offers a comprehensive trading platform for commodity trading. The platform provides real-time market data, technical analysis tools, and advanced charting capabilities, allowing traders to make informed trading decisions. The platform is also user-friendly and customizable, so traders can quickly adapt to their individual needs and preferences.
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Experienced Team of Professionals
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth has a team of experienced professionals who specialize in commodity trading. These team is knowledgeable about the market and can provide valuable insights and advice to help traders maximize their returns. Additionally, the team is always available to answer questions or provide support, ensuring traders have the necessary resources to succeed.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Cost-effective Trading Costs
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth has a competitive pricing structure that minimizes the costs of trading. The company offers low trading fees, free educational resources and tools to help traders make the most of their investments. Additionally, the company has a low minimum deposit requirement, making it accessible to many traders.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Highly Regulated Environment
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth is a regulated broker, meaning that the company is held to high standards of transparency and accountability. The company is subject to regular audits and must comply with strict regulations to protect traders’ funds. We provide a secure and stable environment for commodity trading, giving traders peace of mind knowing that their investments are protected.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <p className="disc last_para"></p>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='why_section-img'>
                                <div className="img">
                                    <img
                                        src={choice}
                                        alt="Growth choice illustration"
                                        className="choice-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why