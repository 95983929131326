import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import think from '../HighFrequencyTrading/Icons/why.svg'



export class Why extends Component {
    render() {
        return (
            <>
                <section className="derivative-why">
                    <Container className="why_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Why Trade Through <span>Growth</span>?
                        </h1>
                        </div>
                        <Row className="why_section-rows">
                            <Col lg={6} md={12} sm={12} className="why-imgs">
                                <div className="imgs">
                                    <img
                                        src={think}
                                        alt="Growth think illustration"
                                        className="think"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="why-texts">
                                <div className="texts">
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Innovative Trading Platform
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        We offer a state-of-the-art trading platform designed to make it easy for you to trade derivatives confidently. Our platform is user-friendly, intuitive, and equipped with the latest trading tools and analysis techniques to help you make informed decisions.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Competitive Pricing
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        We believe in transparent pricing and fair dealing at Growth Securities. We offer competitive pricing on all of our derivative products and never engage in any hidden fees or charges.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Dedicated Customer Support
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Our team of dedicated customer support representatives is available 24/7 to assist you with any questions or concerns. Whether you need help placing a trade or have questions about your account, our team is here to support you.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Risk Management
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        We understand that risk management is a critical component of successful derivative trading. Our expert traders use various risk management techniques to minimize your exposure to market volatility and protect your investments.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <p className="disc last_para">
                                        Derivative trading can be a complex and challenging endeavor, but it can also be incredibly rewarding with the right partner. At Growth Securities, we have the expertise, experience, and innovative trading tools you need to succeed. With competitive pricing, dedicated customer support, and a focus on risk management, we are confident that Growth Securities is the right choice for your derivative trading needs.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why