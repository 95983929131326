import React from "react";
import { 
  Col, 
  Container, 
  Row 
} from "react-bootstrap";
import special from "./Icons/special.svg";



function Special() {
  return (
    <>
      <section className="special_section-container">
        <Container className="special-section">
          <div className="paraTitle">
            <h1 className="title">
              What make us <span>Special</span>
            </h1>
          </div>
          <Row className="special-rows">
            <Col lg={7} md={12} sm={12} className="special-texts">
              <div className="texts">
                <ul className="lists">
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Personalize and transparent service to clients
                    </span>
                  </li>
                  {/* <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      High success rate for research (stock recommendations)
                    </span>
                  </li> */}
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Most preferred HNI, ultra HNI and institutional stock broker.
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Strong fundamental and technical analysis
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Both AMC and brokerage at the lowest.
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      No flexibility in margin for volume generation.
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Believes in business ethics and protects investor interests.
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Technology driven and <a href="high-frequency-trading">high frequency trading</a>
                    </span>
                  </li>
                  <li className="list">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="text">
                      Personalize investment plan that matches the clients need & risk profile.
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={5} md={12} sm={12} className="special-img">
              <div className="special-img">
                <img className="img-fluid" src={special} alt="Growth special illustration" width="100%" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Special;
