import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from '../Icons/brand.png';
import MobileBot from './MobileBot';

function GrowthBot() {
    const [showChatbot, setShowChatbot] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);

    const hideChatbot = () => {
        setShowChatbot(false);
    };

    useEffect(() => {
        if (showTooltip) {
            // Automatically hide the tooltip after 3 seconds (3000 milliseconds)
            const tooltipTimer = setTimeout(() => {
                setShowTooltip(false);
            }, 3000);
    
            return () => clearTimeout(tooltipTimer);
        }
    }, [showTooltip]);
    

    return (
        <>
            <div className="wrapper chatbot-wrapper">
                <div className="gbot-container">
                    <div className="logoSearch">
                        <div className="gbot-box">
                            {showChatbot && (
                                <div className="gbox" id="chatbot">
                                    <div className="header">
                                        <button onClick={hideChatbot} className="close-btn">
                                            <i className="fa fa-close" aria-hidden="true" title="Close Chatbot"></i>
                                        </button>
                                        <img src={logo} alt="growth" className="brand" width="100%" />
                                    </div>
                                    <iframe
                                        src="https://chatbot.meon.co.in/growthsec"
                                        // src="http://localhost:2502/eallen1/chatbot"
                                        width='100%'
                                        height='100%'
                                        title='Growthbot'
                                    ></iframe>
                                </div>
                            )}
                        </div>
                        <OverlayTrigger 
                            placement="left"
                            overlay={<Tooltip id="chatbot-tooltip">Hi there, how can I help you?</Tooltip>}
                            show={showTooltip}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            <Button
                                className="open-btn btn for-big-screen"
                                aria_controls="example-fade-text"
                                onClick={() => {
                                    setShowChatbot(!showChatbot);
                                    setShowTooltip(false); // Hide the tooltip on user interaction
                                  }}
                                  onMouseEnter={() => {
                                    setShowTooltip(true); // Show tooltip when user hovers
                                  }}
                            >
                                <i className="fa fa-comments-o" aria-hidden="true"></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="parentBot" id="chatbot-icon">
                    <MobileBot />
                </div>
            </div>
        </>
    );
}

export default GrowthBot;
