import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import main from '../Icons/maintanance.svg'

export class Maintanance extends Component {
    render() {
        return (
            <>
                <div className='maintanance-container'>
                    <Container className='maintanance'>
                        <Row className='maintanance-rows'>
                            <Col lg={6} md={12} sm={12} className='maintanance-text term-text'>
                                <h2 className="subtitle">
                                    Maintenance of trading account
                                </h2>
                                <p className="disc">
                                    Money pay-in to GSPL - The Client agrees that all payments due to GSPL
                                    will be made within the specified time and in the event of any delay,
                                    GSPL may refuse, at their discretion, to carry out transactions or
                                    closeout the position and the costs/losses if any, thereof shall be
                                    borne solely and completely by the client. All payments made to GSPL
                                    shall be from the account of the client and shall not to be from any
                                    third party.
                                </p>
                                <p className="disc">
                                    Money payout to GSPL - Notwithstanding anything contained in any other
                                    agreement or arrangement, if any, between the parties hereto, the client
                                    hereby authorizes GSPL to release all payments due to him from the trading
                                    account maintain with GSPL, against specific request in this behalf.
                                </p>
                                <p className="disc">
                                    Securities pay-in to GSPL - All delivery to be effected to GSPL for a trade,
                                    must be made within 24 hours from the execution of the sale order or one day
                                    before the pay-in date, whichever is earlier. Losses, if any, that may accrue
                                    in the event of a default in completing the delivery on the exchange by GSPL
                                    as a result of any delay in the delivery by the client, shall be borne solely
                                    and completely by the client. Losses for the purposes of this clause shall
                                    include auction debits/ penalty charges, if any incurred as a result of
                                    non-delivery of securities on the settlement date on the exchange. No third
                                    party shares will be sold through GSPL or third party payment should be made
                                    to GSPL and client will be solely responsible for any violation. If the client
                                    has sold any securities from the exchange against purchase in previous settlements,
                                    such sale shall be at the sole risk as to costs and consequences thereof of the
                                    client.
                                </p>
                            </Col>
                            <Col lg={6} md={12} sm={12} className='maintanance-img'>
                            <img
                                className=''
                                src={main}
                                alt='Growth main illustration'
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className='maintanance-text term-text'>
                                <p className="disc">
                                    Securities pay-out by GSPL - GSPL may directly credit the demat account of the client
                                    with the depository participant or maintain the securities account with GSPL on a
                                    running account basis.
                                </p>
                                <p className="disc">
                                    Provided that if the order placed by the client through the website or otherwise is
                                    for securities which are in the no-delivery period, such securities shall be credited
                                    to the trading account of the client only at the time of settlement of trades, as per
                                    the schedule of the Exchange. However, if any sum due from the client, GSPL may
                                    withholds the credit of securities to the demat account of the client. However, the
                                    client authorizes GSPL to withhold the securities to meet liabilities of client to
                                    GSPL under this agreement.
                                </p>
                                <p className="disc">
                                    GSPL is entitled to consider any sum or money or security lying to the credit of the
                                    client as margin received.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Maintanance