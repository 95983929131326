import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import img from './Icons/happy.jpg'



export class Header extends Component {
    render() {
        return (
            <>
                <section className="depository_service-container">
                    <Container className="depository_service">
                        <Row className="depository_service-rows">
                            <Col lg={7} md={12} sm={12} className='depository_service-texts'>
                                <h4 className="headerTitle">
                                    Growth Securities is a Depository Participant of Central Depository Service Ltd (CDSL) to provide the Depository Services to their clients.
                                    We try our best to satisfy our clients.
                                </h4>
                                <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        Dipository Participant
                                        </p>
                                    </li>
                                </ul>
                                <p className="links">
                                    <span>
                                        Demat Holdings and Transaction Queries :
                                        <a href="https://web.cdslindia.com/myeasi/Home/Login">
                                            &nbsp;Click Here
                                        </a>
                                    </span>
                                    <span>
                                        Online Delivery Instruction Submission (Registration Required) :
                                        <a href="https://web.cdslindia.com/myeasi/Home/Login">
                                            &nbsp;Click Here
                                        </a>
                                    </span>
                                </p>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='depository_service-img'>
                                <div className="img">
                                    <img
                                        className='depository-img'
                                        src={img}
                                        alt="Growth depository illustration"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header