import React from 'react'
import './Style.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function eVot() {
    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || E-Voting
                </title>
            </Helmet>
            <div className="eVot-container">
                <Container className="eVoting">
                    <div className="headerWrap">
                        <h1 className="wrapTitle">
                            <span>E</span>-Voting
                        </h1>
                    </div>
                    <Row className="bodyWrap">
                        <Col lg={6} md={12} sm={12} xs={12} className="btnsBody">
                            <div className="vBtn">
                                <a href="https://www.evoting.nsdl.com/eVotingWeb/commonhtmls/Login.jsp?userType=IN" className="btn nsdl-btn" target='_blank' rel="noopener noreferrer">NSDL eVoting System</a>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12} className="btnsBody">
                            <div className="vBtn">
                                <a href="https://www.evotingindia.com/login_BO.jsp" className="btn nsdl-btn" target='_blank' rel="noopener noreferrer">CDSL eVoting System</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default eVot