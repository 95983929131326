import React, { Component } from "react";
import "./Style.scss";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import calImg from "./accounting.png";
import { Helmet } from "react-helmet";
import learn from "./learn.svg";
import growthCalc from "./Imgs/growthCalc.jpg";
import sip from "./Imgs/sip.jpg";
import mf from "./Imgs/mutualFunds.jpg";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

export class CalcColl extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Growth Securities || Growth Calculators || All Investment
            Calculators || Return On Investment || Dividend || SIP || Lumpsum ||
            Correlation || Retirement || PPF || CAGR || Gratuity || Margin ||
            Moving Average || Option P&L || Margin || Tax Impact || Equity Ratio
          </title>
        </Helmet>
        <div className="wrapper">
          <div className="callColl-container">
            <AnimatedSection>
              <div className="circles">
                <span className="bigCircle1"></span>
                <span className="bigCircle2"></span>
              </div>
            </AnimatedSection>
            <Container className="callColls">
              <AnimatedSection>
                <ul className="breadcrumbLinks">
                  <li className="breadcrumb-link">
                    <i className="fa fa-home" /> <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-link sec">
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li className="breadcrumb-link next">
                    <span>Growth Calculators</span>
                  </li>
                </ul>
              </AnimatedSection>
              <AnimatedSection>
                <Row className="callCollRows">
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/sip-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">SIP</h4>
                        <p className="disc">
                          A tool to calculate returns on mutual fund investments
                          made through Systematic Investment Plan (SIP).
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/lumpsum-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Lumpsum</h4>
                        <p className="disc">
                          A calculator that helps to determine the returns on a
                          one-time investment in a mutual fund scheme.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/ppf-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">PPF</h4>
                        <p className="disc">
                          A Public Provident Fund (PPF) calculator helps to
                          estimate the maturity value and interest earned on PPF
                          investments over a period of time.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/cagr-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">CAGR</h4>
                        <p className="disc">
                          CAGR is a measure of an investment's average annual
                          growth rate over a specified period of time, taking into
                          account the effect of compounding.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/gratuity-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Gratuity</h4>
                        <p className="disc">
                          Gratuity calculator estimates the amount of money
                          payable to an employee upon completion of five years of
                          service with an organization.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/roi-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">ROI</h4>
                        <p className="disc">
                          ROI is a financial metric used to evaluate the
                          profitability of an investment and is calculated as a
                          percentage of the original investment.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/dividend-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Dividend</h4>
                        <p className="disc">
                          Dividend is a portion of a company's profits paid to its
                          shareholders as a distribution.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/correlation-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Correlation</h4>
                        <p className="disc">
                          Correlation is a statistical measure that measures the
                          degree to which two variables are related or move
                          together.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/retirement-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Retirement</h4>
                        <p className="disc">
                          Retirement planning is the process of determining
                          retirement income goals and the actions and decisions
                          necessary to achieve those goals.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/margin-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Margin</h4>
                        <p className="disc">
                          Margin is the difference between the total value of
                          securities held in an account and the amount borrowed
                          from a broker.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/moving_average-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Moving Average</h4>
                        <p className="disc">
                          Moving Average is a widely used technical analysis
                          indicator that helps to smooth out price action by
                          filtering out the noise from random price fluctuations.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/option_pl-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Option P&L</h4>
                        <p className="disc">
                          The Option P&L calculator estimates the potential profit
                          or loss of an option strategy by considering the
                          underlying asset price, strike price, option premium,
                          and expiration date.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/position_size-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Position Size</h4>
                        <p className="disc">
                          Position size is the dollar amount of a trade based on
                          capital, risk management, and trader's risk tolerance.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/volatility-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Volatility</h4>
                        <p className="disc">
                          Volatility is a statistical measure of the dispersion of
                          returns for a given security or market index, indicating
                          the level of risk.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="future-value-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Future Value</h4>
                        <p className="disc">
                          The Future Value Calculator predicts investment growth
                          by factoring in initial amount, interest rate, and
                          duration. It helps with financial planning.
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12} className="callCollCol">
                    <div className="colBox">
                      <a className="calcCol" href="/equity_ratio-calculator">
                        <div className="imgBox">
                          <img
                            src={calImg}
                            alt="Growth Calculator"
                            className="calcImg"
                          />
                        </div>
                        <h4 className="calcName">Equity Ratio</h4>
                        <p className="disc">
                          The equity ratio is a financial ratio that measures the
                          proportion of equity financing in a company's capital
                          structure and indicates the degree of reliance on debt
                          financing.
                        </p>
                      </a>
                    </div>
                  </Col>
                </Row>
              </AnimatedSection>
              <AnimatedSection>
                <Row className="learnToEarnContainer">
                  <Col xl={7} md={7} sm={12} xs={12} className="learnToEarn">
                    <div className="text">
                      <h1 className="title">Learn to Earn</h1>
                      <h6 className="paraText">
                        "Unleash the potential of Growth knowledge and empower
                        yourself to earn and thrive in the dynamic world of
                        investing, where financial success becomes a tangible
                        reality through informed strategies and confident
                        decision-making."
                      </h6>
                      <div className="btns">
                        <a
                          href="/knowledgeCenter"
                          className="btn knowledgeBtn cBtn"
                        >
                          Knowledge Center
                        </a>
                        <a
                          href="https://kyc.growthsec.com/"
                          className="btn accountBtn cBtn"
                        >
                          Open Demat Account
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xl={5} md={5} sm={12} xs={12} className="learnToEarn">
                    <div className="img">
                      <img
                        src={learn}
                        alt="Growth illustration"
                        className="lTE"
                      />
                    </div>
                  </Col>
                </Row>
              </AnimatedSection>
              <AnimatedSection>
                <Row className="articlesContainer">
                  <div className="paraTitle">
                    <h1 className="title">
                      Read all related <span>Articles</span>
                    </h1>
                  </div>
                  <Col lg={4} md={6} sm={12} xs={12} className="article">
                    <div className="artCont">
                      <div className="imgCont">
                        <img
                          src={growthCalc}
                          alt="Growth Illustration"
                          className="artImg"
                        />
                      </div>
                      <h4 className="title">
                        Growth Calculators: Control of Your Financial Future with
                        Our User-Friendly Tool
                      </h4>
                      <p className="disc">
                        In the fast-paced world of investment markets, making
                        informed decisions is crucial for success. One tool that
                        has revolutionized the way investors track their profits
                        and losses is the investment ...
                      </p>
                      <a href="/growth_calculator-article" className="link">
                        Read More
                      </a>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={12} xs={12} className="article">
                    <div className="artCont">
                      <div className="imgCont">
                        <img
                          src={mf}
                          alt="Growth Illustration"
                          className="artImg"
                        />
                      </div>
                      <h4 className="title">
                        Investing Made Easy with Mutual Funds: The Smart Way to
                        Invest Your Money
                      </h4>
                      <p className="disc">
                        Mutual funds offer several benefits over individual stock
                        or bond investments, such as diversification, low cost,
                        liquidity, and professional management. Mutual funds also
                        offer tax benefits under ...
                      </p>
                      <a href="/mutual_fund-article" className="link">
                        Read More
                      </a>
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12} xs={12} className="article">
                    <div className="artCont">
                      <div className="imgCont">
                        <img
                          src={sip}
                          alt="Growth Illustration"
                          className="artImg"
                        />
                      </div>
                      <h4 className="title">
                        What is SIP and its 10 Benefits: Why You Should Start
                        Investing Today
                      </h4>
                      <p className="disc">
                        Systematic Investment Plan (SIP) is a popular investment
                        strategy that allows you to invest a fixed amount of money
                        at regular intervals (weekly, monthly, or quarterly) in
                        mutual funds. In this article we're ...
                      </p>
                      <a href="/sip-article" className="link">
                        Read More
                      </a>
                    </div>
                  </Col>
                </Row>
              </AnimatedSection>
              <AnimatedSection>
                <Row className="faqCalc">
                  <div className="accordFaq">
                    <div className="paraTitle">
                      <h1 className="title">
                        Frequently asked questions on{" "}
                        <span>Growth Calculators</span>
                      </h1>
                    </div>
                    <Accordion defaultActiveKey="0" flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h4 className="head">What is a Growth calculator?</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            A Growth calculator is a tool used to perform various
                            calculations related to stock trading and investing.
                            It helps investors analyze and make informed decisions
                            about their investments by providing calculations such
                            as profit/loss, return on investment (ROI), compound
                            annual growth rate (CAGR), and more.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <h4 className="head">
                            What calculations can I perform using a Growth
                            calculator?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Growth calculators can perform a wide range of
                            calculations, including:
                          </p>
                          <ul className="lists">
                            <li className="list">
                              <p className="disc">
                                Profit/Loss Calculation: Determine the profit or
                                loss on a stock investment by subtracting the
                                purchase price from the selling price, factoring
                                in any transaction costs.
                              </p>
                            </li>
                            <li className="list">
                              <p className="disc">
                                Return on Investment (ROI): Measure the return on
                                an investment, expressed as a percentage, by
                                comparing the gain or loss to the initial
                                investment.
                              </p>
                            </li>
                            <li className="list">
                              <p className="disc">
                                Compound Annual Growth Rate (CAGR): Calculate the
                                annualized growth rate of an investment over
                                multiple years, taking into account compounding.
                              </p>
                            </li>
                            <li className="list">
                              <p className="disc">
                                Dividend Yield: Calculate the percentage return on
                                a stock investment based on the dividends
                                received.
                              </p>
                            </li>
                            <li className="list">
                              <p className="disc">
                                Price-to-Earnings (P/E) Ratio: Determine the ratio
                                of a company's share price to its earnings per
                                share, providing insights into its valuation.
                              </p>
                            </li>
                            <li className="list">
                              <p className="disc">
                                Risk and Reward Ratio: Evaluate the risk-to-reward
                                ratio of an investment by comparing potential
                                gains to potential losses.
                              </p>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <h4 className="head">
                            Are Growth calculators accurate?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Growth calculators provide accurate results based on
                            the inputs provided by the user. However, it's
                            important to note that calculators rely on the
                            accuracy of the data entered, such as stock prices,
                            dividend payments, and transaction costs. Always
                            double-check your inputs and use reliable data sources
                            to ensure accurate calculations.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <h4 className="head">
                            Can I use a Growth calculator to predict future stock
                            prices?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Growth calculators are not designed to predict future
                            stock prices. They are primarily used for analyzing
                            past performance and making informed decisions based
                            on historical data. Predicting future stock prices
                            involves factors beyond the scope of calculators, such
                            as market trends, economic conditions, and
                            company-specific information.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <h4 className="head">
                            Are there any free Growth calculators available?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Yes, there are several free Growth calculators
                            available online. Many financial websites and
                            brokerages offer calculators as part of their
                            investment tools and resources. However, it's
                            important to review the credibility and reliability of
                            the source before using any calculator.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          <h4 className="head">
                            Can a Growth calculator help me determine when to buy
                            or sell stocks?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Growth calculators can assist in analyzing various
                            factors related to buying or selling stocks, such as
                            profit/loss calculations and risk assessments.
                            However, the decision to buy or sell stocks should be
                            based on comprehensive research, market analysis, and
                            individual investment goals. Growth calculators should
                            be used as supportive tools rather than the sole basis
                            for investment decisions.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>
                          <h4 className="head">
                            Are there specialized Growth calculators for options
                            trading or other investment strategies?
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="disc">
                            Yes, there are specialized calculators available for
                            options trading, margin trading, position sizing, and
                            other investment strategies. These calculators take
                            into account specific variables and formulas related
                            to the respective strategies, enabling investors to
                            make more accurate calculations for their particular
                            trading approaches.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Row>
              </AnimatedSection>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default CalcColl;
