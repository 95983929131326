import React, { Component } from 'react'
import { Container, Table } from 'react-bootstrap'

export class Details extends Component {
    render() {
        return (
            <>
                <section className="details-container">
                    <Container className='details'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Bank A/c No</th>
                                    <th>A/c Holder Name</th>
                                    <th>Bank Name</th>
                                    <th>IFSC Code</th>
                                    <th>Segment</th>
                                    <th>Purpose</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>4621600000781</td>
                                    <td>GROWTH SECURITIES PVT LTD</td>
                                    <td>DCB BANK</td>
                                    <td>DCBL0000046</td>
                                    <td>CMFOCDS</td>
                                    <td>CLIENT BANK ACCOUNT</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2211120030000340</td>
                                    <td>GROWTH SECURITIES PVT LTD</td>
                                    <td>UJJIVAN SMALL FINANCE BANK</td>
                                    <td>UJVN0002211</td>
                                    <td>CMFOCDS</td>
                                    <td>CLIENT BANK ACCOUNT</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>921020021519060</td>
                                    <td>GROWTH SECURITIES PVT LTD</td>
                                    <td>AXIS BANK LTD.</td>
                                    <td>UTIB0000004</td>
                                    <td>CMFOCDS</td>
                                    <td>CLIENT BANK ACCOUNT</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>57500000970250</td>
                                    <td>GROWTH SECURITIES PVT LTD</td>
                                    <td>HDFC BANK LTD</td>
                                    <td>HDFC0000003</td>
                                    <td>CMFOCDS</td>
                                    <td>CLIENT BANK ACCOUNT</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                </section>
            </>
        )
    }
}

export default Details