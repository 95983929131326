import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import app from './Icons/app.svg'
import brand from './Icons/brand.png'
import qr from './Icons/Growth.png'
import google from './Icons/google.png'
import apple from './Icons/apple.png'


function AppLink() {
    return (
        <>
            <section className="appLink-container">
                <Container className="appLink">
                    <Row className="appLink-rows">
                        <Col lg={6} md={6} sm={12} xs={12} className="appLink-left">
                            <div className="appBox imgBox">
                                <img
                                    src={app}
                                    alt="Mobile in hand illustration"
                                    className="app-img"
                                    width='100%'
                                    title='Growth app in mobile'
                                />
                                <img
                                    src={brand}
                                    alt="Growth logo"
                                    className="brand"
                                    title='Growth app'
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="appLink-right">
                            <div className="styleA"></div>
                            <Container>
                                <Row className='app-topRows'>
                                    <Col>
                                        <div className="appHeaderTexts">
                                            <h1 className="boxTitle">
                                                Download the app and start investing in equity, IPO, derivatives, mutual funds and more ...
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='app-topRows'>
                                    <Col lg={4} md={6} sm={6} xs={6} className='app-cols'>
                                        <div className="app-col app-col1">
                                            <img
                                                src={qr}
                                                alt='Growth QR'
                                                title="Scan to download the Growth app"
                                                className="qr-img"
                                                width='100%'
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={6} xs={6} className='app-cols'>
                                        <div className="app-col app-col2">
                                            <a href="https://play.google.com/store/apps/details?id=com.rs.growth&hl=en&gl=US" 
                                                rel='noopener noreferrer' target="_blank" className="googleStore">
                                                <img
                                                    src={google}
                                                    alt="Google Store"
                                                    title="Google Play Store"
                                                    className="google"
                                                    width='100%'
                                                />
                                            </a>
                                            <a href="https://apps.apple.com/in/app/growth-stocks-mutual-funds/id1603052789" 
                                               rel='noopener noreferrer' target="_blank" className="appleStore">
                                                <img
                                                    src={apple}
                                                    alt="Apple Store"
                                                    title="Apple App Store"
                                                    className="apple"
                                                    width='100%'
                                                />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={12} sm={12} xs={12} className='app-cols'>
                                        <div className="app-col app-col3">
                                            <p className="text">
                                                Read all the related documents or <a href="/terms-conditions" target='_blank' rel='noopener noreferrer'>terms and conditions</a> or call our customer care
                                            </p>
                                            <p className="call">
                                                <a href="tel:+011-43140000" title='customer care number'><i className="fa fa-phone"></i> 011 431 40000</a>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="styleB"></div>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AppLink