import React from "react";
import WeatherAPI from "./WeatherAPI";
import "./Style.scss";

const Weather = () => {
  return (
    <div>
      <WeatherAPI />
    </div>
  );
};

export default Weather;
