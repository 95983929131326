import React, { Component } from 'react'
import {
    Accordion,
    Col,
    Container,
    Row
} from 'react-bootstrap'

export class Learn extends Component {
    render() {
        return (
            <>
                <section className="learn-container">
                    <Container className='learn'>
                        <div className="paraTitle">
                            <h1 className="title">
                                Learn From <span>Q/A</span>
                            </h1>
                        </div>
                        <Row className='learn-rows'>
                            <Col lg={12} md={12} sm={12} className='learn-texts'>
                                <Accordion defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                Why should I invest?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                Logically speaking, one should invest to protect oneself from the ill effects of rising inflation by utilizing the growth attained from the act. In order to give you a decent chunk of surplus, the rate of return on investments should be greater than the rate of inflation. No matter where your money is invested, be it stocks, bonds, mutual funds or certificates of deposit (CD), the idea is to create wealth for retirement, marriage, college fees, vacations, better standard of living or to just pass on the money as a legacy. Also, it is priceless to watch your investment returns grow at a faster rate than your salary.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                Do I need a fortune to invest?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                The beauty of investing in the financial markets is that it suits everyone's pockets from the obscenely rich to the man next door. There is no fixed amount that investors need to invest so as to generate adequate returns from their savings. The amount that you invest will eventually depend on factors such as your risk profile, time horizon and amount of savings.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                Investment Avenues
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                There are a number of investment options available in the advanced financial markets; the idea is to pick the right investment tool based on the risk profile, circumstances and holding capacity. Market volatility has the potential to give you a high rate of return. But if you are risk averse and simply desire some additional source of income, then the fixed income securities should be given a thought. However, it should be noted that risk is directly proportional to return; higher the risk, higher the return.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                But when is the right time to invest?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                By investing into the market right away you give your investments more time to grow. Here comes the concept of compounding interest that inflates your income by accumulating your earnings and dividends. Compounding refers to growth via reinvestment of returns earned on your savings as you earn income not only on the original investment but also on the reinvestment of dividend/interest accumulated over the years. Taking the volatility of the markets into account, research and experience indicates that all investors, should invest early, invest regularly and have a disciplined approach towards investing.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                What should I invest in?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                There is a plethora of investing options such as:
                                            </p>
                                            <ul className='lists'>
                                                <li className='list'>
                                                    Equities (Shares/Stocks)
                                                </li>
                                                <li className="list">
                                                    Bonds
                                                </li>
                                                <li className="list">
                                                    Mutual funds
                                                </li>
                                                <li className="list">
                                                    Fixed deposits and many more.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                How does the stock market work?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                To learn how to earn money on the stock market, one has to understand how it works. A person desirous of buying/selling shares in the market has to first place his order with a broker. When the buy order of the shares is communicated to the broker he routes the order through his system to the exchange. The order stays in queue on the exchange system and gets executed when it reaches the buy price that has been specified. The shares purchased will be sent to the purchaser by the broker either in physical or demat format.
                                            </p>
                                            <p className="disc">
                                                Indian Stock Market Overview The Bombay Stock Exchange (BSE) and the National Stock Exchange of India Ltd (NSE) are the two primary exchanges in India. In addition, there are 22 Regional Stock Exchanges. However, the BSE and NSE have established themselves as the two leading exchanges and account for about 80 per cent of the equity volume traded in India. Most key stocks are traded on both the exchanges and hence the investor could buy them on either exchange. Both exchanges have a different settlement cycle, which allows investors to shift their positions on the bourses. The BSE Sensex is the older and more widely followed index. The scrips traded on the BSE have been classified into 'A', 'B1', 'B2', 'C', 'F' and 'Z' groups. The 'A' group shares represent those, which are in the carry forward system (Badla). The 'F' group represents the debt market (fixed income securities) segment. The 'Z' group scrips comprise of blacklisted companies. The 'C' group covers the odd lot securities in 'A', 'B1' & 'B2' groups and Rights renunciations. The key regulator governing Stock Exchanges, Brokers, Depositories, Depository participants, Mutual Funds, FIIs and other participants in Indian secondary and primary market is the Securities and Exchange Board of India (SEBI) Ltd.
                                            </p>
                                            <p className="disc">
                                                Rolling Settlement Cycle In a rolling settlement, each trading day is considered as a trading period and trades executed during the day are settled based on the net obligations for the day. At NSE and BSE, trades in rolling settlement are settled on a T+2 basis i.e. on the 2nd working day. To arrive at the settlement date all intervening holidays, which include bank holidays, NSE/BSE holidays, Saturdays and Sundays are excluded. Typically trades taking place on Monday are settled on Wednesday, Tuesday's trades settled on Thursday and so on.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                How should I invest in equities?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                It is generally seen that many investors go about investing in a highly irrational manner. They buy a scrip impulsively upon receiving tips from their associates, news or a rumor about a stock. Acting on such baseless information is like living in a fool's paradise.
                                            </p>
                                            <p className="disc">
                                                Our recommendation is to follow a three part approach to investing in equities.
                                            </p>
                                            <ul className='lists'>
                                                <li className="list">
                                                    Don’t get excited when you are tipped on any stock. In such cases, your next move should be to obtain first hand unbiased information from credible sources.
                                                </li>
                                                <li className="list">
                                                    The next step is to do a little bit of number crunching. Check out the growth rate of the stock's earnings, as shown in a percentage and analyze its graphs. Learn more about the P/E ratio (price-to-earnings ratio), earning per share (EPS), market capitalization to sales ratio, projected earning growth for the next quarter and some historical data, which will tell what the company has done in the past. Get the current status of the stock movement such as real-time quote, average trades per day, total number of shares outstanding, dividend, high and low for the day and for the last 52 weeks. This information should give you an indication of the nature of the company’s performance and stock movement.
                                                </li>
                                                <li className="list">
                                                    Also getting acquainted with the following terms will take you a long way in equity investing:
                                                    <ul className='lists'>
                                                        <li className="list" style={{ listStyle: "number" }}>
                                                            <b>- High (High):</b> The highest price for the stock in the trading day.
                                                        </li>
                                                        <li className="list" style={{ listStyle: "number" }}>
                                                            <b>- Low (Low) :</b>The lowest price for the stock in the trading day.
                                                        </li>
                                                        <li className="list" style={{ listStyle: "number" }}>
                                                            <b>- Close (Close) :</b> The price of the stock at the time the stock market closes for the day.
                                                        </li>
                                                        <li className="list" style={{ listStyle: "number" }}>
                                                            <b>- Chg (Change) :</b> The difference between two successive days' closing price of the stock.
                                                        </li>
                                                        <li className="list" style={{ listStyle: "number" }}>
                                                            <b>- Yld (Yield) : </b>The dividend divided by the price of the stock.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                What are Forward contracts?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                A forward contract is one to one bi-partite contract, to be performed in the future, at the terms decided today. Forward contracts offer tremendous flexibility to the parties to design the contract in terms of the price, quantity, quality (in case of commodities), delivery time and place. However forward contracts suffer from poor liquidity and default risk.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                What are Future contracts?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                Future contracts are organized contracts, which are traded on the exchanges and are very liquid in nature. In the futures market a clearing corporation provides the settlement guarantee.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="10">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                What are Index Futures?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                Index futures are the future contracts for which the underlying asset is the cash market index. For example: BSE may launch a future contract on "BSE Sensitive Index".
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="11">
                                        <Accordion.Header className='qa-heading'>
                                            <h4 className="heading">
                                                What are Options?
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body className='qa-body'>
                                            <p className="disc">
                                                Options are instruments whereby the right is given by the option seller to the option buyer to buy or sell a specific asset at a specific price on or before a specific date.
                                            </p>
                                            <p className="disc">
                                                A call option gives the holder the right to buy an underlying asset at a certain date for a certain price. The seller is under an obligation to fulfill the contract and is paid a certain price which is called "the call option premium or call option price".
                                            </p>
                                            <p className="disc">
                                                A put option, on the other hand gives the holder the right to sell an underlying asset at a certain date for a certain price. The buyer is under an obligation to fulfill the contract and is paid a certain price, which is called "the put option premium or put option price".
                                            </p>
                                            <p className="disc">
                                                The price at which the underlying asset would be bought in the future at a particular date is the "Strike Price" or the "Exercise Price". The date on the options contract is called the "Exercise date", "Expiration Date" or the "Date of Maturity".
                                            </p>
                                            <p className="disc">
                                                There are two kinds of options based on the date. The first is the European Option(CE) which can be exercised only on the maturity date. The second is the American Option(CA) which can be exercised before or on the maturity date.
                                            </p>
                                            <p className="disc">
                                                Cash settled option share those where, on exercise the buyer is paid the difference between stock price and exercise price (call) or between exercise price and stock price (put).
                                            </p>
                                            <p className="disc">
                                                Delivery settled options are those where the buyer takes delivery of undertaking (calls) or offers delivery of the undertaking (puts).
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Learn