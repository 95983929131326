import React, { Component } from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import disclaimer from "../Icons/disclaimer.svg";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";

export class Disclaimer extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Disclaimer</title>
        </Helmet>
        <div className="disclaimer-container">
          <Container className="disclaimer">
            <AnimatedSection>
              <h1 className="title">
                Dis<span>claimer</span>
              </h1>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="disclaimer-rows">
                <Col lg={8} md={12} sm={12} className="disclaimer-txt">
                  <p className="disc">
                    All reasonable care has been taken to ensure that the
                    information contained herein is not misleading or untrue at
                    the time of publication. However, we make no long standing
                    commitment as to its accuracy or completeness. All
                    information is for the private use of the person to whom it
                    is provided without any liability whatsoever on the part of{" "}
                    <b>Growth</b> or any associated company or any member of
                    employee thereof. Nothing contained herein should be
                    construed as an offer to buy or sell or a solicitation of an
                    offer to buy or sell. The value of any investment may fall
                    as well as rise. Investors are advised to avail personal
                    counseling from our research or dealing desk if they wish
                    for any further clarity or clarifications in the
                    understanding on various aspects of investment.
                  </p>
                </Col>
                <Col lg={4} md={12} sm={12} className="disclaimer-img">
                  <img
                    className="disclaimer-img"
                    src={disclaimer}
                    alt="Growth disclaimer illustration"
                    width="100%"
                  />
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="disclaimer-rows">
                <Col lg={12} md={12} sm={12} className="disclaimer-txt">
                  <p className="disc">
                    <b>Growth</b> has taken due care and caution in compilation
                    of data for its web site. The views and investment tips
                    expressed by investment experts on <b>Growth</b> are their
                    own, and not that of the site. <b>Growth</b> advices users
                    to check with certified experts before taking any investment
                    decision. However, <b>Growth</b>
                    does not guarantee the accuracy, adequacy or completeness of
                    any information and is not responsible for any errors or
                    omissions or for the results obtained from the use of such
                    information.
                    <b>Growth</b> especially states that it has no financial
                    liability whatsoever to any user on account of the use of
                    information provided on its website.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
          </Container>
        </div>
      </>
    );
  }
}

export default Disclaimer;
