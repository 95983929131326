import React, { Component } from 'react'
import {
    Container
} from 'react-bootstrap'
import crpi from './Icons/crpi.svg'


export class CRPI extends Component {
    render() {
        return (
            <>
                <section className="crpi_section-container">
                    <Container className="crpi_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            <span>Growth</span>: A <span>Comprehensive Research Platform</span> for <span>Investors</span>
                        </h1>
                        </div>
                        <div className="img">
                            <img
                                src={crpi}
                                alt="Growth crpi illustration"
                                className="crpi-img"
                                width='100%'
                            />
                        </div>
                        <div className="texts">
                            <p className="disc">
                                <b>Growth</b> is a leading provider of research and analysis for investors seeking opportunities in the global financial markets. We provide investors with deep insights, actionable intelligence, and a cutting-edge platform to help them make informed investment decisions. <b>Growth</b> has the expertise and resources to help you reach your financial goals.
                            </p>
                            <p className="disc">
                                Our research platform is based on a proprietary algorithm leveraging big data analytics and machine learning to provide investors with the most comprehensive and up-to-date information. Our experienced analysts, traders, and data scientists work tirelessly to provide investors with the latest market insights and trends, along with actionable investment recommendations.
                            </p>
                            <p className="disc">
                                You need access to the best research and analysis available to make informed investment decisions. That's why we have built a comprehensive research platform designed to be easy to use and understand while still providing the depth and detail sophisticated investors require.
                            </p>
                            <p className="disc">
                                One of the critical advantages of <b>Growth</b> is our ability to provide investors with real-time market intelligence. Our algorithm constantly monitors the financial markets, so we endeavor you always stay one step ahead of the curve. Whether you're looking for the latest news on a particular stock or market sector or want to track a specific portfolio's performance, <b>Growth</b> has you covered.
                            </p>
                            <p className="disc">
                                In addition to our market intelligence, we provide various educational resources to help investors better understand the financial markets and the investment process. Whether you want to start your investing journey or are a seasoned pro, our library of articles, videos, and tutorials will assist you in expanding your knowledge and skills.
                            </p>
                            <p className="disc">
                                At <b>Growth</b>, we are committed to providing our clients with the best possible investment experience. That's why we offer a range of customization options so that you can tailor your research platform to your specific needs and preferences. Whether you prefer to receive alerts via email or SMS or want to view your portfolio in real-time, we have a solution that will meet your needs.
                            </p>
                            <p className="disc">
                                <b>Growth</b> is the ideal platform for investors who want to gain a competitive edge in the financial markets. With our cutting-edge technology, experienced team of analysts, and comprehensive research platform, we provide investors with the tools they need to succeed.
                            </p>
                        </div>
                    </Container>
                </section>
            </>
        )
    }
}

export default CRPI