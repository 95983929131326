import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import logoShade from './Icons/logoshade.svg'

export class Nice extends Component {
    render() {
        return (
            <>
                <section className="nicePageSection_container">
                    <div className="container nicePageSection">
                        <Row className="rows">
                            <Col lg={4} md={6} sm={12} className="niceImg">
                                <div className="img">
                                    <img
                                        src={logoShade}
                                        alt="Growth Securities Logo Brand"
                                        className="logoImg"
                                        title='Growth logo'
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="niceTxt">
                                <div className="txt">
                                    <h1 className="niceTitle">
                                        The Journey Towards Your Financial Freedom
                                    </h1>
                                    <h6 className="niceSubtitle">
                                        {/* Solutions with the right balance of human intelligence and technology to keep you on 
                                        track of your goals, especially during market volatility */}
                                        <i>
                                            "We are pleased to guide you in achieving your financial freedom because we care for you."
                                        </i>                            
                                    </h6>
                                    {/*<h6 className="niceSubtitle">
                                        <i>"The intelligent investor is a realist who sells to optimists and buys from pessimists"</i> ~
                                        <span><b>Benjamin Graham</b></span>
                                    </h6>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <div className="line"></div> */}
                </section>
            </>
        )
    }
}

export default Nice