import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import MutualDetails from "./MutualDetails";
import Why from "./Why";
import Advantages from "./Advantages";
import Partner from "./Partner";
import About from "./About";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function MutualFunds() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Mutual Funds || Online Mutual Fund Investment,
          Best Mutual Funds India || Learn Mutual Funds Basics
        </title>
      </Helmet>
      <div className="wrapper mutual-funds">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <About />
        </AnimatedSection>
        <AnimatedSection>
          <MutualDetails />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
        <AnimatedSection>
          <Advantages />
        </AnimatedSection>
        <AnimatedSection>
          <Partner />
        </AnimatedSection>
      </div>
    </>
  );
}

export default MutualFunds;
