import React, { useEffect, useState } from "react";
import "./Style.scss";
import stockData from "./Data.json";




const MarketIndicator = () => {
  const [marketData, setMarketData] = useState([]);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
    
        const data = stockData; // Make sure stockData is properly imported
        console.log(data); // Log the data to check if it contains all the items
    
        setMarketData(data);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };    

    // Fetch market data initially
    fetchMarketData();

    // Set up interval to fetch market data every 5 seconds
    const interval = setInterval(fetchMarketData, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="market-indicator">
      {marketData.length > 0 ? (
        <div className="scrolling-text-container">
          {marketData.map((stock, index) => (
            <div
              key={index}
              className={`scrolling-text ${
                stock.change < 0 ? "loss" : "gain"
              }`}
            >
              <span className="symbol">{stock.symbol}</span>
              <span className="company">{stock.company}</span>
              <span className="price">{stock.price}</span>
              <span className="change">{stock.change}</span>
              <span className="percentChange">{stock.percentChange}%</span>
              <span className="volume">{stock.volume}</span>
            </div>
          ))}
        </div>
      ) : (
        <span className="loading">Loading market data...</span>
      )}
    </div>
  );
};

export default MarketIndicator;
