import React from 'react'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'
import fo from '../Icons/fo.svg'



function Header() {
  return (
    <>
      <section className="AO-container">
        <Container className="AO">
          <Row className='AO-rows'>
            <Col lg={7} md={6} sm={12} xs={12} className='left-AO'>
              <div className="header-AO">
                <h1 className="title">
                  Select your relevant title from future & option (F&O) and find your solution!
                </h1>
                <ul className="breadcrumb-links">
                  <li className="breadcrumb-link">
                    <i className='fa fa-home' /> <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-link sec">
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li className="breadcrumb-link">
                    <i className='fa fa-question' /> <a href="/support">Supports</a>
                  </li>
                  <li className="breadcrumb-link sec">
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li className="breadcrumb-link next">
                    <span>F&O</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={5} md={6} sm={12} xs={12} className='right-AO'>
              <div className="header-AO">
                <img
                  src={fo}
                  alt="Growth chart of gaining profits"
                  className="sideImg"
                  width="100%"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Header