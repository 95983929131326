import React from "react";
import { Container, Table } from "react-bootstrap";
import BasicServicesDematAccount from "./InvestorCharter/components/BasicServicesDematAccount";
import Transpositioncumdematerialization from "./InvestorCharter/components/Transpositioncumdematerialization";
import LinkageswithClearingSystem from "./InvestorCharter/components/LinkageswithClearingSystem";
import Eaccountopening from "./InvestorCharter/components/E-accountopening";
import Onlineinstructionsforexecution from "./InvestorCharter/components/Onlineinstructionsforexecution";
import EDISDematGateway from "./InvestorCharter/components/e-DISDematGateway";
import ECASfacility from "./InvestorCharter/components/e-CASfacility";
import Miscellaneousservices from "./InvestorCharter/components/Miscellaneousservices";
import DosandDontsforInvestor from "./InvestorCharter/components/DosandDontsforInvestor";
import Rightsofinvestors from "./InvestorCharter/components/Rightsofinvestors";
import ResponsibilitiesofInvestors from "./InvestorCharter/components/ResponsibilitiesofInvestors";
import CodeOfConduct from "./InvestorCharter/components/CodeOfConduct";
import CodeofConductParticipant from "./InvestorCharter/components/CodeofConductParticipant";

function InvestorCharter() {
  return (
    <>
      <div className="wrapper cdsl-investor-charter-header">
        <Container>
          <div className="header">
            <h1>
              Investor Charter for Depositories and Depository Participants
            </h1>
          </div>
        </Container>
      </div>
      <div className="cdsl-investor-charter-section">
        <Container>
          <div className="cdsl-body">
            <h3>Vision</h3>
            <p>
              Towards making Indian Securities Market - Transparent, Efficient,
              & Investor friendly by providing safe, reliable, transparent and
              trusted record keeping platform for investors to hold and transfer
              securities in dematerialized form.
            </p>
            <h3>Mission</h3>
            <ul className="lists">
              <li className="list">
                To hold securities of investors in dematerialized form and
                facilitate its transfer, while ensuring safekeeping of
                securities and protecting interest of investors.
              </li>
              <li className="list">
                To provide timely and accurate information to investors with
                regard to their holding and transfer of securities held by them.
              </li>
              <li className="list">
                To provide the highest standards of investor education, investor
                awareness and timely services so as to enhance Investor
                Protection and create awareness about Investor Rights.
              </li>
            </ul>
            <h3>
              Details of business transacted by the Depository and Depository
              Participant (DP)
            </h3>
            <p>
              A Depository is an organization which holds securities of
              investors in electronic form. Depositories provide services to
              various market participants - Exchanges, Clearing Corporations,
              Depository Participants (DPs), Issuers and Investors in both
              primary as well as secondary markets. The depository carries out
              its activities through its agents which are known as Depository
              Participants (DP). Details available on the link{" "}
              <a
                href="https://www.cdslindia.com/DP/dplist.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.cdslindia.com/DP/dplist.aspx
              </a>
            </p>
            <h3>
              Description of services provided by the Depository through
              Depository Participants (DPs) to investors
            </h3>
            <p>Basic Services</p>
            <div className="tableContainer">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No. </th>
                    <th>Brief about the Activity / Service </th>
                    <th>
                      Expected Timelines for processing by the DP after receipt
                      of proper documents
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Dematerialization of securities </td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Rematerialization of securities </td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Mutual Fund Conversion / Destatementization </td>
                    <td>5 days</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Re-conversion / Restatementisation of Mutual fund units
                    </td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Transmission of securities</td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Registering pledge request</td>
                    <td>15 days</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Closure of demat account</td>
                    <td>30 days</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Settlement Instruction</td>
                    <td>
                      Depositories to accept physical DIS for pay-in of
                      securities up to 4 p.m. and DIS in electronic form up to 6
                      p.m. on T+1 day
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p>
              Depositories provide special services like pledge, hypothecation,
              internet-based services etc. in addition to their core services
              and these include.
            </p>

            <div className="tableContainer">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Type of Activity /Service </th>
                    <th>Brief about the Activity / Service </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Value Added Services</td>
                    <td>
                      <p>
                        Depositories also provide value added services such as
                      </p>
                      <p>
                        <BasicServicesDematAccount />
                      </p>
                      <p>
                        <Transpositioncumdematerialization />
                      </p>
                      <p>
                        <LinkageswithClearingSystem />
                      </p>
                      <p>
                        d. Distribution of cash and non-cash corporate benefits
                        (Bonus, Rights, IPOs etc.), stock lending.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Consolidated Account statement (CAS) </td>
                    <td>
                      CAS is issued 10 days from the end of the month (if there
                      were transactions in the previous month) or half yearly(if
                      no transactions).
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Digitalization of services provided by the depositories{" "}
                    </td>
                    <td>
                      <p>
                        Depositories offer below technology solutions and
                        e-facilities to their demat account holders through DPs:
                      </p>
                      <p>
                        <Eaccountopening />
                      </p>
                      <p>
                        <Onlineinstructionsforexecution />
                      </p>
                      <p>
                        <EDISDematGateway />
                      </p>
                      <p>
                        <ECASfacility />
                      </p>
                      <p>
                        <Miscellaneousservices />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <h3>Details of Grievance Redressal Mechanism</h3>
            <p>The Process of investor grievance redressal</p>
            <div className="tableContainer">
              <Table bordered hover>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Investor Complaint/ Grievances </td>
                    <td>
                      <p>
                        Investor can lodge complaint/ grievance against the
                        Depository/DP in the following ways:
                      </p>
                      <p>a. Electronic mode:</p>
                      <ul className="lists">
                        <li className="list">
                          <a
                            href="https://scores.sebi.gov.in/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            SCORES (a web based centralized grievance redressal
                            system of SEBI)
                          </a>
                        </li>
                        <li className="list">
                          <a
                            href="https://www.cdslindia.com/Footer/grievances.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Respective Depository's web portal dedicated for the
                            filing of compliant
                          </a>
                        </li>
                        <li className="list">
                          Emails to designated email IDs of Depository -{" "}
                          <a href="mailto:complaints@cdslindia.com">
                            complaints@cdslindia.lcom
                          </a>
                        </li>
                      </ul>
                      <p>b. Offline mode:</p>
                      <p>
                        For tracking of your grievance, we request you to submit
                        the same online through the portal.
                      </p>
                      <p>
                        The complaints/ grievances lodged directly with the
                        Depository shall be resolved within 30 days
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      Online Dispute Resolution (ODR) platform for online
                      Conciliation and Arbitration
                    </td>
                    <td>
                      <p>
                        If the Investor is not satisfied with the resolution
                        provided by DP or other Market Participants, then the
                        Investor has the option to file the complaint/ grievance
                        on SMARTODR platform for its resolution through by
                        online conciliation or arbitration.
                      </p>
                      <p>[SMARTODR link to be provided by Depositories]</p>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Steps to be followed in ODR for Review, Conciliation and
                      Arbitration
                    </td>
                    <td>
                      <ul className="lists">
                        <li className="list">
                          Investor to approach Market Participant for redressal
                          of complaint
                        </li>
                        <li className="list">
                          If investor is not satisfied with response of Market
                          Participant, he/she can escalate the complaint on SEBI
                          SCORES portal.
                        </li>
                        <li className="list">
                          Alternatively, the investor may also file a complaint
                          on SMARTODR portal for its resolution through online
                          conciliation and arbitration.
                        </li>
                        <li className="list">
                          Upon receipt of complaint on SMARTODR portal, the
                          relevant MII will review the matter and endeavour to
                          resolve the matter between the Market Participant and
                          investor within 21 days.
                        </li>
                        <li className="list">
                          If the matter could not be amicably resolved, then the
                          Investor may request the MII to refer the matter case
                          for conciliation.
                        </li>
                        <li className="list">
                          During the conciliation process, the conciliator will
                          endeavor for amicable settlement of the dispute within
                          21 days, which may be extended with 10 days by the
                          conciliator.
                        </li>
                        <li className="list">
                          If the conciliation is unsuccessful, then the investor
                          may request to refer the matter for arbitration.
                        </li>
                        <li className="list">
                          The arbitration process to be concluded by
                          arbitrator(s) within 30 days, which is extendable by
                          30 days.
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p>
              For the Multi-level complaint resolution mechanism available at
              the Depositories please refer to link -{" "}
              <a
                href="https://www.cdslindia.com/downloads/Investors/Complaint%20Resolution%20process%20at%20Depositories.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Complaint Resolution process at Depositories
              </a>
            </p>
            <h3>
              Guidance pertaining to special circumstances related to market
              activities: Termination of the Depository Participant
            </h3>
            <div className="tableContainer">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Type of special circumstances </th>
                    <th>Timelines for the Activity/ Service</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <ul className="lists">
                        <li className="list">
                          Depositories to terminate the participation in case a
                          participant no longer meets the eligibility criteria
                          and/or any other grounds as mentioned in the bye laws
                          like suspension of trading member by the Stock
                          Exchanges.
                        </li>
                        <li className="list">
                          Participant surrenders the participation by its own
                          wish.
                        </li>
                      </ul>
                    </td>
                    <td>
                      Client will have a right to transfer all its securities to
                      any other Participant of its choice without any charges
                      for the transfer within 30 days from the date of
                      intimation by way of letter/email.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <h3>Dos and Don'ts for Investors</h3>
            <p>
              For Do's and Don'ts please refer to the link -{" "}
              <DosandDontsforInvestor />
            </p>
            <h3>Rights of investors</h3>
            <p>
              For rights, please refer to the link - <Rightsofinvestors />{" "}
            </p>
            <h3>Responsibilities of Investors</h3>
            <p>
              For responsibilities please refer to the link -{" "}
              <ResponsibilitiesofInvestors />
            </p>
            <h3>Code of Conduct for Depositories</h3>
            <p>
              For Code of Conduct for Depositories link please refer to the link
              - <CodeOfConduct />{" "}
            </p>
            <h3>Code of Conduct for Participants</h3>
            <p>
              For Code of Conduct for Participant link please refer to the link
              - <CodeofConductParticipant />{" "}
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default InvestorCharter;
