import React, { useState, useEffect, useRef } from "react";
import data from "./Data.json";
import "./Style.scss"; // Import the SCSS file without using module.scss
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import WelcomeSummary from "./WelcomeSummary";
import { TypeAnimation } from "react-type-animation";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const Summary = () => {
  const titles = Object.keys(data);
  const [summary, setSummary] = useState(""); // State to store the summary
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [isInputFilled, setIsInputFilled] = useState(false); // State to track if input is filled
  const inputRef = useRef(null); // Reference to the input field
  const minInputLength = 3; // Minimum input length required

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const value = inputRef.current.value.trim();

    if (value.length === 0) {
      // Show an error message if the input is empty
      setSummary(
        <TypeAnimation
          sequence={["Please enter a title to get a summary."]}
          wrapper="div"
          cursor={true}
          cursorBlinkSpeed={0}
        />
      );
      return;
    }

    if (value.length < minInputLength) {
      // Show an error message if the input is too short
      setSummary(
        <TypeAnimation
          sequence={["Please enter a complete word to get a summary."]}
          wrapper="div"
          cursor={true}
        />
      );
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      const matchedTitle = titles.find((title) =>
        title.toLowerCase().includes(value.toLowerCase())
      );

      if (matchedTitle) {
        const { answer, key_points, benefits, additional_information } =
          data[matchedTitle];

        // Format the summary content
        const formattedSummary = (
          <ul className="sumLists">
            {/* <TypeAnimation 
              sequence={[answer]}
              wrapper='div'
              cursor={true}
            /> */}
            <p className="disc">{answer}</p>
            {key_points && key_points.length > 0 && (
              <li className="list">
                <strong>Key Points:</strong>
                <ul>
                  {key_points.map((point, index) => (
                    <li className="subList" key={`point-${index}`}>
                      {point}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {benefits && benefits.length > 0 && (
              <li className="list">
                <strong>Benefits:</strong>
                <ul>
                  {benefits.map((benefit, index) => (
                    <li className="subList" key={`benefit-${index}`}>
                      {benefit}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {additional_information && additional_information.length > 0 && (
              <li className="list">
                <strong>Additional Information:</strong>
                <ul>
                  <li>{additional_information}</li>
                </ul>
              </li>
            )}
          </ul>
        );

        setSummary(formattedSummary); // Set the formatted summary
      } else {
        // Show a message if no summary is found
        setSummary(
          <TypeAnimation
            sequence={[
              "Sorry, no summary found for the given title. Our page is continuously improving, and this topic is currently being trained. Please try again later or enter a different title. We appreciate your patience and thank you for using our summary page. In the meantime, feel free to explore other available topics and their summaries. If you have any suggestions or feedback, we would love to hear from you. Your input helps us enhance the quality and coverage of our summaries. Thank you for your understanding and support.",
            ]}
            wrapper="div"
            cursor={true}
            speed={10}
            cursorBlinkSpeed={0}
          />
          // 'Sorry, no summary found for the given title. Our page is continuously improving, and this topic is currently being trained. Please try again later or enter a different title. We appreciate your patience and thank you for using our summary page. In the meantime, feel free to explore other available topics and their summaries. If you have any suggestions or feedback, we would love to hear from you. Your input helps us enhance the quality and coverage of our summaries. Thank you for your understanding and support.'
        );
      }
    } catch (error) {
      // Show an error message if there's an error fetching the summary
      setSummary(
        <TypeAnimation
          sequence={["Error occurred while fetching the summary."]}
          wrapper="div"
          cursor={true}
        />
      );
    }

    setIsLoading(false); // Set loading state to false
    inputRef.current.value = ""; // Clear the input field
    setIsInputFilled(false); // Set input filled state to false
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    setIsInputFilled(value.length > 0); // Update input filled state
  };

  const refreshSummary = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <Helmet>
        <title>
          Growth securities || Growth's Summary Page || Stock Market Summary ||
          Find Your Answer Here || Start Investing Today
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Container className="summaryPage">
            <div className="summary-container">
              <form onSubmit={onSubmit}>
                <Row>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <div className="sumInp">
                      <div className="custom-input">
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder="Type a title for summary ..."
                          className="hidden-input"
                          onChange={onChangeInput}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className="d-flex align-items-center justify-content-end col-sm"
                  >
                    <div className="sumBtn">
                      <button
                        type="submit"
                        disabled={!isInputFilled || isLoading}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                      <button
                        type="button"
                        className="refresh-btn"
                        onClick={refreshSummary}
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
              <div className="output-box">
                {summary ? <ul>{summary}</ul> : <WelcomeSummary />}
              </div>
            </div>
          </Container>
        </AnimatedSection>
      </div>
    </>
  );
};

export default Summary;
