import React from 'react'
import {
    Container,
    Tab,
    Row,
    Col,
    Nav,
    Accordion
} from 'react-bootstrap'



function Queries() {

    return (
        <>
            <section className="queContainer">
                <Container className="que">
                    <Tab.Container id="" defaultActiveKey="first">
                        <Row>
                            <Col lg={2} md={2} sm={12} xs={12} className='pillSide'>
                                <Nav variant="tabs" className="flex-column">
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="first" >
                                            <span>Getting Started</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="second">
                                            <span>Online Opening</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="third">
                                            <span>Offline Opening</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='navName'>
                                        <Nav.Link className='navBack' eventKey="fourth">
                                            <span>Closing Account</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg={10} md={10} sm={12} xs={12} className='queAns'>
                                <Tab.Content>
                                    <Tab.Pane className='contBack' eventKey="first">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">What is demat account?</h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            A <a href="https://kyc.growthsec.com" target="_blank" rel="noopener noreferrer">demat account</a>, short for "dematerialized account," is an electronic account used to hold and store securities such as stocks, bonds, mutual funds, and other financial instruments. It serves as a digital repository of your investment holdings in a convenient, paperless form.
                                                        </p>
                                                        <p className="disc">
                                                            Instead of holding physical certificates to prove ownership of the securities you own, the demat account electronically records and stores your holdings. The account is managed by a Depository Participant (DP) which is a registered intermediary between the investor and the depository. In India, there are two depositories, the National Securities Depository Limited (NSDL) and the Central Depository Services Limited (CDSL). The demat account makes it easy to buy, sell, and transfer securities online.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What documents need to open a demat account?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            To open a demat account with Growth, you will typically need to provide the following documents:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                <strong>PAN card:</strong> A copy of your Permanent Account Number (PAN) card is mandatory to open a demat account.
                                                            </li>
                                                            <li className="list">
                                                                <strong>Identity proof:</strong> You will need to provide a valid proof of identity such as Aadhaar card, passport, driving license, or voter ID card.
                                                            </li>
                                                            <li className="list">
                                                                <strong>Address proof:</strong> A valid proof of address such as Aadhaar card, passport, driving license, or utility bills like electricity, telephone or gas bill not more than 3 months old needs to be submitted.
                                                            </li>
                                                            <li className="list">
                                                                <strong>Passport size photographs:</strong> You will need to provide a few passport size photographs for account opening.
                                                            </li>
                                                            <li className="list">
                                                                <strong>Bank details:</strong> You will also need to provide a canceled cheque or bank statement to link your demat account with your bank account for smooth transactions.
                                                            </li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Is it important to have demat account to trade through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Yes, if you want to trade in securities such as stocks, bonds, and mutual funds through Growth, having a demat account is mandatory. A demat account, short for dematerialized account, is an electronic account that holds securities in a digital format. It eliminates the need for physical certificates, making the trading process more convenient and efficient.
                                                        </p>
                                                        <p className="disc">
                                                            When you buy securities, they are credited to your demat account, and when you sell them, they are debited from the account. A demat account also provides easy accessibility to your securities and reduces the risk of theft, loss, or damage associated with physical certificates.
                                                        </p>
                                                        <p className="disc">
                                                            To open a demat account, you need to approach a Depository Participant (DP), who acts as an intermediary between you and the Depository. The two Depositories in India are the National Securities Depository Limited (NSDL) and the Central Depository Services Limited (CDSL). You can choose a DP that is registered with either of the Depositories and complete the account opening process.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Can I check my trading account details, trades, and balances online?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Yes, Growth offers online access to trading account details, including trades and balances.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Can I use my bank account as a demat account?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            No, you cannot use your bank account as a Demat account. A Demat account is a type of account that is used to hold securities in electronic form. It is essential for buying and selling shares, mutual funds, bonds, and other securities in India. On the other hand, a bank account is used to deposit and withdraw money.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            How to open a new demat account with Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            To open a new demat account with Growth, visit our website and follow the instructions to complete an application. You will need to provide personal information and identification documents and agree to their terms and conditions.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            Am I eligible to open a trading account?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Eligibility to open a trading account varies by country and financial institution, but generally, individuals who are above 18 and have valid identification can open a trading account.                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            What is a trading account?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            A trading account is a type of investment account that allows individuals to buy and sell securities such as stocks, bonds, and mutual funds.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header className='headBg'>
                                                        <h4 className="head">
                                                            How can I secure my demat account?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            To secure your demat account, you can take the following measures:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                Keep your login credentials confidential: Your login ID and password should be kept confidential and should not be shared with anyone. Avoid writing it down or saving it in an unsecured location.
                                                            </li>
                                                            <li className="list">
                                                                Use a strong password: Use a strong and unique password that includes a combination of uppercase and lowercase letters, numbers, and special characters.
                                                            </li>
                                                            <li className="list">
                                                                Enable two-factor authentication: Enable two-factor authentication (2FA) to add an extra layer of security to your demat account. This will require you to enter a One-Time Password (OTP) sent to your registered mobile number or email ID.
                                                            </li>
                                                            <li className="list">
                                                                Monitor your account regularly: Regularly monitor your demat account transactions and report any unauthorized transactions immediately to your broker or depository participant.
                                                            </li>
                                                            <li className="list">
                                                                Keep your contact details updated: Ensure that your contact details like email ID and phone number are updated with your broker or depository participant so that you can receive alerts and notifications regarding your account.
                                                            </li>
                                                            <li className="list">
                                                                Use a secure network: Access your demat account only from a secure network, and avoid using public Wi-Fi or shared computers to log in.
                                                            </li>
                                                            <li className="list">
                                                                Keep your computer updated: Keep your computer's operating system, antivirus, and firewall updated to protect against viruses and malware.
                                                            </li>
                                                        </ul>
                                                        <p className="disc">
                                                            By following these best practices, you can significantly reduce the risk of unauthorized access to your demat account and protect your investments.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane className='contBack' eventKey="second">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to open a demat account online through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="disc">
                                                            Opening a demat account online is a simple and convenient process. Here are the steps you can follow to open a demat account online:
                                                        </p>
                                                        <ul className="lists">
                                                            <li className="list">
                                                                <b>Visit our website:</b> Click on the button to open a demat account.
                                                            </li>
                                                            <li className="list">
                                                                <b>Fill in the application form:</b> You will be required to fill in an application form with personal and financial details, including your PAN card and bank account details.
                                                            </li>
                                                            <li className="list">
                                                                <b>Submit supporting documents:</b> You will also need to upload supporting documents like your Aadhaar card, address proof, and passport size photograph.
                                                            </li>
                                                            <li className="list">
                                                                <b>e-sign the application:</b> Once you have filled in all the details and uploaded the necessary documents, you will be required to e-sign the application using Aadhaar-based e-KYC verification.
                                                            </li>
                                                            <li className="list">
                                                                <b>In-person verification:</b> After the online verification is complete, the DP may send a representative to verify your identity and address in-person.
                                                            </li>
                                                            <li className="list">
                                                                <b>Receive your account details:</b> Once your demat account is opened, you will receive your account details, including your demat account number, login ID, and password.
                                                            </li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any charges to open a demat account through Growth?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            Is there any hidden charges to buy or sell stocks?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            How to get verified my account online?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I forget my ID or Password?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>
                                                        <h4 className="head">
                                                            What if I see password not match again and again?
                                                        </h4>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        No.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane> */}
                                    {/* <Tab.Pane className='contBack' eventKey="third">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane> */}
                                    {/* <Tab.Pane className='contBack' eventKey="fourth">
                                        <div className="cont">
                                            <Accordion defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </>
    )
}

export default Queries