import React, { Component } from 'react'
import Header from './Header'
import BondsDisc from './BondsDisc'
import { Helmet } from 'react-helmet'
import AnimatedSection from '../../Defaults/Animations/AnimatedSection'


export class Bonds extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Bonds || Sovereign Gold Bond Scheme (SGB) - Personal Banking</title>
        </Helmet>
        <div className="wrapper gold-bonds">
          <AnimatedSection>
            <Header />
          </AnimatedSection>
          <AnimatedSection>
            <BondsDisc />
          </AnimatedSection>
        </div>
      </>
    )
  }
}

export default Bonds