import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const PositionSizeCalculator = () => {
    const [accountSize, setAccountSize] = useState('');
    const [riskPercentage, setRiskPercentage] = useState('');
    const [entryPrice, setEntryPrice] = useState('');
    const [stopLossPrice, setStopLossPrice] = useState('');
    const [positionSize, setPositionSize] = useState(0);

    const calculatePositionSize = () => {
        const account = parseFloat(accountSize);
        const risk = parseFloat(riskPercentage);
        const entry = parseFloat(entryPrice);
        const stopLoss = parseFloat(stopLossPrice);

        const positionSizeValue = (account * risk) / (entry - stopLoss);

        setPositionSize(positionSizeValue);
    };

    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Growth Calculators || Position Size Calculator
                </title>
            </Helmet>
            <section className="allCalcBack">
                <Container className='allCalcScss'>
                    <ul className="breadcrumbLinks">
                        <li className="breadcrumb-link">
                            <i className='fa fa-home' /> <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link">
                            <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
                        </li>
                        <li className="breadcrumb-link sec">
                            <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className="breadcrumb-link next">
                            <span>Position Size</span>
                        </li>
                    </ul>
                    <div className="position-size-calculator calcBody">
                        <div className="calculator-container">
                            <h2>Position Size</h2>
                            <div className="input-group">
                                <label>Account Size:</label>
                                <input
                                    type="text"
                                    value={accountSize}
                                    onChange={(e) => setAccountSize(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Risk Percentage:</label>
                                <input
                                    type="text"
                                    value={riskPercentage}
                                    onChange={(e) => setRiskPercentage(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Entry Price:</label>
                                <input
                                    type="text"
                                    value={entryPrice}
                                    onChange={(e) => setEntryPrice(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Stop Loss Price:</label>
                                <input
                                    type="text"
                                    value={stopLossPrice}
                                    onChange={(e) => setStopLossPrice(e.target.value)}
                                />
                            </div>
                            <button onClick={calculatePositionSize}>Calculate</button>
                            <div className="result">
                                <p>Position Size: {positionSize}</p>
                            </div>
                        </div>
                        <div className="someCalcTxt">
                            <div className="paras">
                                <p className="disc">
                                    The Position Size calculator is a useful tool for traders to determine the appropriate position size for their trades. It takes into account the trader's account size, risk percentage, entry price, and stop loss price to calculate the optimal position size.
                                </p>
                                <p className="disc">
                                    The Position Size calculator helps determine the appropriate position size for a trade based on the account size, risk percentage, entry price, and stop loss price.
                                    By using this calculator, traders can ensure that they are allocating an appropriate portion of their capital to each trade based on their risk tolerance. It helps them manage their risk effectively and avoid taking on excessive positions that could potentially lead to significant losses.
                                </p>
                            </div>
                            <div className="formulaSec">
                                <h2>How to calculate Position Size?</h2>
                                <p className="disc">
                                    The formula used to calculate the position size is as follows:
                                </p>
                                <h6>
                                    Position Size = (Account Size * Risk Percentage) / (Entry Price - Stop Loss Price)
                                </h6>
                                <p className="disc">
                                    Where:
                                </p>
                                <ul>
                                    <li>
                                        <span>Account Size -</span> The total amount of capital in the trading account.
                                    </li>
                                    <li>
                                        <span>Risk Percentage -</span> The percentage of the account balance that is risked on a single trade.
                                    </li>
                                    <li>
                                        <span>Entry Price -</span> The price at which the trade is entered.
                                    </li>
                                    <li>
                                        <span>Stop Loss Price -</span> The price at which the trade will be exited if the market moves against the expected direction.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default PositionSizeCalculator;
