import { Container, Button, Card } from "react-bootstrap";
import React from "react";
import "./Style.scss";
import education from "./Icons/education.jpg";
import investment from "./Icons/investment.jpg";
import secure from "./Icons/secure.jpg";
import confidence from "./Icons/confidence.jpg";

function ReferCard() {
  return (
    <>
      <section className="referCardSection">
        <h1 style={{ textAlign: "center" }}>Read Latest Articles</h1>
        <Container>
          <div className="cardContainer">
            <Card>
              <Card.Img variant="top" src={investment} />
              <Card.Body>
                <Card.Title>Exciting Investment Opportunities</Card.Title>
                <Card.Text>
                  Explore a world of investment possibilities with Growth
                  Securities. Our experts provide insights and strategies for
                  your financial success.
                </Card.Text>
                <Button className="btn btn-primary" variant="primary">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="cardContainer">
            <Card>
              <Card.Img variant="top" src={education} />
              <Card.Body>
                <Card.Title>Financial Education Hub</Card.Title>
                <Card.Text>
                  Join our platform to access a treasure trove of financial
                  knowledge. Enhance your understanding of the stock market and
                  investments.
                </Card.Text>
                <Button className="btn btn-primary" variant="primary">
                  Get Started
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="cardContainer">
            <Card>
              <Card.Img variant="top" src={confidence} />
              <Card.Body>
                <Card.Title>Invest with Confidence</Card.Title>
                <Card.Text>
                  We offer tools and resources to boost your confidence in
                  investing. Make informed decisions and watch your investments
                  grow.
                </Card.Text>
                <Button className="btn btn-primary" variant="primary">
                  Start Investing
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="cardContainer">
            <Card>
              <Card.Img variant="top" src={secure} />
              <Card.Body>
                <Card.Title>Secure Your Financial Future</Card.Title>
                <Card.Text>
                  Plan for a secure financial future with Growth Securities. Our
                  financial advisors are here to guide you every step of the
                  way.
                </Card.Text>
                <Button className="btn btn-primary" variant="primary">
                  Consult an Advisor
                </Button>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </section>
    </>
  );
}

export default ReferCard;
