import React, { Component } from 'react'
import {
  Row,
  Col,
  Container
} from 'react-bootstrap'
import ai from './Icons/airobot.svg'
import invest from './Icons/financeblog.svg'
import hft from './Icons/hft.svg';



export class Blogs extends Component {
  render() {
    return (
      <>
        <section className='blog_section-container'>
          <Container className='blogs_section'>
            <div className="paraTitle">
              <h1 className='title'>
                Read Our Latest <span>Blogs</span>
              </h1>
            </div>
            <Row className='blogs_section-rows'>
              <Col lg={4} md={6} sm={12} className='blog_section blog-1'>
                <div className="inner_blog-card">
                  <div className="img-box">
                    <img
                      className='blog-img img-1'
                      src={ai}
                      alt='Artificial intelligence growth'
                      width={300}
                    />
                  </div>
                  <h4 className='subtitle'>
                    AI Impacting the Stock Market?
                  </h4>
                  <h6 className='date'>
                    Saturday Jan 25<sup>th</sup> 2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                  </h6>
                  <p className='disc'>
                    When it comes to finding the right stock, a lot of research goes into it. For instance,
                    you have to analyze financial statements, revenue, P/E ratio, dividends paid, profit,
                    loss, and whatnot. ...
                  </p>
                  <a href='/artificial-intelligence-trading' className='trade'>Read More</a>
                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className='blog_section blog-2'>
                <div className="inner_blog-card">
                  <div className="img-box">
                    <img
                      className='blog-img img-2'
                      src={invest}
                      alt='Artificial intelligence growth'
                      width={300}
                    />
                  </div>
                  <h4 className='subtitle'>
                    Reason to choose Algo Trading.
                  </h4>
                  <h6 className='date'>
                    Wednesday Sep 14<sup>th</sup> 2022 <i className="fa fa-calendar" aria-hidden="true"></i>
                  </h6>
                  <p className='disc'>
                    Algorithmic trading is a modern and advanced trading method that uses computer 
                    algorithms to execute trades based on specific rules and guidelines. It has gained immense popularity
                  </p>
                  <a href='/blog-algorithmic-trading' className='trade'>Read More</a>
                </div>
              </Col>
              <Col lg={4} md={12} sm={12} className='blog_section blog-3'>
                <div className="inner_blog-card">
                  <div className="img-box">
                    <img
                      className='blog-img img-3'
                      src={hft}
                      alt='Artificial intelligence growth'
                      width={300}
                    />
                  </div>
                  <h4 className='subtitle'>
                  Exploring H.Frequency Trading.
                  </h4>
                  <h6 className='date'>
                  Thursday Jan 12<sup>th</sup> 2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                  </h6>
                  <p className='disc'>
                    High-Frequency Trading (HFT) is a technique that involves using high-speed algorithms to 
                    buy and sell financial securities in milliseconds or even microseconds. HFT has become 
                  </p>
                  <a href='/blog-high-frequency-trading' className='trade'>Read More</a>
                </div>
              </Col>
            </Row>
            {/* <div className='more-btnn'>
              <a className='btn btn-m' href='blogs'>
                <span>Read More Blogs </span>
              </a>
            </div> */}
          </Container>
        </section>
      </>
    )
  }
}

export default Blogs;