import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import pay from '../Icons/pay.svg'

export class Pay extends Component {
    render() {
        return (
            <>
                <div className='paymet-conatainer'>
                    <Container className='payment'>
                        <Row className='terms-rows'>
                            <Col lg={6} md={12} sm={12} className='term-text'>
                                <h2 className='subtitle'>
                                    Online Payment by Client
                                </h2>
                                <p className='disc'>
                                    Growth has availed services of payment gateways, banks and other approved
                                    service providers for receiving funds from its registered clients through
                                    online payment. The link for making such online payment is available in
                                    client’s own back office login, mobile trading application as well as on
                                    the company website <a href='https://www.growthsec.com'>www.growthsec.com</a>
                                </p>
                                <p className='disc'>
                                    The client making payment to Growth through any of such service provider agrees
                                    to the following terms and conditions:
                                </p>
                                <ul>
                                    <li className='list'>
                                        The client is making payment from his/her/its own bank account
                                        which is preregistered with Growth as part of his/her/its KYC.
                                    </li>
                                    <li className='list'>
                                        The client is making payment out of his/her/its own funds.
                                    </li>
                                    <li className='list'>
                                        The client is aware of the provisions of Prevention of Money
                                        Laundering Act and declares that he/she/it is not violating any
                                        provisions while making such online payment.
                                    </li>
                                </ul>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <img
                                    className=''
                                    src={pay}
                                    alt='Growth pay illustration'
                                    style={{ marginTop: "-50px" }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Pay