import React, { Component } from 'react'
import './Style.scss'
import {
    Col,
    Container,
    Row,
    Accordion,
    Table
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import rules from '../Icons/rules.jpg'
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'


export class ArbitrationRules extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Growth Securities || Arbitration Rules</title>
                </Helmet>
                <div className="arbitration_rules-container">
                    <Container className='arbitration_rules'>
                        <AnimatedSection>
                            <h1 className="title">
                                NSE <span>ARBITRATION</span> RULES
                            </h1>
                        </AnimatedSection>
                        <AnimatedSection>
                            <Row className='arbitration_rules-row'>
                                <Col lg={8} md={12} sm={12} className='arbitration_rules-texts term-text'>
                                    <h4 className="disc">
                                        <span>Arbitration</span> is an alternative dispute resolution mechanism provided by the Exchange for resolving disputes between the trading members and between trading members & constituents (i.e. clients of trading members), in respect of trades done on the Exchange. This process of resolving a dispute is comparatively faster than other means of redressal.
                                    </h4>
                                </Col>
                                <Col lg={4} md={12} sm={12} className='arbitration_rules-img'>
                                    <img
                                        className='rule-img'
                                        src={rules}
                                        alt='Growth rules illustration'
                                        width='100%'
                                    />
                                </Col>
                            </Row>
                        </AnimatedSection>
                    </Container>
                    <div className="arbitration_rules_qa-section">
                        <Container className='arbitration_rules'>
                            <Row className='arbitration_rules-rows colored-background'>
                                <AnimatedSection>
                                    <h2 className="subtitle">
                                        Read More From <span>Q/A</span>
                                    </h2>
                                </AnimatedSection>
                                <Col lg={12} md={12} sm={12} className='arbitration-rules-qa'>
                                    <Accordion className='accordion' defaultActiveKey='0' flush>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='0'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Who are the persons who can act as Arbitrators?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The Exchange provides a list of eligible persons approved by SEBI for each of the Regional Arbitration centres. Persons who form part of the list of Arbitrators are the ones who possess an expertise in their respective fields including banking, finance, legal (judges) and capital market areas (brokers).
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='1'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Who can avail the facility of arbitration on the Exchange?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The facility of arbitration on the Exchange can be availed by:
                                                    </p>
                                                    <ul className="lists">
                                                        <li className="list">
                                                            Investors who have dealt on the Exchange through its trading members and who possess a valid contract note issued by the trading member of the Exchange.
                                                        </li>
                                                        <li className="list">
                                                            Investors who have dealt on the Exchange through registered sub-brokers of the trading members of NSE and who possess valid sale/purchase note issued by the registered sub-broker.
                                                        </li>
                                                        <li className="list">
                                                            Trading members who have a claim, dispute or difference with another trading member or a constituent.
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='2'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        What is the period for filing an application for arbitration in the event of there being a claim, difference or dispute?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        An application for arbitration has to be filed within 6 months from the date of dispute.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='3'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        What is the format for filing an application for arbitration by a constituent/trading member against the trading member/constituent?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The trading member/constituent should submit an application to the Exchange for arbitration in Form No. I (three copies in case of sole arbitrator and five copies in case of panel of arbitrators) along with the following documents:
                                                    </p>
                                                    <ul className="lists">
                                                        <li className="list">
                                                            Statement of Case (containing all the relevant details about the dispute and the relief sought)
                                                        </li>
                                                        <li className="list">
                                                            Statement of Accounts
                                                        </li>
                                                        <li className="list">
                                                            Copies of Member - Constituent Agreement
                                                        </li>
                                                        <li className="list">
                                                            Copies of the relevant Contract Notes, Sale/Purchase Notes (in case of registered sub-broker) and Bills
                                                        </li>
                                                        <li className="list">
                                                            A cheque/pay order/demand draft for the deposit payable at the Regional Arbitration Centre in favour of National Stock Exchange of India Limited.
                                                        </li>
                                                        <li className="list">
                                                            Form No.II enlisting the names of arbitrators selected by the applicant from the list of eligible persons provided by the Exchange.
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='4'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Where are the arbitration cases conducted by the Exchange?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        At present, arbitration is conducted by the Exchange at Mumbai, Delhi, Calcutta and Chennai. They are referred to as Regional Arbitration Centers (RAC)s. The states covered by each RAC are as follows:
                                                    </p>
                                                    <p className="disc">
                                                        Seats of Arbitration - REGIONAL ARBITRATION CENTRES States covered by the RAC
                                                    </p>
                                                    <ul className="lists">
                                                        <li className="list">
                                                            <b>DELHI :</b> Delhi, Haryana, Uttar Pradesh, Himachal Pradesh, Punjab, Jammu & Kashmir, Chandigarh, Rajasthan.
                                                        </li>
                                                        <li className="list">
                                                            <b>CALCUTTA :</b> West Bengal, Bihar, Orissa, Assam, Arunachal Pradesh, Mizoram, Manipur, Sikkim, Meghalaya, Nagaland, Tripura.
                                                        </li>
                                                        <li className="list">
                                                            <b>CHENNAI :</b>CHENNAI : Andhra Pradesh, Karnataka, Kerala, Tamilnadu, Andaman & Nicobar, Lakshadweep, Pondicherry.
                                                        </li>
                                                        <li className="list">
                                                            <b>MUMBAI :</b> Maharashtra, Gujarat, Goa, Daman, Diu, Dadra & Nagar Haveli, Madhya Pradesh.
                                                        </li>
                                                    </ul>
                                                    <p className="disc">
                                                        A person has to approach the concerned RAC as mentioned above
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='5'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Are there any criteria for filing the application at any particular Regional Arbitration Centre(RAC) ?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The criteria for filing an application at a RAC are Parties To Dispute Place of filing the application for Arbitration Place of hearing TM* v/s TM*
                                                    </p>
                                                    <ul className="lists">
                                                        <li className="list">
                                                            If the dealing offices of both the trading members from where the dealing was done is situated in any one of the states covered by a particular RAC then the application for arbitration should be filed by the Applicant - Trading Member in that RAC.
                                                        </li>
                                                        <li className="list">
                                                            If the dealing offices of both the trading members from where the dealing was carried out is situated in states covered by different RACs then the application for arbitration should be filed in the RAC covering the state in which the Respondent- Trading Member's dealing office is situated. The hearing shall be held at the RAC where the Applicant - Trading member has filed the application for arbitration and the Respondent - Trading Member should attend the hearing in that particular RAC.TM* v/s C* & C* v/s TM* The application for arbitration should be filed by the applicant at the RAC covering the state in which the dealing office of the trading member is situated. The hearing shall be held in the RAC where the applicant has filed the application for arbitration and the respondent should attend the hearing in that particular RAC.*"TM" stands for "Trading Member" and "C" stands for "Constituent".
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='6'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        What is the amount of deposits to be given to the Exchange by a trading member for filing an application for arbitration?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        Based on the claim amount, the trading member is required to furnish deposit to the Exchange whether they are an applicant or a respondent.
                                                    </p>
                                                    <Table bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>No Amount of claim</th>
                                                                <th>Amount of Deposit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    1. Upto Rs.5 lakhs
                                                                </td>
                                                                <td>Rs.6,000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2. Rs.5 lakhs and above and upto Rs.25 lakhs</td>
                                                                <td>Rs.8,000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3. Above Rs.25 lakhs</td>
                                                                <td>Rs.12,000</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <p className="disc">
                                                        Besides the above-mentioned deposits, the Exchange may call for additional deposits if in its opinion the deposits made are insufficient to cover the expenses of arbitration. The additional deposits will form a part of the deposits and shall be dealt with accordingly.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='7'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Who has dealt on the Exchange through its trading member give a deposit to the Exchange for filing an application for arbitration?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        In case an investor (constituent of the trading member) is an applicant or respondent then he/she is required to furnish deposit to the Exchange based on the claim amount as under:
                                                    </p>
                                                    <Table bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>No Amount of claim
                                                                </th>
                                                                <th>Amount of Deposit
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1. Upto Rs.5 lakhs</td>
                                                                <td>Nil*</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2. Rs.5 lakhs and above and upto Rs.25 lakhs</td>
                                                                <td>Rs.6,000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3. Above Rs.25 lakhs</td>
                                                                <td>Rs.12,000</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <p className="disc">* Upto a claim of Rs 5 lakhs, the expenses for arbitration are borne by the Exchange on behalf of the constituent.</p>
                                                    <p className="disc">
                                                        Besides the above-mentioned deposits, the Exchange may call for additional deposits if the deposits made are insufficient to cover the expenses of arbitration. The additional deposits will form a part of the deposits and shall be dealt with accordingly.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='8'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        What are the other fees, charges, costs to be borne by the parties to the dispute?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        All fees and charges relating to the appointment of the arbitrator and conduct of arbitration proceedings shall be borne by the parties to the reference equally or in such proportions as may be decided by the Arbitrator. Such fees/charges are recovered from the deposits made while filing the arbitration application & balance amount, if any, is refunded to the parties. The costs, if any, to be awarded to either of the parties in addition to the fees and charges that have to be borne by the parties for conducting the arbitration, shall be decided by the Arbitrator. Unless the arbitrator directs otherwise, each party shall bear their own expenses of traveling and other incidental expenses incurred.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='9'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        How is an Arbitrator appointed?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <ul className="lists">
                                                        <li className="list">
                                                            The appointment of an Arbitrator is linked to the claim amount. If the claim amount is Rs.25 lakhs or less, then a Sole Arbitrator is appointed and if the claim amount is more than Rs.25 lakhs, then a panel of three arbitrators is appointed.
                                                        </li>
                                                        <li className="list">
                                                            The parties to arbitration should submit to the Exchange the names (in order of descending preferences) from the list of eligible persons specified by the Exchange to act as arbitrators for the respective RAC. The number of persons to be so selected by the parties shall be seven if the RAC is Mumbai and five in Delhi, Calcutta and Chennai. Upon receiving the Form No.II from both the parties, the Exchange identifies the most common Sole Arbitrator or Panel of Arbitrators to whom the highest preference has been given by both the parties.
                                                        </li>
                                                        <li className="list">
                                                            In case no common Sole Arbitrator or Panel of Arbitrators is/are identifiable, the Exchange shall select the Sole Arbitrator or Panel of Arbitrators from the list of persons eligible to act as arbitrators.
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='10'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Can one request for a hearing in case the claim difference or dispute is a small amount?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        No hearing is afforded to the parties to the dispute if the value of the claim difference or dispute is Rs.25,000 or less. However, if the value of claim, difference or dispute is more than Rs.25,000, the arbitrator shall offer to hear the parties to the dispute unless both parties waive their rights for such hearing in writing.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='11'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Can the Sole Arbitrator or Panel of Arbitrators grant an adjournment of hearing?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        Yes, if either of the parties want an adjournment of hearing, they should make an application in writing to the Exchange giving reasons for seeking adjournment to the Exchange in advance to enable the Exchange to forward such request to the arbitrator. The arbitrator at his/her discretion may grant the adjournment subject to such conditions as deemed fit by him/her.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='12'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        How is an award made by the Sole Arbitrator/Panel of Arbitrators?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        An Award shall be given in writing and made in three originals duly dated and signed by the Sole Arbitrator or in case of a Panel of Arbitrators by all the three arbitrators.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='13'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Can the parties to the dispute request for interest on the claim amount?
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The arbitrator may include interest on the claim amount for which the award is made at a particular rate of interest and period as the arbitrator deems reasonable.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='14'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        Is there any appeal provision for arbitration at the Exchange?

                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        No, there is no appeal provision for arbitration at the Exchange. However, the aggrieved party can make an application to the appropriate court to set aside the award given by a Sole Arbitrator or Panel of Arbitrators, under the provisions of the Arbitration and Conciliation Act, 1996.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                        <AnimatedSection>
                                            <Accordion.Item className='accordion_item' eventKey='15'>
                                                <Accordion.Header className='accordion_header'>
                                                    <h4 className="head">
                                                        When does the Award become a decree?

                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body className='accordion_body'>
                                                    <p className="disc">
                                                        The award becomes a decree when the time for making an application to set aside the award has expired i.e. after expiry of 3 months from the date of receipt of award or the application for setting aside the Award having been made, has been refused by the appropriate court. In such a case the award shall be enforced as if it were a decree of the Court under the provisions of the Arbitration and Conciliation Act 1996. Disclaimer: The information contained herein is subject to change without prior notice. While every effort is made to ensure accuracy and completeness of information contained, the Exchange makes no guarantee and assumes no liability for any errors or omissions of the information. No one can use the information for any claim, demand or cause of action. Please refer to relevant regulations and circulars in case of specific cases and problems.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </AnimatedSection>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
}

export default ArbitrationRules