import React from 'react'
import { Container, Table } from 'react-bootstrap'

function KMP() {
    return (
        <>
            <section className="kmpContainer">
                <Container className='kmp'>

                    <div className="detailsContainer">
                        <h3>
                            Key Managerial Personnel
                        </h3>
                        <Table hover striped bordered className='kmpTable'>
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    <th>Name of Individual</th>
                                    <th>Designation</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Aryan Birla</td>
                                    <td>Head of Customer Care</td>
                                    <td>9599543216</td>
                                    <td>support@growthsec.com</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>Himanjal Brahambhatt</td>
                                    <td>Compliance Officer</td>
                                    <td>9311786339</td>
                                    <td>Compliance@growthsec.in</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>Devansh Gupta</td>
                                    <td>CEO</td>
                                    <td>011-43140000</td>
                                    <td>devansh@growthsec.in</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default KMP