import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import notice from '../Icons/notice.svg'



export class Notice extends Component {
    render() {
        return (
            <section className='notice-container'>
                <Container className='refund notice'>
                    <Row className='notice-rows'>
                        <Col lg={6} md={12} sm={12} className='notice-img'>
                            <img
                                className=''
                                src={notice}
                                alt='Growth notice illustration'
                            />
                        </Col>
                        <Col lg={6} md={12} sm={12} className='notice-text term-text'>
                            <div className="notices">
                                <h2 className='subtitle'>
                                    Notice
                                </h2>
                                <p className="disc">
                                    All notices, correspondences or communications issued under this agreement shall be
                                    served in any one or more of the following modes of communications and such notice
                                    or communication shall be served at the ordinary place of residence and/or last known
                                    web address / residing address and / or at the ordinary business address of the party
                                    to this agreement such as -
                                </p>
                                <ul>
                                    <li className="list">
                                        By hand
                                    </li>
                                    <li className="list">
                                        Delivery by post
                                    </li>
                                    <li className="list">
                                        By registered post
                                    </li>
                                    <li className="list">
                                        Under certificate of posting
                                    </li>
                                    <li className="list">
                                        By email or fax
                                    </li>
                                    <li className="list">

                                    </li>
                                    <li className="list">
                                        By affixing it on the door at the last known business or residential address.
                                    </li>
                                    <li className="list">
                                        By oral communication to the party or on the last known telephone number
                                        or on the recording machine of such number.
                                    </li>
                                    <li className="list">
                                        By advertising in at least one prominent daily newspaper having circulation
                                        in the area where the last known business or residential address of the party is situated.
                                    </li>
                                    <li className="list">
                                        By notice posted on the notice board of the Exchange if no address is known. Any
                                        communication sent by GSPL to the Client shall be deemed to have been properly delivered
                                        or served, if such communication is returned on GSPL as unclaimed / refused /
                                        undelivered, if the same was sent in any one more of the above modes of communication
                                        to the ordinary place of residence and / or last known web address /residing address
                                        and / or at the ordinary business address of the party to this agreement.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Notice;