import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import sip from './Icons/Imgs/sip.jpg'
import algotrade from './Icons/algotrade.jpg'
import ai from '../Home/Icons/ai.jpg'
import hft from './Icons/Images/hft.jpg'
import hft2 from './Icons/Images/hft2.jpg'
import autotrade from './Icons/Images/algotrade2.jpg'




export class SIP extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Blogs || SIP || Systematic Investment Plan || What is SIP and its 10 Benefits: Why You Should Start Investing Today
                    </title>
                </Helmet>
                <section className="blogSection-container">
                    <div className="backgroundClr"></div>
                    <Container className="blogContainer">
                        <Row className="blogs-rows">
                            <Col lg={8} md={12} sm={12} className='blog'>
                                <div className="myBlogs">
                                    <div className="links">
                                        <a href="/high-frequency-trading" className="link">High Frequency Trading</a>
                                        <a href="algorithmic-trading" className="link padding">Algorithmic Trading</a>
                                        <a href="initial-public-offering-ipo" className="link padding">IPOs</a>
                                    </div>
                                    <h1 className="blogTitle">
                                        SIP || Systematic Investment Plan || Growthsec.com
                                    </h1>
                                    <h4 className="blogSubtitle">
                                        What is SIP and its 10 Benefits: Why You Should Start Investing Today
                                    </h4>
                                    <h6 className="date">
                                        Friday May 19<sup>th</sup> 2023 <i className="fa fa-calendar" aria-hidden="true"></i>
                                    </h6>
                                    <h6 className="time">
                                        5minutes to read <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    </h6>
                                    <img
                                        src={sip}
                                        alt="Growth Blog Illustration"
                                        width='100%'
                                        className="blogImage"
                                    />
                                    <h6 className="image">
                                        Image: <span>Freepik</span>
                                    </h6>
                                    <p className="disc">
                                        Systematic Investment Plan (SIP) is a popular investment strategy that allows you to invest a fixed amount of money at regular intervals (weekly, monthly, or quarterly) in mutual funds. SIP investments can be made in equity, debt, or hybrid mutual funds, depending on your investment goals and risk appetite.
                                    </p>
                                    <p className="disc i">
                                        SIP investments are made using the concept of rupee cost averaging, which means that you buy more units of a mutual fund when the market is down and fewer units when the market is up. This helps to average out your cost of investment over time and reduce the impact of market volatility on your portfolio.
                                    </p>
                                    <p className="quote">
                                        "Successful investing is about managing risk, not avoiding it. SIP is a disciplined approach to investing that helps you manage risk and achieve your financial goals over time."
                                    </p>
                                    <p className="disc">
                                        SIP investments can be started with as little as Rs. 500 per month, making it an affordable investment option for everyone. SIP investments can be made through online platforms or by setting up a standing instruction with your bank.
                                    </p>
                                    <p className="disc">
                                        SIP investments offer several benefits over lump-sum investments, such as lower transaction costs, reduced risk exposure, and higher liquidity. SIP investments also offer tax benefits under Section 80C of the Income Tax Act, making it an attractive investment option for tax-saving purposes.
                                    </p>
                                    <p className="disc">
                                        SIP investments are a long-term investment strategy that requires patience and discipline. By investing regularly over a long period of time, you can take advantage of the power of compounding and generate significant wealth over time.
                                    </p>
                                    <p className="disc">
                                        Are you looking for a smart way to grow your wealth? Look no further than Systematic Investment Plan (SIP). SIP is a disciplined approach to investing in mutual funds that offers several benefits. Here are ten reasons why you should consider SIP as your investment strategy.
                                    </p>
                                    <h4 className="subtitle">
                                        Power of Compounding
                                    </h4>
                                    <p className="disc">
                                        " Small investments today, big returns tomorrow."  SIP allows you to invest small amounts of money regularly, which can add up over time and compound to generate significant returns.
                                    </p>

                                    <h4 className="subtitle">
                                        Rupee Cost Averaging
                                    </h4>
                                    <p className="disc">
                                        "Smooth out the ups and downs of the market." SIP allows you to buy more units when the market is down and fewer units when the market is up, which averages out your cost of investment.
                                    </p>
                                    <h4 className="subtitle">
                                        Disciplined Investing
                                    </h4>
                                    <p className="disc">
                                        "Stay the course, reap the rewards." SIP instills discipline in your investment approach, ensuring that you invest regularly, regardless of market conditions.
                                    </p>
                                    <h4 className="subtitle">
                                        Flexibility
                                    </h4>
                                    <p className="disc">
                                        "Set it and forget it." SIP offers flexibility in terms of investment amount, frequency, and duration, allowing you to tailor your investment plan according to your financial goals and risk appetite.
                                    </p>
                                    <h4 className="subtitle">
                                        Diversification
                                    </h4>
                                    <p className="disc">
                                        "Don't put all your eggs in one basket." SIP allows you to invest in a diversified portfolio of mutual funds, reducing your risk exposure and increasing your chances of generating higher returns.
                                    </p>
                                    <h4 className="subtitle">
                                        Professional Management
                                    </h4>
                                    <p className="disc">
                                        "Leave it to the experts." SIP funds are managed by professional fund managers who have expertise in selecting the right stocks and managing risk, ensuring that your investments are in good hands.
                                    </p>
                                    <h4 className="subtitle">
                                        Low Cost
                                    </h4>
                                    <p className="disc">
                                        "Save more, invest more." SIP has lower transaction costs compared to lump-sum investments, allowing you to save more and invest more.
                                    </p>
                                    <h4 className="subtitle">
                                        Tax Benefits
                                    </h4>
                                    <p className="disc">
                                        "Save taxes while you grow wealth." SIP investments are eligible for tax benefits under Section 80C of the Income Tax Act, allowing you to save taxes while you grow your wealth.
                                    </p>
                                    <h4 className="subtitle">
                                        Liquidity
                                    </h4>
                                    <p className="disc">
                                        "Get your money when you need it." SIP investments offer high liquidity, allowing you to redeem your investments whenever you need money.
                                    </p>
                                    <h4 className="subtitle">
                                        Long-term Wealth Creation
                                    </h4>
                                    <p className="disc">
                                        "Plant the seeds today, reap the benefits tomorrow." SIP is a long-term investment strategy that can help you create wealth over time and achieve your financial goals.
                                    </p>
                                    <h3 className="whatIs">
                                        Conclusion
                                    </h3>
                                    <hr width='100%' />
                                    <p className="disc definition">
                                        SIP is suitable for investors of all ages and risk profiles. By starting your SIP today and investing regularly over a long period of time, you can achieve your financial goals and create long-term wealth.
                                        SIP is a smart investment strategy that offers several benefits. By starting your SIP today, you can take advantage of the power of compounding, rupee cost averaging, disciplined investing, flexibility, diversification, professional management, low cost, tax benefits, liquidity, and long-term wealth creation. So what are you waiting for? Start your SIP today with Growth Securities and watch your wealth grow over time!
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4} md={0} sm={0} className='blogSide'>
                                <div className="allBlogs right">
                                    <a className="blogPost" href='/blog-algorithmic-trading'>
                                        <img src={algotrade} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle active">
                                                5 Reasons to Choose Algorithmic Trading for Your Investment Strategy in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/blog-automated-trading'>
                                        <img src={autotrade} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                                See How Automated Trading Influence the Market Efficiency & its Impact on Market Efficiency in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/more-about-high-frequency-trading'>
                                        <img src={hft} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                                Everything you must know about High-Frequency Trading in 2023
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/blog-high-frequency-trading'>
                                        <img src={hft2} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                                Exploring the Rise of High-Frequency Trading: Why Investors are drawn to it.
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                    <a className="blogPost" href='/artificial-intelligence-trading'>
                                        <img src={ai} alt="Growth Blog Post" className="blogPostImg" />
                                        <div className="txts">
                                            <h6 className="blogPostTitle">
                                                AI trading is a growing part of the fintech industry. These companies are paving the way.
                                            </h6>
                                            <p className="blogPostTime"></p>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default SIP