import React from "react";
import { Container, Table } from "react-bootstrap";
import "./Style.scss";
import { Helmet } from "react-helmet";
import investorCharter1 from "../PDFs/investorCharter.pdf";
import investorCharterSEBI from "../PDFs/InvestorCharterSEBI.pdf";
import investorCharterCDSL from "../PDFs/InvestorCharterCDSL.pdf";
import investorCharter2 from "../PDFs/investor_charter_758_2021.pdf";

function InevstorCharters() {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Investor Charter</title>
      </Helmet>
      <div className="wrapper investorCharterSection">
        <Container className="investorChater">
          <Table bordered striped hover className="table">
            <thead>
              <tr>
                <th>SN</th>
                <th>Name</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>Investor Charter (DP)</td>
                <td>
                  <a href={investorCharter1}>
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>Investor Charter NSE</td>
                <td>
                  <a href="https://www.nseindia.com/invest/investor-charter">
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>Investor Charter BSE</td>
                <td>
                  <a href="https://www.bseindia.com/static/investors/investor_charter.aspx">
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>Investor Charter MCX</td>
                <td>
                  <a href="https://www.mcxindia.com/Investor-Services/grievances/client-awareness/investors-charter">
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>Investor Charter SEBI</td>
                <td>
                  <a href={investorCharterSEBI}>
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>Investor Charter CDSL</td>
                <td>
                  {/* <a href={investorCharterCDSL}>
                                        <i className="fa fa-file-pdf-o"></i>
                                    </a> */}
                  <a
                    href="http://growthsec.com/cdsl-investor-charter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td>Investor Charter (Stock Broker)</td>
                <td>
                  <a href={investorCharter2}>
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
}

export default InevstorCharters;
