import React, { useState, useEffect } from 'react';
import './Style.scss';

const StockMarket = () => {
    const [stockData, setStockData] = useState(null);
    const [ipoCalendar, setIpoCalendar] = useState([]);
    const apiKey = '58MDI0VJMGOK761K';
    const symbol = 'AAPL'; // Replace with a valid stock symbol

    useEffect(() => {
        // Fetch stock market data from Alpha Vantage
        fetch(`https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${symbol}&interval=5min&apikey=${apiKey}`)
            .then((response) => response.json())
            .then((data) => {
                // Process and set the data as needed
                setStockData(data);
            })
            .catch((error) => {
                console.error('Error fetching stock data:', error);
            });

        // Fetch IPO calendar data for India
        fetch(`https://www.alphavantage.co/query?function=IPO_CALENDAR&apikey=${apiKey}&country=India`)
            .then((response) => response.json())
            .then((data) => {
                // Process and set the IPO calendar data
                setIpoCalendar(data['IPO Calendar']);
            })
            .catch((error) => {
                console.error('Error fetching IPO calendar:', error);
            });
    }, [apiKey, symbol]);

    return (
        <div className="stock-market-container">
            <div className="stock-data">
                {stockData && (
                    <>
                        <h2>{stockData['Meta Data']['2. Symbol']}</h2>
                        <p>Open: {stockData['Time Series (5min)'][Object.keys(stockData['Time Series (5min)'])[0]]['1. open']}</p>
                        <p>High: {stockData['Time Series (5min)'][Object.keys(stockData['Time Series (5min)'])[0]]['2. high']}</p>
                        <p>Low: {stockData['Time Series (5min)'][Object.keys(stockData['Time Series (5min)'])[0]]['3. low']}</p>
                        <p>Close: {stockData['Time Series (5min)'][Object.keys(stockData['Time Series (5min)'])[0]]['4. close']}</p>
                    </>
                )}
            </div>

            <div className="ipo-calendar">
                <h2>Upcoming IPOs in India</h2>
                <ul>
                    {ipoCalendar.map((ipo, index) => (
                        <li key={index}>
                            <strong>{ipo.name}</strong>
                            <p>{ipo.date}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default StockMarket;
