import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Description() {
    return (
        <section className="fiidii-description">
            <Container>
                <Row>
                    <Col>
                        <div className="paraTitle">
                            <h1 className="title">
                                What are <span>FII</span> and <span>DII</span>?
                            </h1>
                        </div>
                        <p>
                            Foreign Institutional Investors (FIIs) and Domestic Institutional Investors (DIIs) are pivotal players in the financial markets, each with a distinct role:
                        </p>
                        <h3>Foreign Institutional Investors (FIIs)</h3>
                        <p>
                            FIIs are institutional investors from outside the country who channel their investments into India's financial markets. This category includes mutual funds, pension funds, hedge funds, and other significant investment firms. FIIs inject foreign capital into the Indian markets, influencing stock prices, exchange rates, and overall market sentiment.
                        </p>
                        <h3>Domestic Institutional Investors (DIIs)</h3>
                        <p>
                            DIIs, in contrast, are institutional investors based within India. They encompass mutual funds, insurance companies, banks, and various financial institutions. DIIs diversify their investments across different asset classes, including stocks and bonds. Their investment decisions wield substantial influence over the Indian stock market.
                        </p>
                        <p>
                            Keeping a close eye on the trading activities of FIIs and DIIs is crucial for both investors and market analysts. This monitoring offers valuable insights into the behavior of these institutional players and aids in anticipating market trends.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Description;
