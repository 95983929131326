import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import feature from './Icons/features.svg'




export class Features extends Component {
    render() {
        return (
            <>
                <section className="features_section-container">
                    <Container className="features_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            Features of <span>Commodity Trading</span>
                        </h1>
                        </div>
                        <Row className="features_section-rows">
                            <p className="disc last_para">
                                Growth is an excellent choice for commodity trading. With a comprehensive trading platform, an experienced team of experts, low trading costs, and a highly regulated environment, Growth provides traders with the resources and support they need to succeed in the commodity market.
                            </p>
                            <Col lg={4} md={12} sm={12} className="features_section-text">
                                <div className="feature_boxes">
                                    <div className="feature_box">
                                        <h5 className="heading">
                                            Liquidity
                                        </h5>
                                        <p className="disc">
                                            Growth in the commodity trading market provide high liquidity as they are actively traded.
                                        </p>
                                    </div>
                                    <div className="feature_box">
                                        <h5 className="heading">
                                            Diversification
                                        </h5>
                                        <p className="disc">
                                            Commodity trading Growth can offer diversification benefits to investors, reducing the risk of a portfolio being overly exposed to a single asset class.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} className='features_section-img'>
                                <div className="img">
                                    <img
                                        src={feature}
                                        alt="Growth feature illustration"
                                        className="feature-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12} className='features_section-text'>
                                <div className="feature_boxes">
                                    <div className="feature_box">
                                        <h5 className="heading">
                                            Access to commodity markets
                                        </h5>
                                        <p className="disc">
                                            These securities provide investors with access to the commodity markets without requiring direct commodity ownership.
                                        </p>
                                    </div>
                                    <div className="feature_box">
                                        <h5 className="heading">
                                            High returns
                                        </h5>
                                        <p className="disc">
                                            Commodity trading with growth securities can provide higher returns than traditional investments, particularly in economic growth and increasing commodity prices.
                                        </p>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Features