import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NiftyShortTermTrend from './NiftyShortTermTrend/NiftyShortTermTrend'

function GraphTable() {
    return (
        <>
            <section className="graphTableSectionContainer">
                <Container className='graphTable'>
                    <div className="graphTableContainer">
                        <NiftyShortTermTrend />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default GraphTable