import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import Withdraw from './Withdraw';
import BankDetailsContainer from '../BankDetails/BankDetailsContainer';

function CFunds() {
    return (
        <>
            <section className="cFundsSection">
                <Container className="cFundsContainer">
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="cFundsTabs"
                        className="cFundsTabs mb-3"
                    >
                        <Tab eventKey="home" title="Deposite funds">
                            <div className="tab-content">
                                <BankDetailsContainer />
                            </div>
                        </Tab>
                        <Tab eventKey="withdraw" title="Withdraw funds">
                            <div className="tab-content">
                                <Withdraw />
                            </div>
                        </Tab>
                    </Tabs>
                </Container>
            </section>
        </>
    );
}

export default CFunds;
