import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import feedbackForm from './FeedbackForm.svg';

function FeedbackForm() {
  const [showPopup, setShowPopup] = useState(false);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    

    // Perform necessary actions or API calls for form submission
    // Set showPopup to true to display the popup
    setShowPopup(true);
  };

  // Function to handle closing the popup and refreshing the page
  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <section className="feedbackFormContainer">
        <Container className="feedbackForm">
          <div className="paraTitle">
            <h1 className="title"><span>Feedback</span> Form</h1>
          </div>
          <Row className="feedbackFormRows">
            <Col lg={4} md={6} sm={12} xs={12} className="leftFeedback">
              <div className="imgContainer">
                <img src={feedbackForm} alt="feedback" className="img" />
              </div>
            </Col>
            <Col lg={8} md={6} sm={12} xs={12} className="leftFeedback">
              <form className="feedbackFormHere" onSubmit={handleSubmit} action='/submit' method='POST'>
                <div className="row">
                  <div className="form-group col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Name" required />
                  </div>
                  <div className="form-group col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" placeholder="Email" required />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Mobile</label>
                  <input type="text" className="form-control" id="num" placeholder="Mobile Number" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className="form-control" id="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      {showPopup && (
        <div className="popupContainer">
          <div className="popupContent">
            <h2>Thank You!</h2>
            <p>Your feedback has been submitted successfully. We appreciate your input and will use it to improve our services. Your feedback is important to us as we strive to provide the best experience for our customers. Thank you for your valuable contribution!</p>
            <button className="btn btnClose" onClick={handleClosePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackForm;
