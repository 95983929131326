import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Online instructions for execution
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Online instructions for execution internet-enabled services like Speed-e
        (NSDL) & Easiest (CDSL) empower a demat account holder in managing
        his/her securities ‘anytime-anywhere’ in an efficient and convenient
        manner and submit instructions online without the need to use paper.
        These facilities allows Beneficial Owner (BO) to submit transfer
        instructions and pledge instructions including margin pledge from their
        demat account. The instruction facilities are also available on mobile
        applications through android, windows and IOS platforms.
      </p>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const Onlineinstructionsforexecution = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        b. Online instructions for execution{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Onlineinstructionsforexecution;
