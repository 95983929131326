import React from "react";
import { Container } from "react-bootstrap";
import "./Style.scss";
import { Helmet } from "react-helmet";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";

function RiskDisclosure() {
  return (
    <>
      <Helmet>
        <title>Growth Securities || Risk Disclosure</title>
      </Helmet>
      <div className="wrapper ">
        <div className="riskDisclosureContainer">
          <Container className="riskCenter">
            <Container className="riskDisclosure">
              <AnimatedSection>
                <h1 className="title">Risk Disclosure</h1>
              </AnimatedSection>
              <AnimatedSection>
                <div className="tableRisk">
                  <div className="headerContainer">
                    <h3 className="tableHeader">
                      RISK DISCLOSURES ON DERIVATIVES
                    </h3>
                  </div>
                  <div className="bodyContainer">
                    <ul className="lists">
                      <li className="list">
                        9 out of 10 individual traders in equity Futures and
                        Options Segment, incurred net losses.
                      </li>
                      <li className="list">
                        On an average, loss makers registered net trading loss
                        close to ₹ 50,000
                      </li>
                      <li className="list">
                        Over and above the net trading losses incurred, loss
                        makers expended an additional 28% of net trading losses
                        as transaction costs.
                      </li>
                      <li className="list">
                        Those making net trading profits, incurred between 15%
                        to 50% of such profits as transaction cost
                      </li>
                    </ul>
                  </div>
                </div>
              </AnimatedSection>
              <AnimatedSection>
                <div className="footerContainer">
                  <p className="disc">Source:</p>
                  <ul className="lists">
                    <li className="list">
                      <a
                        href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
                        className="subLink"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SEBI study dated January 25, 2023 on "Analysis of Profit
                        and Loss of Individual Traders dealing in Equity F&O
                        Segment", where in Aggregate Level findings are based on
                        annual Profit/Loss incurred by individual traders in
                        equity (F&O) during FY 2021-22
                      </a>
                    </li>
                  </ul>
                </div>
              </AnimatedSection>
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RiskDisclosure;
