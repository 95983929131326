import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const CorrelationCalculator = () => {
  const [dataX, setDataX] = useState('');
  const [dataY, setDataY] = useState('');
  const [correlation, setCorrelation] = useState(null);

  const calculateCorrelation = () => {
    const xValues = dataX.split(',').map(Number);
    const yValues = dataY.split(',').map(Number);

    if (xValues.length !== yValues.length) {
      alert('Data arrays should have the same length.');
      return;
    }

    const n = xValues.length;

    const sumX = xValues.reduce((sum, value) => sum + value, 0);
    const sumY = yValues.reduce((sum, value) => sum + value, 0);

    const sumXY = xValues.reduce((sum, value, index) => sum + value * yValues[index], 0);

    const sumX2 = xValues.reduce((sum, value) => sum + value ** 2, 0);
    const sumY2 = yValues.reduce((sum, value) => sum + value ** 2, 0);

    const numerator = n * sumXY - sumX * sumY;
    const denominator = Math.sqrt((n * sumX2 - sumX ** 2) * (n * sumY2 - sumY ** 2));

    const correlationValue = numerator / denominator;
    setCorrelation(correlationValue.toFixed(4));
  };

  const handleInputChange = (e, setData) => {
    setData(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Correlation Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Correlation</span>
            </li>
          </ul>
          <div className="correlation-calculator calcBody">
            <div className="calculator-container">
              <h2>Correlation</h2>
              <div className="input-group">
                <label>Data X (comma-separated values):</label>
                <input
                  type="text"
                  className="data-x-input"
                  value={dataX}
                  onChange={(e) => handleInputChange(e, setDataX)}
                />
              </div>
              <div className="input-group">
                <label>Data Y (comma-separated values):</label>
                <input
                  type="text"
                  className="data-y-input"
                  value={dataY}
                  onChange={(e) => handleInputChange(e, setDataY)}
                />
              </div>
              <button className="calculate-button" onClick={calculateCorrelation}>
                Calculate Correlation
              </button>
              <div className="result">
                {correlation !== null && <p className="correlation-result">Correlation: {correlation}</p>}
              </div>
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  A correlation calculator measures the statistical relationship between two variables. It helps in determining the extent to which the variables move together and how strong the relationship is. Correlation values range from -1 to 1, with -1 representing a strong negative correlation, 1 representing a strong positive correlation, and 0 representing no correlation.
                </p>
              </div>
              <div className="formulaSec">
                <h2>Correlation Formula:</h2>
                <p className="disc">
                  The correlation between two variables X and Y can be calculated using the following formula:
                </p>
                <h6>
                  r = (nΣXY - ΣXΣY) / √((nΣX^2 - (ΣX)^2)(nΣY^2 - (ΣY)^2))
                </h6>
                <p className="disc">
                  Where:
                </p>
                <ul>
                  <li>
                    <span>r -</span> Correlation coefficient
                  </li>
                  <li>
                    <span>n -</span> Number of data points
                  </li>
                  <li>
                    <span>ΣX -</span> Sum of X values
                  </li>
                  <li>
                    <span>ΣY -</span> Sum of Y values
                  </li>
                  <li>
                    <span>ΣXY -</span> Sum of the product of X and Y values
                  </li>
                  <li>
                    <span>ΣX^2 -</span> Sum of the squares of X values
                  </li>
                  <li>
                    <span>ΣY^2 -</span> Sum of the squares of Y values
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CorrelationCalculator;
