import React from "react";
import { Button, Modal } from "react-bootstrap";

function TermsConditionsBody(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Referral terms & conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="lists" style={{listStyleType: "number"}}>
          <li>
            Eligibility:{" "}
            <ul style={{listStyleType: "number"}}>
              <li>
                To participate in the "Refer & Earn" program (hereinafter
                referred to as the "Program"), you must be an existing customer
                of Growth.
              </li>
              <li>
                Referrals can be made to individuals who are not currently
                clients of Growth.
              </li>
            </ul>
          </li>
          <li>
            Referral Rewards
            <ul style={{listStyleType: "number"}}>
              <li>
                For every successful referral, the referrer (existing
                customer) will be eligible to receive a reward.
              </li>
              <li>
                The reward for the referrer may include a gift voucher or a
                percentage of the brokerage sharing from the referred friend's
                trades, for life, as specified in the Program.
              </li>
              <li>
                The referred friend may be eligible for specific benefits
                as outlined in the Program.
              </li>
            </ul>
          </li>
          <li>
            Referral Process
            <ul style={{listStyleType: "number"}}>
              <li>
                To refer a friend, the referrer must use the referral link
                or code provided by Growth.
              </li>
              <li>
                The referred friend must successfully open an account with
                Growth and meet any additional criteria set forth in
                the Program.
              </li>
              <li>
                The referral is considered successful when the referred
                friend's account is successfully opened and verified.
              </li>
            </ul>
          </li>
          <li>
            Reward Distribution
            <ul style={{listStyleType: "number"}}>
              <li>
                Rewards will be distributed in accordance with the Program
                terms and within a reasonable time frame.
              </li>
              <li>
                The referrer will receive their reward using the payment
                method chosen by Growth.
              </li>
            </ul>
          </li>
          <li>
            Program Changes
            <ul style={{listStyleType: "number"}}>
              <li>
                Growth reserves the right to modify, suspend, or
                terminate the Program at any time without prior notice.
              </li>
              <li>
                Growth may update the terms and conditions of
                the Program as needed. It is the referrer's responsibility to
                stay informed of any changes.
              </li>
            </ul>
          </li>
          <li>
            Code of Conduct
            <ul style={{listStyleType: "number"}}>
              <li>
                Referrers are expected to uphold the highest standards of
                ethics and professionalism when referring friends to Growth
            .
              </li>
              <li>
                Any fraudulent activity or misuse of the Program may result
                in disqualification and the forfeiture of rewards.
              </li>
            </ul>
          </li>
          <li>
            Compliance
            <ul style={{listStyleType: "number"}}>
              <li>
                All participants in the Program must comply with applicable
                laws and regulations.
              </li>
              <li>
                Growth is not responsible for any tax liability
                that may arise from receiving rewards.
              </li>
            </ul>
          </li>
          <li>
            Limitation of Liability
            <ul style={{listStyleType: "number"}}>
              <li>
                Growth shall not be liable for any losses or
                damages resulting from the Program, except where prohibited by
                law.
              </li>
            </ul>
          </li>
          <li>
            Dispute Resolution
            <ul style={{listStyleType: "number"}}>
              <li>
                Any disputes or concerns related to the Program shall be
                resolved in accordance with Growth' policies and
                applicable laws.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          By participating in the Program, you agree to abide by these terms and
          conditions. Growth reserves the right to make the final
          determination regarding any disputes or discrepancies.
        </p>
        <p>
          For any questions or concerns regarding the Program or these terms and
          conditions, please contact Growth' customer support.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function TermsConditions() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button onClick={() => setModalShow(true)}  style={{border: "none", background: "none", color: "#000", padding: "0", margin: "0"}}>T&C</Button>

      <TermsConditionsBody
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default TermsConditions;
