import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Style.scss";
import { Helmet } from "react-helmet";

function CareerForm() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(" ");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    num: "",
    position: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const errors = [];

    if (name === "name" && value.length === 0) {
      errors.push("Please enter your name.");
    }

    if (name === "email" && !/.*@.*\..*/.test(value)) {
      errors.push("Please enter a valid email address.");
    }

    if (name === "num" && value.length !== 10) {
      errors.push("Please enter a valid 10-digit mobile number.");
    }

    setFormData({
      ...formData,
      [name]: value,
      errors,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here

    // For demonstration purposes, we'll just set the form as submitted after a short delay
    setTimeout(() => {
      setIsFormSubmitted(true);
    }, 1000);
  };

  useEffect(() => {
    let timer;
    if (isFormSubmitted) {
      timer = setTimeout(() => {
        setIsFormSubmitted(false);
      }, 60000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isFormSubmitted]);

  const isFormValid =
    formData.name !== "" &&
    formData.email !== "" &&
    formData.num !== "" &&
    formData.position !== "";

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Career || Career at Growth Securities ||
          Career at Stock Market
        </title>
      </Helmet>
      <div className="wrapper">
        <section className="formSectionContainer">
          <Container className="applyForm">
            <div className="formContainer">
              {!isFormSubmitted ? (
                <form
                  className="row g-3"
                  name="careerForm"
                  method="POST"
                  action="http://localhost:8001/server"
                  onSubmit={handleSubmit}
                >
                  <h3 className="careerHead">Apply For Open Position</h3>
                  <div className="col-md-12">
                    <select
                      className="formSelect form-select-md"
                      title="Select your desired position"
                      name="position"
                      id=""
                      required
                      onChange={handleChange}
                      value={formData.position}
                    >
                      <option defaultValue>Choose position</option>
                      <option value="1">Technical Analyst</option>
                      <option value="2">Trader</option>
                      <option value="3">Exchange Back Operation</option>
                      <option value="4">DP Operation</option>
                      <option value="5">Back Office Operation</option>
                      <option value="6">
                        Sales and Marketing Professional
                      </option>
                      <option value="7">HR Operation</option>
                      <option value="8">A/c Operation</option>
                      <option value="9">Others</option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="name"
                      className="formControl"
                      id=""
                      placeholder="Full Name"
                      onChange={handleChange}
                      value={formData.name}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="email"
                      name="email"
                      className="formControl"
                      id=""
                      placeholder="Email Address"
                      onChange={handleChange}
                      value={formData.email}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="number"
                      name="num"
                      className="formControl"
                      id="number"
                      placeholder="Mobile Number"
                      minLength={10}
                      maxLength={10}
                      onChange={handleChange}
                      value={formData.num}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="" className="form-label">
                      Upload CV
                    </label>{" "}
                    <br />
                    <div className="file-drop-area">
                      <input
                        className="file-input"
                        type="file"
                        name="file"
                        id="file"
                        multiple
                      />
                    </div>
                  </div>
                  <div className="col-12 btn-container">
                    {isFormValid ? (
                      <button
                        className="btn submit-btn"
                        title="Please re-check before apply"
                        type="submit"
                      >
                        Apply Now
                      </button>
                    ) : (
                      <button
                        className="btn submit-btn btn-visibility"
                        title="Please fill all the fields"
                        type="submit"
                        disabled
                      >
                        Apply Now
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <div className="submitted-msg">
                  <div className="submitted-msg-box">
                    <h3 className="careerHead">
                      Thank you for your application!
                    </h3>
                    <p className="disc">
                      Dear <b>{formData.name}</b>, we appreciate your interest
                      in joining our team. Due to technical difficulties, we are
                      currently experiencing issues with the form submission.
                      Therefore, we kindly request you to send your CV and other
                      relevant documents to
                      <a href="mailto:careers@growthsec.com">
                        {" "}
                        careers@growthsec.com
                      </a>
                      . Our team will review your application and get back to
                      you as soon as possible.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default CareerForm;
