import React from 'react';
import ReactDOM from 'react-dom/client';
// *** Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './App.scss';

// *** Responsiveness Styles
// import './ResponsiveCSS/MobileSize.scss'
// import './ResponsiveCSS/TabletSize.scss'
// import './ResponsiveCSS/SmallScreen.scss'

import {BrowserRouter, Routes, Route} from 'react-router-dom';


// *** Defaults
import NoPage from './Component/NoPage/NoPage';
import StatusPage from './Component/StatusPage/StatusPage';
import Layout from './Defaults/Layout';
import CookiePolicy from './Component/Cookies/Cookies';
import Cookie from './Component/Cookies/Cookies';
import SessionExpired from './Component/SessionExpired/SessionExpired';

// *** Components 
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Careers from './Component/Careers/Careers';
import CareerForm from './Component/Careers/CareerForm';
import Business from './Component/Business/Business';
import Research from './Component/Research/Research';
import Feedback from './Component/Feedback/Feedback';
import Bond from './Component/Bonds/Bonds'
import MutualFunds from './Component/MutualFunds/MutualFunds';
import Equity from './Component/Equity/Equity';
import CurrencyTrading from './Component/CurrencyTrading/CurrencyTrading';
import DerivativeTrading from './Component/DerivativeTrading/DerivativeTrading'
import CommodityTrade from './Component/CommodityTrading/CommodityTrading'
import AlgorithmicTrading from './Component/AlgorithmicTrading/AlgorithmicTrading';
import HFT from './Component/HighFrequencyTrading/HFT';
import IPO from './Component/IPO/IPO';
import ETF from './Component/ETF/ETF';
import Depository from './Component/Depository/Depository';
import BankDetails from './Component/BankDetails/BankDetails'
import Knowledge from './Component/Knowledge/Knowledge';
import FAQs from './Component/FAQs/FAQs';
import Voting from './Component/Footers/Voting/eVot';
import FundTransfer from './Component/FundTransfer/FundTransfer';
import Referr from './Component/Refer/Referr';


// *** Blogs
import Blogss from './Component/Blogs/Blogss';
import AI from './Component/Blogs/ArtificialIntelligentTrading'
import AlgoTrade from './Component/Blogs/ReasonToChooseAlgoTrade';
import HFTrade from './Component/Blogs/HFTrade'
import AutoTrade from './Component/Blogs/AutoTrade'
import KnowHFTrade from './Component/Blogs/EverythingKnowHFTrade';
import Ipo from './Component/Blogs/Ipo';
import MutualFundBlog from './Component/Blogs/MutualFunds';


// *** Footer Links
import Terms from './Component/Supports/TermsConditions/Terms';
import RefundPolicy from './Component/Supports/RefundPolicy/RefundPolicy';
import Disclaimer from './Component/Supports/Disclaimer/Disclaimer';
import PrivacyPolicy from './Component/Supports/PrivacyPolicy/PrivacyPolicy';
import BrokerNorms from './Component/Supports/BrokerNorms/BrokerNorms';
import ArbitrationRules from './Component/Supports/ArbitrationRules/ArbitrationRules';
import PolicyProcedures from './Component/Supports/PolicyProcedures/PolicyProcedures';
import Data from './Component/Supports/Data/Data';
import AnnualReturn from './Component/Footers/AnnualReturns/AnnualReturn';
import ClientBankDetails from './Component/Supports/ClientBank/ClientBankDetails';
import DoDont from './Component/Supports/DoDont/DoDont';
import RiskDisclosure from './Component/Supports/RiskDisclosure/RiskDisclosure';
import InvestorCharters from './Component/Footers/InvestorCharter/InevstorCharters';
import CRD from './Component/Supports/ClientRegistrationDocuments/CRD';


// *** Help and Support Desk
import Support from './Component/HelpDesk/Support';
import Contact from './Component/Contact/Contact';
import AccountOpening from './Component/HelpDesk/Relevant/AccountOpening/AccountOpening';
import FutureOptions from './Component/HelpDesk/Relevant/FutureOptions/FutureOptions';
import TradingMarket from './Component/HelpDesk/Relevant/TradingMarkets/TradingMarket';


// *** Tools Containers
import MCQs from './Component/QuizCenter/MCQs';
import GrowthMCQs from './GrowthQuiz/MCQs';
import CalcTab from './Calculators/CalcTabs/CalcTab';
import CalcColl from './Calculators/CalcColl/CalcColl';
import Summary from './Component/Summary/Summary';
import Toast from './Defaults/Toast'
import NewsPage from './Component/News/LatestNews';
// import NewsPage from './Component/News/News';
import FiiDii from './Component/FIIDII/FiiDii';
import MarketView from './Component/MarketView/MarketView';
import StockMarket from './Component/StockNews/StockNews';
import Demo from './Component/Demo/Demo';



// *** Calculators
import CAGRCalculator from './Calculators/CAGR/CAGRCalc';
import CorrelationCalculator from './Calculators/CorrelationCalc/CorrelationCalc';
import DividendCalculator from './Calculators/DividendCalc/DividendCalc';
import EquityRatioCalculator from './Calculators/EquityRatio/EquityRatio';
import GratuityCalculator from './Calculators/Gratuity/Gratuity';
import LumpsumCalculator from './Calculators/Lumpsum/LumpsumCalc';
import MarginCalculator from './Calculators/MarginCalculator/MarginCalc';
import MutualFundCalculator from './Calculators/MFCalculator/MFCalc';
import MovingAverageCalculator from './Calculators/MVCalc/MVCalc';
import OptionPLCalculator from './Calculators/OptionsPLCalc/OptionPLCalc';
import PositionSizeCalculator from './Calculators/PositionSizeCalc/PSCalc';
import PPFCalculator from './Calculators/PPF/PPF';
import RetirementCalculator from './Calculators/Retirement/RetirementCalc';
import ROICalculator from './Calculators/ROICalc/ROICalculator';
import SIPCalculator from './Calculators/SIPCalculator/SIPCalc';
import VolatilityCalculator from './Calculators/VolatilityCalc/VolatilityCalc';
import FutureValue from './Calculators/FutureValue/FVCalculator';
import SIP from './Component/Blogs/SIP';
import GrowthCalculator from './Component/Blogs/GrowthCalculator';


// *** Investors
import Mandatory from './Component/Mandatory/Mandatory';
import Downloads from './Component/Footers/Downloads/Downloads';
import InvestorCharter from './Component/Footers/InvestorCharter';


// *** SiteMaps SEOs
import HTMLSiteMap from './Component/SiteMaps/htmlsiteMap';
import XMLSiteMap from './Component/SiteMaps/xmlsiteMap';
import StepProcess from './Component/StepProcess/StepProcess';


// *** Issue Data
import IssueField from './Backend/TicketData/IssueFiled';
import IssueLogin from './Backend/TicketData/IssuesLogin';
import ClientForm from './Backend/TicketBooking/TicketForm';
import Tickets from './Backend/Tickets/Tickets';






export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<Home />} />

          {/* About */}
          <Route path='about-us' element={<About />} />
          <Route path='blogs' element={<Blogss />} />
          <Route path='careers' element={<Careers />} />
          <Route path='career-form' element={<CareerForm />} />
          <Route path='research' element={<Research />} />
          <Route path='business' element={<Business />} />
          <Route path='feedback' element={<Feedback />} />

          {/* Produscts */}
          <Route path='bonds-sgb' element={<Bond />} />
          <Route path='equity-trading' element={<Equity />} />
          <Route path='currency-trading' element={<CurrencyTrading />} />
          <Route path='algorithmic-trading' element={<AlgorithmicTrading />} />
          <Route path='derivative-trading' element={<DerivativeTrading />} />
          <Route path='commodity-trading' element={<CommodityTrade />} />
          <Route path='high-frequency-trading' element={<HFT />} />
          <Route path='mutual-fund' element={<MutualFunds />} />

          {/* services */}
          <Route path='support' element={<Support />} />
          <Route path='knowledgeCenter' element={<Knowledge />} />
          <Route path='bankdetails' element={<BankDetails />} />
          <Route path='initial-public-offering-ipo' element={<IPO />} />
          <Route path='exchange-traded-fund' element={<ETF />} />
          <Route path='depository' element={<Depository />} />
          
          {/* Tools */}
          <Route path='quizCenter' element={<MCQs />} />
          <Route path='growthQuiz' element={<GrowthMCQs />} />
          <Route path='calctab' element={<CalcTab />} />
          <Route path='calculators' element={<CalcColl />} />
          <Route path='summary' element={<Summary />} />
          <Route path='app-toast' element={<Toast />} />
          <Route path='news' element={<NewsPage />} />
          <Route path='fiidii' element={<FiiDii />} />
          <Route path='market-view' element={<MarketView />} />
          <Route path='refer' element={<Referr />} />
          <Route path='demo' element={<Demo />} />
          {/* <Route path='stock-news' element={<StockMarket />} /> */}

          {/* Supports */}
          <Route path='account-opening' element={<AccountOpening />} />
          <Route path='trading-markets' element={<TradingMarket />} />
          <Route path='future-options' element={<FutureOptions />} />
          <Route path='fund-transfer' element={<FundTransfer />} />
          
          {/* Calculators */}
          <Route path='cagr-calculator' element={<CAGRCalculator />} />
          <Route path='correlation-calculator' element={<CorrelationCalculator />} />
          <Route path='dividend-calculator' element={<DividendCalculator />} />
          <Route path='equity_ratio-calculator' element={<EquityRatioCalculator />} />
          <Route path='gratuity-calculator' element={<GratuityCalculator />} />
          <Route path='lumpsum-calculator' element={<LumpsumCalculator />} />
          <Route path='margin-calculator' element={<MarginCalculator />} />
          <Route path='mutual_fund-calculator' element={<MutualFundCalculator />} />
          <Route path='moving_average-calculator' element={<MovingAverageCalculator />} />
          <Route path='option_pl-calculator' element={<OptionPLCalculator />} />
          <Route path='position_size-calculator' element={<PositionSizeCalculator />} />
          <Route path='ppf-calculator' element={<PPFCalculator />} />
          <Route path='retirement-calculator' element={<RetirementCalculator />} />
          <Route path='roi-calculator' element={<ROICalculator />} />
          <Route path='sip-calculator' element={<SIPCalculator />} />
          <Route path='volatility-calculator' element={<VolatilityCalculator />} />
          <Route path='future-value-calculator' element={<FutureValue />} />

          {/* Mendatory */}
          <Route path='mandatory-details' element={<Mandatory />} />

          {/* Blogs */}
          <Route path='artificial-intelligence-trading' element={<AI />} />
          <Route path='blog-algorithmic-trading' element={<AlgoTrade />} />
          <Route path='blog-high-frequency-trading' element={<HFTrade />} />
          <Route path='blog-automated-trading' element={<AutoTrade />} />
          <Route path='more-about-high-frequency-trading' element={<KnowHFTrade />} />
          <Route path='mutual_fund-article' element={<MutualFundBlog />} />
          <Route path='sip-article' element={<SIP />} />
          <Route path='growth_calculator-article' element={<GrowthCalculator />} />
          <Route path='ipo-blog' element={<Ipo />} />

          {/* INVESTORS */}
          <Route path='contact' element={<Contact />} />
          <Route path='faq' element={<FAQs />} />
          <Route path='evoting' element={<Voting />} />
          <Route path='do-dont' element={<DoDont />} />
          <Route path='disclaimer' element={<Disclaimer />} />
          <Route path='investor-complaints-data' element={<Data />} />
          <Route path='client-bank-details' element={<ClientBankDetails />} />
          <Route path='annualreturn' element={<AnnualReturn />} />
          <Route path='investor-charters' element={<InvestorCharters />} />
          <Route path='cdsl-investor-charter' element={<InvestorCharter />} />
          <Route path='downloads' element={<Downloads />} />

          {/* LEGAL */}
          <Route path='risk-disclosure' element={<RiskDisclosure />} />
          <Route path='terms-conditions' element={<Terms />} />
          <Route path='refund-policy' element={<RefundPolicy />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='broker-norms' element={<BrokerNorms />} />
          <Route path='arbitration-rules' element={<ArbitrationRules />} />
          <Route path='policy-procedures' element={<PolicyProcedures />} />
          <Route path='crd' element={<CRD />} />
          
          {/* Issue Data */}
          <Route path='ticketdata' element={<IssueLogin />} />
          <Route path='~!@#$%^&*()_+' element={<IssueField />} />
          <Route path='ticket' element={<ClientForm />} />
          {/* <Route path='tickets' element={<Tickets />} /> */}

          {/* Defaults */}
          <Route path='sitemap' element={<HTMLSiteMap />} />
          <Route path='sitemap.xml' element={<XMLSiteMap />} />
          <Route path='step-by-step' element={<StepProcess />} />
          <Route path='status' element={<StatusPage />} />
          <Route path='cookies' element={<Cookie />} />
          <Route path='cookie-policy' element={<CookiePolicy />} />
          {/* <Route path='session-expired' element={<SessionExpired />} /> */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);