import React, { Component } from "react";
import "./Style.scss";
import Notice from "./Notice";
import Refund from "./Refund";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";

export class RefundPolicy extends Component {
  render() {
    return (
      <>
        <div className="refundpolicy-container">
          <AnimatedSection>
            <Refund />
          </AnimatedSection>
          <AnimatedSection>
            <Notice />
          </AnimatedSection>
        </div>
      </>
    );
  }
}

export default RefundPolicy;
