import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import etf from './Icons/exchange.svg'



export class Header extends Component {
    render() {
        return (
            <>
                <section className="etf_header-section-container">
                    <Container className="etf_header">
                        <Row className="etf_header-rows">
                            <Col lg={7} md={12} sm={12} className='etf-texts'>
                                <div className="texts">
                                    <h2 className="heading">
                                        An exchange-traded fund (ETF) is a basket of securities that trades on an 
                                        exchange just like a stock does.
                                    </h2>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                        Exchange-Traded Fund
                                        </p>
                                    </li>
                                </ul>
                                    <a 
                                        href="https://kyc.growthsec.com" 
                                        className="trade">
                                            Start Now
                                        </a> <i className='fa fa-arrow-right'></i>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='img'>
                                <div className="img">
                                    <img
                                        className='hft_img'
                                        src={etf}
                                        alt="Growth Exchange Traded Fund"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header