import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import difference from './Icons/Difference.svg'

function Difference() {
    return (
        <>
            <section className="differenceSectionContainer">
                <Container className='fiidiidifferenceSection'>
                    <div className="paraTitle">
                        <h1 className="title">
                            Understanding the Difference: FIIs vs. DIIs
                        </h1>
                    </div>
                    <Row className='differenceRows'>
                        <Col lg={6} md={12} sm={12} xs={12} className='differenceTxt'>
                            <div className="differenceContent">
                                <p>
                                    Foreign Institutional Investors (FIIs) and Domestic Institutional Investors (DIIs) are distinct in several ways. It's crucial to grasp these differences to comprehend their roles in the Indian financial landscape effectively.
                                </p>
                                <h3>Key Differences:</h3>
                                <ul>
                                    <li>
                                        <strong>Origin:</strong> FIIs are foreign entities, such as foreign mutual funds and pension funds, investing in Indian markets, whereas DIIs are domestic entities, including mutual funds and insurance companies.
                                    </li>
                                    <li>
                                        <strong>Investment Source:</strong> FIIs typically invest funds originating from abroad, while DIIs use domestic funds collected from retail investors and premium income.
                                    </li>
                                    <li>
                                        <strong>Regulatory Framework:</strong> FIIs are subject to foreign investment regulations set by the Securities and Exchange Board of India (SEBI) and the Reserve Bank of India (RBI). DIIs, on the other hand, follow domestic investment regulations.
                                    </li>
                                    <li>
                                        <strong>Investment Focus:</strong> FIIs often have a more short-term and speculative approach to investments, while DIIs tend to have a longer-term investment horizon, focusing on stability and income generation.
                                    </li>
                                    <li>
                                        <strong>Impact on Markets:</strong> FIIs can have a significant impact on market volatility due to their higher trading volumes and quick reactions to global events. DIIs contribute to market stability by providing consistent domestic capital.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12} className='differenceImg'>
                            <img src={difference} alt="Growth Illustration" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Difference;
