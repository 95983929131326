import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import office from './Icons/office.svg'


export class Hiring extends Component {
    render() {
        return (
            <>
                <section className="hiring_section-container">
                    <Container className="hiring_section">
                        <div className="paraTitle">
                            <h1 className="title">
                                <span>Growth</span>: The Platform for <span>Financial Market Job</span> Aspirants
                            </h1>
                        </div>
                        <Row className="hiring_section-rows">
                            <Col lg={6} md={12} sm={12} className="hiring_section-imgs">
                                <div className="img">
                                    <img
                                        src={office}
                                        alt="Growth office illustration"
                                        className="hiring-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="hiring_section-texts">
                                <div className="text">
                                    <p className="disc">
                                        Growth is a leading player in the capital financial market industry, offering job opportunities to aspirants looking to make a career in this dynamic and fast-paced field. The company provides a platform for individuals to gain valuable experience, knowledge and advance their careers in a challenging and exciting environment.
                                    </p>
                                    <h4 className="heading">
                                        Career Opportunities at Growth
                                    </h4>
                                    <p className="disc">
                                        Growth  is committed to providing job opportunities to aspiring capital financial market professionals and offers a wide range of positions to help individuals build their careers. Whether you are just starting or looking to take your career to the next level, Growth has something to offer.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Hiring