import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Dos and Don’ts for Investor
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Guidance</p>
      <ul className="lists">
        <li className="list">
          Always deal with a SEBI registered Depository Participant for opening
          a demat account.
        </li>
        <li className="list">
          Read all the documents carefully before signing them.
        </li>
        <li className="list">
          Before granting Power of attorney to operate your demat account to an
          intermediary like Stock Broker, Portfolio Management Services (PMS)
          etc., carefully examine the scope and implications of powers being
          granted.
        </li>
        <li className="list">
          Always make payments to registered intermediary using banking
          channels. No payment should be made in name of employee of
          intermediary.
        </li>
        <li className="list">
          Accept the Delivery Instruction Slip (DIS) book from your DP only
          (pre-printed with a serial number along with your Client ID) and keep
          it in safe custody and do not sign or issue blank or partially filled
          DIS slips.
          <br /> Always mention the details like ISIN, number of securities
          accurately. In case of any queries, please contact your DP or broker
          and it should be signed by all demat account holders. Strike out any
          blank space on the slip and Cancellations or corrections on the DIS
          should be initialed or signed by all the account holder(s).
          <br /> Do not leave your instruction slip book with anyone else. Do
          not sign blank DIS as it is equivalent to a bearer cheque.
        </li>
        <li className="list">
          Inform any change in your Personal Information (for example address or
          Bank Account details, email ID, Mobile number) linked to your demat
          account in the prescribed format and obtain confirmation of updation
          in system.
        </li>
        <li className="list">
          Mention your Mobile Number and email ID in account opening form to
          receive SMS alerts and regular updates directly from depository.
        </li>
        <li className="list">
          Always ensure that the mobile number and email ID linked to your demat
          account are the same as provided at the time of account
          opening/updation.
        </li>
        <li className="list"></li>
        <li className="list">
          Do not share password of your online trading and demat account with
          anyone.
        </li>
        <li className="list">
          Do not share One Time Password (OTP) received from banks, brokers,
          etc. These are meant to be used by you only.
        </li>
        <li className="list">
          Do not share login credentials of e-facilities provided by the
          depositories such as e-DIS/demat gateway, SPEED-e/easiest etc. with
          anyone else.
        </li>
        <li className="list">
          Demat is mandatory for any transfer of securities of Listed public
          limited companies with few exceptions.
        </li>
        <li className="list">
          If you have any grievance in respect of your demat account, please
          write to designated email IDs of depositories or you may lodge the
          same with SEBI online at{" "}
          <a
            href="https://scores.sebi.gov.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://scores.sebi.gov.in/
          </a>
        </li>
        <li className="list">
          Keep a record of documents signed, DIS issued and account statements
          received.
        </li>
        <li className="list">
          As Investors you are required to verify the transaction statement
          carefully for all debits and credits in your account. In case of any
          unauthorized debit or credit, inform the DP or your respective
          Depository.
        </li>
        <li className="list">
          Appoint a nominee to facilitate your heirs in obtaining the securities
          in your demat account, on completion of the necessary procedures.
        </li>
        <li className="list">
          Register for Depository's internet based facility or download mobile
          app of the depository to monitor your holdings.
        </li>
        <li className="list">
          Ensure that, both, your holding and transaction statements are
          received periodically as instructed to your DP. You are entitled to
          receive a transaction statement every month if you have any
          transactions.
        </li>
        <li className="list">
          Do not follow herd mentality for investments. Seek expert and
          professional advice for your investments.
        </li>
        <li className="list">Beware of assured/fixed returns.</li>
      </ul>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const DosandDontsforInvestor = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Dos and Don'ts for Investor{" "}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default DosandDontsforInvestor;
