import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import options from './Icons/why.svg'




export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container hft_why">
                    <Container className='why_section'>
                        <div className="paraTitle">
                        <h1 className="title">
                            Why should you join <span>Growth</span> for <span>High-frequency trading</span>?
                        </h1>
                        </div>
                        <Row className='why_section-rows'>
                            <Col lg={7} md={12} sm={12} className='texts'>
                                <div className="why-texts">
                                    <p className="disc">
                                        High-frequency trading (HFT) has rapidly grown andand essential part of the financial industry in a couple of years, offering numerous advantages that has transformed how the market operates. Here are some reasons why HFT is essential:
                                    </p>
                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey={false} flush>
                                            <Accordion.Item eventKey="">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Increased Liquidity
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT adds to the market's overall liquidity by executing trades faster and more efficiently, making it straightforward for investors to buy and sell securities. This increased liquidity helps to reduce market inefficiencies and provides more accurate pricing.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Improved Market Efficiency
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT helps reduce inefficiencies by providing more accurate pricing, benefiting investors. This improved market efficiency leads to cost savings, as high-volume trade’s result in lower transaction fees.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Better Price Discovery
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT allows real-time price discovery, providing investors with up-to-date information on market prices. This helps to reduce price manipulation and improve market fairness.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Algorithmic Trading
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT utilizes complex algorithms to analyze market data and execute trades, which can lead to faster and more informed trading decisions.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <p className="disc last_para">
                                        Overall, HFT has brought significant benefits to the financial industry, helping to increase liquidity, reduce inefficiencies, improve market efficiency, and provide better price discovery. HFT has transformed that how the financial industry operates, and its importance cannot be ignored.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='imgs'>
                                <div className="img-why">
                                    <img
                                        src={options}
                                        alt="Growth options illustration"
                                        className="why-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why