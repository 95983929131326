import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const CAGRCalculator = () => {
  const [futureValue, setFutureValue] = useState(""); 
  const [presentValue, setPresentValue] = useState(""); 
  const [timePeriod, setTimePeriod] = useState(""); 
  const [cagr, setCAGR] = useState(""); 

  const calculateCAGR = () => {
    const FV = parseFloat(futureValue); 
    const PV = parseFloat(presentValue); 
    const n = parseFloat(timePeriod);

    const CAGR = Math.pow(FV / PV, 1 / n) - 1;
    setCAGR((CAGR * 100).toFixed(2)); 
  };

  // JSX code for the CAGR calculator component
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || CAGR Calculator || Compound Annual Growth Rate
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className="allCalcScss">
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className="fa fa-home" /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className="fa fa-calculator" />{" "}
              <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>CAGR</span>
            </li>
          </ul>
          <div className="cagr-calculator calcBody">
            <div className="calculator-container">
              <h2>CAGR</h2>
              <div className="input-group">
                <label>Future Value:</label>
                <input
                  type="number"
                  value={futureValue}
                  onChange={(e) => setFutureValue(e.target.value)}
                  title="Final investment"
                />
              </div>
              <div className="input-group">
                <label>Present Value:</label>
                <input
                  type="number"
                  value={presentValue}
                  onChange={(e) => setPresentValue(e.target.value)}
                  title="Initial investment"
                />
              </div>
              <div className="input-group">
                <label>Time Period (years):</label>
                <input
                  type="number"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  title="Duration of investment"
                />
              </div>
              <button onClick={calculateCAGR}>Calculate</button>
              {cagr && (
                <div className="result">
                  <p>CAGR: {cagr}%</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  CAGR is an acronym for Compounded Annual Growth Rate commonly
                  used in determining how well a business is performing in the
                  fiercely competitive market. It represents the growth of an
                  organisation, and you can easily make out the growth rate, or
                  the lack of it, using a CAGR calculator.
                </p>
                <p className="disc">
                  Such a calculator is easily available online, but
                  user-friendliness is important while calculating CAGR returns.
                  Growth's CAGR calculator brings you an organized and accurate
                  approach to calculate your business’ CAGR.
                </p>
              </div>
              <div className="formulaSec">
                <h2 className="title">How to Calculate CAGR?</h2>
                <p className="disc">
                  The CAGR formula to calculate the investments is represented
                  by:
                </p>
                <h6 className="formula">CAGR = (FV / PV) 1 / n – 1</h6>
                <p className="disc">
                  It is a mathematical calculation that determines the real-time
                  CAGR for any organization. The values stand for the following:
                </p>
                <ul className="lists">
                  <li className="list">
                    <span>FV-</span> Future Value
                  </li>
                  <li className="list">
                    <span>PV-</span> Present Value
                  </li>
                  <li className="list">
                    <span>N-</span> Time period in years
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CAGRCalculator;
