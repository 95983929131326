import React, { Component } from 'react'
import {
    Col,
    Container,
    Row,
    Accordion
} from 'react-bootstrap'
import grow from './Icons/grow.svg'


export class About extends Component {
    render() {
        return (
            <>
                <section className="about_section-container mutual-funds-about">
                    <Container className="about_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            <span>Mutual Funds</span>- Stay ahead always with us!
                        </h1>
                        </div>
                        <h6 className="subtitle">
                            Growth is the ideal choice for those looking to invest in mutual funds. With a focus on growth and risk management, a wide range of investment options, tailored investment solutions, and comprehensive support and reporting, Growth Securities is the perfect partner for your financial future.
                        </h6>
                        <Row className="about_section-rows">
                            <Col lg={6} md={12} sm={12} className="about_section-text">
                                <div className="texts">
                                    <p className="disc">
                                        Growth is a leading investment advisory firm that offers a comprehensive range of mutual fund investment options to clients. With a focus on growth and risk management, we are the ideal choice for those looking to maximize their returns and secure their financial future.
                                    </p>

                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey={false} flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Expertise and Experience
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth is AMFI Registered Mutual Fund Advisor (ARMFA) service provider that led by a team of experienced investment professionals. They have a deep understanding of the markets and a proven track record of success. Our team uses cutting-edge investment strategies and a data-driven approach to identify the best mutual fund options for our clients.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Wide Range of Investment Options
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth offers a diverse range of mutual fund options, including equity funds, debt funds, balanced funds, and more. Our clients have access to a wide range of investment options that are tailored to meet their individual financial goals, risk tolerance, and investment horizons.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Tailored Investment Solutions
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        We understand that every client is unique, and we offer tailored investment solutions to meet their individual needs. Our investment professionals will work closely with clients to understand their financial goals, risk tolerance, and investment horizons, and develop a personalized investment strategy to help them achieve their financial objectives.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Comprehensive Support and Reporting
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        At Growth, we provide comprehensive support and reporting to our clients. Our clients receive regular updates on the performance of their investments, and our investment professionals are available to answer any questions or provide additional information as needed. We believe in complete transparency and open communication, and we are committed to providing our clients with the information and support they need to make informed investment decisions.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Higher Potential Returns
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        One of the primary benefits of choosing our platform for your mutual fund portfolio is the potential for higher returns. Growth stocks are often associated with young, fast-growing companies that are expected to grow at a faster rate than the overall market. As a result, they offer the potential for higher returns over the long term.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Focus on Long-Term Growth
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Another benefit of growth securities is the focus on long-term growth. While some investment options may offer short-term gains, growth securities are designed for investors who are willing to wait for their investments to grow over time. This makes them a great option for individuals who are saving for a long-term goal, such as retirement.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Lower Volatility
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        In addition to higher potential returns, growth securities also tend to be less volatile than other types of investments. This means that their value is less likely to fluctuate rapidly, providing a more stable investment option for those who are risk-averse.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Diversification
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Finally, growth securities offer a great opportunity for diversification within your portfolio. By investing in a variety of growth stocks, you can help to mitigate the risk of any single stock under-performing, which can help to protect your overall investment.                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="about_section-img">
                                <div className="imgs">
                                    <img
                                        src={grow}
                                        alt="Growth grow illustration"
                                        className="grow-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default About