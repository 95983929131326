import React from "react";
import "./Style.scss";
import { Helmet } from "react-helmet";
import Header from "./Header";
import WhatIs from "./WhatIs";
import How from "./How";
import Why from "./Why";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

const IPO = () => {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || IPO || What Is IPO and How It Works || Latest &
          New IPOs List | IPO Watch 2023 Calendar
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <WhatIs />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
        <AnimatedSection>
          <How />
        </AnimatedSection>
      </div>
    </>
  );
};
export default IPO;
