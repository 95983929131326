import React from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import accounts from '../Icons/accounts.svg'

class Account extends React.Component {
    render() {
        return (
            <>
                <div className='account_section-container'>
                    <Container className='account'>
                        <h2 className="subtitle">
                            Online Account Opening
                        </h2>
                        <Row className='account-rows'>
                            <Col lg={4} md={12} sm={12} className='account-img'>
                                <img
                                    className=''
                                    src={accounts}
                                    alt='Growth accounts illustration'
                                />
                            </Col>
                            <Col lg={8} md={12} sm={12} className='account-text term-text'>
                                <div className="account-texts">
                                    <p className="disc">
                                        Online account opening using AADHAAR is currently available only for 
                                        residents of India opening accounts for an individual (HUFs, corporate 
                                        bodies, NRIs, etc., not eligible).
                                    </p>
                                    <p className="disc">
                                        When opening an account online using AADHAAR, data will be fetched from 
                                        the KYC database and cannot be edited.
                                    </p>
                                    <p className="disc">
                                        Pick up of required documents related to the account opening procedure 
                                        is subject to availability of our representatives, given at any particular 
                                        time and location.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Account