import React from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import Header from "./Header";
import WhatIs from "./WhatIs";
import Why from "./Why";
import KeyPoints from "./KeyPoints";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function HFT() {
  return (
    <>
      <Helmet>
        <title>
          Growth Securities || High Frequency Trading || Everything you need to
          know about hft or high frequency trading || What Is High-Frequency
          Trading (HFT)? How It Works and Example
        </title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <WhatIs />
        </AnimatedSection>
        <AnimatedSection>
          <Why />
        </AnimatedSection>
        <AnimatedSection>
          <KeyPoints />
        </AnimatedSection>
      </div>
    </>
  );
}

export default HFT;
