import React from 'react'
import {
  Row,
  Container,
  Col
} from 'react-bootstrap'
import growth from './Icons/about.svg'


const Header = () => {
  return (
    <>
      <section className="about_page-container">
        <Container className='section_header'>
          <Row className='section_header-rows'>
            <Col xl={7} md={12} sm={12} className='section_header-texts'>
              <div className="texts">
                <h3 className='headerTitle'>
                  The stock market is one of the most lucrative avenues of making money, as it provides better returns than other avenues.
                </h3>
                <ul className="breadcrumb-links">
                  <li className="breadcrumb-link">
                    <i className='fa fa-home' /> <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-link sec">
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li className="breadcrumb-link">
                    <p className="link">
                      About Us
                    </p>
                  </li>
                </ul>
                <a className="trade" href="https://kyc.growthsec.com">Start Invest</a>
                <i className='fa fa-arrow-right'></i>
              </div>
            </Col>
            <Col xl={5} md={12} sm={12} className='section_header-img'>
              <div className="imgs">
                <img
                  className='about_img'
                  src={growth}
                  alt='About Section of Growth Securities'
                  width='100%'
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}


export default Header;