import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import derivative from './Icons/derivative.svg'




export class DerivativeTrade extends Component {
    render() {
        return (
            <>
                <section className="derivative_section-container">
                    <Container className="derivative_section">
                        <div className="paraTitle">
                        <h1 className="title">
                            What is <span>Derivative Trading</span>?
                        </h1>
                        </div>
                        <Row className="derivative_section-rows">
                            <Col lg={7} md={12} sm={12} className='derivative_texts'>
                                <div className="texts">
                                    <p className="disc">
                                        Derivative trading can be an excellent way to profit from changes in the value of underlying assets such as stocks, bonds, commodities, and currencies etc. However, with so many options available, it can take time to determine the right choice for your investment needs. This is where Growth come in. A derivative is set between two or more parties that can trade on an exchange or over-the-counter (OTC).
                                    </p>
                                    <p className="disc">
                                        A derivative is a complex type of financial security that is set between two or more parties. Traders use derivatives to access specific markets and trade different assets. Typically, derivatives are considered a form of advanced investing. The most common underlying assets for derivatives are stocks, bonds, commodities, currencies, interest rates, and market indexes. Contract values depend on changes in the prices of the underlying asset.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className="derivative_img">
                                <div className="img">
                                    <img
                                        src={derivative}
                                        alt="Growth derivative illustration"
                                        className="derivative"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default DerivativeTrade