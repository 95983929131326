import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import algotrade from './Icons/algotrade.jpg'

export class Header extends Component {
    render() {
        return (
            <>
                <section className="algo_header-section-container">
                    <Container className="algo_header-section">
                        <Row className="algo_header-section-rows">
                            <Col lg={7} md={12} sm={12} className='algo_header-section-texts'>
                                <div className="texts">
                                    <h4 className="headerTitle">
                                        Algorithmic trading allows traders to perform high-frequency trades.
                                        The speed of high-frequency trades used to measure to milliseconds.
                                        Today, they may be measured in microseconds or nanoseconds (billionths
                                        of a second).
                                    </h4>
                                    <ul className="breadcrumb-links">
                                    <li className="breadcrumb-link">
                                       <i className='fa fa-home' /> <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-link">
                                    <i className="fa fa-angle-double-right"></i>
                                    </li>
                                    <li className="breadcrumb-link">
                                        <p className="link">
                                            Algorithmic Trading
                                        </p>
                                    </li>
                                </ul>
                                    <a
                                        href="https://kyc.growthsec.com"
                                        className="trade"
                                    >
                                        Start Algo Trading
                                    </a><i className="fa fa-arrow-right"></i>
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='algo_header-section-img'>
                                <div className="img">
                                    <img
                                        className='algo-trade'
                                        src={algotrade}
                                        alt="Growth Algorithm Trading Img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Header;