import React from "react";
import SearchBox from "./SearchBox";
import "./Style.scss";

const SearchContainer = () => {
  return (
    <div>
      <SearchBox />
    </div>
  );
};

export default SearchContainer;
