import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'react-bootstrap'
import frequency from './Icons/frequency.svg'


export class KeyPoint extends Component {
    render() {
        return (
            <>
                <section className="hft_keyPoint-section-container">
                    <Container className="hft_keyPoint">
                        <div className="paraTitle">
                        <h1 className="title">
                            What makes <span>High Frequency Trading</span> special?
                        </h1>
                        </div>
                        <Row className="hft_keyPoint-rows">
                            <Col lg={7} md={12} sm={12} className='hft_keyPoint-img'>
                                <div className="img">
                                    <img
                                        className='hft_img'
                                        src={frequency}
                                        alt="Growth frequency illustration"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={5} md={12} sm={12} className='hft_keyPoint-texts'>
                                <div className="texts">
                                    <p className="heading">
                                        The Securities and Exchange Commission (SEC) has no formal definition of HFT but attributes certain features to it:
                                    </p>
                                    <div className="accordion-texts">
                                        <Accordion defaultActiveKey={false}flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Speed
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT strategies rely on ultra-fast computers and networks to execute trades in milliseconds or even microseconds. This allows traders to take advantage of small price movements in the market and make profits on a large number of trades.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Volume
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT traders typically execute a large number of trades in a short period of time, often in the millions or even billions per day. This high volume of trades can create liquidity in the market and help to ensure that buyers and sellers can always find a counterparty for their trades.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Complexity
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT strategies use complex algorithms and mathematical models to identify trading opportunities and execute trades automatically. These models may take into account a wide range of factors, such as market data, news events, and historical trends.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Risk
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        HFT strategies are highly dependent on technology and can be vulnerable to technical glitches or malfunctions. In addition, the high speed and volume of trades can increase the risk of market instability or flash crashes.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Regulation
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        The use of HFT has raised concerns about market fairness and transparency, and regulators have implemented new rules and guidelines to address these concerns. For example, exchanges may impose fees or speed bumps to limit the advantage of HFT traders.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default KeyPoint