import React, { useState } from "react";
import webpageContent from "./Data.json";
import "./Style.scss";

const SearchFilter = () => {
  // State variables
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Handle search functionality
  const handleSearch = () => {
    const results = webpageContent.filter((page) =>
      page.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  };

  // Abbreviate the query to match shortened titles
  const abbreviateQuery = (query) => {
    const words = query.split(" ");
    return words.map((word) => word[0]).join("");
  };
  
  // Handle input change in the search bar
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    // Filter the webpage content based on the search query in titles only
    const matchedPages = webpageContent.filter((page) =>
      page.title.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResults(matchedPages);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    const matchedPage = webpageContent.find(
      (page) => page.title === suggestion
    );
    if (matchedPage) {
      setSearchQuery(suggestion);
      setSuggestions([]);
      setSearchResults([matchedPage]);
    }
  };

  // Handle search icon click
  const handleSearchIconClick = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchQuery("");
    setSearchResults([]);
    setSuggestions([]);
    setSelectedItem(null);
  };

  // Handle click on search result item
  const handleResultItemClick = (result) => {
    setSelectedItem(result);
  };

  // Handle close popup
  const handleClosePopup = () => {
    setIsSearchOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
    <div className="search-filter">
      <div className="search-bar">
        <i
          className="fa fa-search search-iconBig"
          title="Click & enter Tab to search anything"
          onClick={handleSearchIconClick}
        ></i>
        {/* <i className="fa fa-search search-icon small-deviceSearch" onClick={handleSearchIconClick}></i> */}
      </div>

      {/* Render search popup if open */}
      {isSearchOpen && (
        <div className="search-popup">
          <div className="search-popup-content">
            <h4>Uncover, Explore, and Prosper</h4>
            <div className="search-input-container">
              <svg
                className="growth"
                xmlns="http://www.w3.org/2000/svg"
                width="307"
                height="348.993"
                viewBox="0 0 307 348.993"
              >
                <g id="logo_full-shade" opacity="0.319">
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="20.653"
                    cy="20.653"
                    r="20.653"
                    transform="translate(265.695 243.591)"
                    fill="#269ed6"
                  />
                  <ellipse
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="20.653"
                    cy="21.083"
                    rx="20.653"
                    ry="21.083"
                    transform="translate(132.492 220.344)"
                    fill="#269ed6"
                  />
                  <ellipse
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="20.294"
                    cy="21.083"
                    rx="20.294"
                    ry="21.083"
                    transform="translate(0 243.005)"
                    fill="#269ed6"
                  />
                  <ellipse
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="20.294"
                    cy="21.083"
                    rx="20.294"
                    ry="21.083"
                    transform="translate(62.173 140.363)"
                    fill="#269ed6"
                  />
                  <ellipse
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="20.294"
                    cy="21.083"
                    rx="20.294"
                    ry="21.083"
                    transform="translate(133.693)"
                    fill="#269ed6"
                  />
                  <ellipse
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="20.294"
                    cy="21.083"
                    rx="20.294"
                    ry="21.083"
                    transform="translate(189.073 119.28)"
                    fill="#269ed6"
                  />
                  <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M151,506.382,287.684,422.91V257.4l-136.44-83.183L14.324,257.4V423.2Zm-3.2-10.136L35.551,427.978l-7.075-6.346,119.326-18.7Zm-120.856-80.7,3.633-9.944,49.146-80.794,10.613,10.9,49.051,56.22,8.414,3.73Zm112.146-34.375L85.27,319.935,194.652,301l2.294,7.172L151,391.453Zm15.595,115.071V402.928l118.943,21.418ZM276.5,418.226l-11.092-2.677L156.6,396.234l6.513-14.024,43.514-77.382,9.206,11.714L276.5,415.357Zm4.015-9.944-2.486-2.487L220.579,313.05,212.246,299l68.268-34.709ZM276.5,258.263,208.8,293.257l-2.179-3.44L158.892,186.553Zm-76.013,32.7-8.033,4.59L87.374,313.05,151,186.553Zm-63.967-100.3,5.451-1.243L82.593,308.174H72.649L23.12,259.889Zm-115.5,76.2,53.257,51.967v2.533l-6.789,12.526-46.468,74.96Z"
                    transform="translate(2.742 -157.389)"
                    fill="#269ed6"
                  />
                </g>
              </svg>

              <input type="text"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search by title ..."
              />
            </div>

            {/* Display autocomplete suggestions */}
            {suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <i className="fa fa-search suggestion-icon"></i>
                    <span className="suggestion-content">{suggestion}</span>
                  </li>
                ))}
              </ul>
            )}

            {/* Display search results */}
            {searchResults.length > 0 && (
              <ul className="search-popup-results">
                {searchResults.map((result, index) => (
                  <li
                    key={index}
                    className="result-item"
                    onClick={() => handleResultItemClick(result)}
                  >
                    <a href={result.link}>
                      <h3>{result.title}</h3>
                      <p>{result.description}</p>
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <button className="close-button" onClick={handleClosePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default SearchFilter;
