import React from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import Header from "./Header";
import OnlineAC from "./OnlineAC";
import OfflineAC from "./OfflineAC";
import ComplaintProcess from "./ComplaintProcess";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function StepProcess() {
  return (
    <>
      <Helmet>
        <title>Complaints Process | Growth Securities</title>
      </Helmet>
      <div className="wrapper">
        <AnimatedSection>
          <Header />
        </AnimatedSection>
        <AnimatedSection>
          <OnlineAC />
        </AnimatedSection>
        <AnimatedSection>
          <ComplaintProcess />
        </AnimatedSection>
        <AnimatedSection>
          <OfflineAC />
        </AnimatedSection>
      </div>
    </>
  );
}

export default StepProcess;
