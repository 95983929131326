import React from 'react';
import sitemapData from './xmlData.json';
import { Helmet } from 'react-helmet';

const xmlsiteMap = () => {
    const generateXmlSitemap = () => {
        let sitemap = `<?xml version="1.0" encoding="UTF-8"?>\n`;
        sitemap += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

        sitemapData.forEach(page => {
            sitemap += `\t<url>\n`;
            sitemap += `\t\t<loc>${page.url}</loc>\n`;
            sitemap += `\t\t<lastmod>${page.lastmod}</lastmod>\n`;
            sitemap += `\t\t<changefreq>${page.changefreq}</changefreq>\n`;
            sitemap += `\t\t<priority>${page.priority}</priority>\n`;
            sitemap += `\t</url>\n`;
        });

        sitemap += `</urlset>`;
        return sitemap;
    };

    return (
        <>
            <Helmet>
                <title>Growth Securities || XML Site Map</title>
            </Helmet>
            <div style={{ marginTop: "1%" }}>
                <h6>This XML file does not appear to have any style information associated with it. The document tree is shown below.</h6>
                <hr width='100%' />
                <pre>
                    {generateXmlSitemap()}
                </pre>
            </div>
        </>
    );
};

export default xmlsiteMap;
