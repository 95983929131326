import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const PPFCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [rate, setRate] = useState('7.1');
  const [years, setYears] = useState(15);
  const [investmentOption, setInvestmentOption] = useState('yearly');
  const [maturityValue, setMaturityValue] = useState('');
  const [totalInvestment, setTotalInvestment] = useState('');
  const [totalInterest, setTotalInterest] = useState('');

  const handlePrincipalChange = (e) => {
    setPrincipal(e.target.value);
  };

  const handleRateChange = (e) => {
    setRate(e.target.value);
  };

  const handleYearsChange = (e) => {
    setYears(Number(e.target.value));
  };

  const handleInvestmentOptionChange = (e) => {
    setInvestmentOption(e.target.value);
  };

  const calculateMaturityValue = () => {
    const p = Number(principal);
    const r = Number(rate) / 100;
    const n = years;
    let f = 0;
    let totalInv = 0;
    let totalInt = 0;

    if (investmentOption === 'yearly') {
      f = p * (((1 + r) ** n - 1) / r);
      totalInv = p * n;
      totalInt = f - totalInv;

    } else if (investmentOption === 'monthly') {
      const m = n * 12;
      f = p * (((1 + r / 12) ** m - 1) / (r / 12));
      totalInv = p * m;
      totalInt = f - totalInv;

    } else if (investmentOption === 'quarterly') {
      const q = n * 4;
      f = p * (((1 + r / 4) ** q - 1) / (r / 4));
      totalInv = p * q;
      totalInt = f - totalInv;
    }

    // Add comma separation to the values for Indian number system
    setMaturityValue(f.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    setTotalInvestment(totalInv.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    setTotalInterest(totalInt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  };


  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || PPF || Public Provident Fund
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>PPF</span>
            </li>
          </ul>
          <div className="ppfc-calculator calcBody">
            <div className="calculator-container">
              <h2>PPF</h2>
              <div className="input-group">
                <label htmlFor="principal">Principal (₹)</label>
                <input
                  type="number"
                  id="principal"
                  value={principal}
                  onChange={handlePrincipalChange}
                  placeholder="Enter principal amount"
                  min="10000"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="rate">Interest Rate (%)</label>
                <input
                  type="number"
                  id="rate"
                  value={rate}
                  onChange={handleRateChange}
                  placeholder="Enter interest rate"
                  min="0"
                  disabled
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="years">Investment Duration (Years)</label>
                <input
                  type="range"
                  id="years"
                  value={years}
                  onChange={handleYearsChange}
                  min="15"
                  max="50"
                  step="1"
                  required
                />
                <span className="slider-value">{years} years</span>
              </div>
              <div className="input-group">
                <label htmlFor="investmentOption">Investment Option</label>
                <select
                  id="investmentOption"
                  value={investmentOption}
                  onChange={handleInvestmentOptionChange}
                >
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                </select>
              </div>
              <button className="calculate-btn" onClick={calculateMaturityValue}>
                Calculate
              </button>
              {maturityValue && (
                <div className="result">
                  <p>Maturity Value: ₹ {maturityValue}</p>
                  <p>Total Investment: ₹ {totalInvestment}</p>
                  <p>Total Interest: ₹ {totalInterest}</p>
                </div>
              )}
            </div>
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  The first step towards wealth management is accumulating savings. You will find a lot of options for savings accounts; however, look for the ones that guarantee substantial returns risk-free. PPF accounts are one of the most common features which come into the picture. PPF account refers to Public Provident fund account and is meant to invest your valuable capital.
                </p>
                <p className="disc">
                  If you are a new employee or a responsible parent who wishes to save for the future, then PPF is ideal for you. Calculating the interest rates and returns on your PPF account turns a bit difficult. To make these difficult calculations easy, PPF account calculator can be used.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculate PPF?
                </h2>
                <p className="disc">
                  Growth uses a formula to compute the deposited amount, interest, etc. This formula has been given below –
                </p>
                <h6>
                  F = P [(((1+i)^n)-1)/i]
                </h6>
                <p className="disc">
                  This formula represents the following variables –
                </p>
                <ul>
                  <li>
                    <span>I-</span> Rate of interest
                  </li>
                  <li>
                    <span>F-</span> Maturity of PPF
                  </li>
                  <li>
                    <span>N-</span> Total number of years
                  </li>
                  <li>
                    <span>P-</span> Annual installments
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PPFCalculator;
