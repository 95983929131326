import React, { Component } from 'react'
import './Style.scss'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import client from './client.svg'
import Details from './Details'
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'


export class ClientBankDetails extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        Growth Securities || Client Bank Details
                    </title>
                </Helmet>
                <section className="client-details-container">
                    <AnimatedSection>
                        <Container className="client-details">
                            <Row className="client-details-rows">
                                <Col lg={6} md={12} sm={12} className="client-details-texts">
                                    <div className="text">
                                        <p className="disc">
                                            Investors are requested to note that Stock broker Growth is permitted to receive/pay money
                                            from/to investor through designated bank accounts only named as client bank accounts. Stock broker Growth
                                            is also required to disclose these client bank accounts to Stock Exchange.  Hence, you are
                                            requested to use following client bank accounts only for the purpose of dealings in your trading account with
                                            us. The details of these client bank accounts are also displayed by Stock Exchanges on their website under
                                            “Know/ Locate your Stock Broker."
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} sm={12} className="client-details-imgs">
                                    <div className="img">
                                        <img
                                            src={client}
                                            alt="Growth clients illustration"
                                            className="client-img"
                                            width='100%'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </AnimatedSection>
                </section>
                <AnimatedSection>
                    <Details />
                </AnimatedSection>
            </>
        )
    }
}

export default ClientBankDetails