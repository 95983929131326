import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Modal Component
const MyVerticallyCenteredModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Code of Conduct for Participants
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ul className="lists">
        <li className="list">
          A participant shall make all efforts to protect the interests of
          investors.
        </li>
        <li className="list">
          A participant shall always endeavour to-
          <ul className="lists">
            <li className="list">
              render the best possible advice to the clients having regard to
              the client's needs and the environments and his own professional
              skills;
            </li>
            <li className="list">
              ensure that all professional dealings are effected in a prompt,
              effective and efficient manner;
            </li>
            <li className="list">
              inquiries from investors are adequately dealt with;
            </li>
            <li className="list">
              grievances of investors are redressed without any delay.
            </li>
          </ul>
        </li>
        <li className="list">
          3. A participant shall maintain high standards of integrity in all its
          dealings with its clients and other intermediaries, in the conduct of
          its business.
        </li>
        <li className="list">
          4. A participant shall be prompt and diligent in opening of a
          beneficial owner account, dispatch of the dematerialisation request
          form, rematerialisation request form and execution of debit
          instruction slip and in all the other activities undertaken by him on
          behalf of the beneficial owners.
        </li>
        <li className="list">
          5. A participant shall endeavour to resolve all the complaints against
          it or in respect of the activities carried out by it as quickly as
          possible, and not later than one month of receipt.
        </li>
        <li className="list">
          6. A participant shall not increase charges/fees for the services
          rendered without proper advance notice to the beneficial owners.
        </li>
        <li className="list">
          7. A participant shall not indulge in any unfair competition, which is
          likely to harm the interests of other participants or investors or is
          likely to place such other participants in a disadvantageous position
          while competing for or executing any assignment.
        </li>
        <li className="list">
          8. A participant shall not make any exaggerated statement whether oral
          or written to the clients either about its qualifications or
          capability to render certain services or about its achievements in
          regard to services rendered to other clients.
        </li>
        <li className="list">
          9. A participant shall not divulge to other clients, press or any
          other person any information about its clients which has come to its
          knowledge except with the approval/authorisation of the clients or
          when it is required to disclose the information under the requirements
          of any Act, Rules or Regulations.
        </li>
        <li className="list">
          10. A participant shall co-operate with the Board as and when
          required.
        </li>
        <li className="list">
          11. A participant shall maintain the required level of knowledge and
          competency and abide by the provisions of the Act, Rules, Regulations
          and circulars and directions issued by the Board. The participant
          shall also comply with the award of the Ombudsman passed under the
          Securities and Exchange Board of India (Ombudsman) Regulations, 2003.
        </li>
        <li className="list">
          12. A participant shall not make any untrue statement or suppress any
          material fact in any documents, reports, papers or information
          furnished to the Board.
        </li>
        <li className="list">
          13. A participant shall not neglect or fail or refuse to submit to the
          Board or other agencies with which it is registered, such books,
          documents, correspondence, and papers or any part thereof as may be
          demanded/requested from time to time.
        </li>
        <li className="list">
          14. A participant shall ensure that the Board is promptly informed
          about any action, egal proceedings, etc., initiated against it in
          respect of material breach or non-compliance by it, of any law, Rules,
          regulations, directions of the Board or of any other regulatory body.
        </li>
        <li className="list">
          15. A participant shall maintain proper inward system for all types of
          mail received in all forms.
        </li>
        <li className="list">
          16. A participant shall follow the maker-Checker concept in all of its
          activities to ensure the accuracy of the data and as a mechanism to
          check unauthorised transaction.
        </li>
        <li className="list">
          17. A participant shall take adequate and necessary steps to ensure
          that continuity in data and record keeping is maintained and that the
          data or records are not lost or destroyed. It shall also ensure that
          for electronic records and data, up- to-date back up is always
          available with it.
        </li>
        <li className="list">
          18. A participant shall provide adequate freedom and powers to its
          compliance officer for the effective discharge of his duties.
        </li>
        <li className="list">
          A participant shall ensure that it has satisfactory internal control
          procedures in place as well as adequate financial and operational
          capabilities which can be reasonably expected to take care of any
          losses arising due to theft, fraud and other dishonest acts,
          professional misconduct or omissions.
        </li>
        <li className="list">
          20. A participant shall be responsible for the acts or omissions of
          its employees and agents in respect of the conduct of its business. ,
          particularly decision
        </li>
        <li className="list">
          21. A participant shall ensure that the senior management makers have
          access to all relevant information about the business on a timely
          basis.
        </li>
        <li className="list">
          22. A participant shall ensure that good corporate policies and
          corporate governance are in place.
        </li>
      </ul>
    </Modal.Body>
  </Modal>
);

MyVerticallyCenteredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

// Main Component
const CodeofConductParticipant = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ICButton"
        style={{ textDecoration: "underline" }}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Code of Conduct for Participants
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default CodeofConductParticipant;
