import React, { Component } from 'react'
import {
    Col,
    Container,
    Row,
    Accordion
} from 'react-bootstrap'
import think from '../Equity/Icons/choice.svg'


export class Why extends Component {
    render() {
        return (
            <>
                <section className="why_section-container why-ipo">
                    <Container className="why_section">
                        <div className="paraTitle">
                            <h1 className="title">
                                Why <span>Growth</span> is the Ideal Platform for an <span>Initial Public Offering (IPO)</span>
                            </h1>
                        </div>
                        <Row className="why_section-rows">
                            <Col lg={6} md={12} sm={12} className="why_section-texts">
                                <div className="text">
                                    <p className="disc">
                                        An Initial Public Offering (IPO) is a critical event for any company that wants to go public and raise capital by offering/selling shares/securities to the public. Choosing the right platform for an IPO can make a significant difference in the success of the offering and the growth of the company. Growth is an ideal place which provides hassle free platform for through with you can apply for an IPO.
                                    </p>

                                    <div className="accordion-style">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Robust and Experienced Team
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth has a highly experienced and robust team of professionals who understand the IPO process and have a fair and proven record of success. Our team of research analyst, securities lawyers, and market experts have years of experience in helping companies to navigate an IPO process.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Comprehensive Services
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Growth is the ideal platform for any company considering an IPO. With our experienced and robust team, comprehensive services, in-depth market knowledge, broad investor network, flexible and tailored solutions, we are well-equipped to help companies successfully navigate an IPO process and achieve their growth goals.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        In-depth Market Knowledge
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        At Growth, we have extensive knowledge of the financial markets and a deep understanding of the IPO process. Our research analyst and experts stay up-to-date on market trends and regulatory requirements, ensuring our clients are always in the best position to succeed.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Broad Investor Network
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        We have a broad network of institutional and retail investors actively seeking investment opportunities. Our professional team work tirelessly to match our clients with the right investment opportunity.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    <h4 className="head">
                                                        Flexible and Tailored Solutions
                                                    </h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="disc">
                                                        Every company is unique; therefore, every IPO is impressive. At Growth, we offer flexible and tailored solutions to accomplish the specific needs of each of our clients. Our goal is to provide a customized and streamlined process that meets respective company's and individual needs.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="why_section-imgs">
                                <div className="img">
                                    <img
                                        src={think}
                                        alt="Growth think illustration"
                                        className="why-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Why