import React, { useEffect } from 'react';
import './Style.scss';

const SessionExpired = ({ history }) => {
  // Custom countdown logic
  useEffect(() => {
    let timeLeft = 10; // Initial time in seconds

    const timer = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
      } else {
        history.push('/'); // Redirect to the homepage or login page
      }
    }, 100);

    return () => clearInterval(timer);
  }, [history]);

  return (
    <div className="session-expired">
      <div className="session-expired-content">
        <h1>Session Expired</h1>
        <p>Your session has expired due to inactivity.</p>
        <p>Redirecting...</p>
        <div className="action-buttons">
          <button onClick={() => history.push('/status')}>See Status</button>
          <button onClick={() => history.push('/')}>Return to Homepage</button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
