import React, { useEffect, useState } from 'react';
import './Style.scss';

function Scroll() {
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 290);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {showScrollTop && (
        <span className="scrolltop" onClick={scrollToTop}>
          <i className="fa fa-arrow-up" aria-hidden="true" title="Go Top"></i>
        </span>
      )}
    </>
  );
}

export default Scroll;
