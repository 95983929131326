import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import questionsData from "./Data.json";
import QuizTerms from "./QuizTerms";
import QuizEnd from "./QuizEnd";
import AnimatedSection from "../../Defaults/Animations/AnimatedSection";

function MCQs({ totalQuestions }) {
  const [userName, setUserName] = useState("");
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [hearts, setHearts] = useState(5);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(120); // 2 minutes in seconds
  const [timerId, setTimerId] = useState(null);
  const [showCover, setShowCover] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [previousAnswer, setPreviousAnswer] = useState("");
  const [motivationalMessage, setMotivationalMessage] = useState("");

  const questions = questionsData.questions;

  useEffect(() => {
    if (!showCover) {
      shuffleOptions();
      startTimer();
    }
  }, [currentQuestion, showCover]);

  useEffect(() => {
    if (timeRemaining === 0) {
      disableQuiz();
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (
      showResult &&
      selectedOption === questions[currentQuestion].correctAnswer
    ) {
      showMotivationalMessage();
    }
  }, [showResult]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const checkAnswer = () => {
    if (selectedOption === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
      setShowResult(true);
      setSelectedOption("");
      resetTimer();
      setPreviousAnswer("");
      showMotivationalMessage(); // Display motivational message
      setTimeout(nextQuestion, 2000); // Move to the next question after 2 seconds
    } else {
      setHearts(hearts - 1);
      setPreviousAnswer(selectedOption);
      setSelectedOption("");
      setShowPopup(true);
      shuffleOptions();
    }
  };

  const nextQuestion = () => {
    setShowResult(false);
    setCurrentQuestion(currentQuestion + 1);
    setSelectedOption("");
    resetTimer();
    setShowPopup(false);
    setPreviousAnswer("");
  };

  const shuffleOptions = () => {
    const options = [...questions[currentQuestion].options];
    const shuffledOptions = options.sort(() => Math.random() - 0.5);
    setShuffledOptions(shuffledOptions);
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);
    setTimerId(timer);
  };

  const resetTimer = () => {
    clearInterval(timerId);
    setTimeRemaining(120); // Reset timer to 2 minutes
  };

  const disableQuiz = () => {
    clearInterval(timerId);
    setHearts(0);
    setShowPopup(true);
    resetTimer();
    setTimeout(() => {
      window.location.reload(); // Auto-refresh the page after 2 minutes
    }, 5000);
  };

  const handleNameBlur = (e) => {
    const enteredName = e.target.value;
    if (!enteredName) {
      alert("Please enter your name to start the quiz.");
      setShowCover(true);
    } else {
      setUserName(enteredName);
    }
  };

  const handleStartQuiz = () => {
    setShowCover(false);
    // Show the name popup if a name is not submitted
    if (!nameSubmitted) {
      setShowPopup(true);
    }
  };

  const handleNameSubmit = () => {
    // Hide the name popup if a name is entered
    if (userName.trim().length > 0) {
      setShowPopup(false);
      setNameSubmitted(true);
    } else {
      // Show an alert if the user did not enter a name
      alert("Please enter your name to start the quiz.");
    }
  };

  const renderOptions = () => {
    return shuffledOptions.map((option, index) => (
      <div
        key={index}
        className={`option ${selectedOption === option ? "selected" : ""}`}
        onClick={() => handleOptionClick(option)}
      >
        {option}
      </div>
    ));
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setSelectedOption("");
    setShowResult(false);
    setScore(0);
    setHearts(5);
    shuffleOptions();
    setShowPopup(false);
    startTimer();
  };

  const showMotivationalMessage = () => {
    let message = "";
    let score = 0;
    if (score === 1) {
      message = "Hmm! You're off to great start! Keep it up!";
    } else if (score === 2) {
      message = "Awesome! You're on fire!";
    } else if (score === 3) {
      message = "Excellent job! You're unstoppable!";
    } else if (score === 4) {
      message = "On fire! Your knowledge is burning bright!";
    } else if (score === 5) {
      message = "You are killing it! Keep those correct answers coming!";
    } else if (score === 6) {
      message = "Incredible work! You're a quiz master!";
    } else if (score === 7) {
      message = "Unstoppable! Nothing can stand in your way!";
    } else if (score === 8) {
      message = "Genius level unlocked! Your brainpower knows no bounds!";
    } else if (score === 9) {
      message = "Phenomenal performance! You're true quiz genius!";
    } else if (score === 10) {
      message = "Perfection achieved! You're a quiz legend!";
    } else {
      message = "Keep it up! Your knowledge is expanding!";
    }
    setMotivationalMessage(message);
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Quiz Center || Growth Quiz || Stock Market
          Quizzes || Quiz Questions || MCQs || Multiple Choice Questions
        </title>
      </Helmet>
      <AnimatedSection>
        <div className="mcqContainer">
          {showCover ? (
            <div className="cover-page">
              <h1 className="cover-heading">Welcome To Stock Market Quiz!</h1>
              <h2 className="cover-subheading">
                #Before starting the quiz, let's understand the rules
              </h2>
              <ul className="cover-list">
                <li>You will be presented with a total of 150 questions.</li>
                <li>
                  Read each question carefully before selecting an answer.
                </li>
                <li>
                  You have {hearts} {hearts === 1 ? "heart" : "hearts"} to
                  answer correctly. After exhausting all hearts, the quiz will
                  restart (Here hearts mean chances).
                </li>
                <li>Each correct answer will earn you one point.</li>
                <li>
                  Each correct answer will provide you with a motivational
                  message.
                </li>
                <li>Once you start the quiz, the timer won't pause.</li>
                <li>There is a time limit of 2 minutes per question.</li>
              </ul>
              <QuizTerms />
              <button className="start-button" onClick={handleStartQuiz}>
                Start Quiz
              </button>
            </div>
          ) : hearts === 0 ? (
            <QuizEnd
              score={score}
              totalQuestions={totalQuestions}
              restartQuiz={restartQuiz}
            />
          ) : (
            <div>
              <div className="question-container">
                <div className="timer">
                  {Math.floor(timeRemaining / 60)}:
                  {timeRemaining % 60 < 10 ? "0" : ""}
                  {timeRemaining % 60}
                </div>
                <h1 className="heading">Stock Market Quiz</h1>
                <p className="question-number">
                  Question Number: {currentQuestion + 1}
                </p>
                <h2 className="question">
                  {questions[currentQuestion].question}
                </h2>
                <div className="options">{renderOptions()}</div>
                <button
                  className="next-button"
                  onClick={checkAnswer}
                  disabled={!selectedOption || timeRemaining === 0}
                >
                  {currentQuestion === questions.length - 1 ? "Finish" : "Next"}
                </button>
                {showResult && (
                  <div className="result">
                    <p className="correct-answer">Correct answer!</p>
                    {motivationalMessage && (
                      <p className="motivational-message">
                        {motivationalMessage}, {userName}
                      </p>
                    )}
                  </div>
                )}
                {showPopup && (
                  <div className="popup">
                    <div className="popup-content">
                      {!nameSubmitted ? (
                        <>
                          <h2 className="popup-heading">
                            What should I call you?
                          </h2>
                          <input
                            type="text"
                            placeholder="First Name"
                            onBlur={handleNameBlur}
                            onChange={(e) => setUserName(e.target.value)} // Set the user name in state
                          />
                          <br />
                          <button
                            className="popup-button"
                            onClick={handleNameSubmit}
                          >
                            Start Quiz
                          </button>
                        </>
                      ) : (
                        <>
                          <h2 className="popup-heading">Incorrect Answer!</h2>
                          <p className="popup-text">
                            Oops! That was incorrect, {userName}. Please try
                            again!
                          </p>
                          <button
                            className="popup-button"
                            onClick={() => setShowPopup(false)}
                          >
                            OK
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {showPopup && <div className="overlay"></div>}
              <div className="cautionContainer">
                <p className="disc">
                  This quiz is for educational purposes only and does not
                  provide financial advice. Always do your own research and
                  consult with a financial professional before making investment
                  decisions.
                </p>
              </div>
            </div>
          )}
        </div>
      </AnimatedSection>
    </>
  );
}

export default MCQs;
