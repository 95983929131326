import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import ourresearch from './Icons/ourresearch.svg'



export class OurResearch extends Component {
    render() {
        return (
            <>
                <section className="our-research-container">
                    <Container className="our-research">
                        <div className="paraTitle">
                            <h1 className="title">
                                Our <span>Research</span>
                            </h1>
                        </div>
                        <p className="quote">
                            “I started investing at the age of 11. I was late!”
                        </p>
                        <Row className="our-research-rows">
                            <Col lg={6} md={12} sm={12} className="our-research-img">
                                <div className="img-side">
                                    <img
                                        src={ourresearch}
                                        alt="Growth our research illustration"
                                        className="ourresearch-img"
                                        width='100%'
                                    />
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="our-research-texts">
                                <div className="txt-side">
                                    <p className="disc">
                                        Growth offer different trading platforms to suite our clients
                                        customer requirement. We have multiple brokerage schemes available with us. We continuously
                                        strive to empower the retail community by sharing knowledge, providing superior
                                        tools and great support, all at a minimal to zero cost. We have a team of highly
                                        skilled and intellectual staff, specialized in various segments, to help you in any kind.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default OurResearch