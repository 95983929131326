import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';




const GratuityCalculator = () => {
  const [numberOfYears, setNumberOfYears] = useState('');
  const [lastDrawnSalary, setLastDrawnSalary] = useState('');
  const [gratuityAmount, setGratuityAmount] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const calculateGratuity = () => {
    const n = parseFloat(numberOfYears);
    const b = parseFloat(lastDrawnSalary);

    if (b < 10000) {
      setAlertMessage('Minimum salary must be ₹10,000');
      setShowAlert(true);
      setGratuityAmount('Not Eligible');
    } else if (n < 5) {
      setAlertMessage('Minimum years of service must be 5');
      setShowAlert(true);
      setGratuityAmount('Not Eligible');
    } else {
      const G = (n * b * 15) / 26;
      setGratuityAmount(G.toFixed(2));
    }
  };

  const closeAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  return (
    <>
      <Helmet>
        <title>
          Growth Securities || Growth Calculators || Gratuity Calculator
        </title>
      </Helmet>
      <section className="allCalcBack">
        <Container className='allCalcScss'>
          <ul className="breadcrumbLinks">
            <li className="breadcrumb-link">
              <i className='fa fa-home' /> <a href="/">Home</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link">
              <i className='fa fa-calculator' /> <a href="calculators">Calculators</a>
            </li>
            <li className="breadcrumb-link sec">
              <i className="fa fa-angle-double-right"></i>
            </li>
            <li className="breadcrumb-link next">
              <span>Gratuity</span>
            </li>
          </ul>
          <div className="gratuity-calculator calcBody">
            <div className="calculator-container">
              <h2>Gratuity</h2>
              <div className="input-group">
                <label>Years of service:</label>
                <input
                  type="number"
                  value={numberOfYears}
                  onChange={(e) => setNumberOfYears(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>Last Drawn Salary:</label>
                <input
                  type="number"
                  value={lastDrawnSalary}
                  onChange={(e) => setLastDrawnSalary(e.target.value)}
                />
              </div>
              <button onClick={calculateGratuity}>Calculate</button>
              {gratuityAmount && (
                <div className="result">
                  <p>Gratuity Amount: ₹ {gratuityAmount}</p>
                </div>
              )}
            </div>

            {showAlert && (
              <div className="popup">
                <div className="popup-content">
                  <h3>Alert</h3>
                  <p>{alertMessage}</p>
                  <button onClick={closeAlert}>Close</button>
                </div>
              </div>
            )}
            <div className="someCalcTxt">
              <div className="paras">
                <p className="disc">
                  Any employee of a private company that provides EPF facilities is eligible to receive an amount as gratuity after completing 5 years of service. However, an employee is also eligible to get the gratuity before five years if he/she gets injured and disabled in an accident or due to a disease. Groww provides you with a free gratuity calculator which you can use and calculate how much your organization owes you. All gratuity payments are controlled by the laws laid down under the Payment of Gratuity Act 1972. The amount depends on the last drawn salary and the years of service served to the company.
                </p>
              </div>
              <div className="formulaSec">
                <h2>
                  How to calculate Gratuity?
                </h2>
                <p className="disc">
                  Growth uses a proprietary formula that is benchmarked with other similar offerings. The formula used by our Growth gratuity calculator is:
                </p>
                <h6>
                  G = n*b*15/26
                </h6>
                <p className="disc">
                  In the formula, the values are the following:
                </p>
                <ul>
                  <li>
                    <span>N/n</span> The number of years you have worked in the concerned organisation
                  </li>
                  <li>
                    <span>B/b</span> Last drown basic salary Dearness Allowance (DA)
                  </li>
                  <li>
                    <span>G</span> Gratuity amount you are eligible for
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default GratuityCalculator;
