import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Style.scss'
import {
    Container,
    Tab,
    Tabs
} from 'react-bootstrap';
import SIPCalculator from '../SIPCalculator/SIPCalc';
import RetirementCalculator from '../Retirement/RetirementCalc';
import LumpsumCalculator from '../Lumpsum/LumpsumCalc';
import PPFCalculator from '../PPF/PPF';
import CAGRCalculator from '../CAGR/CAGRCalc';
import GratuityCalculator from '../Gratuity/Gratuity';
import ROICalculator from '../ROICalc/ROICalculator.jsx'
import DividendCalculator from '../DividendCalc/DividendCalc';
import CorrelationCalculator from '../CorrelationCalc/CorrelationCalc';
import MarginCalculator from '../MarginCalculator/MarginCalc';
import MovingAverageCalculator from '../MVCalc/MVCalc';
import OptionPLCalculator from '../OptionsPLCalc/OptionPLCalc';
import PositionSizeCalculator from '../PositionSizeCalc/PSCalc';
import VolatilityCalculator from '../VolatilityCalc/VolatilityCalc';


function CalcTab() {
    const [key, setKey] = useState('home');

    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Growth Calculators || Return On Investment || Dividend || SIP || Lumpsum || Correlation || Retirement || PPF || CAGR || Gratuity || Margin || Moving Average
                </title>
            </Helmet>
            <section className="CalcTabContainer">
                <div className="circles">
                    <span className="bigCircle1"></span>
                    <span className="bigCircle2"></span>
                </div>
                <Container className='calcTab'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab className='tabBorder' eventKey="home" title="SIP Calculator">
                            <SIPCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="lumpsum" title="Lumpsum Calculator">
                            <LumpsumCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="ppf" title="PPF Calculator">
                            <PPFCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="cagr" title="CAGR Calculator">
                            <CAGRCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="gratuity" title="Gratuity Calculator">
                            <GratuityCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="roi" title="Return On Investment">
                            <ROICalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="dividend" title="Dividend Calculator">
                            <DividendCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="correlation" title="Correlation Calculator">
                            <CorrelationCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="contact" title="Retirement Calculator">
                            <RetirementCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="margin" title="Margin Calculator">
                            <MarginCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="mvc" title="Moving Average Calculator">
                            <MovingAverageCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="opl" title="Options P&L Calculator">
                            <OptionPLCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="psc" title="Position Size Calculator">
                            <PositionSizeCalculator />
                        </Tab>
                        <Tab className='bordered' eventKey="vc" title="Volatility Calculator">
                            <VolatilityCalculator />
                        </Tab>
                    </Tabs>
                </Container>
            </section>
        </>
    );
}

export default CalcTab;