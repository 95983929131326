import React, { Component } from 'react'
import { 
    Col, 
    Container, 
    Row 
} from 'react-bootstrap'
import mutual from './Icons/mutual.svg'


export class Mutual extends Component {
  render() {
    return (
      <>
        <section className="mutual_detail-container">
            <Container className="mutual_detail">
                <div className="paraTitle">
                <h1 className="title">
                    Introduction of <span>Mutual Funds</span>
                </h1>
                </div>
                <Row className="mutual_detail-rows">
                    <Col lg={7} md={12} sm={12} className="mutual_detail-text">
                        <p className="disc">
                            <strong>Growth</strong> is an AMFI Registered Mutual Fund Advisor (ARMFA). An investor can benefit from our wide expertise in the Capital Market segment. Our trained managers take care to understand the client's requirements, risk profile and the financial goals they wish to achieve and then advise them accordingly. A full-fledged research team combined with a strong information system helps Growth securities Pvt Ltd to maximize returns on your investment..
                        </p>
                        <p className="disc">
                            It’s an attempt to offer financial products that cater to the various investment needs of our esteemed clients. An effort to guide the investors to a product portfolio that best suits their risk -return profile
                        </p>
                        <p className="disc">
                            Start Invest on 
                            <a href='https://kyc.growthsec.com' className='links'> Click</a>
                        </p>
                    </Col>
                    <Col lg={5} md={12} sm={12} className="mutual_detail-img">
                        <img 
                            className='mutual-img'
                            src={mutual} 
                            alt="Growth mutual funds illustration" 
                            width='100%'
                        />
                    </Col>
                </Row>
            </Container>
        </section>
      </>
    )
  }
}

export default Mutual