import React from 'react'
import { Container, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './Style.scss'
import Assamese from './CRD/Assamese.zip'
import Bengali from './CRD/Bengali.zip'
import Gujrati from './CRD/Gujrati.zip'
import Hindi from './CRD/Hindi.zip'
import Kanada from './CRD/Kanada.zip'
import Kashmiri from './CRD/Kashmiri.zip'
import Konkani from './CRD/Konkani.zip'
import Malyalam from './CRD/Malyalam.zip'
import Marathi from './CRD/Marathi.zip'
import Oriya from './CRD/Oriya.zip'
import Punjabi from './CRD/Punjabi.zip'
import Sindhi from './CRD/Sindhi.zip'
import Tamil from './CRD/Tamil.zip'
import Telegu from './CRD/Telegu.zip'
import Urdu from './CRD/Urdu.zip'
import AnimatedSection from '../../../Defaults/Animations/AnimatedSection'



function CRD() {
    return (
        <>
            <Helmet>
                <title>
                    Growth Securities || Client Registration Details
                </title>
            </Helmet>
            <div className="wrapper crdSection">
                <Container className='crdContainer'>
                    <AnimatedSection>
                        <div className="tableItems">
                            <h1>
                                Client Registration Details
                            </h1>
                            <Table striped hover bordered className='crdTable'>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Languages</th>
                                        <th>
                                            Download <i className="fa fa-download"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Assamese</td>
                                        <td>
                                            <a href={Assamese} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Bengali</td>
                                        <td>
                                            <a href={Bengali} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Gujrati</td>
                                        <td>
                                            <a href={Gujrati} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Hindi</td>
                                        <td>
                                            <a href={Hindi} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>Kanada</td>
                                        <td>
                                            <a href={Kanada} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Kashmiri</td>
                                        <td>
                                            <a href={Kashmiri} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>Konkani</td>
                                        <td>
                                            <a href={Konkani} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>Malyalam</td>
                                        <td>
                                            <a href={Malyalam} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9.</td>
                                        <td>Marathi</td>
                                        <td>
                                            <a href={Marathi} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10.</td>
                                        <td>Oriya</td>
                                        <td>
                                            <a href={Oriya} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11.</td>
                                        <td>Punjabi</td>
                                        <td>
                                            <a href={Punjabi} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12.</td>
                                        <td>Sindhi</td>
                                        <td>
                                            <a href={Sindhi} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>13.</td>
                                        <td>Tamil</td>
                                        <td>
                                            <a href={Tamil} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>14.</td>
                                        <td>Telegu</td>
                                        <td>
                                            <a href={Telegu} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>15.</td>
                                        <td>Urdu</td>
                                        <td>
                                            <a href={Urdu} className="DownloadLink">
                                                Download <i className="fa fa-file-pdf-o"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </AnimatedSection>
                </Container>
            </div>
        </>
    )
}

export default CRD;