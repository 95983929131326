import React, { Component } from 'react'
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import core from './Icons/coreValue.svg'


export class Core extends Component {
    render() {
        return (
            <>
                <section className='core_section-container'>
                    <Container className='core_section'>
                        <div className="paraTitle">
                            <h1 className='title'>
                                Our <span className='subtitle'>Core Values</span>
                            </h1>
                        </div>
                        <Row className='core_section-rows'>
                            <Col lg={5} md={12} sm={12} className='core_img'>
                                <img
                                    className='core-image'
                                    src={core}
                                    alt='Options at growth'
                                    width='100%'
                                />
                            </Col>
                            <Col lg={7} md={12} sm={12} className='core_contents'>
                                <ul className='core_lists'>
                                    <li className='core_item'>
                                        <h5 className='subtitle'>COMMITMENT</h5>
                                        <p className='disc'>
                                            We're committed to provide transparent and personalize services to the clients
                                        </p>
                                    </li>
                                    <li className='core_item'>
                                        <h5 className='subtitle'>EFFICIENCY</h5>
                                        <p className='disc'>
                                            We quickly adapt to changes to increase our efficiency and become effective in our approach to
                                            give best solution each time.
                                        </p>
                                    </li>
                                    <li className='core_item'>
                                        <h5 className='subtitle'>INTEGRITY</h5>
                                        <p className='disc'>
                                            We give highest priority to values, principles and honesty which is clearly evident in dealing with clients.
                                        </p>
                                    </li>
                                    <li className='core_item'>
                                        <h5 className='subtitle'>QUALITY</h5>
                                        <p className='disc'>
                                            We continuously thrive to give best and unmatched results for all round satisfaction.
                                        </p>
                                    </li>
                                    <li className='core_item'>
                                        <h5 className='subtitle'>SAFETY</h5>
                                        <p className='disc'>
                                            We try to ensure that the data of clients remains safe and secure while dealing with us.
                                        </p>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Core;