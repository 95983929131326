import React from 'react'
import {
    Col,
    Container,
    Row
} from 'react-bootstrap'
import arrow from './Icons/arrow.svg'



function HelpBoxes() {
    return (
        <>
            <section className="helpBoxes-container">
                <Container className="helpBox">
                    <div className="paraTitle">
                        <h1 className="title">
                            <span>Relevant</span> Topics
                        </h1>
                    </div>
                    <Row className="help-rows">
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <i className="fa fa-user-plus"></i>
                                </div>
                                <a href="/account-opening">
                                    <h4 className="card-title">
                                        Account opening
                                    </h4>
                                    <p className="card-disc">
                                        How to open a new demat account with Growth process, steps, queries
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/></svg>
                                </div>
                                <a href="/future-options">
                                    <h4 className="card-title">
                                        Future & Options (F&O)
                                    </h4>
                                    <p className="card-disc">
                                        How to activate intraday trading (F&O), process, important documents
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <img
                                        src={arrow}
                                        alt='Growth app arrow'
                                        className="helpImg"
                                    />
                                </div>
                                <a href="/app-queries">
                                    <h4 className="card-title">
                                        Related to app problem
                                    </h4>
                                    <p className="card-disc">
                                        Facing problem in trading app. Explore and find solution
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                                </div>
                                <a href="/trading-markets">
                                    <h4 className="card-title">Trading & Markets</h4>
                                    <p className="card-disc">
                                        What about the trading and markets, mutual funds and more
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <i className='fa fa-rupee'></i>
                                </div>
                                <a href="/fund-transfer">
                                    <h4 className="card-title">Fund transfer</h4>
                                    <p className="card-disc">
                                        How to add and  withdraw funds management
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className="boxes">
                            <div className="card-style">
                                <div className="img">
                                    <i className="fa fa-terminal" aria-hidden="true"></i>
                                </div>
                                <a href="/console">
                                    <h4 className="card-title">Console</h4>
                                    <p className="card-disc">
                                        More about IPOs, profile, reports, closing and more
                                    </p>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HelpBoxes